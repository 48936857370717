import { reject, resolve } from 'rsvp';

import Component from '@ember/component';
import Logger from '../../utils/logger';
import Regex from '../../utils/regex';
import addDefaultParams from '../../utils/default-wyapi-params';
import { computed } from '@ember/object';
import domainParser from '../../utils/domain-parser';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  activeHosting: false,
  builderLink: null,
  cart: service(),
  classNames: ['new-yola-form'],
  cms: service(),
  config: service(),
  customerDomain: '',
  customerEmail: '',
  gtmEvents: service(),
  hasErrors: false,
  intl: service(),
  loadingText: '',
  modal: service(),
  selected: 12,
  store: service(),
  taskQueue: service(),
  template: '',
  templateSelected: false,
  customerDomainLowercase: computed('customerDomain', function () {
    return this.get('customerDomain').toLowerCase();
  }),
  isReadyToOrder: computed('customerDomainLowercase', function () {
    const parsedDomain = domainParser(
      this.get('customerDomainLowercase'),
      this.tlds
    );
    return (
      Regex.DOMAIN_REGEX.test(this.get('customerDomainLowercase')) &&
      parsedDomain.parsed
    );
  }),
  init() {
    this._super(...arguments);

    this.set('tlds', this.store.peekAll('tld'));
    if (this.get('options.customerDomain')) {
      this.set('customerDomain', this.get('options.customerDomain'));
    }
  },
  didInsertElement() {
    this.setProperties({
      customerDomain: this.options.customerDomain || ''
    });
  },
  _checkIsIgnum(parsedDomain) {
    const { API_HOST } = this.config.getProperties('API_HOST');
    const adapter = this.store.adapterFor('wyApi');
    const url = new URL(`${API_HOST}/order/checkDomainName`);
    return adapter
      .ajax(addDefaultParams(url.toString()), 'GET', {
        data: {
          domain_name: parsedDomain.domain,
          toplevel: parsedDomain.tld
        }
      })
      .then(data => {
        if (data.success) {
          if (data.domain_status === 'EXIST_IN_SYSTEM') {
            return resolve(true);
          }
          return resolve(false);
        } else {
          return reject(data);
        }
      });
  },
  async _cannotTransfer() {
    tryInvoke(this, 'close');
    this.get('modal').show('info/not-transferable', null, {
      type: 'domena'
    });
  },

  _askRegistration(parsedDomain) {
    tryInvoke(this, 'close');
    this.get('modal').show('info/available-domain', null, {
      type: 'domena',
      onClose: addRegistration => {
        if (addRegistration) {
          this._addRegistration(parsedDomain);
        }
      }
    });
  },

  async _addRegistration(parsedDomain) {
    try {
      await this.get('cart').addItem('registration', {
        name: parsedDomain.domain,
        tld: parsedDomain.tld
      });
      tryInvoke(this, 'close');
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      } else {
        this.set('hasErrors', {
          localeString: 'hosting_order.validation_msg_2'
        });
      }
    }
  },
  async _addTransfer(parsedDomain) {
    try {
      await this.get('cart').addItem('transfer', {
        name: parsedDomain.domain,
        tld: parsedDomain.tld,
        authCode: ''
      });
      tryInvoke(this, 'close');
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      } else {
        this.set('hasErrors', {
          localeString: 'hosting_order.validation_msg_2'
        });
      }
    }
  },

  actions: {
    checkAvailability(event) {
      event.preventDefault();
      if (!this.isReadyToOrder) return;
      this.set('hasErrors', null);
      const store = this.get('store');
      const tlds = store.peekAll('tld');
      const parsedDomain = domainParser(this.get('customerDomain'), tlds);
      if (this.get('domain')) {
        this._cannotTransfer();
        return;
      }

      const domainCheck = store
        .peekAll('domain-check')
        .findBy('tldName', parsedDomain.tld);
      if (!domainCheck) {
        this._cannotTransfer();
        return;
      }
      this.set('loadingText', `${this.intl.t('free_mail.loading')}`);
      this.get('taskQueue')
        .enqueue(
          'domainCheckTask',
          domainCheck,
          `${parsedDomain.domain}.${parsedDomain.tld}`
        )
        .then(
          data => {
            if (!data) {
              this.set('loadingText', '');
              return;
            }

            if (data.get('available')) {
              this._askRegistration(parsedDomain);
            } else {
              // obsazená doména
              this._checkIsIgnum(parsedDomain).then(
                isIgnum => {
                  this.set('loadingText', '');

                  if (isIgnum) {
                    this.set('activeHosting', 'ignum');
                  } else {
                    this._addTransfer(parsedDomain);
                  }
                },
                e => {
                  Logger.error(e);
                  this.set('loadingText', '');
                  this.set('hasErrors', {
                    localeString: 'hosting_order.validation_msg_2'
                  });
                }
              );
            }
          },
          e => {
            Logger.error(e);
            this.set('loadingText', '');
            this.set('hasErrors', {
              localeString: 'hosting_order.validation_msg_2'
            });
          }
        );
    }
  }
});
