/* eslint-disable max-lines */
import Mixin from '@ember/object/mixin';
import { task, allSettled } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { assign } from '@ember/polyfills';
import { isEmpty } from '@ember/utils';
import { htmlSafe } from '@ember/string';
import EmberObject, { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Logger from '../utils/logger';
import AgreeMessage from '../utils/agree-message';
import ShowRegistrationRulesConsent from '../utils/show-registration-rules-consent';

const DEFAULT_ITEM_SOURCE = 'checkout';

export default Mixin.create({
  store: service(),
  cart: service(),
  checkout: service(),
  ideas: service(),
  domainList: service(),
  itemSource: DEFAULT_ITEM_SOURCE,
  currentUser: service(),
  resolvedOrders: null,
  notDeletedItems: alias('checkout.notDeletedItems'),
  agreeMessage: computed('notDeletedItems.@each.type', function () {
    const items = this.get('notDeletedItems');
    return htmlSafe(AgreeMessage(items, this.intl));
  }),
  showRegistrationRulesConsent: computed(
    'notDeletedItems.@each.tldName',
    function () {
      return ShowRegistrationRulesConsent(this.get('notDeletedItems'));
    }
  ),
  callbackFnc(type, params) {
    // types: orderAllFail,orderFail,success,payment,paymentFail
    params = params ? params : {};
    const queryParams = params.queryParams || {};

    const pathItem = this.get(
      `${this.get('itemSource')}.baseItems.firstObject`
    );
    const sourcePath = this.get('checkout').getBackUrlForItem(pathItem);

    queryParams['sourcePath'] = sourcePath;
    switch (type) {
      case 'success':
        this.send('replaceWith', 'thanks', params);
        break;
      case 'payment':
        this.send('replaceWith', 'payment', params);
        break;

      case 'orderFail':
        this.send('replaceWith', 'order-fail', params);
        break;

      case 'paymentFail':
        this.send('replaceWith', 'payment-fail', params);
        break;

      case 'orderAllFail':
        this.send('replaceWith', 'order-all-fail', params);
        break;
    }
  },
  invoice(orders, callback, options = {}) {
    this.set('domainList.forceReload', true);
    if (!callback) {
      callback = (type, params) => this.callbackFnc(type, params);
    }

    // reseni kam se vratit a jestli je clovek z admina
    const pathItem = this.get(
      `${this.get('itemSource')}.baseItems.firstObject`
    );
    const sourcePath = this.get('checkout').getBackUrlForItem(pathItem);

    if (!options.successUrl || !options.failureUrl) {
      options = assign(options, {
        successUrl: `${window.location.origin}/thanks?sourcePath=${sourcePath}`,
        failureUrl: `${window.location.origin}/payment-fail?sourcePath=${sourcePath}`
      });
    }

    const attributes = [
      'domainOrderIds',
      'paid',
      'remaining',
      'totalPrice',
      'vs',
      'paymentMethodId',
      'spayd'
    ];
    const paymentMethodId = this.get('checkout.model.paymentMethod');

    if (!paymentMethodId) {
      Logger.error(Error('Missing payment method'));
      this.send('replaceWith', 'order-all-fail');
      return;
    }

    const paymentMethod = this.get('store').peekRecord(
      'payment_method',
      paymentMethodId
    );

    const invoice = this.get('store').createRecord('invoice', {
      paymentMethodId,

      orders // orderModels, mailHostingOrders, safetyGuardOrders, safetyGuardDeactiovation, transferOrder, renewalOrder
    });

    invoice.save().then(
      inv => {
        // make localstorage copy and persist it
        let data = inv.getProperties(attributes);

        data['key'] = 'current';
        data['channel'] = options.channel;
        data['origInvoiceId'] = inv.get('id');

        let invoiceLocal = this.get('store').peekRecord(
          'invoice-local',
          'current'
        );
        if (isEmpty(invoiceLocal)) {
          invoiceLocal = this.get('store').createRecord('invoice-local', data);
        } else {
          invoiceLocal.setProperties(data);
        }
        invoiceLocal.save();

        let invoiceId = inv.get('id');
        let paid = inv.get('paid');

        orders.forEach(orderModel => {
          let data = { domainName: orderModel.get('domainName') },
            zoneFile = this.get('zoneFile');

          if (zoneFile && zoneFile.constructor.modelName === 'zone-template') {
            data['zoneTemplateId'] = zoneFile.get('id');
          } else if (zoneFile && zoneFile.constructor.modelName === 'zone') {
            data['content'] = zoneFile.get('content');
          }

          if (orderModel.get('type') === 'registration') {
            let zone = this.get('store').createRecord('zone', data);
            zone.save();
          }
        });

        if (paid) {
          // invoice je zaplacena, napr kreditem
          this.get('currentUser').reload();
          callback('success', {
            queryParams: {
              invoice_id: invoiceId,
              payment_method_id: paymentMethodId
            }
          });
        } else if (paymentMethod.get('online')) {
          // online platba
          this.payment(invoiceId, paymentMethodId, callback, options);
        } else {
          // bankovni prevod
          callback('payment', { queryParams: { invoice_id: invoiceId } });
        }
      },
      error => {
        Logger.error(error);
        callback('orderAllFail', {
          queryParams: { errorCodes: 'invoice_fail' }
        });
      }
    );
  },
  payment(invoiceId, paymentMethodId, callback, options = {}) {
    if (!callback) {
      callback = (type, params) => this.callbackFnc(type, params);
    }

    const { channel, vs, amount, successUrl, failureUrl } = options;
    const payment = this.get('store').createRecord('payment', {
      invoiceId,
      paymentMethodId,
      options: { channel },
      vs,
      amount,
      successUrl,
      failureUrl
    });

    payment.save().then(
      pay => {
        window.location = pay.get('redirectUrl');
      },
      () => {
        callback('paymentFail', {
          queryParams: { errorCodes: 'invoice_fail' }
        });
      }
    );
  },
  makeOrder(callback, options = {}) {
    if (!callback) {
      callback = (type, params) => this.callbackFnc(type, params);
    }

    const orderModel = this.get('checkout.model');
    const cartItems = this.get(`${this.get('itemSource')}.notDeletedItems`);
    const cartItemsHash = this._createOrderItemHash(cartItems);
    const saveOrderTask = this.get('_saveOrdersTask');
    const orders = [];
    options['channel'] = orderModel.get('channel');

    // data reset
    // this.get('store').unloadAll('domain-order');
    // this.get('store').unloadAll('invoice');
    this.get('store')
      .query('invoice-local', { key: 'current' })
      .then(
        invoiceLocals => {
          invoiceLocals.forEach(
            invoiceLocal => invoiceLocal && invoiceLocal.destroyRecord()
          );
        },
        () => {}
      );

    cartItemsHash.forEach(cartItemsHashItem => {
      const { type, items } = cartItemsHashItem;

      items
        .reject(
          item => item.get('type') === 'safetyGuard' && item.get('parentId')
        )
        .forEach(item => {
          const params = this._createParamsHash(type, orderModel, item);
          const record = this.get('store').createRecord(
            item.convertKeyToClassName(),
            params
          );
          item.setProperties({ orderId: null, orderError: null });
          item.save();
          item.set('orderObject', record);
          orders.push(saveOrderTask.perform(record, item));
        });
    });

    // orders jsou tasky, takze nevraci value/result
    allSettled(orders).then(orderResults => {
      const baseItems = this.get(`${this.get('itemSource')}.baseItems`);
      const total = baseItems.get('length');
      const rejected = baseItems.filterBy('orderError');
      orderResults.filterBy('state', 'rejected').forEach(rejectedOrder => {
        rejected.pushObject(
          EmberObject.create({
            orderError: [rejectedOrder.reason.name]
          })
        );
      });
      const failedCount = rejected.get('length');
      const successful = baseItems
        .mapBy('orderObject')
        .compact()
        .filterBy('isNew', false); // base items with domain orderObject
      successful.mapBy('cartItem').forEach(cartItem => {
        successful.pushObjects(
          cartItem
            .get('related')
            .mapBy('orderObject')
            .compact()
            .filterBy('isNew', false)
        );
      });

      const errorCodes = rejected.reduce(
        (sum, item) => sum.pushObjects(item.get('orderError')),
        []
      );

      if (total === failedCount) {
        // all failed
        callback('orderAllFail', {
          queryParams: {
            errorCodes: errorCodes.join('|')
          }
        });

        this.get('ideas').clear(); // TODO-FIX lepsi volat na checkout?
      } else if (failedCount === 0) {
        // all success

        // if any for invoice
        if (
          successful.filter(item => item.get('price') > 0).get('length') > 0
        ) {
          this.invoice(successful, callback, options);
        } else {
          // else directly thanks
          callback('success', {
            queryParams: {
              invoice_id: null,
              payment_method_id: orderModel.get('paymentId'),
              order_ids: successful.mapBy('id').join(',')
            }
          });
        }
      } else {
        // partial success
        callback('orderFail', {
          queryParams: {
            errorCodes: errorCodes
          }
        });
      }
    });
  },
  _createParamsHash(type, order, cartItem) {
    const invoiceContactId = order.get('invoiceContact.id')
      ? order.get('invoiceContact.id')
      : order.get('ownerContact.id');
    const ownerContactId = order.get('ownerContact.id');
    const baseParams = {
      cartItemId: cartItem.get('id'),
      price: cartItem.get('itemPrice')
    };

    if (type === 'registration') {
      return assign(baseParams, {
        ownerContactId,
        invoiceContactId,
        adminContactId: order.get('adminContact.id'),
        domainName: cartItem.get('name') + '.' + cartItem.get('tld'),
        years: cartItem.get('quantity'),
        safetyGuard: cartItem.get('relatedTypes').includes('safetyGuard'),
        identityCardNumber: order.get('identityCardNumber'),
        registrationRulesConsent: order.get('registrationRulesConsent'),
        vatNumber: order.get('vatNumber'),
        xxxId: order.get('xxxId'),
        xxxActive: order.get('xxxActive'),
        docUrl: order.get('docUrl'),
        autoRenew: order.get('autoRenew'),
        nssetId: order.get('nssetId'),
        cartItemId: cartItem.get('id'),
        httpsCheck: order.get('httpsCheck')
      });
    }

    if (type === 'email') {
      if (cartItem.get('mailHostingObject.customerContact')) {
        baseParams['customerContact'] = {
          name: order.get('mailHostingName'),
          email: order.get('mailHostingEmail')
        };
      }

      return assign(baseParams, {
        domainName: cartItem.get('name'),
        mailboxUserName: cartItem.get('userName'),
        domainServiceType: cartItem.get('hostingType'),
        period: cartItem.get('selectedPeriod'),
        capacity: cartItem.get('selectedCapacity'),
        users: cartItem.get('quantity'),
        invoiceContactId,
        adminEmail: order.get('mailHostingEmail')
          ? order.get('mailHostingEmail')
          : this.get('currentUser.email'),
        phone: order.get('mailHostingPhone')
          ? `${order.get('mailHostingDialingCode')}.${order.get(
              'mailHostingPhone'
            )}`
          : null
      });
    }

    if (type === 'safetyGuard') {
      return assign(baseParams, {
        domainName: cartItem.get('name') + '.' + cartItem.get('tld')
      });
    }

    if (type === 'renewal') {
      return assign(baseParams, {
        domainName: cartItem.get('name') + '.' + cartItem.get('tld'),
        years: cartItem.get('quantity')
      });
    }

    if (type === 'transfer') {
      return assign(baseParams, {
        ownerContactId,
        invoiceContactId,
        domainName: cartItem.get('name') + '.' + cartItem.get('tld'),
        registrationRulesConsent: order.get('registrationRulesConsent'),
        authCode: cartItem.get('authCode'),
        nameServerNames: cartItem.get('nameServerNames')
      });
    }

    if (type === 'sslCert') {
      if (cartItem.get('certType.wildcard')) {
        cartItem.set(
          'extra.commonName',
          `*.${cartItem.get('extra.commonName')}`
        );
      }

      return assign(baseParams, cartItem.get('extra'), {
        invoiceContactId,
        years: cartItem.get('years'),
        sslCertType: cartItem.certType
      });
    }

    return {};
  },
  _saveOrdersTask: task(function* (order, item) {
    return yield order.save().then(
      result => {
        item.set('orderId', result.get('id'));
        item.save();
        order.set('cartItem', item);
        return result;
      },
      e => {
        item.set('orderError', e.errors.mapBy('code').compact().uniq());
        item.save();
        return e;
      }
    );
  }).enqueue(),
  _createOrderItemHash(cartItems) {
    const cartItemsHash = [];
    cartItems.forEach(cartItem => {
      const itemType = cartItem.get('type');
      const itemsByType = cartItemsHash.findBy('type', itemType);
      if (itemsByType) {
        itemsByType.items.push(cartItem);
      } else if (itemType === 'registration') {
        cartItemsHash.unshift({ type: itemType, items: [cartItem] });
      } else {
        cartItemsHash.push({ type: itemType, items: [cartItem] });
      }
    });
    return cartItemsHash;
  }
});
