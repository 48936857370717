import WySerializer from './wy-api';

export default WySerializer.extend({
  normalizeResponse(/* store, primaryModelClass, payload, id, requestType */) {
    const normalizedData = this._super(...arguments);

    const { data } = normalizedData;
    normalizedData.data = data.map(item => {
      let { attributes: attrs } = item;
      attrs = {
        length: { min: attrs.tlSizeMin, max: attrs.tlSizeMax },
        ...attrs
      };

      item.attributes = attrs;
      return item;
    });
    return normalizedData;
  }
});
