import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  tldException: service(),
  cart: service(),
  ideas: service(), // pouzito v -contact-item-content*
  classNames: ['contact-item'],
  classNameBindings: [
    'show',
    'invalid',
    'forceInvalid:error',
    'forceInvalid:invalid',
    'exceptionsInvalid:error',
    'exceptionsInvalid:invalid',
    'isRrIds',
    'displayRow:col-12:col-3'
  ],
  contact: null,
  countries: null,
  show: false,
  forceExpand: false,
  forceInvalid: false,
  selected: null,
  domains: alias('cart.baseItems'),
  addressbook: null,
  searchValue: '',
  validation: true,
  exceptionsInvalid: false,
  rrIdsOpen: null,
  displayRow: false,
  contactDirectory: false,
  canHasHiddenAttributes: computed('cart.baseItems.[]', function () {
    return this.get('cart.baseItems').isEvery('tldName', 'cz');
  }),
  isRrIdsOpen: computed(
    'rrIdsOpen',
    'displayRow',
    'contact.rrIdsFound',
    function () {
      let rrIdOpen = this.get('rrIdsOpen');
      if (rrIdOpen !== null) {
        return rrIdOpen;
      }
      return this.get('contactDirectory') && this.get('contact.rrIdsFound');
    }
  ),
  isRrIds: computed('contact.rrIds', function () {
    if (!this.get('contact.rrIds')) {
      return false;
    }
    return Object.keys(this.get('contact.rrIds')).length !== 0;
  }),
  exceptionsCheck() {
    if (this.get('addressbook') === 'invoice') {
      return false;
    }

    this.get('tldException').errorReset();

    if (
      this.get('tldException').exceptionsCheck(
        this.get('domains'),
        this.get('contact')
      )
    ) {
      this.set('exceptionsInvalid', true);
      return true;
    }

    return false;
  },
  init() {
    this._super(...arguments);

    if (this.get('show')) {
      return;
    }

    scheduleOnce('afterRender', () => {
      this.exceptionsCheck();
    });
  },
  didInsertElement() {
    if (this.get('show')) {
      return;
    }

    if (this.get('contact.scrollTo')) {
      let top = this.$().offset().top - $('.top-page-stack').height() - 10;
      $(window).scrollTop(top);

      this.set('contact.scrollTo', false);
    }

    scheduleOnce('afterRender', () => {
      $('#addressbook_loading').hide();
    });
  },
  collapsed: computed('show', 'selected', function () {
    return !(
      this.get('show') ||
      this.get('selected') === this.get('contact') ||
      this.get('forceExpand') ||
      (this.get('displayRow') && isPresent(this.get('searchValue'))) ||
      this.get('contactDirectory')
    );
  }),
  invalid: computed('contact.{phone,email,street}', function () {
    if (this.get('show') || !this.get('validation')) {
      return false;
    }

    return !this.isContactValid(this.get('contact'), this.get('domains'));
  }),
  invalidAttributes: computed(
    'contact.{phone,email,street}',
    'domains.[]',
    function () {
      return this.contactValidationReturnsErrors(
        this.get('contact'),
        this.get('domains')
      );
    }
  ),
  click() {
    this.send('checked');
  },
  actions: {
    checked() {
      if (this.get('show') || this.get('forceInvalid')) {
        return;
      }

      this.set('collapsed', false);

      if (this.exceptionsCheck() || this.get('invalid')) {
        return;
      }

      this.set('selected', this.get('contact'));
      this.$('[type="radio"]').prop('checked', true);
    },
    completeMissingData() {
      tryInvoke(this, 'completeMissingData', [false, this.get('contact')]);
    },
    toggleRrIds() {
      this.toggleProperty('rrIdsOpen');
    }
  }
});
