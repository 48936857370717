import WySerializer from './wy-api';

export default WySerializer.extend({
  normalizeResponse(store /* , primaryModelClass, payload, id, requestType*/) {
    const data = this._super(...arguments);
    const { data: userData } = data || {};
    const { attributes } = userData || {};
    const { mojeid, userInfo, vatRate } = attributes || {};

    // move attrs in user
    userInfo.vatRate = parseFloat(vatRate) / 100 + 1;
    delete attributes.vatRate;
    userInfo.mojeid = mojeid;
    delete attributes.mojeid;
    delete data.data.attributes.userInfo;

    const normalizedUserData =
      store
        .serializerFor('user')
        .normalizeResponse(
          store,
          store.modelFor('user'),
          { data: userInfo },
          'current',
          'findRecord'
        ) || {};

    data.included = [normalizedUserData.data];
    return data;
  }
});
