import ApplicationSerializer from './application';
import normalizeAttrs from '../utils/normalize-attr-keys';
import { underscore } from '@ember/string';

const API_DATA_KEYS = ['data', 'list'];
const META_KEYS = ['success', 'status'];

export default ApplicationSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload, id /*, requestType*/) {
    let usedApiKey = API_DATA_KEYS.find(apiDataKey => payload[apiDataKey]);
    if (usedApiKey) {
      if (Array.isArray(payload[usedApiKey])) {
        return {
          data: payload[usedApiKey].map(item => ({
            id: item[this.primaryKey],
            type: primaryModelClass.modelName,
            attributes: normalizeAttrs(item)
          }))
        };
      } else {
        const isListOfObjects = Object.keys(payload[usedApiKey])
          .filter(item => !META_KEYS.includes(item))
          .every(
            item =>
              !Array.isArray(payload[usedApiKey][item]) &&
              typeof payload[usedApiKey][item] === 'object'
          );
        let data = {};
        if (isListOfObjects) {
          data = Object.keys(payload[usedApiKey]).reduce((out, item) => {
            const attrs = payload[usedApiKey][item];
            const normalizedAttrs = normalizeAttrs(attrs);
            out.push({
              id: item,
              type: primaryModelClass.modelName,
              attributes: normalizedAttrs
            });
            return out;
          }, []);
        } else {
          const attrs = payload[usedApiKey];
          const normalizedAttrs = normalizeAttrs(attrs);
          data = {
            id: id === 'current' ? id : attrs[this.primaryKey],
            type: primaryModelClass.modelName,
            attributes: normalizedAttrs
          };
        }
        return { data };
      }
    }
    return payload;
  },
  serialize() {
    const payload = this._super(...arguments);
    return payload.data.attributes;
  },
  keyForAttribute(attr) {
    return underscore(attr);
  },
  keyForRelationship: function (rawKey) {
    return underscore(rawKey);
  }
});
