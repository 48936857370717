import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  ibankingTypes: DS.attr(),
  invoiceSettings: DS.attr(),
  packs: DS.attr(),
  services: computed('packs.[]', function () {
    return this.get('packs').map(pack => {
      return {
        basePrice: parseFloat(pack.base_price),
        customerDiscount: pack.customer_discount,
        customerPrice: parseFloat(pack.customer_price),
        description: pack.description,
        id: pack.pack,
        pack: pack.pack,
        packParams: pack.pack_params,
        supportedPeriods: pack.supported_periods,
        title: pack.title,
        category: pack.category
      };
    });
  })
});
