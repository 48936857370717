import ApplicationAdapter from './application';
import ENV from 'domena-mobile/config/environment';
import { inject as service } from '@ember/service';

const VIZITKA_API_HOST = ENV.VIZITKA_API_HOST || '';

export default ApplicationAdapter.extend({
  namespace: 'api/v1',
  host: VIZITKA_API_HOST,
  currentUser: service(),
  ensureResponseAuthorized() {}
});
