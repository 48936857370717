const root = document.documentElement;

const topPageInfoBlockHeight = parseInt(
  getComputedStyle(root).getPropertyValue('--top-page-info-block'),
  10
);

const navbarHeightDesktop = parseInt(
  getComputedStyle(root).getPropertyValue('--navbar-height-desktop'),
  10
);

const navbarHeightMobile = parseInt(
  getComputedStyle(root).getPropertyValue('--navbar-height-mobile'),
  10
);

const topPageElementsSizeDesktop = `${
  topPageInfoBlockHeight + navbarHeightDesktop
}px`;

const topPageElementsSizeMobile = `${
  topPageInfoBlockHeight + navbarHeightMobile - 1
}px`;

export {
  topPageInfoBlockHeight,
  navbarHeightDesktop,
  navbarHeightMobile,
  topPageElementsSizeDesktop,
  topPageElementsSizeMobile
};
