import DS from 'ember-data';
import Order from './order';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { alias } from '@ember/object/computed';

export default Order.extend({
  invoice: DS.belongsTo('invoice'),
  // invoice: DS.belongsTo('invoice', {inverse: 'orders', polymorphic: true}), // FIXME
  invoiceContact: DS.belongsTo('contact'),
  domainService: DS.belongsTo('domainService'),
  totalPrice: DS.attr('number'),
  domainServiceObject: computed('domainService', function () {
    if (!isEmpty(this.get('domainService'))) {
      this.reload();
    }
    return this.get('domainService');
  }),
  displayName: alias('domainServiceObject.domainName'),
  serviceType: alias('domainServiceObject.serviceType')
});
