import { inject as service } from '@ember/service';
import Component from '@ember/component';
import Common from '../../mixins/common';
import fetchAjax from '../../utils/ajax';

export default Component.extend(Common, {
  store: service(),
  ideas: service(),
  tldException: service(),
  intl: service(),
  session: service(),
  classNames: ['contact-form', 'contact-import-form'],
  model: null,
  importContact: null,
  importContactValidation: false,
  importContactSearched: false,
  importContactInvalid: false,
  importContactLoading: false,
  countries: null,
  contacts: null,
  init() {
    this._super(...arguments);
    if (!this.countries) {
      this.set('countries', this.store.peekAll('country'));
    }
  },
  _submit() {
    if (typeof this.contactImport === 'function') {
      this.contactImport(this.get('importContact'));
    }
  },
  actions: {
    contactImport() {
      this._submit();
    },
    contactImportSearch(params) {
      if (params.length === 0) {
        this.set('contacts', null);
        return;
      }

      this.send('contactImportRemove');
      this.set('importContactLoading', true);
      const adapter = this.store.adapterFor('wy-api');
      const path = `${adapter.buildURL('reg', 'contacts')}#import`;
      let { token } = this.get('session.data.authenticated');

      fetchAjax(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          action: 'import',
          nic_id: params,
          tld: 'cz'
        })
      }).then(
        result => {
          if (result.success) {
            this.set('importContactInvalid', false);
            this.store.findAll('contact', { reload: true }).then(contacts => {
              this.set(
                'importContact',
                contacts.find(contact => {
                  return contact.id === result.contact_id.toString();
                })
              );
              this._submit();
              this.set('importContactLoading', false);
            });
          }
        },
        error => {
          const errorObject = JSON.parse(error.body || '{}');
          const {
            error: { code }
          } = errorObject;
          this.set('error', this.intl.t(`error_codes.${code}`));
          this.set('importContactLoading', false);
        }
      );
    },
    contactImportRemove() {
      this.setProperties({
        contacts: null,
        importContact: null,
        importContactSearched: false
      });
    }
  }
});
