import Component from '@ember/component';
import ENV from 'domena-mobile/config/environment';
import SERVICE_CONFIG from '../utils/service-config';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';
import { uniqBy } from '@ember/object/computed';

export default Component.extend({
  classNames: ['mail-hosting'],
  serviceConfig: SERVICE_CONFIG[ENV.LOCALE],
  cms: service(),
  store: service(),
  currentUser: service(),
  plans: uniqBy('mailHostingPlans', 'pack'),
  didInsertElement() {
    this.set('mailHostingPlans', this.get('store').peekAll('mailHostingPlan'));
  },
  sortedPlans: computed('plans.[]', function () {
    const typesOrder = this.cms?.pages?.produkty?.blocks.mapBy('slug');
    return this.plans?.toArray()?.sort((itemA, itemB) => {
      const itemAPos = typesOrder.indexOf(dasherize(itemA.pack));
      const itemBPos = typesOrder.indexOf(dasherize(itemB.pack));
      return itemAPos - itemBPos;
    });
  }),
  actions: {
    select(planCode, plan) {
      tryInvoke(this, 'close', [planCode, plan]);
    }
  }
});
