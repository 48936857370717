import Service from '@ember/service';
import { inject as service } from '@ember/service';
import Common from '../mixins/common';

export default Service.extend(Common, {
  events: service(),
  isOpen: false,
  close() {
    this.set('isOpen', false);
    if (document.querySelector('.overflow-menu')) {
      document.querySelector('.overflow-menu').style.display = 'none';
    }
  },
  open() {
    this.set('isOpen', true);
    this.onBackClose('menu');
    this.get('events').logEvent('click', 'menu', 'open');
    if (document.querySelector('.overflow-menu')) {
      document.querySelector('.overflow-menu').style.display = 'block';
    }
  }
});
