import ApplicationAdapter from './application';
import config from 'domena-mobile/config/environment';
import { inject as service } from '@ember/service';

export default ApplicationAdapter.extend({
  fastboot: service(),
  urlForFindAll() {
    const path = this.get('fastboot.isFastBoot')
      ? `${this.get('fastboot.request.protocol')}//${this.get(
          'fastboot.request.host'
        )}/`
      : '/';
    return `${path}paymentMethods-${config.LOCALE || 'cs'}.json`;
  }
});
