import Service, { inject as service } from '@ember/service';
import Logger from '../utils/logger';

import { reject } from 'rsvp';
import { task } from 'ember-concurrency';

const MAX_CONCURRENCY = 3;
export default Service.extend({
  config: service(),
  store: service(),
  domainList: service(),
  _dasRequest: task(function* (checkedName) {
    const DAS_HOST = this.get('config.DAS_HOST');
    const controller = new window.AbortController();
    const signal = controller.signal;
    const adapter = this.get('store').adapterFor('domain');
    let isErrored = false;
    const url = DAS_HOST
      ? `${DAS_HOST}/${checkedName}`
      : `${adapter.buildURL('domain', 'check')}/${checkedName}`;

    try {
      return yield fetch(url, {
        signal,
        headers: {
          Authorization: `Bearer ${this.get('domainList.dasToken')}`
        }
      }).then(response => {
        if (!response.ok) {
          throw Error('fetch error');
        }
        return response.json();
      });
    } catch (e) {
      isErrored = true;
      return reject(e);
    } finally {
      if (!isErrored) {
        controller.abort();
      }
    }
  }),
  domainCheckTask: task(function* (model, checkedName) {
    let checkTask;
    try {
      checkTask = this._dasRequest.perform(checkedName);
      return yield checkTask.then(result => {
        let state;
        let respondNameWithTld;
        let premium;

        if (result['data']) {
          state = result['data']['attributes']['available'];
          premium = result['data']['attributes']['premium'];
          respondNameWithTld = result['data']['attributes']['name'];
        } else {
          state = result['available'];
          premium = result['premium'];
          respondNameWithTld = result['name'];
        }

        if (respondNameWithTld.toLowerCase() !== checkedName.toLowerCase()) {
          throw Error('name different');
        }
        model.setProperties({
          state: state ? 'available' : 'taken',
          available: state,
          premium,
          name: respondNameWithTld
        });
        return model;
      });
    } catch (e) {
      model.set('state', 'invalid');
      Logger.error(e);
    } finally {
      checkTask && checkTask.cancel();
    }
  })
    .maxConcurrency(MAX_CONCURRENCY)
    .enqueue(),
  cancel(taskName) {
    this.get(taskName).cancelAll();
  },
  enqueue(taskName, model, checkedName) {
    return this.get(taskName).perform(model, checkedName);
  }
});
