import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  classNames: ['form-edit-link'],
  item: true,
  hasCancel: false,
  actions: {
    edit() {
      tryInvoke(this, 'edit');
    },
    cancel() {
      tryInvoke(this, 'cancel');
    }
  }
});
