import Component from '@ember/component';

export default Component.extend({
  scrollToUrlHashElement() {
    const hash = window.location.hash;
    const headerHeight = document.querySelector('.top-page-stack').clientHeight;
    const html = document.querySelector('html');
    html.style.scrollPaddingTop = `${headerHeight}px`;

    if (hash) {
      setTimeout(() => {
        const scrollToElement =
          document.querySelector(`#${hash.slice(1)}`) || null;

        // html.style.scrollBehavior = 'smooth';

        if (scrollToElement && headerHeight) {
          window.scrollTo({
            top: scrollToElement.offsetTop - headerHeight
          });
        }
      }, 0);
    }
  },
  didInsertElement() {
    this.scrollToUrlHashElement();
  },
  willDestroyElement() {
    window.removeEventListener(
      'hashchange',
      this.scrollToUrlHashElement,
      false
    );
  }
});
