import intersect from 'domena-mobile/utils/intersect';
import { camelize } from '@ember/string';

const GDPR_TLDS = ['eu', 'cz', 'sk'];

export default function showRegistrationRulesConsent(items) {
  return intersect(
    items
      .filter(item => {
        return ['registration', 'transfer', 'renewal'].includes(
          camelize(item.get('type'))
        );
      })
      .mapBy('tld'),
    GDPR_TLDS
  );
}
