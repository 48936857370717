import Component from '@ember/component';
import config from '../../config/environment';

const menuItems = {
  cs: [
    {
      link: 'domains',
      nameIntl: 'domains',
      nameShortIntl: 'domains'
    },
    {
      link: 'yola',
      nameIntl: 'yola',
      nameShortIntl: 'yola'
    },
    {
      link: 'wordpress-hosting',
      nameIntl: 'wordpress',
      nameShortIntl: 'wordpress'
    },
    {
      link: 'hosting',
      nameIntl: 'hosting',
      nameShortIntl: 'hosting'
    },
    {
      link: 'email-k-domene',
      nameIntl: 'email',
      nameShortIntl: 'email'
    },
    // {
    //   link: 'microsoft-365',
    //   nameIntl: 'office_365',
    //   nameShortIntl: 'office_365_short'
    // },
    // {
    //   link: 'webova-vizitka',
    //   nameIntl: 'cards',
    //   nameShortIntl: 'cards'
    // },
    {
      link: 'ssl-certificates',
      nameIntl: 'ssl_certificates',
      nameShortIntl: 'ssl_certificates'
    }
  ],
  sk: [
    {
      link: 'domains',
      nameIntl: 'domains',
      nameShortIntl: 'domains'
    },
    {
      link: 'yola',
      nameIntl: 'yola',
      nameShortIntl: 'yola'
    },
    {
      link: 'wordpress-hosting',
      nameIntl: 'wordpress',
      nameShortIntl: 'wordpress'
    },
    {
      link: 'hosting',
      nameIntl: 'hosting',
      nameShortIntl: 'hosting'
    },
    {
      link: 'email-k-domene',
      nameIntl: 'email',
      nameShortIntl: 'email'
    },
    // {
    //   link: 'microsoft-365',
    //   nameIntl: 'office_365',
    //   nameShortIntl: 'office_365_short'
    // },
    {
      link: 'ssl-certificates',
      nameIntl: 'ssl_certificates',
      nameShortIntl: 'ssl_certificates'
    }
  ]
};

export default Component.extend({
  menuItems: menuItems[config.LOCALE],
  config,
  enableBlogPosts: config.LOCALE === 'cs'
});
