import Component from '@ember/component';
import { computed } from '@ember/object';
import config from '../config/environment';
import keyIndexes from 'domena-mobile/utils/key-indexes';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['landing-page', 'landing-page-yola', 'reset-offset', 'bg-white'],
  cms: service(),
  enableCustomSolution: config.LOCALE === 'cs',
  enableTips: config.LOCALE === 'cs',
  importDomainName: null,
  modal: service(),
  router: service(),
  store: service(),
  yolaItems: null,

  didInsertElement() {
    this.set('yolaItems', this.store.peekAll('yola-plan'));
    if (this.importDomainName) {
      this._showOrder('YOLA_PREMIUM');
    }
  },
  showcaseItems: computed(function () {
    return keyIndexes(this.get('cms._translationData.yola'), 'yola-showcase');
  }),
  videoPlay: false,
  yolaFeatureItems: computed(function () {
    return keyIndexes(this.get('cms._translationData.yola'), 'yola-feature');
  }),
  _showOrder(plan) {
    const planObject = this.yolaItems.findBy('id', plan);

    if (planObject) {
      this.get('modal').show('forms/new-yola-form', planObject, {
        type: 'domena',
        yolaImport: this.importDomainName,
        vizitkaDomainName: this.vizitkaDomainName,
        onClose: selectedType => {
          if (selectedType) {
            this.router.transitionTo('cart', {
              queryParams: {
                page: 1,
                addressbook: 'invoice'
              }
            });
          }
        }
      });
    }
  },
  actions: {
    handleVideoPlaceholderClick(event) {
      this.set('videoPlay', true);
      setTimeout(() => {
        event.target.play();
      });
    },
    showOrder(selectedPlan) {
      this._showOrder(selectedPlan);
    },
    findOutMore() {
      this._showOrder('YOLA_FREE');
    }
  }
});
