import { computed } from '@ember/object';
import Component from '@ember/component';
import Common from '../mixins/common';
import { alias } from '@ember/object/computed';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  classNames: ['selection-numbers'],
  classNameBindings: ['single'],
  quantity: null,
  quantityKey: 'quantity',
  valueKey: 'value',
  nameKey: 'name',
  selected: null,
  min: computed('data', function () {
    return this.get('data.firstObject') || 1;
  }),
  max: computed('data', function () {
    return this.get('data.lastObject') || 50;
  }),
  data: null,
  dataIndex: computed('data', 'selected', function () {
    let data = this.get('data');
    if (this.isArray(data)) {
      return data.indexOf(this.selected);
    }
    return 0;
  }),
  text: '',
  single: computed('data', function () {
    return this.get('data.length') === 1;
  }),
  didInsertElement() {
    const data = this.get('data');
    this.set('quantity', alias(`selected.${this.quantityKey}`));
    if (!this.selected) {
      this.set('selected', data.get('lastObject'));
    }
  },
  actions: {
    increment() {
      const data = this.get('data');
      if (this.get('dataIndex') < data.length - 1) {
        this.incrementProperty('dataIndex');
        this.set('selected', data[this.get('dataIndex')]);
      }
      tryInvoke(this, 'changed', [this.selected]);
    },
    decrement() {
      const data = this.get('data');
      if (this.get('dataIndex') > 0) {
        this.decrementProperty('dataIndex');
        this.set('selected', data[this.get('dataIndex')]);
      }
      tryInvoke(this, 'changed', [this.selected]);
    }
  }
});
