import $ from 'jquery';
import Component from '@ember/component';
import { trySet } from '@ember/object';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  classNames: ['footer-dialog'],
  classNameBindings: ['isShowed'],
  isShowed: false,
  didInsertElement() {
    $('#chatra').addClass('with-footer');
  },
  willDestroyElement() {
    $('#chatra').removeClass('with-footer');
  },
  actions: {
    close() {
      trySet(this, 'isShowed', false);
      tryInvoke(this, 'closeAction', []);
    }
  }
});
