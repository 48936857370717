import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/payment-method'
    }
  }
];

export default Route.extend({
  queryParams: {
    invoice_id: {},
    order_ids: {}
  },
  checkout: service(),
  order: service(),
  headTags: HEAD_TAGS,
  model(params) {
    return hash({
      paymentMethods: this.store.peekAll('paymentMethod'),
      invoice: this.store
        .findRecord('invoice', params.invoice_id, { reload: true })
        .then(
          record => {
            return record;
          },
          () => {
            return null;
          }
        )
    });
  },
  afterModel(model) {
    this._super(...arguments);
    const prevPaymentMethod =
      model.invoice && model.invoice.get('paymentMethod');
    const defaultPaymentMethod = model.paymentMethods.get('firstObject');
    if (model.invoice) {
      if (!prevPaymentMethod) {
        model.invoice.set('paymentMethod', defaultPaymentMethod);
      }

      this.set('checkout.model.channel', model.invoice.get('channel'));
      this.set(
        'checkout.model.paymentMethod',
        model.invoice.get('paymentMethod.id')
      );
    }
  },

  resetController(controller, model) {
    this._super(controller, model);
    controller.set('paying', false);
  }
});
