import Component from '@ember/component';
import Logger from '../utils/logger';
import config from '../config/environment';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  classNames: ['registration-page'],
  gtmEvents: service(),
  store: service(),
  modal: service(),
  currentUser: service(),
  localIsCs: config.LOCALE === 'cs',
  session: service(),
  tagName: 'form',
  showErrors: false,
  loading: false,
  showFirstPasswordInfo: false,
  didInsertElement() {
    this.set(
      'user',
      this.store.createRecord('user', {
        invoiceLanguage: config.LOCALE
      })
    );
  },
  willDestroyElement() {
    const user = this.get('user');
    if (user.isNew) {
      user.rollbackAttributes();
    }
  },
  submit() {
    this.set('loading', true);
    this.set('user.validatorDisabled', true);
    // this.set('user.login', this.get('user.email'));
    this.set('user.password', undefined);

    if (this.get('user.validations.isValid')) {
      this.get('user')
        .save()
        .then(
          result => {
            let login = result.get('login');
            let password = result.get('password');
            let credentials = { identification: login, password };
            this.gtmEvents.logSignUp(result);

            return this.get('session')
              .authenticate('authenticator:wy-api', credentials)
              .then(() => {
                this.set('showFirstPasswordInfo', true);
                result.unloadRecord();
                tryInvoke(this, 'authenticationSuccess');
              });
          },
          error => {
            const errorMessages = (error.errors || [])
              .map(e => e.detail)
              .join('\n');
            if (errorMessages.length > 0) {
              alert(errorMessages);
            }
            Logger.error(error);
            this.set('loading', false);
            this.set('showErrors', true);
          }
        );
    } else {
      this.set('loading', false);
      this.set('showErrors', true);
    }
    return false;
  }
});
