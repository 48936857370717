import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  name: DS.attr('string'),
  ignum: DS.attr('boolean', { defaultValue: false }),
  // nameServers: DS.hasMany('nameServer'),
  rrid: DS.attr('number'),

  // WY-API attrs
  iDzakaznika: DS.attr('string'),
  dnsGroup: DS.attr('string'),
  dnsNs1: DS.attr('string'),
  dnsNs2: DS.attr('string'),
  dnsNs3: DS.attr('string'),
  dnsNs4: DS.attr('string'),
  dnsNs5: DS.attr('string'),
  dnsNs6: DS.attr('string'),
  dnsNs7: DS.attr('string'),
  dnsNs8: DS.attr('string'),
  dnsNs9: DS.attr('string'),
  groupType: DS.attr('string'),
  ipv41: DS.attr('string'),
  ipv42: DS.attr('string'),
  ipv43: DS.attr('string'),
  ipv44: DS.attr('string'),
  ipv45: DS.attr('string'),
  ipv46: DS.attr('string'),
  ipv47: DS.attr('string'),
  ipv48: DS.attr('string'),
  ipv49: DS.attr('string'),
  ipv61: DS.attr('string'),
  ipv62: DS.attr('string'),
  ipv63: DS.attr('string'),
  ipv64: DS.attr('string'),
  ipv65: DS.attr('string'),
  ipv66: DS.attr('string'),
  ipv67: DS.attr('string'),
  ipv68: DS.attr('string'),
  ipv69: DS.attr('string'),
  masterIp: DS.attr('string'),
  // name: DS.attr('string'),
  nssetId: DS.attr('string'),
  registeredIn: DS.attr('string'),
  requestTechId: DS.attr('string'),
  setSysGroup: DS.attr('string'),
  status: DS.attr('string'),
  techId1: DS.attr('string'),
  techId2: DS.attr('string'),
  techId3: DS.attr('string'),
  nameServers: computed(function () {
    return Array.from(Array(10).keys())
      .filter(index => Boolean(this[`dnsNs${index}`]))
      .map(index => ({
        name: this[`dnsNs${index}`],
        ipv4: this[`ipv4${index}`],
        ipv6: this[`ipv6${index}`]
      }));
  })
});
