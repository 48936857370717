import Helper from '@ember/component/helper';
import markdown from './markdown';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';

export default Helper.extend({
  intl: service(),
  cms: service(),
  compute([text], { block = false }) {
    const cmsValue = this.get(`cms._translationData.${text}`);
    if (typeof cmsValue === 'undefined')
      return htmlSafe(
        `<div class="text-danger" title=${text}>!!! ${this.intl.t(
          'not_found'
        )} !!!</div>`
      );
    if (typeof cmsValue !== 'string') {
      return cmsValue;
    }
    return markdown.compute([cmsValue], { block });
  }
});
