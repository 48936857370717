import { later, next } from '@ember/runloop';

import Component from '@ember/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Component.extend({
  ideas: service(),
  intl: service(),
  modal: service(),
  deviceDetection: service(),
  store: service(),
  classNames: ['advanced-settings'],
  adminContact: null,
  ownerContact: null,
  autoRenew: true,
  nsset: null,
  zoneFile: null,
  isOpen: false,
  order: null,
  adminContactAllowed: computed('adminContact', 'ideas.items.[]', function () {
    let allowed = this.get('ideas.adminContactState.state') !== 'not_allowed';
    if (!allowed) {
      next(() => this.set('adminContact', null));
    }
    return allowed;
  }),
  setLastUsedContact() {
    if (isEmpty(this.get('adminContact'))) {
      // pokud se bude pouzivat, je potreba upravit na user.profile
      let adminContactId = this.get('currentUser.user.adminContactId');
      this.set(
        'adminContact',
        this.get('store').peekRecord('contact', adminContactId)
      );
    }
  },
  setOwnerContact() {
    let state = this.get('ideas.adminContactState.state');
    if (
      (isEmpty(this.get('adminContact')) && state === 'mandatory') ||
      state === 'mixed'
    ) {
      this.set('adminContact', this.get('ownerContact'));
    }
  },
  checkOpening() {
    let order = this.get('order');
    if (
      !order.get('autoRenew') ||
      order.get('adminContactId') ||
      this.get('adminContact.id') ||
      order.get('nssetId') !== 'IGNUM' ||
      order.get('zoneId')
    ) {
      if (this.get('adminContact.id') === this.get('ownerContact.id')) {
        this.close();
      } else {
        this.open();
      }
    } else {
      this.close();
    }
  },
  didInsertElement() {
    // this.setLastUsedContact();
    // const { nssetId, zoneId, zoneType } = this.get('order').getProperties(
    //   'nssetId',
    //   'zoneId',
    //   'zoneType'
    // );

    // if (zoneType && zoneId) {
    //   // TODO tady neexistuje zone-template/zones show
    //   this.store.findAll(zoneType).then(zoneFiles => {
    //     this.set('order.zoneFile', zoneFiles.findBy('id', zoneId));
    //   });
    // }

    // if (nssetId) {
    //   this.store.find('nsset', nssetId).then(nsset => {
    //     this.set('order.nsset', nsset);
    //   });
    // }

    this.setOwnerContact();
    later(() => {
      this.checkOpening();
    });
  },
  toggle() {
    const advanceSettingsElem = this.element.querySelector(
      '.advanced-settings-wrapper'
    );
    advanceSettingsElem && advanceSettingsElem.classList.toggle('isOpen');
    this.toggleProperty('isOpen');
  },
  open() {
    const advanceSettingsElem = this.element.querySelector(
      '.advanced-settings-wrapper'
    );
    advanceSettingsElem && advanceSettingsElem.classList.add('isOpen');
    this.set('isOpen', true);
  },
  close() {
    const advanceSettingsElem = this.element.querySelector(
      '.advanced-settings-wrapper'
    );
    advanceSettingsElem && advanceSettingsElem.classList.remove('isOpen');
    this.set('isOpen', false);
  },
  actions: {
    toggle() {
      this.toggle();
    },
    open() {
      this.open();
    },
    close() {
      this.close();
    },
    openNssetModal() {
      this.get('modal').show('forms/nsset-select', this.get('order.nssetId'), {
        header: this.intl.t('advanced_settings.header1'),
        onClose: selectedNsset => {
          if (selectedNsset) {
            this.get('order').setProperties({
              nsset: selectedNsset,
              nssetId: selectedNsset.id
            });
            this.get('order').save();
          }
        }
      });
    },
    openZoneFileModal() {
      this.get('modal').show('forms/zone-file-select', this.get('order'), {
        header: this.intl.t('advanced_settings.header2'),
        onClose: selectedZoneFile => {
          if (selectedZoneFile) {
            this.get('order').setProperties({
              zoneId: selectedZoneFile.id,
              zoneFile: selectedZoneFile,
              zoneType: selectedZoneFile.constructor.modelName
            });
            this.get('order').save();
          }
        }
      });
    },
    editAdminContact() {
      this.get('modal').show('forms/contact-select-full', null, {
        header: this.intl.t('advanced_settings.header3'),
        onSelect: selectedContact => {
          this.set('adminContact', selectedContact);
        },
        selected: this.get('adminContact')
      });
    }
  }
});
