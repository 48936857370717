import { isArray } from '@ember/array';
import { isEmpty } from '@ember/utils';
import Mixin from '@ember/object/mixin';
import { singularize } from 'ember-inflector';

export default Mixin.create({
  nonRestAction(action, method, data, options) {
    const modelName = this.constructor.modelName;
    const adapter = this.store.adapterFor(modelName);

    return adapter
      .ajax(this.getActionUrl(action, adapter, options), method, {
        data: data
      })
      .then(result => {
        if (isEmpty(result)) {
          return {};
        }
        return this.normalizePayload(result);
      });
  },

  getActionUrl(action, adapter, options) {
    const type = this.constructor.modelName;
    // (modelName, id, snapshot, requestType, query) {
    if (options && options.withoutId) {
      return `${adapter.buildURL(type)}/${action}`;
    } else {
      const id = this.get('id');
      return `${adapter.buildURL(type, id)}/${action}`;
    }
  },

  normalizePayload(payload) {
    let data = [];
    let included = [];
    let requestData = payload.data || payload.list;

    if (isArray(requestData)) {
      requestData.forEach(item => {
        data.pushObject(
          this.store.normalize(singularize(item.type), item).data
        );
      });
    } else {
      // TODO: Při jednom záznamu nevracet pole
      data = this.store.normalize(singularize(requestData.type), requestData)
        .data;
    }

    if (payload.included) {
      payload.included.forEach(item => {
        included.pushObject(
          this.store.normalize(singularize(item.type), item).data
        );
      });
    }

    payload['data'] = data;
    payload['included'] = included;

    return payload;
  }
});
