import { isEmpty } from '@ember/utils';
// import Domain from './domain-base'; // TODO-FIX domain/domain-base
import Domain from './domain';
import attr from 'ember-data/attr';
import NonRestActions from '../mixins/non-rest-action';
import { inject as service } from '@ember/service';

export default Domain.extend(NonRestActions, {
  config: service(),
  name: attr('string'),
  available: attr('boolean'),
  premium: attr('boolean', { defaultValue: false }),
  position: attr('number'),
  lengthError: null,
  state: 'initial',
  isInvalid(name) {
    if (isEmpty(name)) {
      return true;
    }
    let lengthConfig = this.get('tld.length');
    if (lengthConfig) {
      let { min, max } = lengthConfig;
      if (name.length < min) {
        this.set('lengthError', 'tooShort');
        return true;
      }

      if (max < name.length) {
        this.set('lengthError', 'tooLong');
        return true;
      }
    }
    return false;
  }
});
