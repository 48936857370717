import Service, { inject as service } from '@ember/service';

export default Service.extend({
  store: service(),
  idToAlpha2(id) {
    const country = this.store.peekRecord('country', id);
    return country && country.get('alpha2');
  },
  alpha2ToId(countryCode) {
    const country = this.store.peekAll('country').findBy('alpha2', countryCode);
    return country && country.get('id');
  }
});
