import { isEmpty, isPresent } from '@ember/utils';

import EmberObject from '@ember/object';
import Mixin from '@ember/object/mixin';
import { later } from '@ember/runloop';
import regex from '../utils/regex';

export default Mixin.create({
  PAGE: EmberObject.create({
    CART: 1,
    NEW_CONTACT: 2,
    CONTACTS: 3,
    PAYMENT: 4,
    SUMMARY: 5
  }),
  pages: 5,
  isArray(item) {
    return Object.prototype.toString.call(item) === '[object Array]';
  },
  onBackClose(name) {
    window.location.hash = name;
    window.onhashchange = () => {
      if (!window.location.hash) {
        history.pushState(
          null,
          null,
          window.location.pathname + window.location.search
        );
        history.back();

        this.send(name + 'Close');
      }
    };
  },
  stripAccent(str) {
    let answer = '';
    let stripString =
      'AAAAAAACEEEEIIIIDNOOOOO.OUUUUY..aaaaaaaceeeeiiii' +
      'dnooooo.ouuuuy.yAaAaAaCcCcCcCcDdDdEeEeEeEeEeGgGg' +
      'GgGgHhHhIiIiIiIiIiIiJjKkkLlLlLlLlJlNnNnNnnNnOoOo' +
      'OoOoRrRrRrSsSsSsSsTtTtTtUuUuUuUuUuUuWwYyYZzZzZz.';

    for (let i = 0; i < str.length; i++) {
      let ch = str[i];
      let chIndex = ch.charCodeAt(0) - 192;

      if (chIndex >= 0 && chIndex < stripString.length) {
        let outch = stripString.charAt(chIndex);
        if (outch !== '.') {
          ch = outch;
        }
      }

      answer += ch;
    }

    return answer;
  },
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  contactValidationReturnsErrors(contact, domains) {
    let errors = [];
    if (contact) {
      const isBilling = contact.constructor.modelName === 'billing-profile';
      let onlyCz = domains && domains.isEvery('tldName', 'cz');
      let isPhone = isPresent(contact.get('phone'));
      let isEmail = isPresent(contact.get('email'));
      let isStreet = isPresent(contact.get('street'));
      let isOneName = contact.get('company')
        ? false
        : (contact.get('name') || '').search(regex.FULL_NAME_REGEX) === -1;
      let hiddenAttributes = contact.get('hiddenAttributes');

      if (!onlyCz && contact.get('hasHiddenAttributes')) {
        errors.push('hidden');
      }

      if (hiddenAttributes) {
        isPhone = hiddenAttributes.includes('phone') ? true : isPhone;
        isEmail = hiddenAttributes.includes('email') ? true : isEmail;
        isStreet = hiddenAttributes.includes('street') ? true : isStreet;
      }

      if (this.get('ideas.addressbook') === 'invoice') {
        isPhone = true;
      }

      if (!onlyCz && !isPhone) {
        errors.push('phone');
      }

      if (!isBilling && !isEmail) {
        errors.push('email');
      }

      if (!isStreet) {
        errors.push('street');
      }

      if (this.get('ideas.addressbook') === 'owner' && isOneName) {
        errors.push('name');
      }
    }

    return errors;
  },
  isContactValid(contact, domains) {
    return isEmpty(this.contactValidationReturnsErrors(contact, domains));
  },
  isContactValidComplex(contact, domains) {
    if (!domains) return true;

    let valid = this.isContactValid(contact, domains),
      hidden = contact.get('hasHiddenAttributes'),
      onlyCz = domains.isEvery('tldName', 'cz'),
      isAllowedCountry = this.get('ideas').isAllowedCountry(contact);

    if ((hidden && !onlyCz) || !isAllowedCountry) {
      return false;
    }
    return valid;
  },
  toOrigAdmin(path = '') {
    const userAdapter = this.get('store').adapterFor('user');
    userAdapter
      .ajax(userAdapter.buildURL() + '/sso_urls/original_admin', 'GET', {
        data: { path: path }
      })
      .then(data => {
        later(() => {
          window.location = data.data.attributes.url;
        }, 500);
      });
  }
});
