import Component from '@ember/component';
import { computed } from '@ember/object';
import keyIndexes from 'domena-mobile/utils/key-indexes';
import { inject as service } from '@ember/service';

export default Component.extend({
  blockData: computed('pageSlug', 'datoSlug', function () {
    return this.get(`cms._translationData.${this.pageSlug}.${this.datoSlug}`);
  }),
  btnOutline: false,
  classNames: [
    'landing-page__block',
    'landing-page__general-block',
    'text-center'
  ],
  cms: service(),
  columnsInverted: false,
  mobileColumnInverted: false,
  pageSlug: computed('datoSlug', function () {
    return this.datoSlug.split('-').slice(0, 1).join('-');
  }),
  slugKey: computed('pageSlug', 'datoSlug', function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  }),
  twoColums: false,
  whyItems: computed('pageSlug', 'datoSlug', function () {
    return keyIndexes(
      this.get(`cms._translationData.${this.pageSlug}`),
      `${this.datoSlug}`
    );
  })
});
