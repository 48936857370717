import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  currentUser: service(),
  gtmEvents: service(),
  actions: {
    notifyPhoneChange() {
      this.set('currentUser.user.phone', this.get('currentUser.user.phone'));
    },
    close() {
      this.gtmEvents.logSubmitForm('new-ssl-cert-form');
      tryInvoke(this, 'close');
    }
  }
});
