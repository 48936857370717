import Component from '@ember/component';
import Logger from '../utils/logger';
import fetchAjax from '../utils/ajax';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  fastboot: service(),
  classNames: ['contact-page relative'],
  contactEmail: '',
  contactText: '',
  responseMessage: '',
  actions: {
    contactFormSubmit() {
      const path = this.get('fastboot.isFastBoot')
        ? `${this.get('fastboot.request.protocol')}//${this.get(
            'fastboot.request.host'
          )}/`
        : '/';

      fetchAjax(`${path}feedbacks`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            data: {
              attributes: {
                email: this.get('contactEmail'),
                text: this.get('contactText')
              }
            }
          }
        })
      }).then(
        () => {
          this.set(
            'responseMessage',
            `${this.intl.t('landing_page_contact.thanks_info')}`
          );
        },
        error => {
          Logger.error(error);
          this.set(
            'responseMessage',
            `${this.intl.t('landing_page_contact.error')}`
          );
        }
      );
    }
  }
});
