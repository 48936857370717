import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: ['page', 'login', 'reset_code'],
  page: null,
  login: null,
  reset_code: null,
  showErrors: false,
  ipasError: ''
});
