import { camelize } from '@ember/string';

export default function agreeMessage(items, intl) {
  const isInsurance = items.find(item => camelize(item.type) === 'safetyGuard');
  const isMailHosting = items.find(item => camelize(item.type) === 'email');
  const isSslCert = items.find(item => camelize(item.type) === 'sslCert');

  const links = [
    `&nbsp;<a href="${intl.t(
      'agree_messages.links.link1.link'
    )}" target="_blank" rel="noopener">` +
      `${intl.t(
        'agree_messages.links.link1.text'
      )} <img alt="icon-external" class="p-l-1" height="13" src="/images/svg-icons/ic-external.svg" width="13"></a> `
  ];

  if (isInsurance) {
    links.pushObject(
      `&nbsp;<a href="${intl.t(
        'agree_messages.links.link2.link'
      )}" target="_blank" rel="noopener">` +
        `${intl.t(
          'agree_messages.links.link2.text'
        )} <img alt="icon-external" class="p-l-1" height="13" src="/images/svg-icons/ic-external.svg" width="13"></a> `
    );
  }

  if (isMailHosting) {
    links.pushObject(
      `&nbsp;<a href="${intl.t(
        'agree_messages.links.link3.link'
      )}" target="_blank" rel="noopener">` +
        `${intl.t(
          'agree_messages.links.link3.text'
        )} <img alt="icon-external" class="p-l-1" height="13" src="/images/svg-icons/ic-external.svg" width="13"></a> `
    );
  }

  if (isSslCert) {
    links.pushObject(
      `&nbsp;<a href="${intl.t(
        'agree_messages.links.link4.link'
      )}" target="_blank" rel="noopener">` +
        `${intl.t(
          'agree_messages.links.link4.text'
        )} <img alt="icon-external" class="p-l-1" height="13" src="/images/svg-icons/ic-external.svg" width="13"></a> `
    );
  }

  const lastMessage = links.pop();

  return `${intl.t('agree_messages.message')} ${links.join(', ')}${
    links.length ? 'a ' : ''
  }${lastMessage}`;
}
