import Controller from '@ember/controller';

// https://test.domena.cz/firstpasswordset?hash=d507df681bebe1dd9c98c21d7db6fe8d&login=test-askdfj348jf023u@example.org

export default Controller.extend({
  queryParams: ['login', 'hash'],
  login: null,
  hash: null,
  showErrors: false,
  ipasError: ''
});
