import { all, resolve } from 'rsvp';

import ItemsCartMixin from '../mixins/cart-items-manipulation';
import Logger from '../utils/logger';
import Service from '@ember/service';
import { computed } from '@ember/object';
import intersect from 'domena-mobile/utils/intersect';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

const CONFIG = {
  tldNewContact: [],
  tldSelectContact: ['eu', 'sk', 'com', 'net', 'org', 'biz', 'info'],
  dnsSelect: ['eu']
};

export default Service.extend(ItemsCartMixin, {
  config: CONFIG,
  model: null,
  cart: service(),
  store: service(),
  init() {
    this.set('items', []);
    this._super(...arguments);
  },
  tldNewContact: computed(
    'config.tldNewContact',
    'notDeletedItems.@each.tld',
    function () {
      return isPresent(
        intersect(
          this.get('config.tldNewContact'),
          this.get('notDeletedItems').filterBy('type', 'transfer').mapBy('tld')
        )
      );
    }
  ),
  tldSelectContact: computed(
    'config.tldSelectContact',
    'notDeletedItems.@each.tld',
    function () {
      return isPresent(
        intersect(
          this.get('config.tldSelectContact'),
          this.get('notDeletedItems').filterBy('type', 'transfer').mapBy('tld')
        )
      );
    }
  ),
  withoutContact: computed('tldNewContact', 'tldSelectContact', function () {
    return this.get('tldNewContact') && !this.get('tldSelectContact');
  }),
  dnsSelect: computed(
    'config.dnsSelect',
    'notDeletedItems.@each.tld',
    function () {
      return isPresent(
        intersect(
          this.get('config.dnsSelect'),
          this.get('notDeletedItems').filterBy('type', 'transfer').mapBy('tld')
        )
      );
    }
  ),
  insertFromCart() {
    this.get('items').clear().pushObjects(this.get('cart.notDeletedItems'));
  },
  addItem(type, params) {
    return this.reset().then(() => {
      const store = this.get('store');
      const objectKey = this._getKeyForObject(type, params);
      let parent;
      if (params.parentType && params.parentId) {
        parent = this.get('store').peekRecord(
          `${params.parentType}-cart-item`,
          params.parentId
        );
      }

      if (!params.key) {
        params['key'] = objectKey;
      }
      params['type'] = type;
      params['source'] =
        (parent && parent.get('source')) ||
        params.source ||
        this.get('source') ||
        'admin';
      params['createdAt'] = new Date();
      Logger.log('adding', type, params);
      const item = store.createRecord(`${type}CartItem`, params);
      item.setDefaults();
      this.get('items').pushObject(item);
      return resolve(item);
    });
  },
  replaceAllWithItem(type, params) {
    return this.clear().then(() => {
      return this.addItem(type, params);
    });
  },
  clear() {
    Logger.log('cart mixin reset');
    return all(
      this.get('items')
        .filterBy('isDeleted', false)
        .map(item => item.destroyRecord())
    ).then(() => {
      return this.set('items', []);
    });
  },
  reset() {
    Logger.log('reseting');
    const checkoutModel = this.get('model');
    checkoutModel.reset();
    return checkoutModel.save();
  },
  loadCurrentCheckoutModel() {
    return this.get('store')
      .queryRecord('checkout', { key: 'current' })
      .then(record => {
        if (!record) {
          record = this.get('store').createRecord('checkout', {
            key: 'current'
          });
        }
        this.set('model', record);
        return record;
      });
  },
  getBackUrlForItem(item) {
    if (!item) {
      return 'domains';
    }
    const type = item.get('type');
    // email, registration, renewal, safety-guard, ssl-cert, transfer
    switch (type) {
      case 'email':
        // return `domain-services.show|${item.get('extra.serviceId')}`;
        return 'domain-services';
      case 'registration':
        return 'domains';
      case 'renewal':
        return `domains.show|${item.get('fullName')}`;
      case 'safetyGuard':
        return `domains.show|${item.get('fullName')}`;
      case 'transfer':
        return 'domains';
      default:
        return 'domains';
    }
  }
});
