import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  router: service(),
  _clickHandler: null,
  didInsertElement() {
    this._super(...arguments);
    this.set('_clickHandler', e => {
      let el = e.target;
      if (el.tagName !== 'A') {
        el = el.closest('a');
      }
      const hash = el.hash;
      const url = el.getAttribute('href');
      if (url) {
        if (url.indexOf('http://') == -1 && url.indexOf('https://') == -1) {
          const info = this.router.recognize(url);
          if (info && this.router.currentRouteName !== info.name) {
            e.preventDefault();
            const transition = this.router.transitionTo(info.name);
            transition.promise.then(() => {
              window.location.hash = hash;
            });
          }
        } else {
          el.setAttribute('target', '_blank');
          el.setAttribute('rel', 'noopener');
        }
      }
    });
    this.$().on('click', 'a', this.get('_clickHandler'));
  },
  willDestroyElement() {
    this._super(...arguments);
    this.$().off('click', 'a', this.get('_clickHandler'));
  }
});
