import Route from '@ember/routing/route';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/contact'
      }
    }
  ]),
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  },
  updateContactEmail(controller) {
    let userEmail = this.get('currentUser.email');

    if (userEmail) {
      controller.set('contactEmail', userEmail);
    }
  },
  setupController(controller) {
    this.updateContactEmail(controller);
  },
  resetController(controller) {
    this.updateContactEmail(controller);
  }
});
