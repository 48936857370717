import { next, scheduleOnce } from '@ember/runloop';

import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { computed } from '@ember/object';
import config from '../config/environment';
import { inject as service } from '@ember/service';
import {
  navbarHeightDesktop,
  navbarHeightMobile
} from '../utils/page-elements-sizing';

const UPSELL_ITEMS = {
  cs: ['vizitka', 'email', 'office', 'ebook'],
  sk: ['email', 'office']
};

const CIRCLE_TLD_NAMES = [
  'space',
  'coffee',
  'store',
  'love',
  'tech',
  'sexy',
  'online',
  'yoga'
];

export default Component.extend(Common, {
  cart: service(),
  classNames: ['landing-page-domains', 'reset-offset'],
  config,
  currentUser: service(),
  deviceDetection: service(),
  enableBlogPosts: config.LOCALE === 'cs',
  enabledItems: false, // transfer ted neresime
  enableTips: config.LOCALE === 'cs',
  intl: service(),
  modal: service(),
  model: null,
  name: '',
  router: service(),
  session: service(),
  store: service(),
  upsellItems: UPSELL_ITEMS[config.LOCALE],
  _transferAction() {
    this.get('modal').show('forms/transfer-domain-form', null, {
      type: 'domena'
    });
  },

  circleTldNames: CIRCLE_TLD_NAMES,
  circleDomains: computed('model.tldPrices.@each.tld', function () {
    const circleTldNames = this.get('circleTldNames');
    return this.get('model.tldPrices').filter(tldPrice =>
      circleTldNames.includes(tldPrice.get('tld'))
    );
  }),

  actions: {
    domainNameAction() {
      $('html, body').animate(
        {
          scrollTop: 0
        },
        'fast'
      );
      $('.domain-search-box input').focus();
    },
    expandAdvice(advice) {
      let $elm = this.$('.advice-row.' + advice);
      $elm.toggleClass('advice-row--expanded');
    },
    scrollToPickGood() {
      const offsetTop = this.$('#how-to-pick-good-domain-name').first().offset()
        .top;

      $('html, body').animate(
        {
          scrollTop:
            offsetTop -
            (this.get('deviceDetection.isDesktop')
              ? navbarHeightMobile
              : navbarHeightDesktop)
        },
        'fast'
      );
    },
    transferDomain() {
      this._transferAction();
    },
    transitionToHosting() {
      this.router.transitionTo('hosting');
    }
  },
  didInsertElement() {
    this._super(...arguments);
    const $elem = $(window.location.hash.substring(1));
    if ($elem.length) {
      scheduleOnce('afterRender', () => {
        next(() => {
          this._scroll($elem);
        });
      });
    }
  },
  _scroll($elem) {
    const headerHeight = $('.top-page-stack').height();
    $('html,body').animate(
      { scrollTop: $elem.position().top - headerHeight + 60 },
      'slow'
    );
  }
});
