import Common from '../mixins/common';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend(Common, {
  session: service(),
  enabledItems: false,
  queryParams: ['previousPage'],
  previousPage: null,
  passwordChanged: null,
  mojeIdIdentity: null,
  loginLoading: false,
  actions: {
    afterLoginSuccess() {
      // TODO after login
    }
  }
});
