import CartItem from './cart-item';
import DS from 'ember-data';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default CartItem.extend({
  currentUser: service(),
  tld: DS.attr('string'),
  years: alias('quantity'),
  domain: null, // added when inserted to checkout
  tldObject: computed('tld', function () {
    return this.store.peekRecord('tld', this.tld);
  }),
  yearsData: computed('_priceObject.renewal', 'domain.expiresOn', function () {
    let renewal = this.get('_priceObject.renewal');
    if (isEmpty(renewal)) {
      return [];
    }

    let yearsArray = Object.keys(renewal).map(item => {
      return parseInt(item);
    });

    let yearDiff = moment().diff(this.get('domain.expiresOn'), 'years', true);

    if (yearDiff < 0) {
      yearDiff = -Math.floor(yearDiff);
      const max = yearsArray[yearsArray.length - 1];
      yearsArray = yearsArray.filter(year => year <= max - yearDiff);
    }

    return yearsArray;
  }),
  setDefaults() {
    if (!this.get('years')) {
      this.set('years', this.get('defaultQuantity'));
    }
  },
  defaultQuantity: computed('_priceObject', function () {
    const regObject = this.get('_priceObject.renewal');
    if (!regObject) {
      return;
    }
    return Math.min(...Object.keys(regObject).map(item => parseInt(item, 10)));
  }),
  itemPrice: computed(
    '_priceObject.renewal',
    'quantity',
    'basketPrice',
    function () {
      if (this.get('basketPrice') === 0)
        return parseFloat(this.get('basketPrice'));

      return parseFloat(
        this.get('basketPrice') ||
          this.get(
            `_priceObject.renewal.${
              this.get('years') || this.get('defaultQuantity')
            }`
          )
      );
    }
  ),
  domainName: alias('name'),
  fullName: computed('name', 'tld', function () {
    return `${this.get('name')}.${this.get('tld')}`;
  }),
  displayName: alias('fullName'),
  attrs() {
    return {
      ...this._super(),
      name: `${this.name}.${this.tld}`,
      pack: 'renewal',
      type: 'renewal'
    };
  }
});
