import WyApiSerializer from './wy-api';

export default WyApiSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'createRecord') {
      payload = { data: payload };
    }
    return this._super(store, primaryModelClass, payload, id, requestType);
  }
  // serialize: function (snapshot, options) {
  //   var json = this._super(snapshot, options);

  //   if (json.data.attributes.ic === '') {
  //     json.data.attributes.ic = null;
  //   }
  //   if (json.data.attributes.dic === '') {
  //     json.data.attributes.dic = null;
  //   }
  //   if (json.data.attributes.phone === '') {
  //     json.data.attributes.phone = null;
  //   }
  //   if (json.data.attributes['company-name'] === '') {
  //     json.data.attributes['company-name'] = null;
  //   }
  //   if (json.data.attributes.city === '') {
  //     json.data.attributes.city = null;
  //   }
  //   if (json.data.attributes.zip === '') {
  //     json.data.attributes.zip = null;
  //   }
  //   if (json.data.attributes.password === '') {
  //     json.data.attributes.password = null;
  //   }

  //   return json;
  // }
});
