import { reject, resolve } from 'rsvp';

import Component from '@ember/component';
import addDefaultParams from '../../utils/default-wyapi-params';
import ajax from '../../utils/ajax';
import config from 'domena-mobile/config/environment';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';

const TYPES = { cs: 'ares', sk: 'finstat' };

export default Component.extend({
  store: service(),
  countryConverter: service(),
  tagName: 'form',
  classNames: ['contact-form'],

  didInsertElement() {
    this._super(...arguments);
    if (!this.countries) {
      this.set('countries', this.store.peekAll('country'));
    }
  },
  _getData(type) {
    this.set('contactLoadingError', false);
    this.set('contactLoading', true);
    return ajax(
      addDefaultParams(
        `${config.API_HOST}/order/esc/${this.get('model.ic')}?type=${type}`
      ),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    ).then(data => {
      if (!data.success || !data.data) {
        this.set('contactLoadingError', true);
        this.set('contactLoading', false);
        return resolve();
      }

      const { data: result } = data || {};

      this.get('model').setProperties({
        city: result.town,
        countryId: this.countryConverter.alpha2ToId(result.state),
        // invoiceState: result.state,
        legalForm: result.legal_form,
        dic: result.taxid,
        contactName: '',
        companyName: result.name,
        street: result.street,
        zip: result.postcode
      });

      scheduleOnce('afterRender', () => {
        const inputs = this.element.querySelectorAll('input');
        inputs.forEach(element => {
          if (element.value) {
            element.dispatchEvent(new Event('blur'));
          }
        });
      });
      this.set('contactLoading', false);
      this.set('submitContactsFromAres', true);
      return resolve();
    }, reject);
  },
  actions: {
    loadContactByIc() {
      return this._getData(TYPES[config.LOCALE]);
    }
  }
});
