import Service, { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';

import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Service.extend({
  store: service(),
  events: service(),
  checkout: service(),
  currentUser: service(),
  navDots: service(),
  tips: service(),
  tlds: null,
  addressbook: 'owner',
  fixed: false,
  // TODO-FIX tohle uptavit
  cart: service(),
  items: alias('cart.notDeletedItems'),
  init() {
    this._super(...arguments);
    this.set('tlds', []);
  },

  clear() {
    this.get('tips').resetTip();
    return this.get('checkout').reset();
  },
  clearOrder() {
    const order = this.get('checkout.model');
    if (this.get('totalPrice') > this.get('currentUser.deposit')) {
      order.set('paymentMethod', null);
    }
    order.resetPartial();
    this.set('addressbook', 'owner');
  },
  clearSpecials() {
    const order = this.get('checkout.model');
    order.resetSpecials();
  },

  // TODO-FIX tohle ted nepujde
  allowedCountryIds: computed('items.[]', function () {
    return this.get('items')
      .reduce((prev, item) => {
        let countries = item.get('tld.contactCountries') || []; // pro mailhosting neni tld
        if (isEmpty(prev)) {
          return countries;
        }
        if (isEmpty(countries)) {
          return prev;
        }
        return prev.filter(i => {
          return countries.indexOf(i) !== -1;
        });
      }, [])
      .uniq();
  }),
  // TODO-FIX tohle ted nepujde
  isAllowedCountry(contact) {
    return !this.get('items')
      .map(item => {
        let countries = item.get('tld.contactCountries');
        if (isPresent(countries)) {
          return countries.includes(contact.get('countryId'));
        }
      })
      .compact()
      .some(i => !i);
  },
  // TODO-FIX tohle ted nepujde
  hasOnlyCz: computed('items.[]', function () {
    return this.get('items').isEvery('tldName', 'cz');
  }),
  // TODO-FIX tohle ted nepujde
  hasSkEu: computed('items.[]', function () {
    let tldNames = this.get('items').mapBy('tldName'),
      sk = tldNames.includes('sk') ? 'sk' : '',
      eu = tldNames.includes('eu') ? 'eu' : '';
    return sk || eu;
  }),
  // TODO-FIX tohle ted nepujde
  cartValid: computed(
    'items.[]',
    'items.@each.{notBuy,mailHostingType}',
    function () {
      let items = this.get('items');
      if (!items.isEvery('notBuy')) {
        return true;
      }
      let anyMailHosting = items.isAny('mailHostingType');
      if (!anyMailHosting) {
        this.get('navDots').reset();
      }
      return anyMailHosting;
    }
  ),
  // TODO-FIX tohle ted nepujde
  adminContactState: computed('items.[]', function () {
    let state = '',
      tlds = { allowed: [], notAllowed: [] },
      items = this.get('items').filterBy('notBuy', false),
      isEveryOptional = items.isEvery('tld.adminContact', 'optional'),
      isEveryNotAllowed = items.isEvery('tld.adminContact', 'not_allowed'),
      isAnyNotAllowed = items.isAny('tld.adminContact', 'not_allowed'),
      isAnyMandatory = items.isAny('tld.adminContact', 'mandatory');

    if (isEveryOptional) {
      state = 'optional';
    } else if (isEveryNotAllowed) {
      state = 'not_allowed';
    } else if (isAnyMandatory && isAnyNotAllowed) {
      state = 'mixed';
    } else if (isAnyMandatory) {
      state = 'mandatory';
    }

    if (state === 'not_allowed' || state === 'mixed') {
      tlds.allowed = items.filter(item => {
        let itemState = item.get('tld.adminContact');
        return itemState === 'optional' || itemState === 'mandatory';
      });
      tlds.notAllowed = items.filterBy('tld.adminContact', 'not_allowed');

      for (let tld in tlds) {
        tlds[tld] = tlds[tld].map(item => `.${item.get('tldName')}`).uniq();
      }
    }

    return { state, tlds };
  })
});
