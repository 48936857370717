import DS from 'ember-data';
import { alias } from '@ember/object/computed';

export default DS.Model.extend({
  basePrice: DS.attr('number'),
  // category: DS.attr('string'),
  customerDiscount: DS.attr('number'),
  customerPrice: DS.attr('number'),
  description: DS.attr('string'),
  pack: DS.attr('string'),
  packParams: DS.attr(),
  supportedPeriods: DS.attr(),
  title: DS.attr('string'),
  price: alias('customerPrice')
});
