import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Model from 'ember-data/model';
import DS from 'ember-data';

export default Model.extend({
  currentUser: service(),
  tld: DS.belongsTo('tld'),
  price: DS.belongsTo('price'),
  categories: alias('tld.categories'),
  tldName: alias('tld.name'),
  priceValue: alias('price.default'),
  activeOffer: alias('tld.activeOffer'),
  perYearValue: computed(
    'price.perYear',
    'currentUser.loadedData.prices',
    function () {
      if (this.get('currentUser.loadedData.prices') && this.get('price')) {
        return this.get('price.perYear');
      }
      return 0;
    }
  ),
  totalPrice: alias('price.perYear')
});
