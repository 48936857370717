import { alias, filter } from '@ember/object/computed';
import { computed, observer } from '@ember/object';
/* eslint-disable max-lines */
import { isEmpty, isPresent } from '@ember/utils';

import AgreeMessage from '../utils/agree-message';
import Common from '../mixins/common';
import Controller from '@ember/controller';
import ShowRegistrationRulesConsent from '../utils/show-registration-rules-consent';
import { all } from 'rsvp';
import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/string';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { trySet } from '@ember/object';

export default Controller.extend(Common, {
  session: service(),
  currentUser: service(),
  cart: service(),
  gtmEvents: service(),
  checkout: service(),
  ideas: service(),
  intl: service(),
  tldException: service(),
  navDots: service(),
  modal: service(),
  taskQueue: service(),
  orderService: service('order'),
  queryParams: ['page', 'addressbook', 'signin'],
  countries: alias('model.countries'),
  page: 1,
  order: alias('checkout.model'), // TODO-FIX primo checkout.model
  addressbook: alias('ideas.addressbook'),
  ownerContact: alias('order.ownerContact'),
  invoiceContact: alias('order.invoiceContact'),
  adminContact: alias('order.adminContact'),
  paymentMethod: alias('order.paymentMethodObject'),
  userSelectedPaymentMethod: null,
  notDeletedItems: alias('checkout.notDeletedItems'),
  agreeMessage: computed('notDeletedItems.@each.type', function () {
    const items = this.get('notDeletedItems');
    return htmlSafe(AgreeMessage(items, this.intl));
  }),
  showRegistrationRulesConsent: computed(
    'notDeletedItems.@each.tldName',
    function () {
      return ShowRegistrationRulesConsent(this.get('notDeletedItems'));
    }
  ),
  selectedContact: computed('addressbook', 'ownerContact', 'invoiceContact', {
    get() {
      if (this.get('addressbook') === 'invoice') {
        return this.get('invoiceContact');
      }
      return this.get('ownerContact');
    },
    set(key, value) {
      if (this.get('addressbook') === 'invoice') {
        this.set('invoiceContact', value);
      } else {
        this.set('ownerContact', value);
      }
      return value;
    }
  }),
  signin: null,
  signinObserver: observer('page', 'session.isAuthenticated', function () {
    this.set(
      'signin',
      this.get('page') === this.get('PAGE.CONTACTS') &&
        !this.get('session.isAuthenticated')
        ? true
        : null
    );

    if (this.get('page') === this.get('PAGE.SUMMARY')) {
      this.get('checkout').insertFromCart();
    }
  }),
  mailHostingType: '',
  registrationRulesConsent: alias('order.registrationRulesConsent'),
  mailHostingEmail: computed('order.mailHostingEmail', {
    get() {
      return this.get('order.mailHostingEmail');
    },
    set(key, value) {
      this.set('order.mailHostingEmail', value);
      this.get('order').save();
      return value;
    }
  }),
  mailHostingName: computed('order.mailHostingName', {
    get() {
      return this.get('order.mailHostingName');
    },
    set(key, value) {
      this.set('order.mailHostingName', value);
      this.get('order').save();
      return value;
    }
  }),
  mailHostingDialingCode: computed('order.mailHostingDialingCode', {
    get() {
      return this.get('order.mailHostingDialingCode');
    },
    set(key, value) {
      this.set('order.mailHostingDialingCode', value);
      this.get('order').save();
      return value;
    }
  }),
  mailHostingPhone: computed('order.mailHostingPhone', {
    get() {
      return this.get('order.mailHostingPhone');
    },
    set(key, value) {
      this.set('order.mailHostingPhone', value);
      this.get('order').save();
      return value;
    }
  }),
  coupon: computed('order.coupon', {
    get() {
      return this.get('order.coupon');
    },
    set(key, value) {
      this.cart.setCoupon(value);
      this.set('order.coupon', value);
      this.get('order').save();
      return value;
    }
  }),
  autoRenew: computed('order.autoRenew', {
    get() {
      return this.get('order.autoRenew');
    },
    set(key, value) {
      this.set('order.autoRenew', value);
      this.get('order').save();
      return value;
    }
  }),
  nsset: null,
  zoneFile: null,
  lastCreatedContact: null,
  mailHosting: computed('cart.items.@each.type', function () {
    const ALLOWED_TYPES = [
      'IGN_FlexiMail',
      'ExchangeOnline',
      'Microsoft365_B_Basic',
      'Microsoft365_B_Standard'
    ];

    if ((this.get('cart.items') || []).isAny('type', 'email')) {
      return this.get('cart.items')
        .mapBy('extra.mailHostingType')
        .map(
          type =>
            ALLOWED_TYPES.includes(type) &&
            `${this.intl.t(`cms.produkty.${dasherize(type)}.title`)}`
        )
        .filter(Boolean)
        .join(' a ');
    } else {
      return false;
    }
  }),
  needInvoiceContact: computed('cart.baseItems.[]', function () {
    return this.get('cart.baseItems').reduce((out, item) => {
      return (
        out &&
        ['email', 'sslCert', 'hosting', 'transfer'].includes(item.get('type'))
      );
    }, true);
  }),
  isEmailServiceCompleteOpen: false,
  showErrors: false,
  pagesChange: observer('page', function () {
    this.set('showErrors', false);
    this.set('agreeErrors', false);

    if (this.get('page') > this.get('navDots.visited')) {
      this.set('navDots.visited', this.get('page'));
    }
    this.set('lastCreatedContact', null);

    if (window && window.scrollTo) {
      window.scrollTo(0, 0);
    }

    if (this.get('page') === this.get('PAGE.SUMMARY')) {
      scheduleOnce('afterRender', () => {
        this.send('checkUserDataValidity');
        this.send('emailServiceCompleteOpen');
      });
    }

    if (this.get('page') === this.get('PAGE.CONTACTS')) {
      scheduleOnce('afterRender', () => {
        const registrationItems = this.get('cart.items').filter(item => {
          return ['registration'].includes(item.get('type'));
        });
        all(
          registrationItems.map(registrationItem => {
            const model = this.get('store').createRecord('domainCheck');
            return this.get('taskQueue')
              .enqueue(
                'domainCheckTask',
                model,
                registrationItem.get('fullName')
              )
              .then(result => result);
          })
        ).then(results => {
          const checkResults = results.map(item => {
            return {
              available: item && item.available,
              cartItem: registrationItems.findBy('fullName', item && item.name)
            };
          });
          const unAvailableItems = checkResults.filterBy('available', false);
          if (isPresent(unAvailableItems)) {
            this.get('modal').show(
              'info/unavailable-domains',
              unAvailableItems,
              {
                header: `${this.get('intl')
                  .t('models.domain', { count: unAvailableItems.length })
                  .toString()
                  .capitalize()}
                ${unAvailableItems
                  .mapBy('cartItem.fullName')
                  .join(', ')} ${this.intl.t('info.unavailable_domains')}`,
                onClose: () => {
                  all(
                    unAvailableItems
                      .map(item => item.cartItem)
                      .filter(item => !item.get('isDeleted'))
                      .map(item =>
                        this.get('cart').removeItem(
                          item.type,
                          item.getProperties('name', 'tld')
                        )
                      )
                  ).then(() => {
                    if (this.get('cart.isAny')) {
                      this.transitionToRoute('cart', {
                        queryParams: { page: this.get('PAGE.CART') }
                      });
                    } else {
                      this.transitionToRoute('home');
                    }
                  });
                }
              }
            );
          }
        });
      });
    }

    if (
      // skip payment if free
      this.get('page') === this.get('PAGE.PAYMENT') &&
      this.get('cart.totalPrice') === 0
    ) {
      this.send('toPage', this.get('PAGE.SUMMARY'));
      return;
    }
    this.gtmEvents.logCartChange(this.get('page'));
  }),
  backToSummary: false,
  suggestedName: computed('cart.baseItems.[]', function () {
    return this.get('cart.baseItems')
      .sortBy('createdAt')
      .get('lastObject.domainName');
  }),
  _setDefaultContacts() {
    const contacts = this.get('contacts');
    let mostUsed = contacts
      .filterBy('usageCount')
      .sortBy('usageCount')
      .reverse()
      .get('firstObject');

    // defaultni vybrany vlastnik - nejcastejsi kontakt
    if (isEmpty(this.get('ownerContact'))) {
      if (this.get('addressbook') === 'owner') {
        if (contacts.length === 1) {
          this.set('ownerContact', contacts.get('firstObject'));
        } else if (mostUsed) {
          this.set('ownerContact', mostUsed);
        }
      }
    }

    // defaultni vybrany fakturacni kontakt - vlastnik
    if (
      this.get('addressbook') === 'invoice' &&
      isEmpty(this.get('invoiceContact'))
    ) {
      this.set('invoiceContact', this.get('ownerContact'));
    }
  },
  observer: observer('contacts.[]', 'page', function () {
    if (this.get('session.isAuthenticated')) {
      this._setDefaultContacts();
    }
  }),
  contacts: computed(
    'addressbook',
    'ownerContact',
    'invoiceContact',
    'lastCreatedContact.id',
    'currentUser.{contacts.[],invoiceContacts.[],loadedData.invoiceContacts,loadedData.contacts,contacts.@each.isLastImported}',
    function () {
      const contacts = this.get(
        this.addressbook !== 'invoice'
          ? 'currentUser.contacts'
          : 'currentUser.invoiceContacts'
      );

      function moveToFirstPosition(firstContact) {
        if (!firstContact) {
          return;
        }
        contacts.forEach(contact => trySet(contact, 'isFirst', false));
        trySet(firstContact, 'isFirst', true);
      }

      if (isEmpty(contacts)) return [];
      let lastImportedId = this.get('lastCreatedContact.id');
      if (lastImportedId) {
        let contact = contacts.findBy('id', lastImportedId);
        if (contact) {
          moveToFirstPosition(contact);
          this.set('selectedContact', contact);
          this.set('lastCreatedContact', null);
        }
      }

      // defaultni vybrany fakturacni kontakt - vlastnik
      if (
        this.get('addressbook') === 'invoice' &&
        isEmpty(this.get('invoiceContact'))
      ) {
        if (contacts.length >= 10) {
          moveToFirstPosition(this.get('ownerContact'));
        }
      }
      return contacts.compact().sort((a, b) => {
        return (b.get('isFirst') ? 1 : 0) - (a.get('isFirst') ? 1 : 0);
      });
    }
  ),
  contactsWithoutExceptions: computed(
    'contacts.[]',
    'cart.baseItems.[]',
    function () {
      return this.get('contacts').filter(contact => {
        return !this.get('tldException').exceptionsCheck(
          this.get('cart.baseItems'),
          contact
        );
      });
    }
  ),
  contactsValid: filter('contactsWithoutExceptions', function (contact) {
    return contact && this.isContactValid(contact, this.get('cart.baseItems'));
  }),
  orderValid: computed('order.paymentMethod', function () {
    if (this.get('checkout.totalPrice') === 0) {
      return true;
    }
    const paymentMethod = this.get('order').get('paymentMethod');
    return paymentMethod;
  }),
  favoritesContacts: computed('contactsValid.[]', function () {
    let contacts = this.get('contactsValid');
    let mostUsed = contacts
      .filterBy('usageCount')
      .sortBy('usageCount')
      .slice(-3)
      .reverse();
    let lastUsed = contacts
      .filterBy('lastUsedAt')
      .sortBy('lastUsedAt')
      .get('lastObject');

    if (mostUsed && mostUsed.includes(lastUsed)) {
      return mostUsed.compact();
    } else {
      let mixed = [];
      mixed = mixed.concat(mostUsed.slice(0, 2));
      mixed.push(lastUsed);
      return mixed.compact();
    }
  }),
  selectedContactValid: computed(
    'selectedContact',
    'cart.baseItems.[]',
    function () {
      return this.isContactValid(
        this.get('selectedContact'),
        this.get('cart.baseItems')
      );
    }
  ),
  vatRate: computed(
    'ownerContact.vatRate',
    'invoiceContact.vatRate',
    function () {
      const contact = this.get('invoiceContact')
        ? this.get('invoiceContact')
        : this.get('ownerContact');
      return contact && contact.get('vatRate') ? contact.get('vatRate') : 1.21;
    }
  ),
  isExceptions: computed('ownerContact', function () {
    return (
      this.get('contacts.length') !==
      this.get('contactsWithoutExceptions.length')
    );
  }),
  isExceptionsOpen: false,
  exceptionsCart: computed('cart.baseItems.[]', function () {
    let tlds = ['es', 'se', 'dk', 'xxx', 'nu'];
    let out = [];

    this.get('cart.baseItems').forEach(domain => {
      if (tlds.includes(domain.get('tldName'))) {
        out.pushObject('.' + domain.get('tldName'));
      }
    });

    return out.join(' / ');
  }),
  httpsTlds: computed('cart.baseItems.[]', function () {
    let out = [];

    this.get('cart.baseItems').forEach(domain => {
      if (domain.get('tldObject.httpsOnly')) {
        out.pushObject('.' + domain.get('tldName'));
      }
    });

    return out.map(item => item.toUpperCase()).join(' a ');
  }),
  agreeWithTerms: false,
  // TODO-FIX ideas jsou jinak
  extraInfo: computed(
    'ideas.items.[]',
    'ownerContact.{company,dic}',
    'order.{identityCardNumber,vatNumber,xxxActive,xxxId}',
    function () {
      let data = [];
      let company = this.get('ownerContact.company');
      let order = this.get('order');
      let intl = this.get('intl');
      this.get('ideas.items').forEach(domain => {
        let attrName = company
          ? domain.get('tldObject.attrCompany')
          : domain.get('tldObject.attrPerson');
        switch (attrName) {
          case 'identity_card':
            data.pushObject(
              intl.t('specials_texts.identityCard', {
                domainName: domain.get('fullName'),
                identityCardNumber: order.get('identityCardNumber')
              })
            );
            break;
          case 'dic':
            if (order.get('vatNumber') !== this.get('ownerContact.dic')) {
              data.pushObject(
                intl.t('specials_texts.vat', {
                  domainName: domain.get('fullName'),
                  vatNumber: order.get('vatNumber')
                })
              );
            }
            break;
          case 'xxx_id':
            if (order.get('xxxActive')) {
              data.pushObject(
                intl.t('specials_texts.xxxActive', {
                  domainName: domain.get('fullName'),
                  xxxId: order.get('xxxId')
                })
              );
            } else {
              data.pushObject(
                intl.t('specials_texts.xxxBlocked', {
                  domainName: domain.get('fullName')
                })
              );
            }
            break;
        }
      });
      return data;
    }
  ),
  extraInfoMailInfo: computed('cart.items.@each.type', function () {
    return this.get('cart.items').filter(
      item =>
        item.get('type') === 'email' &&
        item.get('mailHostingObject.customerContact')
    );
  }),
  agreeErrors: false,
  orderLoading: false,
  actions: {
    disabled() {
      return false;
    },
    nextPage() {
      this.get('page') == this.get('PAGE.CART')
        ? this.set('page', this.get('PAGE.CONTACTS'))
        : this.incrementProperty('page');
    },
    prevPage() {
      this.get('page') == this.get('PAGE.CONTACTS')
        ? this.set('page', this.get('PAGE.CART'))
        : this.decrementProperty('page');
    },
    toPage(page) {
      if (
        // skip payment if free
        page !== this.get('PAGE.PAYMENT') ||
        this.get('cart.totalPrice') !== 0
      ) {
        this.set('page', page);
      }
    },
    toOwner() {
      this.send('toPage', this.get('PAGE.CONTACTS'));
      this.set('addressbook', 'owner');
      this.get('ideas').clearSpecials();
    },
    toInvoice() {
      this.send('toPage', this.get('PAGE.CONTACTS'));
      this.set('addressbook', 'invoice');
    },
    openInvoiceModal() {
      this.get('modal').show('forms/contact-select-full', null, {
        expandAll: true,
        header: this.intl.t('contact-select-full.header'),
        invoiceContact: true,
        onSelect: selectedContact => {
          this.set('invoiceContact', selectedContact);
        },
        selected: this.get('invoiceContact')
      });
    },
    findMore() {
      this.send('transitionTo', 'home');
    },
    toLogin() {
      this.send('toPage', this.get('PAGE.CONTACTS'));

      if (this.get('session.isAuthenticated')) {
        this.send('toContacts');
      }
    },
    toContacts() {
      if (this.get('needInvoiceContact')) {
        this.set('addressbook', 'invoice');
        this.set('ownerContact', null);
      } else {
        this.set('addressbook', 'owner');
      }

      if (this.get('session.isAuthenticated')) {
        this.send('nextPage');
      } else {
        this.send('toLogin');
      }
    },
    additionalOpen() {
      this.send('emailServiceCompleteOpen');
      this.send('checkUserDataValidity');
      if (!this.get('isEmailServiceCompleteOpen')) {
        this.send('exceptionsOpen');
      }
    },
    checkUserDataValidity() {
      if (
        !this.get('currentUser.user.contactPhone') ||
        this.get('currentUser.user.contactPhone').length === 0
      ) {
        this.modal.show('forms/addition-user-info', null, {
          header: this.intl.t('addition-user-info.missing')
        });
        this.send('toPage', this.get('PAGE.CONTACTS'));
      }
    },
    emailServiceCompleteOpen(edit) {
      if (!this.get('mailHosting')) {
        return;
      }

      if (!this.get('mailHostingEmail')) {
        this.set('mailHostingEmail', this.get('currentUser.email'));
      }
      if (!this.get('mailHostingPhone')) {
        this.set('mailHostingPhone', this.get('currentUser.phoneWithoutDial'));
        if (!this.get('mailHostingPhone')) {
          this.set('isEmailServiceCompleteOpen', true);
        }
      }

      if (
        this.get('extraInfoMailInfo.length') &&
        (!this.get('mailHostingEmail') || !this.get('mailHostingName'))
      ) {
        this.set('isEmailServiceCompleteOpen', true);
      }

      if (edit) {
        this.set('isEmailServiceCompleteOpen', true);
      }
    },
    exceptionsOpen() {
      if (
        this.get('tldException').checkAll(
          this.get('cart.baseItems'),
          this.get('ownerContact'),
          this.get('order')
        )
      ) {
        this.get('order').save();
        this.send('nextPage');
      } else {
        this.set('isExceptionsOpen', true);
      }
    },
    exceptionsEditOpen() {
      this.setProperties({
        identityCardNumberPrev: this.get('order.identityCardNumber'),
        vatNumberPrev: this.get('order.vatNumber'),
        xxxIdPrev: this.get('order.xxxId')
      });
      this.get('tldException').checkAll(
        this.get('cart.baseItems'),
        this.get('ownerContact'),
        this.get('order'),
        true
      );
      this.set('isExceptionsOpen', true);
    },
    exceptionsClose() {
      if (this.get('page') === this.get('PAGE.SUMMARY')) {
        this.get('order').setProperties({
          identityCardNumber: this.get('identityCardNumberPrev'),
          vatNumber: this.get('vatNumberPrev'),
          xxxId: this.get('xxxIdPrev')
        });
        this.set('isExceptionsOpen', false);
      } else {
        this.send('exceptionsSubmit');
      }
    },
    exceptionsSubmit() {
      this.get('tldException').errorReset();
      this.get('order').save();
      this.set('isExceptionsOpen', false);
    },
    orderSubmit() {
      if (this.get('agreeWithTerms')) {
        this.set('agreeErrors', false);
      } else {
        scheduleOnce('afterRender', () => {
          window.scroll({
            top: document.querySelector('body').clientHeight,
            behavior: 'smooth'
          });
        });
        this.set('agreeErrors', true);
        return;
      }
      if (
        !this.get('registrationRulesConsent') &&
        isPresent(this.get('showRegistrationRulesConsent'))
      ) {
        scheduleOnce('afterRender', () => {
          window.scroll({
            top: document.querySelector('body').clientHeight,
            behavior: 'smooth'
          });
        });

        this.set('agreeErrors', true);

        return;
      } else {
        this.set('agreeErrors', false);
      }
      this.set('orderLoading', true);

      scheduleOnce('afterRender', () =>
        this.orderService
          .createOrder(this.get('checkout.notDeletedItems'))
          .then(
            order => {
              const paymentOnline = this.get(
                'order.paymentMethodObject.online'
              );
              const redirectUrl = order.get('paymentLink');

              if (!order.get('invoice')) {
                this.replaceRoute('thanks');
                return;
              }

              if (paymentOnline) {
                if (!redirectUrl) {
                  // reset order items in cookies - order failed
                  document.cookie = 'order_items=; path=/';
                  throw new Error('Payment link missing');
                }
                window.location = redirectUrl;
              } else {
                if (order.get('invoice.invoice_status') === 'PAID') {
                  this.replaceRoute('thanks', {
                    queryParams: {
                      invoice_id: this.get('order.invoice.IDinvoice')
                    }
                  });
                } else {
                  this.replaceRoute('payment', {
                    queryParams: {
                      invoice_id: this.get('order.invoice.IDinvoice')
                    }
                  });
                }
              }
            },
            error => {
              // reset order items in cookies - order failed
              document.cookie = 'order_items=; path=/';
              this.replaceRoute('order-all-fail', {
                queryParams: {
                  errorCodes: (error.errors || []).mapBy('detail')
                }
              });
            }
          )
          .finally(() => {
            this.set('orderLoading', false);
          })
      );
    },
    openOwnerHelp() {
      this.modal.show('info/owner-help', null, {
        header: this.intl.t('cart.summary.subtitle4'),
        modalClasses: 'info-window'
      });
    },
    contactSelectedUnshift(/* selected */) {
      // this.set('lastCreatedContact', selected); // TODO je to k necemu potreba?
    }
  }
});
