import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/signin'
    }
  }
];

export default Route.extend({
  session: service(),
  currentUser: service(),
  queryParams: {
    email: {
      refreshModel: true
    },
    mojeid: {},
    url: {},
    passwordChanged: {}
  },
  mojeIdAuth: null,
  headTags: HEAD_TAGS,
  beforeModel() {
    if (this.get('session.isAuthenticated')) {
      this.replaceWith('home');
    }
  },
  model(params) {
    const user = this.store.createRecord('user', { key: 'login_tmp' });

    if (params.email && params.mojeid) {
      this.set('mojeIdAuth', {
        email: params.email,
        mojeid: params.mojeid,
        url: params.url
      });
      user.set('login', params.email);
    }
    user.set('toOrigAdminCheck', true);
    return user;
  },
  setupController(controller) {
    this._super(...arguments);
    controller.set('mojeIdIdentity', this.get('mojeIdAuth.mojeid'));
  },
  resetController(controller) {
    const user = controller.get('model');
    user.rollbackAttributes();
    controller.setProperties({
      email: undefined,
      mojeid: undefined,
      passwordChanged: null
    });
  },
  actions: {
    willTransition({ targetName }) {
      if (targetName !== 'signup') {
        this.set('currentUser.afterLoginAction', null);
      }
    }
  }
});
