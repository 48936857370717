import { helper } from '@ember/component/helper';

export function contactAttrVisible(params /* , hash*/) {
  if (params[0] === undefined) {
    return true;
  }
  return params[0].indexOf(params[1]) === -1;
}

export default helper(contactAttrVisible);
