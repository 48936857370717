import Component from '@ember/component';

export default Component.extend({
  classNames: ['grid-gallery'],
  actions: {
    prevSlide() {
      const elemWidth = this.element.querySelector('.item').clientWidth;

      this.element.querySelector('.grid').scrollBy({
        top: 0,
        left: -elemWidth,
        behavior: 'smooth'
      });
    },
    nextSlide() {
      const elemWidth = this.element.querySelector('.item').clientWidth;

      this.element.querySelector('.grid').scrollBy({
        top: 0,
        left: elemWidth,
        behavior: 'smooth'
      });
    }
  }
});
