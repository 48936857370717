import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  classNames: ['ssl-cert-type-select'],
  intl: service(),
  store: service(),
  modal: service(),
  didInsertElement() {
    this.set(
      'sslCertTypes',
      this.get('store').peekAll('sslCertType').filterBy('enabled')
    );
  },
  actions: {
    select(type) {
      tryInvoke(this, 'close', [type]);
    },
    openEvInfo() {
      this.modal.show('info/ssl-ev-auth', null, {
        header: this.intl.t('open.ev_info'),
        modalClasses: 'info'
      });
    },
    openDvInfo() {
      this.modal.show('info/ssl-dv-auth', null, {
        header: this.intl.t('open.dv_info'),
        modalClasses: 'info'
      });
    }
  }
});
