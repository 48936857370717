import Route from '@ember/routing/route';
import { reject } from 'rsvp';

export default Route.extend({
  queryParams: {
    gopay: {},
    id: {},
    transactionId: {}
  },
  model(params) {
    return this.store.findAll('checkout').then(checkoutModels => {
      const checkoutModel = checkoutModels.findBy('key', 'current');
      const type = 'wy-order';
      const adapter = this.store.adapterFor(type);
      const url = `${adapter
        .buildURL(type)
        .replace('/order?', '/checkPay?')}&encode=0`;
      return adapter
        .ajax(url, 'POST', {
          data: {
            data: {
              gopay: params.gopay,
              id: params.transactionId || params.id
            },
            data2: [
              {
                HTTP_REFERER: window && window.location && window.location.href
              }
            ]
          }
        })
        .then(data => {
          if (!data.success) {
            return reject('Payment check failed');
          }
          if (['fail', 'pending'].includes(data.result)) {
            return this.replaceWith('payment-fail', {
              queryParams: { code: data.result }
            });
          } else {
            return this.replaceWith('thanks', {
              queryParams: {
                invoice_id: checkoutModel.get('invoice.IDinvoice')
              }
            });
          }
        });
    });
  }
});
