import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['payment-summary'],
  total: 0,
  vatRate: 0,
  deviceDetection: service(),
  desktop: alias('deviceDetection.isDesktop')
});
