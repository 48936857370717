import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  checkout: service(),
  intl: service(),
  queryParams: ['code'],
  code: '',
  errors: null,
  init() {
    this._super(...arguments);
    this.set('errors', {
      wp_err_cardholder_auth_failed: this.intl.t(
        'payment_fail.errors.wp_err_cardholder_auth_failed'
      ),
      wp_err_technical_problem: this.intl.t(
        'payment_fail.errors.wp_err_technical_problem'
      ),
      wp_err_card_blocked: this.intl.t(
        'payment_fail.errors.wp_err_card_blocked'
      ),
      wp_err_declined: this.intl.t('payment_fail.errors.wp_err_declined'),
      wp_err_card_problem: this.intl.t(
        'payment_fail.errors.wp_err_card_problem'
      ),
      wp_err_account_problem: this.intl.t(
        'payment_fail.errors.wp_err_account_problem'
      ),
      wp_err_session_expired: this.intl.t(
        'payment_fail.errors.wp_err_session_expired'
      ),
      wp_err_general: this.intl.t('payment_fail.errors.wp_err_general'),
      wp_err_canceled: this.intl.t('payment_fail.errors.wp_err_canceled'),
      payment_canceled: this.intl.t('payment_fail.errors.payment_canceled'),
      payment_declined: this.intl.t('payment_fail.errors.payment_declined'),
      payment_revocated: this.intl.t('payment_fail.errors.payment_revocated'),
      payment_session_expired: this.intl.t(
        'payment_fail.errors.payment_session_expired'
      ),
      payment_not_found: this.intl.t('payment_fail.errors.payment_not_found'),
      payment_not_in_valid_state: this.intl.t(
        'payment_fail.errors.payment_not_in_valid_state'
      ),
      payment_operation_not_allowed: this.intl.t(
        'payment_fail.errors.payment_operation_not_allowed'
      ),
      response_already_exists: this.intl.t(
        'payment_fail.errors.response_already_exists'
      )
    });
  },
  actions: {
    retry() {
      this.payment(
        this.get('model.origInvoiceId'),
        this.get('model.paymentMethodId'),
        null,
        { channel: this.get('model.channel') }
      );
    },
    changePaymentMethod() {
      this.transitionToRoute('payment-method');
    }
  }
});
