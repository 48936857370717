import DS from 'ember-data';
import WyServicePack from './wy-service-pack';
import { alias } from '@ember/object/computed';

export default WyServicePack.extend({
  code: alias('domainServiceType'),
  capacity: DS.attr('string'),
  period: DS.attr('string'),
  price: alias('customerPrice'),
  pricePer: DS.attr('string'),
  maxUsers: DS.attr('number', { defaultValue: 50 }),
  customerContact: DS.attr('boolean'),

  // WY-API attrs
  domainServiceType: alias('pack')
});
