import { filter, uniqBy } from '@ember/object/computed';
import { next, scheduleOnce } from '@ember/runloop';

import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import ENV from 'domena-mobile/config/environment';
import SERVICE_CONFIG from '../utils/service-config';
import { computed } from '@ember/object';
import config from '../config/environment';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';

// const EMAIL_PRICE_TMP = {
//   'ign-flexi-mail': 99,
//   'exchange-online': 119
// };

export default Component.extend(Common, {
  cms: service(),
  modal: service(),
  router: service(),
  classNames: ['landing-page-email', 'reset-offset'],
  model: null,
  plans: uniqBy('model.mailHostingPlans', 'pack'),
  enableTips: config.LOCALE === 'cs',
  serviceConfig: SERVICE_CONFIG[ENV.LOCALE],
  filteredPlans: filter('plans.[]', function (plan) {
    return !this.serviceConfig[plan.pack].customerContact;
  }),
  sortedFilteredPlans: computed('filteredPlans.[]', function () {
    const typesOrder = this.cms?.pages?.produkty?.blocks.mapBy('slug');
    return this.filteredPlans?.toArray()?.sort((itemA, itemB) => {
      const itemAPos = typesOrder.indexOf(dasherize(itemA.pack));
      const itemBPos = typesOrder.indexOf(dasherize(itemB.pack));
      return itemAPos - itemBPos;
    });
  }),
  actions: {
    selectDomainOpen(code) {
      this.get('modal').show('forms/new-email-form', null, {
        isBig: false,
        selectedPlanCode: code,
        withFilter: false,
        onClose: selectedPlan => {
          if (selectedPlan) {
            this.router.transitionTo('cart', {
              queryParams: { page: this.get('PAGE.CART') }
            });
          }
        }
      });
    }
  },
  didInsertElement() {
    const $elem = $(window.location.hash.substring(1));
    if ($elem.length) {
      scheduleOnce('afterRender', () => {
        next(() => {
          this._scroll($elem);
        });
      });
    }
  },
  _scroll($elem) {
    const headerHeight = $('.top-page-stack').height();
    $('html,body').animate(
      { scrollTop: $elem.position().top - headerHeight + 60 },
      'slow'
    );
  }
});
