import Application from './application';
import DS from 'ember-data';
import ENV from 'domena-mobile/config/environment';
import addDefaultParams from '../utils/default-wyapi-params';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

const API_HOST = ENV.API_HOST || '';
const LOCALE_TO_ISO = { cs: 'cs_CZ', sk: 'sk_SK', en: 'en_US' };

export default Application.extend({
  session: service('session'),
  init() {
    this._super(...arguments);

    // rewrite JSONAPIAdapter headers for WY API
    this.set('headers', {
      accept: 'application/json',
      'content-type': 'application/json',
      'X-ERROR-LANG': LOCALE_TO_ISO[ENV.LOCALE]
    });
  },
  authorize(xhr) {
    let { token } = this.get('session.data.authenticated');
    if (token) {
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
  },
  namespace: '',
  host: API_HOST,
  isSuccess(status, headers, payload) {
    if (status < 200 || status >= 400) {
      return false;
    }

    return payload.success;
  },
  isInvalid(status, headers, payload) {
    if (status < 200 || status >= 400) {
      return true;
    }

    return !payload.success;
  },
  buildURL() {
    const url = this._super(...arguments);
    return addDefaultParams(url);
  },
  pathForType(type) {
    return underscore(type);
  },
  handleResponse(status, headers, payload) {
    this.ensureResponseAuthorized(status, headers, payload);
    const { error, msg, status: textStatus, type } = payload || {};
    const message = msg || error;

    if (this.isSuccess(status, headers, payload)) {
      return payload;
    } else if (this.isInvalid(status, headers, payload)) {
      return new DS.InvalidError([
        {
          code: type,
          status: '422',
          source: { pointer: '' },
          title: textStatus,
          detail: message
        }
      ]);
    }

    return this._super(...arguments);
  }
});
