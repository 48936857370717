import Contact from './contact';
import DS from 'ember-data';

export default Contact.extend({
  type: 'billing',
  // WY-API attrs
  customerDic: DS.attr('string'),
  customerEmail: DS.attr('string'),
  customerIcdph: DS.attr('string'),
  customerIco: DS.attr('string'),
  customerName: DS.attr('string'),
  customerPhone: DS.attr('string'),
  customerPostName: DS.attr('string'),
  customerPostPostcode: DS.attr('string'),
  customerPostProvince: DS.attr('string'),
  customerPostState: DS.attr('string'),
  customerPostStreet: DS.attr('string'),
  customerPostTown: DS.attr('string'),
  customerPostcode: DS.attr('string'),
  customerProvince: DS.attr('string'),
  customerState: DS.attr('string'),
  customerStreet: DS.attr('string'),
  customerTown: DS.attr('string'),
  domains: DS.attr(),
  withoutVat: DS.attr('boolean')
});
