export function initialize(application) {
  ['component', 'controller', 'route'].forEach(type => {
    application.inject(type, 'deviceDetection', 'service:deviceDetection');
  });
}

export default {
  name: 'device-detection',
  initialize
};
