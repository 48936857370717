import WyApiAdapter from './wy-api';
import addDefaultParams from '../utils/default-wyapi-params';

const addParams = url => {
  const params = { tld: 'cz', version_r: 2 };
  return addDefaultParams(url, params);
};

export default WyApiAdapter.extend({
  namespace: 'order',
  pathForType() {
    return 'listRegistrants';
  },
  buildURL() {
    const url = this._super(...arguments);
    return addParams(url);
  }
});
