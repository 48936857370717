import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import keyIndexes from 'domena-mobile/utils/key-indexes';
import { inject as service } from '@ember/service';

export default Controller.extend({
  cms: service(),
  data: alias('cms._translationData.terms-and-conditions'),
  conditionsItems: computed(function () {
    return keyIndexes(
      this.get('cms._translationData.terms-and-conditions'),
      'conditions-item'
    );
  })
});
