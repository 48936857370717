import Common from '../mixins/common';
import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Controller.extend(Common, {
  cart: service(),
  checkout: service(),
  paymentDetailsShown: false,
  otherEmail: '',
  isOtherEmailOpen: false,
  otherEmailSuccess: false,
  otherEmailError: false,
  vatRate: computed('checkout.model.contactId', function () {
    let contactId = this.get('checkout.model.contactId');
    let contact = this.store.peekRecord('contact', contactId);

    return contact ? contact.get('vatRate') : 1.21;
  }),
  confirmationEmail: computed(
    'currentUser.email',
    'model.domains.[]',
    function () {
      let out = '';

      this.get('model.domains').forEach(domain => {
        let tld = this.store.peekRecord('tld', domain.get('tldName'));

        if (isPresent(tld.get('confirmBy'))) {
          out = this.get('currentUser.email');
        }
      });

      return out;
    }
  ),
  suggestedName: computed(
    'model.invoiceOrig.{domainOrders,mailHostingOrders}',
    function () {
      let orders = this.get('model.invoiceOrig.domainOrders') || [];
      orders = orders.toArray();
      const mailOrders = this.get('model.invoiceOrig.mailHostingOrders') || [];
      orders.pushObjects(mailOrders.toArray());
      const domains = orders.sortBy('id');
      return (
        domains.get('lastObject.onlyDomainName') ||
        domains.get('lastObject.name')
      );
    }
  ),
  init() {
    this._super(...arguments);

    scheduleOnce('afterRender', () => {
      if (this.get('deviceDetection.isDesktop')) {
        this.send('viewPaymentDetails');
      }
    });
  },
  actions: {
    viewPaymentDetails() {
      this.set('paymentDetailsShown', true);
    },
    otherEmailOpen() {
      this.setProperties({
        otherEmail: '',
        isOtherEmailOpen: true,
        otherEmailSuccess: false,
        otherEmailError: false
      });
    },
    submitOtherEmail() {
      let otherEmail = this.get('otherEmail');
      let invoiceId = this.get('model.invoice.origInvoiceId');

      const adapter = this.store.adapterFor('invoice');

      this.setProperties({ otherEmailSuccess: false, otherEmailError: false });

      if (!this.validateEmail(otherEmail)) {
        this.set('otherEmailError', true);
        return;
      }

      adapter
        .ajax(adapter.buildURL() + `/invoices/${invoiceId}/resend`, 'PUT', {
          data: { data: { attributes: { email: otherEmail } } }
        })
        .then(
          () => {
            this.setProperties({ otherEmail: '', otherEmailSuccess: true });
          },
          () => {
            this.set('otherEmailError', true);
          }
        );
    }
  }
});
