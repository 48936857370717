import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend(Common, {
  cms: service(),
  classNames: ['dictionary-list'],
  searchValue: '',
  filterToList: computed('searchValue', 'list.[]', function () {
    let searchValue = this.stripAccent(
      this.get('searchValue').toLowerCase().replace(/[<>]/g, '')
    );
    let list = this.get('list');

    if (searchValue) {
      return list.filter(item => {
        return (
          this.stripAccent(item.content.toLowerCase()).indexOf(searchValue) !==
            -1 ||
          this.stripAccent(item.title.toLowerCase()).indexOf(searchValue) !==
            -1 ||
          this.stripAccent(item.subtitle.toLowerCase()).indexOf(searchValue) !==
            -1
        );
      });
    } else {
      return this.get('list');
    }
  }),
  list: computed(function () {
    const pages = this.get('cms.pages');
    const { dictionary } = pages || {};
    const { blocks: items } = dictionary || {};

    return items.map(term => ({
      title: term.title,
      subtitle: term.subtitle,
      content: term.content
    }));
  }),
  actions: {
    itemClick(event) {
      event.preventDefault();
      const $elem = $(event.target);
      this._scroll($elem);
      window.location.hash = $elem.attr('href');
    }
  }
});
