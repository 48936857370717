import { helper } from '@ember/component/helper';

export function parseF(params /* , hash*/) {
  if (!params || !params[0]) {
    return;
  }

  return parseFloat(params[0], 10);
}

export default helper(parseF);
