import Component from '@ember/component';
import { filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  type: 'domena',
  currentUser: service(),
  modal: service(),
  classNames: ['modal-manager'],
  modals: filter('modal.modals', function (modal) {
    return modal.get('type') === this.get('type');
  }),
  actions: {
    close(modal, ...rest) {
      const callback = modal.get('options.onClose');
      if (callback) {
        callback(...rest, modal.get('model'));
      }
      this.get('modal').close(modal, ...rest);
    }
  }
});
