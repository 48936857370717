import Helper from '@ember/component/helper';
import contactAttrVisible from './contact-attr-visible';
import domenaIcon from './di';
import highlightText from './highlight-text';
import { htmlSafe } from '@ember/string';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Helper.extend({
  intl: service(),
  localization(key) {
    switch (key) {
      case 'phone':
        return `${this.intl.t('contact_attribute_with_errors.phone')}`;
      case 'email':
        return `${this.intl.t('contact_attribute_with_errors.email')}`;
      case 'street':
        return `${this.intl.t('contact_attribute_with_errors.street')}`;
      default:
        return '';
    }
  },
  compute([contact, key], hash) {
    let value = contact.get(key);

    if (key === 'phoneWithDial') {
      key = 'phone';
    }

    let searchValue = hash['search'];
    let text = '';
    let hasError = hash['errors'] && hash['errors'].includes(key);
    let itemClass = hasError ? 'error' : '';
    if (contactAttrVisible.compute([contact.get('hiddenAttributes'), key])) {
      if (value) {
        text = highlightText.compute([value, searchValue]);
      }
    } else if (hash['secretValue']) {
      itemClass = 'secret-value';
      text = this.get('intl').t(`secret_values.${key}`);
    }

    if (isPresent(text)) {
      return htmlSafe(
        `<span class=${itemClass}>${domenaIcon.compute([
          hash['icon']
        ])}${text}</span>`
      );
    } else {
      if (hasError) {
        text = this.localization(key);
      }
      return isPresent(text)
        ? htmlSafe(
            `<span class=${itemClass}>${domenaIcon.compute([
              hash['icon']
            ])}${text}</span>`
          )
        : '';
    }
  }
});
