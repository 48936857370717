import DS from 'ember-data';
import { computed } from '@ember/object';
import { filter } from '@ember/object/computed';
import normalizeAttrs from '../utils/normalize-attr-keys';
import { inject as service } from '@ember/service';

export default DS.Model.extend({
  store: service(),
  domainOrderIds: DS.attr(),
  paid: DS.attr('boolean'),
  // remaining: DS.attr('number'),
  totalPrice: DS.attr('number'),
  vs: DS.attr('string'),
  payments: DS.hasMany('payment'),
  channel: DS.attr('string'),
  paymentMethodId: DS.attr('number'),
  paymentMethod: computed('paymentMethodId', function () {
    if (!this.get('paymentMethodId')) {
      return;
    }
    return this.get('store').peekRecord(
      'paymentMethod',
      this.get('paymentMethodId')
    );
  }),
  // spayd: DS.attr('string'),
  items: DS.attr(),
  orders: computed('items.[]', function () {
    return (this.items || []).map(item => {
      return this.store.createRecord('wy-order', normalizeAttrs(item));
    });
  }),
  ordersPrice: computed('orders.@each.price', function () {
    return this.get('orders').reduce((sum, order) => {
      return (sum += order.get('price'));
    }, 0);
  }),
  // orders: DS.hasMany('order', { polymorphic: true, inverse: 'invoice' }), //FIXME
  domainOrders: filter('orders', function (order) {
    return order.constructor.modelName === 'domain-order';
  }),
  mailHostingOrders: filter('orders', function (order) {
    return order.constructor.modelName === 'mail-hosting-order';
  }),
  awaitingPayment: computed('payments.@each.status', function () {
    return this.get('payments').isAny('status', 'success');
  }),
  // WY-API attrs
  bankdata: DS.attr(),
  currency: DS.attr('string'),
  dtMaturity: DS.attr('date'),
  priceBase: DS.attr('number'),
  pricePaid: DS.attr('number'),
  pricePaidOrig: DS.attr('number'),
  priceVat: DS.attr('number'),
  remaining: computed('priceBase', 'priceVat', function () {
    return parseFloat(this.priceBase) + parseFloat(this.priceVat);
  }),
  spayd: computed('remaining', 'id', 'currency', 'dtMaturity', function () {
    const remainingString = Math.round(this.remaining * 100) / 100;
    return `SPD*1.0*ACC:${this.bankdata.bank__iban.replace(
      /\s/gu,
      ''
    )}*AM:${remainingString}*CC:${this.currency}*X-VS:${this.vs}`;
  })
});
