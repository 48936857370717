export default function urlParams(url) {
  if (typeof document === 'undefined') {
    return {};
  }
  var parser = document.createElement('a'),
    searchObject = {},
    queries,
    split,
    i;

  if (url.indexOf('@') > -1) {
    var urlParts = url.split('@');
    var dataParts = urlParts[0].split('://');

    // Let the browser do the work
    parser.href = dataParts[0] + '://' + urlParts[1];
  } else {
    parser.href = url;
  }
  // Convert query string to object
  queries = parser.search.replace(/^\?/, '').split('&');
  for (i = 0; i < queries.length; i++) {
    split = queries[i].split('=');
    searchObject[split[0]] = decodeURIComponent(split[1]);
  }

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash,
    username: dataParts[1],
    origin: `${parser.protocol}//${[parser.hostname, parser.port]
      .filter(item => item.length)
      .join(':')}`
  };
}
