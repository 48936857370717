import Provider from 'torii/providers/oauth2-code';
import addDefaultParams from '../utils/default-wyapi-params';
import ajax from '../utils/ajax';
import { assign } from '@ember/polyfills';
import config from '../config/environment';
import { reject } from 'rsvp';
import { inject as service } from '@ember/service';

export default Provider.extend({
  session: service(),
  baseUrl: `${config.API_HOST}/auth/mojeid-redir`,
  name: 'mojeid',
  responseParams: Object.freeze(['lticket', 'username']),
  responseType: 'token',
  apiKey: 'mojeid',
  // redirectUri: `${
  //   window && window.location && window.location.origin
  // }/torii/redirect.html`,

  open: function open(options) {
    let url = new URL(this.baseUrl);
    url.searchParams.append('return_to', `${config.API_HOST}/auth/mojeid`);
    url.searchParams.append('redir_ticket', this.redirectUri);

    options = assign(options || {}, { height: '700' });

    return ajax(addDefaultParams(url), {
      method: 'POST'
    }).then(data => {
      if (!data.success) {
        return reject(data);
      }

      return this.get('popup')
        .open(data.url, this.get('responseParams'), options)
        .then(authData => {
          return this.get('session')
            .authenticate('authenticator:wy-api', {
              lticket: decodeURIComponent(authData.lticket),
              identification: decodeURIComponent(authData.username)
            })
            .then(() => {
              return this.get('session.data.authenticated');
            });
        }, reject);
    }, reject);
    // const name = this.get('name'),
    //   redirectUri = this.get('redirectUri'),
    // responseParams = this.get('responseParams'),
    //   responseType = this.get('responseType');
  }
});
