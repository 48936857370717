import WyApiAdapter from './wy-api';
import addDefaultParams from '../utils/default-wyapi-params';
import { inject as service } from '@ember/service';

const addParams = (url, currency) => {
  const params = { with_price: true, apply_discounts: true };
  if (currency) {
    params.currency = currency;
  }
  return addDefaultParams(url, params);
};

export default WyApiAdapter.extend({
  currentUser: service(),
  namespace: 'order',
  pathForType() {
    return 'listTld';
  },
  buildURL() {
    const url = this._super(...arguments);
    return addParams(url, this.get('currentUser.currency'));
  }
});
