import DS from 'ember-data';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import moment from 'moment';

export default DS.Model.extend({
  active: DS.attr('boolean'),
  admin: DS.attr(),
  approverEmail: DS.attr('string'),
  ca: DS.attr('string'),
  crt: DS.attr('string'),
  csr: DS.attr('string'),
  domainName: DS.attr('string'),
  multidomain: DS.attr('boolean'),
  notAfter: DS.attr('date'),
  notBefore: DS.attr('date'),
  sans: DS.attr(),
  valid: DS.attr('boolean'),
  sslCertType: DS.belongsTo('sslCertType'),
  displayName: alias('domainName'),
  // sslCertOrder: DS.belongsTo('sslCertOrder'),
  state: computed('notAfter', function () {
    const date = moment(this.notAfter);
    if (date.isBefore(moment())) {
      return 'expired';
    } else if (date.isBetween(moment(), moment().add(30, 'days'))) {
      return 'expiring';
    } else {
      return 'valid';
    }
  }),
  extraClassesString: computed('state', function () {
    const classes = [];
    if (this.state === 'expired') classes.push('is-inactive');
    return classes.join(' ');
  })
});
