import DS from 'ember-data';
import moment from 'moment';
import { computed } from '@ember/object';

export default DS.Model.extend({
  tld: DS.attr('string'),
  originalPrice: DS.attr('number'),
  newPriceOverwrite: DS.attr('number'),
  originalPrices: DS.attr(),
  from: DS.attr('date'),
  position: DS.attr('number'),
  text: DS.attr('string'),
  service: DS.attr('string'),
  until: DS.attr('date'),
  years: computed('servicePeriod', function () {
    return this.servicePeriod / 12;
  }),

  // WY-API attrs
  canTransfer: DS.attr('boolean'),
  categoryName: DS.attr('string'),
  conditions: DS.attr(),
  customerType: DS.attr('string'),
  description: DS.attr('string'),
  discountAbsolute: DS.attr('number'),
  discountPercentage: DS.attr('number'),
  discountPeriod: DS.attr('number'),
  dtFrom: DS.attr('date'),
  dtTill: DS.attr('date'),
  iDcoupon: DS.attr('string'),
  // iDdiscount: DS.attr('string'),
  invoiceMorePeriods: DS.attr('boolen'),
  nextPeriod: DS.attr(),
  onlyFrom: DS.attr('string'),
  onlyTransfer: DS.attr('boolean'),
  serviceMinPrice: DS.attr('number'),
  serviceName: DS.attr('string'),
  servicePeriod: DS.attr('number'),
  // status: DS.attr('boolean')
  active: computed('from', 'until', function () {
    let from = this.get('from'),
      until = this.get('until'),
      nowTimestamp = new Date().getTime(),
      fromDateTimestamp,
      untilDateTimestamp;

    if (from) {
      fromDateTimestamp = moment(from).startOf('day').valueOf();
    }

    if (until) {
      untilDateTimestamp = moment(until).endOf('day').valueOf();
    }

    if (!from || fromDateTimestamp <= nowTimestamp) {
      if (!until || nowTimestamp <= untilDateTimestamp) {
        return true;
      }
    }

    return false;
  })
});
