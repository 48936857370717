import Component from '@ember/component';

export default Component.extend({
  didInsertElement() {
    const scriptTags = window.document.getElementsByTagName('script');

    for (let scriptTag of scriptTags) {
      if (scriptTag.src === this.src) {
        return;
      }
    }

    // Create a new script element
    const script = window.document.createElement('script');

    // Set the script element `src`
    script.src = this.src;
    Object.keys(this.data).forEach(key => {
      script.setAttribute(`data-${key}`, this.data[key]);
    });

    this.set('scriptElem', script);

    // Inject the script into the DOM
    document.body.appendChild(script);
  }
});
