import Mixin from '@ember/object/mixin';

export default Mixin.create({
  urlForFindRecord(id, modelName, snapshot) {
    return this._super(null, modelName, snapshot);
  },
  urlForUpdateRecord(id, modelName, snapshot) {
    return this._super(null, modelName, snapshot);
  }
});
