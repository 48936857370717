import config from '../config/environment';

export default function addDefaultParams(url, params = {}) {
  try {
    const outputUrl = new URL(url);
    if (!outputUrl.searchParams.has('form_name')) {
      outputUrl.searchParams.append(
        'form_name',
        config.LOCALE === 'cs' ? 'domena.cz' : 'domena.sk'
      );
    }
    Object.keys(params).forEach(key => {
      if (!outputUrl.searchParams.has(key)) {
        outputUrl.searchParams.append(key, params[key]);
      }
    });
    return outputUrl.toString();
  } catch (e) {
    return url;
  }
}
