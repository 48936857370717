import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['cart-item', 'cart-item-base'],
  cart: service(),
  cms: service(),
  remove(item) {
    this.get('cart').removeItem(item.get('type'), {
      key: item.get('key'),
      id: item.get('id')
    });
  },
  actions: {
    remove(item) {
      this.remove(item);
    },
    updateCart() {
      this.cart.save();
    }
  }
});
