import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  cart: service(),
  config: service(),
  intl: service(),
  modal: service(),
  store: service(),
  taskQueue: service(),
  queryParams: {
    d: {}
  },
  model(params) {
    return this.replaceWith('yola', { queryParams: { domainName: params.d } });
  }
});
