import Component from '@ember/component';

export default Component.extend({
  classNames: ['collapse-list'],
  classNameBindings: ['open', 'disabled'],
  open: true,
  actions: {
    toggle() {
      this.$('.collapse-list-body').slideToggle(() => {
        this.toggleProperty('open');
      });
    }
  }
});
