import Route from '@ember/routing/route';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/tips'
      }
    }
  ]),
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  }
});
