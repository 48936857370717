import { inject as service } from '@ember/service';
import Component from '@ember/component';
import Common from '../mixins/common';
import config from '../config/environment';

export default Component.extend(Common, {
  store: service(),
  cms: service(),
  enabledItems: config.LOCALE === 'cs',
  actions: {
    desktopLink() {
      this.toOrigAdmin();
    }
  }
});
