import { buildValidations, validator } from 'ember-cp-validations';

import DS from 'ember-data';
import Order from './order';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import config from '../config/environment';
import domainParser from 'domena-mobile/utils/domain-parser';
import { inject as service } from '@ember/service';
import regex from '../utils/regex';

const validations = buildValidations({
  adminEmail: validator('format', {
    type: 'email',
    messageKey: 'validations.email'
  }),
  phone: [
    validator('length', {
      is: 9,
      messageKey: 'validations.phone_number_cz',
      dependentKeys: ['model.dialingCode'],
      disabled: computed(function () {
        return this.get('model.dialingCode') !== '+420';
      }).volatile()
    }),
    validator('length', {
      min: 5,
      max: 15,
      messageKey: 'validations.phone_number_other',
      dependentKeys: ['model.dialingCode'],
      disabled: computed(function () {
        return this.get('model.dialingCode') === '+420';
      }).volatile()
    }),
    validator('format', {
      regex: /^[0-9]+$/,
      messageKey: 'validations.only_numbers'
    })
  ],
  userName: validator('format', {
    // IPAS ji nepotrebuje staci jen text
    regex: regex.FULL_NAME_REGEX,
    messageKey: 'validations.min_length_4_with_space',
    disabled: computed('model.isNameNeeded', function () {
      return !this.get('model.isNameNeeded');
    }).volatile()
  })
});

export default Order.extend(validations, {
  invoice: DS.belongsTo('invoice'),
  // invoice: DS.belongsTo('invoice', {inverse: 'orders', polymorphic: true}), // FIXME
  ideas: service(),
  intl: service(),
  domainName: DS.attr('string'),
  mailboxUserName: DS.attr('string'),
  domainServiceType: DS.attr('string'),
  period: DS.attr('number'),
  capacity: DS.attr('string'),
  users: DS.attr('number'),
  adminEmail: DS.attr('string'),
  dialingCode: DS.attr('string', {
    defaultValue: `+${config.DEFAULT_DIALING_CODE}` || '+420'
  }),
  phone: DS.attr('string'),
  price: DS.attr('number'),
  customerContact: DS.attr(), // {name: 'Ratata', email: 'dusanek@iquest.cz'}
  priceObject: null,
  name: computed('domainName', function () {
    if (!this.get('domainName')) {
      return '';
    }
    const parsedDomain = domainParser(
      this.get('domainName'),
      this.get('store').peekAll('tld')
    );
    if (parsedDomain.parsed) {
      return `${parsedDomain.subdomains.join('.')}.${parsedDomain.domain}`;
    }
    return this.get('domainName').replace(/\.\w+$/, '');
  }),
  onlyDomainName: computed('domainName', function () {
    if (!this.get('domainName')) {
      return '';
    }
    const parsedDomain = domainParser(
      this.get('domainName'),
      this.get('store').peekAll('tld')
    );
    if (parsedDomain.parsed) {
      return parsedDomain.domain;
    }
    return this.get('domainName').replace(/\.\w+$/, '');
  }),
  tldName: computed('domainName', function () {
    if (!this.get('domainName')) {
      return '';
    }
    const parsedDomain = domainParser(
      this.get('domainName'),
      this.get('store').peekAll('tld')
    );
    if (parsedDomain.parsed) {
      return parsedDomain.tld;
    }
    return this.get('domainName').split('.').slice(1).join('.');
  }),
  displayName: alias('domainName'),
  fullName: computed('displayName', 'mailboxUserName', function () {
    const { displayName, mailboxUserName } = this.getProperties(
      'displayName',
      'mailboxUserName'
    );
    return mailboxUserName
      ? `${mailboxUserName}@${displayName}`
      : `${displayName}`;
  }),
  mailHostingPlanObject: computed(
    'domainServiceType',
    'capacity',
    'period',
    'availableCapacities',
    function () {
      return this.store.peekAll('mailHostingPlan').find(mailHosting => {
        return (
          mailHosting.get('period') === this.get('period') &&
          mailHosting.get('capacity') ===
            (this.get('capacity') ||
              this.get('availableCapacities.firstObject')) &&
          mailHosting.get('domainServiceType') === this.get('domainServiceType')
        );
      });
    }
  ),
  selectedVariant: computed(
    'mailHostingPlanObject.pricePer',
    'capacity',
    'users',
    function () {
      if (
        (this.get('mailHostingPlanObject.pricePer') &&
          this.get('mailHostingPlanObject.pricePer') === 'user_period') ||
        this.get('users')
      ) {
        const userCount = this.get('users');
        return `${userCount} ${this.get('intl')
          .t('users', { count: userCount })
          .toString()}`;
      } else {
        if (this.get('capacity')) {
          return `${this.get('capacity')} GB`;
        } else {
          return null;
        }
      }
    }
  )
});
