import Helper from '@ember/component/helper';
import moneyString from '../utils/money-string';
import { inject as service } from '@ember/service';

export default Helper.extend({
  intl: service(),
  currentUser: service(),
  compute(params, hash) {
    if (!params[0] || params[0] === '0') {
      return this.intl.t('free').toString();
    } else {
      hash = {
        currency: this.currentUser.currencySymbol,
        ...hash
      };
      return moneyString(params, hash);
    }
  }
});
