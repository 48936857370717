import { alias } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  login: alias('model'),
  actions: {
    close() {
      this.get('close')();
    }
  }
});
