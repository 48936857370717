import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  store: service(),
  classNames: ['contact-item-basic'],
  classNameBindings: [
    'noIcons',
    'box',
    'contact.defaultInvoiceContact:default'
  ],
  contact: null,
  selected: null,
  expanded: false,
  noIcons: false,
  rrIds: false,
  rrIdsExpanded: false,
  box: false,
  search: '',
  didInsertElement() {
    let { contact, selected } = this.getProperties('contact', 'selected');
    if (isPresent(selected) && contact.get('id') === selected.get('id')) {
      this.set('expanded', true);
    }
  },
  click() {
    this.set('expanded', true);
  },
  actions: {
    rrIdsToggle() {
      this.toggleProperty('rrIdsExpanded');
    }
  }
});
