import Base from './base-cart-item';
import { assign } from '@ember/polyfills';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Base.extend({
  intl: service(),
  modal: service(),
  store: service(),
  classNames: ['cart-item', 'ssl-cert-item-registration'],
  yearsData: computed('model.certType.prices', function () {
    return Object.keys(this.get('model.certType.prices')).map(item => {
      return parseInt(item) / 12;
    });
  }),
  actions: {
    changeVariant() {
      this.modal.show('ssl-cert-type-select', null, {
        header: this.intl.t('ssl-certs.info.select_type_header'),
        modalClasses: 'ssl-cert-type-modal',
        onClose: typeId => {
          const type = typeId && this.store.peekRecord('ssl-cert-type', typeId);
          if (type) {
            const origParams = this.get('model.extra');
            const origTypeId = this.get('model.certType.id');
            // clean up
            delete origParams.sslCertTypeId;
            if (origTypeId !== type.id) {
              delete origParams.sans;
            }

            this.modal.show('forms/new-ssl-cert-form', type, {
              params: origParams,
              modalClasses: 'new-ssl-modal',
              onClose: (certType, newCartItem, newData) => {
                if (certType) {
                  if (newCartItem) {
                    newCartItem.setProperties(
                      this.get('model').getProperties('quantity')
                    );
                  }
                  if (newData) {
                    const commonName = newData.commonName;
                    delete newData.commonName;
                    const mergedExtra = assign(
                      newCartItem.get('extra'),
                      newData
                    );
                    newCartItem.set('extra', mergedExtra);
                    newCartItem.set('extra.commonName', commonName);
                  }
                  if (origTypeId !== certType.id) {
                    this.remove(this.get('model'));
                  }
                }
              }
            });
          }
        }
      });
    }
  }
});
