import DS from 'ember-data';
import { computed } from '@ember/object';
import { alias, equal, filterBy, notEmpty } from '@ember/object/computed';

export default DS.Model.extend({
  capacity: DS.attr('string'),
  deletionAt: DS.attr('date'),
  domainName: DS.attr('string'),
  expirationAt: DS.attr('date'),
  licenceCount: DS.attr('number'),
  paymentPeriod: DS.attr('number'),
  serviceState: DS.attr('string'),
  serviceType: DS.attr('string'),
  invoiceContact: DS.belongsTo('contact'),
  mailRenewalOrders: DS.hasMany('mailRenewalOrder', {
    inverse: 'domainService'
  }),
  awaitingActiveOrders: filterBy('mailRenewalOrders', 'awaitingPaymentInvoice'),
  hasAwaitingOrders: notEmpty('awaitingActiveOrders'),
  displayName: alias('domainName'),
  serviceName: computed('serviceType', function () {
    return this.get('serviceType').replace(/[-,_]/g, ' ');
  }),
  type: computed('serviceType', function () {
    const type = this.get('serviceType');
    return type === 'flexi_mail' ? 'ignum_mail' : type;
  }),
  isActive: equal('serviceState', 'active'),
  isDeactivated: equal('serviceState', 'deactivated'),
  isFlexiMail: equal('serviceType', 'flexi_mail'),
  renewalOrder: null,
  hasAwaitingOrder: alias('renewalOrder.awaitingPaymentInvoice'),
  mailHostingPlan: computed('type', function () {
    return this.get('store')
      .peekAll('mailHostingPlan')
      .findBy('code', this.get('type'));
  })
});
