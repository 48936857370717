import { isEmpty, isPresent } from '@ember/utils';

import Component from '@ember/component';
import { computed } from '@ember/object';
import config from '../config/environment';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

const DEFAULT_COUNTRY_ID = config.DEFAULT_COUNTRY_ID || '203';

export default Component.extend({
  ideas: service(),
  store: service(),
  tagName: 'select',
  countries: null,
  selected: null,
  returnKey: 'id',
  defaultCountry: DEFAULT_COUNTRY_ID,
  countriesFiltered: computed('countries.[]', function () {
    let allowedCountryIds = this.get('ideas.allowedCountryIds');
    if (isEmpty(allowedCountryIds)) {
      return this.get('countries');
    }
    return this.get('countries').filter(country => {
      return allowedCountryIds.includes(parseInt(country.get('id')));
    });
  }),
  init() {
    this._super(...arguments);

    scheduleOnce('afterRender', () => {
      // Pokud není cz, vybere se první ze seznamu
      let allowedCountryIds = this.get('ideas.allowedCountryIds');
      if (
        isPresent(allowedCountryIds) &&
        !allowedCountryIds.includes(parseInt(this.get('defaultCountry')))
      ) {
        this.set('selected', allowedCountryIds[0].toString());
      }

      if (this.get('selected')) {
        if (this.get('selected').toString() !== this.get('defaultCountry')) {
          this.$('option').removeProp('selected');
          this.$(`option[value="${this.get('selected')}"]`).prop(
            'selected',
            true
          );
        }
      }
    });
  },
  didInsertElement() {
    if (!this.countries) {
      this.set('countries', this.store.peekAll('country'));
    }
  },
  change() {
    const object = this.get('countries').findBy('id', this.$().val());
    const value = this.returnKey
      ? this.get('countries').findBy('id', this.$().val()).get(this.returnKey)
      : this.$().val();
    tryInvoke(this, 'onSelect', [object]);
    this.set('selected', value);
  }
});
