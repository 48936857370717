import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  actions: {
    exceptionsClose() {
      tryInvoke(this, 'exceptionsClose', arguments);
    },
    exceptionsSubmit() {
      tryInvoke(this, 'exceptionsSubmit', arguments);
    }
  }
});
