import DS from 'ember-data';
import { alias } from '@ember/object/computed';

export default DS.Model.extend({
  adminContactId: DS.attr('number'),
  favoriteTlds: DS.attr(),

  // WY atributes
  custloginName: DS.attr('string'),
  iDcustlogin: DS.attr('string'),
  login: alias('custloginName'),
  userId: alias('iDcustlogin')
});
