import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Component.extend({
  cms: service(),
  store: service(),
  classNames: ['landing-page__block', 'domains-promo-bar'],
  promoConditions: alias(
    'cms._translationData.produkty.domains-promo-conditions.content'
  ),
  offers: computed('tlds.@each.tld', 'tldPrices.@each.tld', function () {
    const tlds = this.get('tlds');
    const tldPrices = this.get('tldPrices');
    if (!tlds || !tldPrices) {
      return;
    }

    const offers = tlds
      .filter(tld => tld.get('offers') && tld.get('offers.length'))
      .map(tld => tld.get('offers.firstObject'));

    return offers.map(offer => {
      const domainPrice = tldPrices.findBy('tld', offer.get('tld'));
      const newPrice = parseFloat(
        domainPrice && domainPrice.get('defaultPrice')
      );

      return {
        tld: offer.get('tld'),
        oldPrice: offer.originalPrice,
        newPrice: offer.newPriceOverwrite || newPrice
      };
    });
  }),
  didInsertElement() {
    this.setProperties({
      tlds: this.store.peekAll('tld'),
      tldPrices: this.store.peekAll('price')
    });
  }
});
