import Component from '@ember/component';
import Common from '../mixins/common';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  actions: {
    authenticationSuccess() {
      tryInvoke(this, 'authenticationSuccess');
    },
    authenticationFailed() {
      tryInvoke(this, 'authenticationFailed');
    }
  }
});
