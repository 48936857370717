import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Common from '../mixins/common';
import config from '../config/environment';
import Controller from '@ember/controller';
import keyIndexes from 'domena-mobile/utils/key-indexes';

export default Controller.extend(Common, {
  enableTips: config.LOCALE === 'cs',
  domainsPromoConditions: alias(
    'cms._translationData.produkty.wordpress-promo-conditions.content'
  ),
  cms: service(),
  router: service(),
  galleryData: alias(
    'cms._translationData.wordpress.wordpress-best-gallery.images'
  ),
  modal: service(),
  hostingItems: alias('model.hostingItems'),
  reasonItems: computed(function () {
    return keyIndexes(
      this.get('cms._translationData.wordpress'),
      'wordpress-reason'
    );
  }),
  showcaseItems: computed(function () {
    return keyIndexes(
      this.get('cms._translationData.wordpress'),
      'wordpress-showcase'
    );
  }),
  enableCustomSolution: config.LOCALE === 'cs',
  actions: {
    showOrder(hostingType) {
      this.get('modal').show('hosting-plan-select', hostingType, {
        type: 'domena',
        hostingType: 'wordpressHostingPlan',
        onClose: selectedType => {
          if (selectedType) {
            this.router.transitionTo('cart', {
              queryParams: {
                page: this.get('PAGE.CART'),
                addressbook: 'invoice'
              }
            });
          }
        }
      });
    },
    showNext() {
      this.get('modal').show('info/hosting-next-parameters', null, {
        type: 'domena'
      });
    }
  }
});
