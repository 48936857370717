import Route from '@ember/routing/route';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/order-fail'
    }
  }
];

export default Route.extend({
  headTags: HEAD_TAGS
});
