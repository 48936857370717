import Route from '@ember/routing/route';
import logger from '../utils/logger';
import { inject as service } from '@ember/service';

export default Route.extend({
  fastboot: service(),
  queryParams: {
    path: {}
  },
  model(params) {
    if (!this.get('fastboot.isFastBoot')) {
      logger.log('redirection', params.path);
      this.replaceWith(params.path);
    }
  }
});
