import Service, { inject as service } from '@ember/service';

import { computed } from '@ember/object';
import config from '../config/environment';

export default Service.extend({
  config,
  intl: service(),
  selectedTip: null,
  tips: computed('config.LOCALE', function () {
    return [
      {
        text: this.intl.t('tips.text1')
      },
      {
        text: this.intl.t('tips.text2')
      },
      {
        text: this.intl.t('tips.text3')
      },
      {
        text: this.intl.t('tips.text4')
      },
      {
        text: this.intl.t('tips.text5')
      },
      {
        text: this.intl.t('tips.text6')
      },
      {
        text: this.intl.t('tips.text7')
      }
    ];
  }),
  setRandomTip() {
    if (!window || !window.localStorage) {
      return;
    }
    let randomTipIndex = window.localStorage.getItem('selectedTip');
    if (!randomTipIndex) {
      const tips = this.get('tips');
      randomTipIndex = Math.floor(Math.random() * (tips.get('length') + 1));
      window.localStorage.setItem('selectedTip', randomTipIndex);
    }
    const selectedTip = this.get('tips').objectAt(randomTipIndex);
    this.set('selectedTip', selectedTip);
  },
  resetTip() {
    if (!window || !window.localStorage) {
      return;
    }
    this.set('selectedTip', null);
    window.localStorage.removeItem('selectedTip');
  }
});
