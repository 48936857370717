import WyApiSerializer from './wy-api';

export default WyApiSerializer.extend({
  primaryKey: 'IDregistrant',
  normalizeResponse() {
    const payload = this._super(...arguments);

    return {
      ...payload,
      data: payload.data.map(item => {
        item.attributes.city = item.attributes.owner.town;
        delete item.attributes.owner.town;
        item.attributes.company = item.attributes.owner.legal_form !== 'FO';
        item.attributes.companyName = item.attributes.owner.name;
        delete item.attributes.owner.legal_form;
        item.attributes.name = item.attributes.owner.name;
        delete item.attributes.owner.name;
        item.attributes['dialing-code'] = (item.attributes.contact.phone.match(
          /^\+[0-9]+./
        ) || [''])[0].replace('.', '');
        item.attributes.dic = item.attributes.owner.vatid;
        delete item.attributes.owner.vatid;
        item.attributes.email = item.attributes.contact.email;
        delete item.attributes.contact.email;
        item.attributes.phone = item.attributes.contact.phone;
        delete item.attributes.contact.phone;
        item.attributes.street = item.attributes.owner.street;
        delete item.attributes.owner.street;
        item.attributes.zip = item.attributes.owner.postcode;
        delete item.attributes.owner.postcode;
        item.attributes.hiddenAttributes = item.attributes.hidden;
        delete item.attributes.hidden;

        return item;
      })
    };
  },
  serialize(record, options) {
    let json = this._super(record, options);

    if (json.ic === '') {
      json.ic = null;
    }
    if (json.dic === '') {
      json.dic = null;
    }
    if (json.phone === '') {
      json.phone = null;
    }
    if (json.companyName === '') {
      json.companyName = null;
    }
    if (json.city === '') {
      json.city = null;
    }
    if (json.zip === '') {
      json.zip = null;
    }

    return json;
  }
});
