import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['list-item-selectable'],
  classNameBindings: ['isSelected:selected'],
  item: null,
  selected: null,
  isSelected: computed('item.id', 'selected.id', function () {
    return this.get('item.id') === this.get('selected.id');
  }),
  click(event) {
    event.stopPropagation();
    this.set('selected', this.get('item'));
  }
});
