import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend(Common, {
  cart: service(),
  classNames: [
    'flex-align-center',
    'flex-justify-space-between',
    'flex-wrap-mobile',
    'flex-wrap',
    'flex',
    'nav-bar'
  ],
  currentUser: service(),
  fastboot: service(),
  intl: service(),
  isMenuOpen: false,
  modal: service(),
  navMenu: service(),
  router: service(),
  routing: service(),
  session: service(),
  store: service(),

  didInsertElement() {
    this._super(...arguments);
  },
  actions: {
    menuOpen() {
      this.navMenu.open();
    },
    menuClose() {
      this.navMenu.close();
    },
    goHome() {
      this.send('menuClose');
      const urls = [
        '/',
        '/order-all-fail',
        '/order-fail',
        '/payment',
        '/thanks'
      ];
      if (urls.includes(window.location.pathname)) {
        this._searchInputReset();
      }

      this.router.transitionTo('home', { queryParams: { name: '' } });
    },
    toCart() {
      this.send('menuClose');
      this.router.transitionTo('cart', {
        queryParams: { page: this.get('PAGE.CART') }
      });
    },
    showLoginModal() {
      this.send('menuClose');
      this.modal.show('login-modal', null, {
        header: this.intl.t('login.title'),
        afterLogin: 'admin'
      });
    }
  },
  _searchInputReset() {
    $('html,body').animate({ scrollTop: 0 }, 'fast');
    $('.domain-search').removeClass('fixed');
    $('.domain-search input').val('');
    $('body').trigger('search:reset');
  }
});
