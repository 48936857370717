import WyApiSerializer from './wy-api';
import { underscore } from '@ember/string';

export default WyApiSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload /* id , requestType*/) {
    return {
      data: {
        attributes: {
          paymentLink: payload.payment_link,
          idsObjects: payload.ids_objects,
          invoice: payload.invoice
        },
        type: primaryModelClass.modelName,
        id: payload.IDinvoice
      }
    };
  },
  keyForAttribute(attr) {
    return underscore(attr);
  }
});
