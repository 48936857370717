import { Promise as EmberPromise, all } from 'rsvp';

import Component from '@ember/component';
import Logger from '../utils/logger';
import config from '../config/environment';
import fetchAjax from '../utils/ajax';
import moment from 'moment';
import { trySet } from '@ember/object';

export default Component.extend({
  classNames: ['wp-blog-posts'],
  classNameBindings: ['loading'],
  postsData: null,
  loading: false,
  didInsertElement() {
    this.set('loading', false);
    new EmberPromise((resolve, reject) => {
      const out = [];
      // úvodní fotka, nadpis, datum vydání, autor a rubrika
      fetchAjax(
        `${config.BLOG_URL}/wp-json/wp/v2/posts?per_page=3&orderby=date&order=desc&_embed=1`
      ).then(data => {
        if (this.get('isDestroyed')) {
          return;
        }
        data.forEach(item => {
          const tmpEl = this.$('<span>').html(item.title.rendered || '');
          const dataItem = {
            title: tmpEl ? tmpEl.html() : '',
            createdAt: moment(item.date_gmt),
            author: item._embedded.author[0].name,
            titlePhotoUrl:
              item._embedded['wp:featuredmedia'][0].media_details.sizes
                .medium_large.source_url,
            categories: item.categories,
            link: item.link
          };

          out.pushObject(dataItem);
        });
        const categoryIds = out
          .reduce((sum, item) => {
            return sum.concat(item.categories);
          }, [])
          .uniq();
        all(
          categoryIds.map(categoryId =>
            fetchAjax(
              `${config.BLOG_URL}/wp-json/wp/v2/categories/${categoryId}`
            )
          )
        ).then(categoriesData => {
          out.map(dataItem => {
            dataItem['categories'] = dataItem.categories.map(
              categoryId => categoriesData.findBy('id', categoryId).name
            );
          });
          resolve(out);
        }, reject);
      }, Logger.error);
    })
      .then(postsData => {
        trySet(this, 'postsData', postsData);
      })
      .finally(() => {
        trySet(this, 'loading', false);
      });
  }
});
