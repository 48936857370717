import { helper } from '@ember/component/helper';

export function isAny(params, { key, value }) {
  if (!key || !value) {
    return false;
  }
  return params[0].isAny(key, value);
}

export default helper(isAny);
