import { alias, filter, filterBy, gt } from '@ember/object/computed';

import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import config from '../config/environment';
import { inject as service } from '@ember/service';

export default Mixin.create({
  currentUser: service(),
  source: computed('isAdmin', function () {
    return this.get('isAdmin') ? 'admin' : 'domena';
  }),
  items: null,
  notDeletedItems: filterBy('items', 'isDeleted', false), // items stay in inner storage after deletion
  baseItems: filter('notDeletedItems', function (item) {
    return !item.get('parentId');
  }),
  count: alias('baseItems.length'),
  isAny: gt('count', 0),
  totalPrice: computed(
    'baseItems.@each.{itemPrice,relatedPrice}',
    'currentUser.loadedData.prices',
    function () {
      const total = this.get('baseItems').reduce((sum, item) => {
        return sum + item.get('itemPrice') + item.get('relatedPrice');
      }, 0);
      let places = 2;
      if (typeof (config.money && config.money.decimalPlaces) !== 'undefined') {
        places = config.money.decimalPlaces;
      }

      return Math.round(total * 10 ** places) / 10 ** places;
    }
  ),
  inCart(type, params) {
    return this.getItem(type, params) || null;
  },
  getItem(type, params) {
    return this.get('notDeletedItems').findBy(
      'key',
      this._getKeyForObject(type, params)
    );
  },
  _getKeyForObject(type, params) {
    if (params.key) {
      return params.key;
    }
    switch (type) {
      case 'registration':
        return `registration_${params.name}_${params.tld}`;
      case 'renewal':
        return `renewal_${params.name}_${params.tld}`;
      case 'transfer':
        return `transfer_${params.name}_${params.tld}`;
      case 'email':
        return `email_${params.name}_${params.extra.mailHostingType}`;
      case 'hosting':
        return `hosting_${params.name}_${params.extra.hostingType}`;
      case 'safetyGuard':
        return `safetyGuard_${params.name}_${params.tld}`;
      case 'sslCert':
        return `sslCert_${params.extra.commonName}_${params.extra.sslCertTypeId}`;
      case 'yola':
        return `yola_${params.name}_${params.extra.yolaType}`;
      default:
        return params.key;
    }
  }
});
