import Controller from '@ember/controller';
import Payment from '../mixins/payment';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Controller.extend(Payment, {
  checkout: service(),
  order: service(),
  paying: false,
  paymentMethod: computed(
    'model.{invoice.paymentMethodId,paymentMethods.[]}',
    'checkout.model.paymentMethod',
    {
      get() {
        if (this.get('checkout.model.paymentMethod')) {
          return this.store.peekRecord(
            'paymentMethod',
            this.get('checkout.model.paymentMethod')
          );
        } else {
          const paymentMethod = this.store
            .peekAll('paymentMethod')
            .get('firstObject');
          /* eslint-disable ember/no-side-effects */
          this.set('checkout.model.selectedChannel', null);
          this.set('checkout.model.paymentMethod', paymentMethod.get('id'));
          /* eslint-enable ember/no-side-effects */
          return paymentMethod;
        }
      },
      set(key, value) {
        this.set('model.invoice.paymentMethodId', value.get('id'));
        this.set('checkout.model.paymentMethod', value.get('id'));
        this.get('checkout.model').save();

        return value;
      }
    }
  ),
  channel: computed('model.invoice.channel', 'checkout.model.channel', {
    get() {
      return this.get('checkout.model.channel');
    },
    set(key, value) {
      this.set('checkout.model.channel', value);
      this.set('model.invoice.channel', value);
      this.get('checkout.model').save();
      this.get('model.invoice').save();

      return value;
    }
  }),
  actions: {
    pay() {
      this.set('paying', true);
      const invoiceModel = this.get('model.invoice');
      const paymentMethod = this.get('paymentMethod');
      this.order
        .payOrder(invoiceModel, paymentMethod)
        .then(
          data => {
            if (!data.success) {
              this.replaceRoute('order-all-fail', {
                queryParams: {
                  errorCodes: (data.errors || []).mapBy('detail')
                }
              });
            }
            if (paymentMethod.code === 'credit') {
              this.replaceRoute('thanks', {
                queryParams: {
                  invoice_id: invoiceModel.id
                }
              });
            } else {
              const redirectUrl = data.paylink;
              if (!redirectUrl) {
                throw new Error('Payment link missing');
              }
              window.location = redirectUrl;
            }
          },
          error => {
            this.replaceRoute('order-all-fail', {
              queryParams: {
                errorCodes: (error.errors || []).mapBy('detail')
              }
            });
          }
        )
        .finally(() => {
          this.set('paying', false);
        });
    }
  }
});
