import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  blockData: computed('pageSlug', 'datoSlug', function () {
    return this.get(`cms._translationData.${this.pageSlug}.${this.datoSlug}`);
  }),
  classNames: 'elementor-banner',
  cms: service(),
  pageSlug: computed('datoSlug', function () {
    return this.datoSlug.split('-').slice(0, 1).join('-');
  }),
  slugKey: computed('pageSlug', 'datoSlug', function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  })
});
