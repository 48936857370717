import DS from 'ember-data';
import Order from './order';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import domainParser from 'domena-mobile/utils/domain-parser';

export default Order.extend({
  invoice: DS.belongsTo('invoice'),
  // invoice: DS.belongsTo('invoice', {inverse: 'orders', polymorphic: true}), // FIXME
  domainName: DS.attr('string'),
  authCode: DS.attr('string'),
  ownerContactId: DS.attr('number'),
  years: DS.attr('number'),
  displayName: alias('domainName'),
  nameServerNames: DS.attr(),
  tldName: computed('domainName', function () {
    const domainName = this.get('domainName');
    let parsedDomain = {};

    if (domainName && domainName.indexOf('.') !== -1) {
      const tlds = this.get('store').peekAll('tld');
      parsedDomain = domainParser(domainName, tlds);
    }

    return parsedDomain.tld;
  }),
  tld: alias('tldName')
});
