import $ from 'jquery';
import config from '../config/environment';
import { computed, observer } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  config,
  classNames: ['lazy-loading-list'],
  items: null,
  itemsPerPage: 25,
  target: '',
  _target: null,
  page: 1,
  scrollHandler: null,
  loading: false,
  pagedItems: computed('items.[]', 'page', function () {
    return (this.get('items') || []).slice(
      0,
      this.get('page') * this.get('itemsPerPage')
    );
  }),
  itemsObserver: observer('items.length', function () {
    this.get('_target').scrollTop(0);
  }),
  didInsertElement() {
    const targetSelector = this.get('target');
    let target = this.$('.list');
    if (targetSelector) {
      target = this.$().closest(targetSelector);
    }
    const targetElem = targetSelector === 'body' ? $(window) : target;
    this.set('_target', target);

    this.set('scrollHandler', () => {
      let scrollBottom = target.scrollTop() + targetElem.outerHeight(),
        list = targetSelector === 'body' ? $('body') : this.$('.list'),
        listHeight = list.outerHeight() - this.$('.lazy-loading').outerHeight();

      if (scrollBottom >= listHeight && !this.get('loading')) {
        if (this.get('pagedItems.length') < this.get('items.length')) {
          this.set('loading', true);
          this.incrementProperty('page');
          this.$('.lazy-loading .loader').removeClass('hidden');
        } else {
          this.set('loading', false);
        }
      }
    });
    targetElem.on('scroll', this.get('scrollHandler'));
  },
  didRender() {
    this.set('loading', false);
    this.$('.lazy-loading .loader').addClass('hidden');
  },
  willDestroyElement() {
    this.get('_target').off('scroll', this.get('scrollHandler'));
  }
});
