import DS from 'ember-data';
import WyServicePack from './wy-service-pack';
import { computed } from '@ember/object';

export default WyServicePack.extend({
  confirmationMailLocalParts: DS.attr(),
  enabled: DS.attr('boolean'),
  extendedAttributes: DS.attr(),
  maxSans: DS.attr('number', { defaultValue: 0 }),
  name: DS.attr('string'),
  prices: DS.attr(),
  wildcard: DS.attr('boolean'),
  replacedBy: DS.belongsTo('sslCertType', { inverse: null }),
  price: computed('customerPrice', 'supportedPeriods.[]', function () {
    let defaultPeriod = this.supportedPeriods.firstObject;
    if ((this.supportedPeriods || []).includes(12)) {
      defaultPeriod = 12;
    }
    return this.customerPrice * defaultPeriod;
  })
});
