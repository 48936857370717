import { reject, resolve } from 'rsvp';

import Component from '@ember/component';
import Logger from '../../utils/logger';
import Regex from '../../utils/regex';
import addDefaultParams from '../../utils/default-wyapi-params';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import domainParser from '../../utils/domain-parser';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  cart: service(),
  cms: service(),
  config: service(),
  gtmEvents: service(),
  intl: service(),
  store: service(),
  taskQueue: service(),
  classNames: ['new-yola-form'],
  builderLink: null,
  activeHosting: false,
  customerDomain: '',
  customerEmail: '',
  page: '0',
  selectedPlan: alias('model'),
  hasErrors: false,
  yolaItems: null,
  customerDomainLowercase: computed('customerDomain', function () {
    return this.get('customerDomain').toLowerCase();
  }),
  customerEmailLowercase: computed('customerEmail', function () {
    return this.get('customerEmail').toLowerCase();
  }),
  yearsData: computed('selectedPlan', function () {
    return (this.selectedPlan?.supportedPeriods || []).map(period =>
      parseInt(period, 10)
    );
  }),
  selected: 12,
  loadingText: '',
  template: '',
  templateSelected: false,
  isReadyToOrder: computed('customerDomainLowercase', function () {
    const parsedDomain = domainParser(
      this.get('customerDomainLowercase'),
      this.tlds
    );
    return (
      Regex.DOMAIN_REGEX.test(this.get('customerDomainLowercase')) &&
      parsedDomain.parsed
    );
  }),
  isFreeReadyToOrder: computed('customerEmailLowercase', function () {
    return Regex.EMAIL_REGEX.test(this.get('customerEmailLowercase'));
  }),
  init() {
    this._super(...arguments);

    this.set('tlds', this.store.peekAll('tld'));
    if (this.get('options.customerDomain')) {
      this.set('customerDomain', this.get('options.customerDomain'));
    }
  },
  didInsertElement() {
    this.set('yolaItems', this.store.peekAll('yola-plan'));
    if (this.get('selectedPlan')) {
      this.set('page', '1');
    }

    if (this.get('options.vizitkaDomainName')) {
      this.set('customerDomain', this.get('options.vizitkaDomainName'));
    }
  },
  async _addTransfer(parsedDomain) {
    try {
      this._addYola();
      await this.get('cart').addItem('transfer', {
        name: parsedDomain.domain,
        tld: parsedDomain.tld,
        authCode: ''
      });
      tryInvoke(this, 'close');
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      } else {
        this.set('hasErrors', {
          localeString: 'hosting_order.validation_msg_2'
        });
      }
    }
  },
  _checkIsIgnum(parsedDomain) {
    const { API_HOST } = this.config.getProperties('API_HOST');
    const adapter = this.store.adapterFor('wyApi');
    const url = new URL(`${API_HOST}/order/checkDomainName`);
    return adapter
      .ajax(addDefaultParams(url.toString()), 'GET', {
        data: {
          domain_name: parsedDomain.domain,
          toplevel: parsedDomain.tld
        }
      })
      .then(data => {
        if (data.success) {
          if (data.domain_status === 'EXIST_IN_SYSTEM') {
            return resolve(true);
          }
          return resolve(false);
        } else {
          return reject(data);
        }
      });
  },
  _orderFree() {
    const { API_HOST, LOCALE } = this.config.getProperties(
      'API_HOST',
      'LOCALE'
    );
    const adapter = this.store.adapterFor('wyApi');
    const url = new URL(`${API_HOST}/order/webbuilder/create-test-acc`);
    return adapter.ajax(addDefaultParams(url.toString()), 'POST', {
      data: {
        email: this.customerEmailLowercase,
        lang: LOCALE
      }
    });
  },
  async _addYola(parsedDomain, parentId) {
    this.set('hasErrors', null);
    this.set('submitLoading', true);

    try {
      const yolaParams = {
        extra: {
          yolaType: this.selectedPlan.id,
          yolaImport: this.options.yolaImport,
          modelName: this.selectedPlan?.constructor?.modelName
        },
        months: this.selected,
        name: this.customerDomain
      };

      if (parentId) {
        yolaParams.parentId = parentId;
        yolaParams.parentType = 'registration';
      }

      await this.cart.addItem('yola', yolaParams);

      tryInvoke(this, 'close', [this.selectedPlan.id]);
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      } else {
        this.set('hasErrors', {
          localeString: 'hosting_order.validation_msg_2'
        });
      }
      this.set('submitLoading', false);
    }
  },
  async _addRegistrationYola(parsedDomain) {
    try {
      await this.get('cart')
        .addItem(
          'registration',
          {
            name: parsedDomain.domain,
            tld: parsedDomain.tld
          },
          false
        )
        .then(item => {
          this._addYola(parsedDomain, item.id);
        });
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      } else {
        this.set('hasErrors', {
          localeString: 'hosting_order.validation_msg_2'
        });
      }
    }
  },
  actions: {
    handleSelectPlan(plan) {
      this.set('selectedPlan', plan);
      const store = this.get('store');
      const tlds = store.peekAll('tld');
      const parsedDomain = domainParser(this.get('customerDomain'), tlds);
      this._addRegistrationYola(parsedDomain);
    },
    checkAvailability(event) {
      event?.preventDefault();
      if (!this.isReadyToOrder) return;
      this.set('hasErrors', null);
      const store = this.get('store');
      const tlds = store.peekAll('tld');
      const parsedDomain = domainParser(this.get('customerDomain'), tlds);
      if (this.get('domain')) {
        this._addYola();
        return;
      }

      const domainCheck = store
        .peekAll('domain-check')
        .findBy('tldName', parsedDomain.tld);
      if (!domainCheck) {
        this._addYola();
        return;
      }
      this.set('loadingText', `${this.intl.t('free_mail.loading')}`);
      this.get('taskQueue')
        .enqueue(
          'domainCheckTask',
          domainCheck,
          `${parsedDomain.domain}.${parsedDomain.tld}`
        )
        .then(
          data => {
            if (!data) {
              this.set('loadingText', '');
              return;
            }

            if (data.get('available')) {
              this._addRegistrationYola(parsedDomain);
            } else {
              // obsazená doména
              this._checkIsIgnum(parsedDomain).then(
                isIgnum => {
                  this.set('loadingText', '');

                  if (isIgnum) {
                    this.set('activeHosting', 'ignum');
                  } else {
                    // TODO az pujde transfer dodelat
                    this.set('activeHosting', 'transfer');
                  }
                },
                e => {
                  Logger.error(e);
                  this.set('loadingText', '');
                  this.set('hasErrors', {
                    localeString: 'hosting_order.validation_msg_2'
                  });
                }
              );
            }
          },
          e => {
            Logger.error(e);
            this.set('loadingText', '');
            this.set('hasErrors', {
              localeString: 'hosting_order.validation_msg_2'
            });
          }
        );
    },
    createOrder(event) {
      event.preventDefault();
      if (!this.isFreeReadyToOrder) return;
      this.set('hasErrors', null);
      this.set('submitLoading', true);
      this._orderFree().then(
        data => {
          this.set('submitLoading', false);
          if (data.success) {
            this.gtmEvents.logYolaFreeEcommerce(data);
            this.set('page', '3');
            const ADMIN_URL = this.get('config.ADMIN_URL');
            this.set(
              'builderLink',
              `${ADMIN_URL}/webbuilder/login-to-test-acc?userID=${data.userID}&hash=${data.hash}`
            );
          } else {
            this.set('hasErrors', {
              localeString: 'yola_order.cannot_order'
            });
          }
        },
        error => {
          Logger.error(error);
          this.set('submitLoading', false);
          this.set('hasErrors', {
            localeString: 'yola_order.cannot_order'
          });
        }
      );
    },
    transferDomain() {
      const parsedDomain = domainParser(
        this.get('customerDomainLowercase'),
        this.tlds
      );
      this._addTransfer(parsedDomain);
    }
  }
});
