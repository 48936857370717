import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/domeny'
      }
    }
  ]),
  model() {
    return new hash({
      tldPrices: this.store.peekAll('price')
    });
  },
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  }
});
