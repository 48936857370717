import { buildValidations, validator } from 'ember-cp-validations';
import { computed, observer } from '@ember/object';

import DS from 'ember-data';
import Order from './order';
import SERVICE_CONFIG from '../utils/service-config';
import { alias } from '@ember/object/computed';
import config from '../config/environment';
import regex from '../utils/regex';

const DEFAULT_DIALING_CODE = `+${config.DEFAULT_DIALING_CODE}` || '+420';

const validations = buildValidations({
  adminMail: validator('format', {
    type: 'email',
    messageKey: 'validations.email'
  }),
  adminName: validator('format', {
    // IPAS ji nepotrebuje staci jen text
    regex: regex.FULL_NAME_REGEX,
    messageKey: 'validations.min_length_4_with_space'
  }),
  commonName: validator('format', {
    regex: regex.DOMAIN_REGEX,
    messageKey: 'validations.please_fill'
  }),
  certOrganization: validator('presence', {
    presence: computed('model.sslCertTypeId', function () {
      return (
        SERVICE_CONFIG[config.LOCALE][this.get('model.sslCertTypeId')]
          .enableOrganizationField || false
      );
    }),
    messageKey: 'validations.please_fill'
  }),
  certOrganizationUnit: validator('presence', {
    presence: config.LOCALE === 'cs' ? true : false,
    messageKey: 'validations.please_fill'
  }),
  certStreet: validator('presence', {
    presence: true,
    messageKey: 'validations.please_fill'
  }),
  certZip: [
    validator('length', {
      is: 5,
      messageKey: 'validations.zip_cz',
      dependentKeys: ['model.certCountry'],
      disabled: computed(function () {
        return this.get('model.certCountry') !== 'CZ';
      }).volatile()
    }),
    validator('number', {
      allowString: true,
      integer: true,
      messageKey: 'validations.zip_cz',
      dependentKeys: ['model.certCountry'],
      disabled: computed(function () {
        return this.get('model.certCountry') !== 'CZ';
      }).volatile()
    }),
    validator('format', {
      regex: /^[\da-zA-Z\-\s]{2,10}$/,
      messageKey: 'validations.zip_other',
      dependentKeys: ['model.certCountry'],
      disabled: computed(function () {
        return this.get('model.certCountry') === 'CZ';
      }).volatile()
    })
  ],
  certCity: validator('presence', {
    presence: true,
    messageKey: 'validations.please_fill'
  }),
  certState: validator('presence', {
    presence: true,
    messageKey: 'validations.please_fill'
  }),
  confirmationMail: validator('format', {
    type: 'email',
    messageKey: 'validations.please_fill'
  }),
  'sans.0.name': validator('format', {
    regex: regex.DOMAIN_REGEX,
    messageKey: 'validations.please_fill',
    allowBlank: true
  }),
  'sans.1.name': validator('format', {
    regex: regex.DOMAIN_REGEX,
    messageKey: 'validations.please_fill',
    allowBlank: true
  }),
  'sans.0.confirmation-mail': validator('format', {
    type: 'email',
    messageKey: 'validations.please_fill',
    dependentKeys: ['sans.0.name'],
    disabled: computed(function () {
      return (
        !this.get('model.sans.0.name.length') ||
        this.get('model.sans.0.name.length') === 0
      );
    }).volatile()
  }),
  'sans.1.confirmation-mail': validator('format', {
    type: 'email',
    messageKey: 'validations.please_fill',
    dependentKeys: ['sans.1.name'],
    disabled: computed(function () {
      return (
        !this.get('model.sans.1.name.length') ||
        this.get('model.sans.1.name.length') === 0
      );
    }).volatile()
  }),
  phoneFormatted: [
    validator('length', {
      is: 9,
      messageKey: 'validations.phone_number_cz',
      dependentKeys: ['model.dialingCode'],
      disabled: computed(function () {
        return this.get('model.dialingCode') !== '+420';
      }).volatile()
    }),
    validator('length', {
      min: 5,
      max: 15,
      messageKey: 'validations.phone_number_other',
      dependentKeys: ['model.dialingCode'],
      disabled: computed(function () {
        return this.get('model.dialingCode') === '+420';
      }).volatile()
    }),
    validator('format', {
      regex: /^[0-9]+$/,
      messageKey: 'validations.only_numbers'
    })
  ]
});

export default Order.extend(validations, {
  dialingCode: DEFAULT_DIALING_CODE,
  phoneObserver: observer('phoneFormatted', 'dialingCode', function () {
    this.set(
      'adminPhone',
      `${this.get('dialingCode')}.${this.get('phoneFormatted')}`
    );
  }),
  sslCertTypeId: DS.attr('string'),
  sslCertType: DS.belongsTo('sslCertType'),
  commonName: DS.attr('string'),
  years: DS.attr('number'),
  renew: DS.attr('boolean'),
  csr: DS.attr('string'),
  adminName: DS.attr('string'),
  adminMail: DS.attr('string'),
  adminPhone: DS.attr('string'),
  certOrganization: DS.attr('string'),
  certOrganizationUnit: DS.attr('string'),
  certStreet: DS.attr('string'),
  certZip: DS.attr('string'),
  certCity: DS.attr('string'),
  certState: DS.attr('string'),
  certCountry: DS.attr('string'),
  confirmationMail: DS.attr('string'),
  ic: DS.attr('string'),
  sans: DS.attr(), // [{"name":"www.iquest.com","confirmation-mail":"admin@iquest.cz"}]
  displayName: alias('commonName'),
  invoice: DS.belongsTo('invoice'),
  extraClassesString: 'is-inactive',
  state: 'inactive'
});
