import Component from '@ember/component';
import Logger from '../utils/logger';
import Regex from '../utils/regex';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import domainParser from '../utils/domain-parser';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';
import SERVICE_CONFIG from '../utils/service-config';
import config from '../config/environment';

const PAGES = { HOSTING: 'hosting', SELECT_TYPE: 'selectType' };
const serviceConfig = SERVICE_CONFIG[config.LOCALE];

export default Component.extend({
  PAGES,
  availablePlans: null,
  cart: service(),
  cms: service(),
  intl: service(),
  store: service(),
  taskQueue: service(),
  classNames: ['hosting-plan-select'],
  hostingType: 'webHostingPlan', // default value
  activeHosting: false,
  customerDomain: '',
  hostingTypeId: '',
  hasErrors: false,
  customerDomainLowercase: computed('customerDomain', function () {
    return this.get('customerDomain').toLowerCase();
  }),
  customerDomainSanitized: computed('customerDomainLowercase', function () {
    const store = this.get('store');
    const tlds = store.peekAll('tld');
    const parsedDomain = domainParser(
      this.get('customerDomainLowercase'),
      tlds
    );
    return `${parsedDomain.domain}.${parsedDomain.tld}`;
  }),
  selectedPlan: computed('hostingTypeId', function () {
    if (!this.hostingTypeId) return null;
    return (this.availablePlans || []).findBy('id', this.hostingTypeId);
  }),
  yearsData: computed('selectedPlan', function () {
    return (this.selectedPlan?.supportedPeriods || []).map(period =>
      parseInt(period, 10)
    );
  }),
  enableTemplateField: false,
  selected: 12,
  loadingText: '',
  page: PAGES.HOSTING,
  template: '',
  templateSelected: false,
  isReadyToOrder: computed('customerDomainSanitized', function () {
    return Regex.DOMAIN_REGEX.test(this.get('customerDomainSanitized'));
  }),
  init() {
    this._super(...arguments);
    this.setProperties({
      availablePlans: []
    });

    if (!this.get('model')) {
      this.set('page', PAGES.SELECT_TYPE);
    }

    if (this.get('options.customerDomain')) {
      this.set('customerDomain', this.get('options.customerDomain'));
    }

    if (this.get('options.hostingType')) {
      this.set('hostingType', this.get('options.hostingType'));
    }
  },
  sortedAvailablePlans: computed('availablePlans.[]', function () {
    const typesOrder = this.cms?.pages?.produkty?.blocks.mapBy('slug');
    return this.availablePlans?.toArray()?.sort((itemA, itemB) => {
      const itemAPos = typesOrder.indexOf(dasherize(itemA.pack));
      const itemBPos = typesOrder.indexOf(dasherize(itemB.pack));
      return itemAPos - itemBPos;
    });
  }),
  didInsertElement() {
    const availablePlans = this.store.peekAll(this.hostingType);
    this.setProperties({
      availablePlans,
      hostingTypeId: this.get('model')
    });
    if (this.selectedPlan) {
      this.set(
        'enableTemplateField',
        serviceConfig[this.selectedPlan.pack].enableTemplateField
      );
    }
  },
  async _addHosting(parsedDomain, id) {
    this.set('hasErrors', false);
    this.set('submitLoading', true);

    try {
      await this.cart.addItem('hosting', {
        extra: {
          domains:
            this.selectedPlan?.domainsCount?.length > 0
              ? parseInt(this.selectedPlan?.domainsCount[0])
              : null,
          hostingType: this.hostingTypeId,
          template: this.template,
          modelName: this.selectedPlan?.constructor?.modelName
        },
        months: this.selected,
        name: this.customerDomainSanitized,
        parentId: id || null,
        parentType: 'registration'
      });

      tryInvoke(this, 'close', [this.hostingTypeId]);
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      } else {
        this.set('hasErrors', {
          localeString: 'hosting_order.validation_msg_2'
        });
      }
      this.set('submitLoading', false);
    }
  },
  _addRegistrationHosting(parsedDomain) {
    try {
      this.get('cart')
        .addItem(
          'registration',
          {
            name: parsedDomain.domain,
            tld: parsedDomain.tld
          },
          false
        )
        .then(item => {
          this._addHosting(parsedDomain, item.id);
        });
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      }
    }
  },
  actions: {
    checkAvailability(event) {
      this.set('hasErrors', '');
      event.preventDefault();
      const store = this.get('store');
      const tlds = store.peekAll('tld');
      const parsedDomain = domainParser(
        this.get('customerDomainSanitized'),
        tlds
      );
      if (this.get('domain')) {
        this._addHosting(parsedDomain, this.get('domain.id'));
        return;
      }

      const domainCheck = store
        .peekAll('domain-check')
        .findBy('tldName', parsedDomain.tld);
      if (!domainCheck) {
        this._addHosting(parsedDomain);
        return;
      }
      this.set('loadingText', `${this.intl.t('free_mail.loading')}`);
      this.get('taskQueue')
        .enqueue(
          'domainCheckTask',
          domainCheck,
          `${parsedDomain.domain}.${parsedDomain.tld}`
        )
        .then(
          data => {
            this.set('loadingText', '');

            if (!data) {
              return;
            }

            if (data.get('available')) {
              this._addRegistrationHosting(parsedDomain);
            } else {
              this._addHosting(parsedDomain);
            }
          },
          e => {
            this.set('loadingText', '');
            this.set('hasErrors', {
              localeString: 'hosting_order.validation_msg_2'
            });
            Logger.error(e);
          }
        )
        .finally(() => {
          this.set('error', null);
        });
    },
    removePlaceholderGray() {
      this.element
        .querySelector('.hosting-order__select')
        .classList.remove('gray-select');
      this.set('templateSelected', true);
    },
    addPlaceholderGray(value) {
      if (value === 'none') {
        this.element
          .querySelector('.hosting-order__select')
          .classList.add('gray-select');

        this.set('template', '');
      } else {
        this.set('template', value);
      }
    },
    handleSelectPlan(plan) {
      this.set('hostingTypeId', plan.id);
      if (plan.supportedPeriods)
        this.set(
          'selected',
          plan.supportedPeriods.includes(12) ? 12 : plan.supportedPeriods[0]
        );
      this.set('page', PAGES.HOSTING);
    }
  }
});
