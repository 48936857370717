import Regex from 'domena-mobile/utils/regex';
import Route from '@ember/routing/route';
import domainParser from '../utils/domain-parser';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/password-reset'
      }
    }
  ]),
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  },
  setupController(controller) {
    controller.set('user', this.store.createRecord('user'));

    if (controller.get('reset_code')) {
      controller.set('page', 'set');
    }
  },
  resetController(controller) {
    controller.setProperties({
      page: null,
      login: null,
      reset_code: null
    });
  },
  actions: {
    willTransition() {
      const user = this.controller.get('user');
      user.setProperties({ login: '', password: '' });
    },
    restoreData() {
      const adapter = this.store.adapterFor('user');
      const newUrl = new URL(adapter.buildURL());
      const loginString = this.controller.get('login');
      const isEmail = Regex.EMAIL_REGEX.test(loginString);
      const { domain } = domainParser(loginString);
      const postData = {};

      if (domain && !isEmail) {
        newUrl.pathname = `${newUrl.pathname}/lost-login`;
        postData.domain = this.controller.get('login');
      } else {
        newUrl.pathname = `${newUrl.pathname}/lost`;
        postData.login = this.controller.get('login');
      }

      this.controller.set('ipasError', '');

      adapter
        .ajax(newUrl.toString(), 'POST', {
          data: postData
        })
        .then(
          () => {
            this.controller.set('page', 'confirm');
          },
          error => {
            this.controller.set('ipasError', error.errors[0]);
          }
        );
    },
    confirmData() {
      this.controller.set('ipasError', '');

      let validations = this.controller.get('user.validations.attrs');

      if (
        validations.get('password.isValid') &&
        validations.get('passwordConfirm.isValid')
      ) {
        const adapter = this.store.adapterFor('user');
        const newUrl = new URL(adapter.buildURL());
        newUrl.pathname = `${newUrl.pathname}/key`;

        adapter
          .ajax(newUrl.toString(), 'POST', {
            data: {
              key: this.controller.get('reset_code'),
              password: this.controller.get('user.password')
            }
          })
          .then(
            () => {
              this.transitionTo('signin', {
                queryParams: { passwordChanged: true }
              });
            },
            error => {
              this.controller.set('ipasError', error.errors[0]);
            }
          );
      } else {
        this.controller.set('showErrors', true);
      }
    }
  }
});
