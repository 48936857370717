import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';
import Common from '../mixins/common';

export default Component.extend(Common, {
  actions: {
    afterLoginSuccess() {
      tryInvoke(this, 'close', [true]);
    },
    close() {
      tryInvoke(this, 'close');
    }
  }
});
