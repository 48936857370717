define("domena-mobile/adapters/domain-selected", ["exports", "domena-mobile/adapters/local-storage"], function (_exports, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _localStorage.default;
    }
  });
});
