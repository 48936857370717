import { isEmpty } from '@ember/utils';
import { Promise as EmberPromise } from 'rsvp';
import { cancel, later, bind } from '@ember/runloop';
import $ from 'jquery';
import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import Logger from '../utils/logger';

export default Service.extend(Evented, {
  session: service(),
  currentUser: service(),
  scrollTop: 0,
  scrollTopRaw: 0,
  scrollTopDelayTimer: null,
  resizeDelayTimer: null,
  loggingTimer: null,
  signoutTimer: null,
  signoutTimestamp: 0,
  events: null,
  init() {
    this._super(...arguments);
    this.set('events', []);
    if (!$) {
      return;
    }

    this.set('events', []);

    window.addEventListener(
      'scroll',
      () => {
        this.trigger('scroll:changeRaw');
        this.set('scrollTopRaw', $(window).scrollTop());
        cancel(this.get('scrollTopDelayTimer'));
        this.set(
          'scrollTopDelayTimer',
          later(() => {
            this.set('scrollTop', $(window).scrollTop());
            this.trigger('scroll:change');
          }, 1000)
        );
      },
      { passive: true }
    );

    window.addEventListener(
      'resize',
      () => {
        cancel(this.get('resizeDelayTimer'));
        this.set(
          'resizeDelayTimer',
          later(() => {
            this.trigger('resize');
          }, 500)
        );
      },
      { passive: true }
    );
  },
  isVisible(elem, raw = false, offset = 0) {
    const scrollTop = this.get(raw ? 'scrollTopRaw' : 'scrollTop');
    const windowHeight = $(window).height();
    let isVisible = false;
    let offsetTop;

    if (elem) {
      offsetTop = elem.offset().top;
      isVisible =
        scrollTop < offsetTop && offsetTop < scrollTop + windowHeight + offset;
    }

    return isVisible;
  },
  watchedEvents: Object.freeze({
    click: ['.nav-menu-link']
  }),
  logEvent(/* event, target, value */) {
    // let data = {
    //   event: event,
    //   target: target,
    //   value: value,
    //   url: window.location.pathname,
    //   time: Date.now()
    // };
    //
    // this.get('events').push(data);
  },
  sendLog() {
    return new EmberPromise(resolve => {
      let event;

      while ((event = this.get('events').shift()) !== null) {
        if (event) {
          // TODO: odesilani
          Logger.debug('sending', event);
        }

        resolve();
      }
    });
  },
  createHandlers() {
    if (!$) {
      return;
    }

    // Ember.$('body').on('click', e => {
    //   this.clickHandler(e.target);
    // });

    $('body').on('click keydown', bind(this, this.stopSignout));
    $('body').on('click keydown', bind(this, this.autoSignout));
  },
  autoSignout() {
    let eightHours = 8 * 60 * 60 * 1000;
    let tenMinutes = 10 * 60 * 1000;

    this.set(
      'signoutTimer',
      later(() => {
        let signoutInterval = Date.now() - this.get('signoutTimestamp');

        if (signoutInterval > eightHours) {
          this.get('session')
            .invalidate()
            .then(() => {
              return this.currentUser.afterLogout();
            });
        }

        this.autoSignout();
      }, tenMinutes)
    );
  },
  stopSignout() {
    this.set('signoutTimestamp', Date.now());
    cancel(this.get('signoutTimer'));
  },
  autoSendLog() {
    let timer = this.get('loggingTimer');
    if (timer) {
      cancel(timer);
    }

    this.set(
      'loggingTimer',
      later(() => {
        this.sendLog();
        this.autoSendLog();
      }, 5000)
    );
  },
  stopSendLog() {
    let timer = this.get('loggingTimer');
    if (timer) {
      cancel(timer);
      this.set('running', false);
    }
  },
  startSendLog() {
    let timer = this.get('loggingTimer');
    if (isEmpty(timer)) {
      this.autoSendLog();
      this.set('running', true);
    }
  },
  clickHandler(htmlElem) {
    let watched = this.get('watchedEvents').click;
    let elem = $(htmlElem);
    let isWatched = false;
    let target = '';

    watched.forEach(item => {
      let className = item.substr(1);

      if (elem.hasClass(className)) {
        target = elem.data('event-target') || elem.attr('href');
        isWatched = true;
      }

      let closest = elem.closest(item);

      if (closest.length > 0) {
        target = closest.data('event-target') || closest.attr('href');
        isWatched = true;
      }
    });

    if (isWatched) {
      this.logEvent('click', 'menu', target);
    }
  }
});
