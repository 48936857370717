import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  cms: service(),
  classNames: [
    'landing-page__block',
    'try-before-buy-block',
    'bg-primary',
    'relative'
  ],
  pageSlug: computed(function () {
    return this.datoSlug.split('-').slice(0, 2).join('-');
  }),
  slugKey: computed(function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  })
});
