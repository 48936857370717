import $ from 'jquery';
import { observer } from '@ember/object';
import Component from '@ember/component';
import Common from '../mixins/common';
import { inject as service } from '@ember/service';
import { trySet } from '@ember/object';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  deviceDetection: service(),
  classNames: ['simple-modal', 'fixed'],
  classNameBindings: ['isOpen:open', 'actionBar'],
  isOpen: false,
  actionBar: false,
  keyUpHandler: null,
  whatModal: '',
  observer: observer('isOpen', function () {
    this._setClass(this.get('isOpen'));

    if (this.get('isOpen')) {
      tryInvoke(this, 'didOpen');
    }

    if (this.get('deviceDetection.isDesktop')) {
      return;
    }

    if (this.get('isOpen')) {
      this.onBackClose('modal');
    }
  }),
  willDestroyElement() {
    this._setClass(false);
  },
  actions: {
    modalClose() {
      this._setClass(false);
      tryInvoke(this, 'action');
    }
  },
  _setClass(isOpen) {
    if (isOpen) {
      $('body, html').addClass('disable-scroll');
    } else {
      $('body, html').removeClass('disable-scroll');
    }
  },
  didInsertElement() {
    this._super(...arguments);
    if (!$) {
      return;
    }
    let _this = this;
    this.set('keyUpHandler', e => {
      if (e.keyCode === 27) {
        trySet(_this, 'isOpen', false);
      }
    });
    this._setClass(this.get('isOpen'));
    $(window).on('keyup', this.get('keyUpHandler'));
  },
  didDestroyElement() {
    if (!$) {
      return;
    }
    $(window).off('keyUp', this.get('keyUpHandler'));
  }
});
