import { computed, observer } from '@ember/object';

import Component from '@ember/component';
import Logger from '../../utils/logger';
import SERVICE_CONFIG from '../../utils/service-config';
import { alias } from '@ember/object/computed';
import { assign } from '@ember/polyfills';
import config from '../../config/environment';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

const PARAMS = [];

const EXTRA_PARAMS = [{ id: 'ic', type: 'string', page: 2, position: 40 }]; // required urcuje server

export default Component.extend({
  tagName: 'form',
  classNames: ['new-ssl-cert-form'],
  gtmEvents: service(),
  modal: service(),
  store: service(),
  intl: service(),
  cart: service(),
  data: null,
  enableOrganizationField: null,
  disableOrganizationUnitField: config.LOCALE === 'cs',
  isValid: alias('data.validations.isValid'),
  observer: observer('page', function () {
    const titleText =
      this.page === 1
        ? `<h2>${this.intl.t('ssl-certs.info.add_info')} ${this.intl.t(
            `cms.produkty.${dasherize(this.get('data.sslCertTypeId'))}.title`
          )}</h2>`
        : `<h2>${this.intl.t('ssl-certs.info.confirm_info')}</h2>`;
    this.set('options.header', titleText);
  }),
  params: computed('page', 'extraParams', function () {
    return PARAMS.concat(this.extraParams)
      .filterBy('page', this.page)
      .sort((a, b) => a.position - b.position);
  }),
  extraParams: computed('model.extendedAttributes', function () {
    const extendedAttributes = this.get('model.extendedAttributes');
    if (extendedAttributes) {
      const keys = Object.keys(extendedAttributes);
      return EXTRA_PARAMS.filter(item => keys.includes(item.id)).map(item => {
        item['required'] =
          extendedAttributes[item.id] && extendedAttributes[item.id].required;
        return item;
      });
    }
  }),
  page: 1,
  didInsertElement() {
    // 203 CR, 703 SK
    const defCountry = this.store.peekRecord(
      'country',
      config.DEFAULT_COUNTRY_ID || '203'
    );
    const params = assign(
      {
        sslCertTypeId: this.get('model.id'),
        renew: false,
        certCountry: defCountry.get('alpha2'),
        certState: defCountry.get('name'),
        years: 1
      },
      this.get('options.params') || {}
    );
    params['phoneFormatted'] = (params.adminPhone || '').split('.')[1];
    const model = this.store.createRecord('sslCertOrder', params);

    if (!model.get('sans.length')) {
      model.set('sans', []);
    }

    for (let i = 0; i < this.get('model.maxSans'); i++) {
      model.get('sans').pushObject({ name: '', 'confirmation-mail': '' });
    }

    this.set('data', model);

    this.set(
      'enableOrganizationField',
      SERVICE_CONFIG[config.LOCALE][model.sslCertTypeId].enableOrganizationField
    );

    this.set(
      'options.header',
      `<h3>${this.intl.t('ssl-certs.info.add_info')} ${this.intl.t(
        `cms.produkty.${dasherize(model.sslCertTypeId)}.title`
      )}</h3>`
    );
  },
  willDestroyElement() {
    this.get('data').rollbackAttributes();
  },
  async submit() {
    if (!this.get('isValid')) {
      return false;
    }

    // clean up empty sans
    this.set(
      'data.sans',
      this.get('data.sans').filter(
        item => item.name && item['confirmation-mail']
      )
    );
    const extraData = this.get('data').toJSON();

    // remove extra data
    delete extraData.invoiceContactId;
    delete extraData.registrationRulesConsent;
    delete extraData.cartItemId;
    delete extraData.price;
    delete extraData.vat;
    delete extraData.state;

    try {
      const sslCartItem = await this.get('cart').addItem('sslCert', {
        name: this.get('data.commonName'),
        extra: extraData,
        certTypeId: this.get('model.id')
      });
      tryInvoke(this, 'close', [this.get('model'), sslCartItem, extraData]);
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      }
    }

    return false;
  },
  actions: {
    countrySelect(countryObj) {
      this.set('data.certCountry', countryObj.get('alpha2'));
      this.set('data.certState', countryObj.get('name'));
    },
    submitForm() {
      this.gtmEvents.logSubmitForm('new-ssl-cert-form');
      this.submit();
    },
    helpOpen() {
      this.modal.show('info/new-ssl-help', null, {
        modalClasses: 'info'
      });
    }
  }
});
