import $ from 'jquery';
import { observer, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import Common from '../mixins/common';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  cart: service(),
  ideas: service(),
  classNames: ['ideas-cart'],
  classNameBindings: ['pinned', 'collapsed'],
  domains: null,
  // / ????
  domainsSelected: alias('cart.notDeletedItems'),
  domainsSelectedLength: alias('domainsSelected.length'),
  domainsSelectedLengthObserver: observer('domainsSelectedLength', function () {
    this.set('fromSummary', null);
  }),
  // / END

  domainsCheck: alias('domains.domainsCheck'),
  fromSummary: false,
  pinned: false,
  collapsed: true,
  showTips: true,
  suggestedName: computed('domainsSelected.length', function () {
    const data = this.get('domainsSelected').filterBy('type', 'registration');
    return data.sortBy('createdAt').get('lastObject.name');
  }),
  suggestedDomains: computed(
    'domainsSelected.[]',
    'domainsCheck.@each.available',
    function () {
      return this.get('domainsCheck')
        .filter(item => {
          return (
            typeof this.get('domainsSelected').findBy(
              'tld.name',
              item.get('tldName')
            ) === 'undefined' && item.get('available') !== false
          );
        })
        .sortBy('position')
        .slice(0, 3);
    }
  ),
  vatRate: 1.21,
  scrollHandler: null,
  didInsertElement() {
    if (!window || $('.ideas-cart').length === 0) {
      return;
    }

    const elementPosition = $('.ideas-cart').offset().top - 95;
    if ($(window).scrollTop() > elementPosition) {
      this.$().addClass('fixed');
      this.set('ideas.fixed', true);
    } else {
      this.$().removeClass('fixed');
      this.set('ideas.fixed', false);
    }

    this.set('scrollHandler', () => {
      if ($(window).scrollTop() > elementPosition) {
        this.$().addClass('fixed');
        this.set('ideas.fixed', true);
      } else {
        this.$().removeClass('fixed');
        this.set('ideas.fixed', false);
      }
    });

    $(window).scroll(this.get('scrollHandler'));
  },
  didDestroyElement() {
    $(window).off('scroll', this.get('scrollHandler'));
  },
  actions: {
    transitionTo(path, params) {
      tryInvoke(this, 'action', [path, params]);
    },
    toggle() {
      if (!this.get('deviceDetection.isDesktopLarge')) {
        this.toggleProperty('collapsed');
      }
    },
    onScroll() {
      $(window).trigger('scroll');
    }
  }
});
