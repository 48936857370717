import { helper } from '@ember/component/helper';

export function isEvery(params, { key, value }) {
  if (!key || !value) {
    return false;
  }
  return params[0].isEvery(key, value);
}

export default helper(isEvery);
