import { helper } from '@ember/component/helper';

export function numberFormat([number] /* , hash*/) {
  if (!number) {
    return '';
  }
  return number.toString().replace(/\d(?=(\d{3})+$)/g, '$& ');
}

export default helper(numberFormat);
