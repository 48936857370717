import { helper } from '@ember/component/helper';

export function includes([a, b] /* , hash*/) {
  if (!a) {
    return false;
  }
  return a.indexOf(b) !== -1;
}

export default helper(includes);
