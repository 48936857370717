import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';
export default Component.extend({
  actions: {
    addToCart() {
      this.options.addToCartCallback();
      tryInvoke(this, 'close', ...arguments);
    },
    close() {
      tryInvoke(this, 'close', ...arguments);
    }
  }
});
