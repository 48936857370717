import { isEmpty, isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default Helper.extend({
  store: service(),
  compute(params) {
    if (isEmpty(params)) {
      return false;
    }
    const tldName = params[0];
    const tld = this.get('store').peekAll('tld').findBy('name', tldName);
    const item = tld && tld.get('activeOffer');
    return isPresent(item);
  }
});
