import Common from '../mixins/common';
import Component from '@ember/component';
import { computed } from '@ember/object';
import config from '../config/environment';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';

const SSL_PRICE_TMP = {
  'ign-positive-ssl': 399,
  'ign-positive-ssl-wc': 2499,
  'ign-positive-ssl-ev': 4099,
  'ign-positive-ssl-multi': 1499
};

export default Component.extend(Common, {
  cms: service(),
  modal: service(),
  router: service(),
  store: service(),
  enableTips: config.LOCALE === 'cs',
  staticPrices: config.LOCALE === 'cs',
  prices: SSL_PRICE_TMP,
  didInsertElement() {
    this.set('sslCertTypes', this.store.peekAll('sslCertType'));
  },
  sortedSslCertTypes: computed('sslCertTypes.[]', function () {
    const typesOrder = this.cms?.pages?.produkty?.blocks.mapBy('slug');
    return this.sslCertTypes?.toArray()?.sort((itemA, itemB) => {
      const itemAPos = typesOrder.indexOf(dasherize(itemA.pack));
      const itemBPos = typesOrder.indexOf(dasherize(itemB.pack));
      return itemAPos - itemBPos;
    });
  }),
  actions: {
    orderSsl(type) {
      this.modal.show(
        'forms/new-ssl-cert-form',
        this.sslCertTypes.findBy('id', type),
        {
          modalClasses: 'new-ssl-modal',
          onClose: selectedType => {
            if (selectedType) {
              this.router.transitionTo('cart', {
                queryParams: {
                  page: this.get('PAGE.CART'),
                  addressbook: 'invoice'
                }
              });
            }
          }
        }
      );
    }
  }
});
