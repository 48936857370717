import Service, { inject as service } from '@ember/service';

import { LEGAL_FORMS } from '../models/user';
import addDefaultParams from '../utils/default-wyapi-params';
import ajax from '../utils/ajax';
import config from '../config/environment';

const customer = user => {
  if (user.idCustomer) {
    return {
      IDcustomer: user.idCustomer
    };
  }
  const [first_name, last_name] = (user.contactName || '').split(' ');
  return {
    IDcustomer: null,
    invoice_data: {
      name: user.invoiceName,
      street: user.invoiceStreet,
      town: user.invoiceTown,
      postcode: user.invoicePostcode,
      state: user.invoiceState,
      province: '',
      legal_form: user.legalForm,
      orgid: user.invoiceCompanyid,
      vatid: '',
      taxid: '',
      statutory: '',
      post_invoice_type: ''
    },
    post_data: {
      name: user.postName,
      street: user.postStreet,
      town: user.postTown,
      postcode: user.post,
      state: user.postState,
      province: ''
    },
    contact_data: {
      title: '',
      first_name,
      last_name,
      email: user.invoiceEmail,
      phone: user.invoicePhone,
      language: user.invoiceLanguage
    }
  };
};

const billingProfile = (contact, user) => {
  if (contact.id && !['default', 'faked'].includes(contact.id)) {
    return {
      IDprofile: contact.id
    };
  }

  return {
    IDprofile: null,
    invoice_data: {
      customer_name: contact.companyName,
      name: contact.name,
      street: contact.customerPostStreet || contact.street,
      town: contact.customerPostTown || contact.city,
      postcode: contact.customerPostPostcode || contact.zip,
      state:
        contact.customerPostState || (contact.owner && contact.owner.state),
      province: '',
      legal_form: user.legalForm,
      orgid: contact.customerIco || contact.ic,
      vatid: contact.customerIcdph,
      taxid: contact.customerDic || contact.dic,
      statutory: '',
      post_invoice_type: ''
    }
  };
};

const createItemAttrs = item => {
  const out = item.attrs();
  const paramsExtra = out.params.findBy('name', 'extra') || {};
  const extra = out.extra;
  delete out.extra;

  const certAttrs = [
    'adminMail',
    'adminName',
    'adminPhone',
    'certCity',
    'certCountry',
    'certOrganization',
    'certOrganizationUnit',
    'certState',
    'certStreet',
    'certZip',
    'commonName',
    'confirmationMail',
    'csr',
    'ic',
    'renew',
    'sans'
  ];

  switch (item.type) {
    case 'sslCert':
      out.params = [];
      certAttrs.forEach(attr => {
        const attrName = attr === 'certState' ? 'certProvince' : attr; //API chce province
        out.params.push({
          name: `OP_${attrName}`,
          value:
            typeof extra[attr] !== 'string'
              ? JSON.stringify(extra[attr])
              : extra[attr]
        });
      });
      return out;

    case 'hosting':
      out.params = [
        {
          name: 'OP_CMS',
          value: paramsExtra.value && paramsExtra.value.template
        },
        {
          name: 'DOMAINS',
          value: paramsExtra.value && paramsExtra.value.domains
        }
      ];
      return out;

    case 'yola':
      out.params = [
        {
          name: 'OP_yola_import',
          value: paramsExtra.value && paramsExtra.value.yolaImport
        }
      ];
      return out;

    case 'email':
      out.params = [];
      out.params.push({
        name: 'OP_CONTACT_EMAIL',
        value: extra.mailHostingEmail
      });

      out.params.push({
        name: 'OP_FIRST_MAIL',
        value: extra.mailHostingName || null
      });

      out.params.push({
        name: 'OP_CONTACT_PHONE',
        value: extra.mailHostingPhone
      });

      out.params.push({
        name: 'DISK',
        value: extra.capacity || null
      });

      out.params.push({
        name: 'COUNT',
        value: extra.users || null
      });
      return out;

    default:
      delete out.params;
      return out;
  }
};

const orderItems = (items, nssetId) => {
  return items.map(item => {
    const itemAttributes = createItemAttrs(item);

    const itemJSON = {
      ...itemAttributes,
      server: '',
      refcode: '',
      import: '',
      note: '',
      multihosting_main_domain: '',
      regdata: null,
      geo_location: ''
    };

    itemJSON.dnsdata = {
      type: '1'
    };

    if (nssetId) {
      itemJSON.dnsdata.type = 'G';
      itemJSON.dnsdata.group_id = nssetId;
    }

    return itemJSON;
  });
};

const defaultRegdata = (contact, user) => {
  if (contact && contact.id && !['default', 'faked'].includes(contact.id)) {
    return {
      IDregistrant: contact.id
    };
  }

  if (contact.id === 'faked') {
    const [first_name, last_name] = (contact.name || '').split(' ');

    return {
      IDregistrant: null,
      owner: {
        name: contact.company ? contact.companyName : contact.name,
        street: contact.street,
        town: contact.city,
        postcode: contact.zip,
        state: contact.get('owner.state'),
        province: '',
        legal_form: contact.company ? LEGAL_FORMS.AS : LEGAL_FORMS.FO,
        orgid: contact.ic,
        vatid: '',
        taxid: contact.dic,
        statutory: '',
        post_invoice_type: ''
      },
      contact: {
        title: '',
        first_name,
        last_name,
        email: contact.email,
        phone: `${contact.dialingCode}.${contact.phoneWithoutDial}`,
        language: config.LOCALE
      }
    };
  }

  const [first_name, last_name] = (user.contactName || '').split(' ');

  return {
    IDregistrant: null,
    owner: {
      name: user.invoiceName,
      street: user.invoiceStreet,
      town: user.invoiceTown,
      postcode: user.invoicePostcode,
      state: user.invoiceState,
      province: '',
      legal_form: user.legalForm,
      orgid: user.invoiceCompanyid,
      vatid: '',
      taxid: user.invoiceTax,
      statutory: '',
      post_invoice_type: ''
    },
    contact: {
      title: '',
      first_name,
      last_name,
      email: user.contactEmail,
      phone: user.contactPhone,
      language: config.LOCALE
    }
    // nic_id: '',
    // localData: {}
  };
  // }
};

export default Service.extend({
  serverCart: service(),
  store: service(),
  currentUser: service(),
  session: service(),

  createOrder(items) {
    const user = this.get('currentUser.user');
    const checkoutModel = this.store
      .peekAll('checkout')
      .findBy('key', 'current');
    const invoiceContact = checkoutModel.get('invoiceContact');
    const ownerContact = checkoutModel.get('ownerContact');
    const nssetId = checkoutModel.get('nssetId');

    const order = {
      customer: customer(user),
      items: orderItems(items, nssetId),
      coupon: checkoutModel.get('coupon')
      // dealer: '',
      // customer_note: '',
      // internal_note: '',
    };

    if (ownerContact && ownerContact.id === 'faked') {
      order.billing_profile = billingProfile(ownerContact, user);
    }

    if (invoiceContact && invoiceContact.id !== 'default') {
      order.billing_profile = billingProfile(invoiceContact, user);
    }

    // pro registraci by melo byt nastveno - registrace ma povinne regdata
    if (ownerContact) {
      order.default_regdata = defaultRegdata(ownerContact, user);
    }

    if (checkoutModel) {
      const paymentType = checkoutModel.get('paymentMethodObject.paymentType');
      if (paymentType) {
        order['payment_type'] = paymentType;
      }
    }

    const basket = this.store.peekAll('basket').filterBy('key', 'current');

    const orderModel = this.store.createRecord('wy-order', {
      basketHash: basket && basket.get('hash'),
      rurl: `${window.location.origin}/payment-check`,
      currency: this.get('currentUser.currency'),
      order,
      paylinks: false,
      test: false
    });

    return orderModel.save().then(order => {
      document.cookie = `order_items=${JSON.stringify(
        order.order.items
      )}; path=/`;
      document.cookie = `checkout_model=${JSON.stringify(
        checkoutModel
      )}; path=/`;
      checkoutModel.set('invoice', order.get('invoice'));
      return checkoutModel.save().then(() => {
        this.serverCart.delete();
        return order;
      });
    });
  },
  payOrder(invoiceModel, paymentMethodObject) {
    const paymentCode = paymentMethodObject.code;
    const { token } = this.get('session.data.authenticated');
    if (paymentCode === 'credit') {
      const url = new URL(`${config.API_HOST}/invoices/${invoiceModel.id}`);
      return ajax(addDefaultParams(url.toString()), {
        body: JSON.stringify({ use_credit: 'true' }),
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: 'PUT'
      });
    } else {
      const url = new URL(`${config.API_HOST}/order/paylink`);
      url.searchParams.append('vs', invoiceModel.vs);
      url.searchParams.append('type', paymentMethodObject.paymentType);
      url.searchParams.append(
        'rurl',
        `${window.location.origin}/payment-check`
      );
      return ajax(addDefaultParams(url.toString()));
    }
  }
});
