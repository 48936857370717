import DS from 'ember-data';
import { computed } from '@ember/object';
import config from 'domena-mobile/config/environment';

export default DS.Model.extend({
  dueDate: DS.attr('date'),
  number: DS.attr('string'),
  paid: DS.attr('boolean'),
  remaining: DS.attr('string'),
  taxDate: DS.attr('date'),
  total: DS.attr('string'),
  vs: DS.attr('number'),
  pdf: computed('id', function () {
    return `${config.API_HOST}/api/v2/tax_invoices/${this.get('id')}.pdf`;
  })
});
