import { helper } from '@ember/component/helper';

export function mailHostingPlan([type] /* , hash*/) {
  switch (type) {
    case 'flexi_mail':
      return 'FlexiMail';
    case 'office365_essentials':
      return 'Microsoft 365 Business Basic';
    case 'office365_premium':
      return 'Microsoft 365 Business Standard';
    case 'exchange_online':
      return 'Exchange Online';
  }
}

export default helper(mailHostingPlan);
