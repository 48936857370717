import { hash } from 'rsvp';
import Route from '@ember/routing/route';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/wordpress'
      }
    }
  ]),
  model() {
    return hash({
      hostingItems: this.store.peekAll('wordpress-hosting-plan')
    });
  }
});
