import CartItem from './cart-item';
import DS from 'ember-data';
import { SERVICE_TYPES } from '../utils/service-config';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

const ENABLED_AVAILABLE_SERVICES = [
  SERVICE_TYPES.SAFETY_GUARD,
  // SERVICE_TYPES.YOLA,
  SERVICE_TYPES.HOSTING,
  SERVICE_TYPES.EMAIL
];

export default CartItem.extend({
  currentUser: service(),
  store: service(),
  key: DS.attr('string'),
  quantity: DS.attr('number', { defaultValue: 1 }),
  tld: DS.attr('string'),
  years: alias('quantity'),
  domainName: alias('name'),
  period: computed('quantity', function () {
    return this.quantity * 12;
  }),
  // mailPlanHeader: ''
  tldObject: computed('tld', function () {
    return this.get('store').peekRecord('tld', this.get('tld'));
  }),
  _priceObject: computed('currentUser.loadedData.prices', 'tld', function () {
    // wait for prices
    if (this.get('currentUser.loadedData.prices')) {
      return this.store.peekRecord('price', this.get('tld'));
    }
  }),
  defaultQuantity: computed('_priceObject', function () {
    const regObject = this.get('_priceObject.registration');
    if (!regObject) {
      return;
    }
    return Math.min(...Object.keys(regObject).map(item => parseInt(item, 10)));
  }),
  itemPrice: computed(
    '_priceObject.registration',
    'defaultQuantity',
    'years',
    'basketPrice',
    function () {
      if (this.get('basketPrice') === 0)
        return parseFloat(this.get('basketPrice'));

      return parseFloat(
        this.get('basketPrice') ||
          this.get(
            `_priceObject.registration.${
              this.get('years') || this.get('defaultQuantity')
            }`
          )
      );
    }
  ),
  setDefaults() {
    if (!this.get('years')) {
      this.set('years', this.get('defaultQuantity'));
    }
  },
  availableServices: computed('_priceObject', function () {
    const services = [];

    if (ENABLED_AVAILABLE_SERVICES.includes(SERVICE_TYPES.SAFETY_GUARD)) {
      const formSettings = this.store.peekRecord('formSettings', 'current');
      const domainInsurance = formSettings
        .get('packs')
        .findBy('pack', 'DOMAIN_INSURANCE');

      if (domainInsurance) {
        const supportedTlds = domainInsurance.supported_tlds;

        if (supportedTlds.includes(this.tld)) {
          services.pushObject({
            type: SERVICE_TYPES.SAFETY_GUARD,
            itemPrice:
              domainInsurance.customer_price *
              domainInsurance.supported_periods[0]
          });
        }
      }
    }

    const store = this.store;

    if (ENABLED_AVAILABLE_SERVICES.includes(SERVICE_TYPES.YOLA)) {
      const cheapesWebEditorPlan = store
        .peekAll('yolaPlan')
        .sortBy('price', 'asc')
        .get('firstObject');

      services.push({
        type: SERVICE_TYPES.YOLA,
        itemPrice: cheapesWebEditorPlan.price
      });
    }

    if (ENABLED_AVAILABLE_SERVICES.includes(SERVICE_TYPES.EMAIL)) {
      const cheapesMailPlan = store
        .peekAll('mailHostingPlan')
        .sortBy('price', 'asc')
        .get('firstObject');

      services.push({
        type: SERVICE_TYPES.EMAIL,
        itemPrice: cheapesMailPlan.price
      });
    }

    if (ENABLED_AVAILABLE_SERVICES.includes(SERVICE_TYPES.HOSTING)) {
      const cheapesWebPlan = store
        .peekAll('webHostingPlan')
        .sortBy('price', 'asc')
        .get('firstObject');

      services.push({
        type: SERVICE_TYPES.HOSTING,
        itemPrice: cheapesWebPlan.price
      });
    }
    return services;
  }),
  // TODO-FIX fallback properties
  price: alias('_priceObject'),
  totalPrice: alias('itemPrice'),
  tldName: alias('tld'),
  displayName: alias('name'),
  fullName: computed('domainName', 'tldName', function () {
    return `${this.get('domainName')}.${this.get('tldName')}`;
  }),
  attrs() {
    return {
      ...this._super(),
      name: `${this.name}.${this.tld}`,
      period: this.period,
      pack: 'Registration',
      type: 'registration'
    };
  }
});
