import Config from '../config/environment';
import { htmlSafe } from '@ember/string';
import { isEmpty } from '@ember/utils';

export default function moneyString(params, hash) {
  const config = Config.money;
  const currencyBefore = hash.currencyBefore || config.currencyBefore;
  const currency = hash.currency || config.currency || 'Kč';
  const decimalDelimiter = hash.decimal || config.decimal || ',';
  let decimalPlaces = 2;

  if (typeof config.decimalPlaces !== 'undefined') {
    decimalPlaces = config.decimalPlaces;
  }

  if (typeof hash.decimalPlaces !== 'undefined') {
    decimalPlaces = hash.decimalPlaces;
  }
  const thousandsDelimiter = hash.thousands || config.thousands || '&nbsp;';
  if (isEmpty(params[0])) {
    return params[0] || `0 ${currency}`;
  }

  if (params[0].toString().indexOf('<') !== -1) {
    return htmlSafe(params[0]);
  }

  const price =
    Math.round(parseFloat(params[0]) * 10 ** decimalPlaces) /
    10 ** decimalPlaces;

  if (isNaN(price)) {
    return params[0];
  }

  const [wholeNumber, decimals] = price.toString().split('.');
  const number = [
    wholeNumber.replace(/\d(?=(\d{3})+$)/g, `$&${thousandsDelimiter}`)
  ];
  if (decimals) {
    number.push(decimals);
  }
  return htmlSafe(
    currencyBefore
      ? `${currency}${number.join(decimalDelimiter)}`
      : `${number.join(decimalDelimiter)} ${currency}`
  );
}
