import DS from 'ember-data';
import Order from './order';
import { alias } from '@ember/object/computed';

export default Order.extend({
  invoice: DS.belongsTo('invoice'),
  // invoice: DS.belongsTo('invoice', {inverse: 'orders', polymorphic: true}), // FIXME
  domainName: DS.attr('string'),
  displayName: alias('domainName')
});
