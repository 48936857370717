import Component from '@ember/component';

export default Component.extend({
  classNames: ['loader flex'],
  classNameBindings: [
    'onLeft:loader__left',
    'center:loader__center',
    'onRight:flex-justify-right',
    'medium:loader__md',
    'large:loader__lg',
    'fixed',
    'absolute',
    'alternative:loader__alt'
  ],
  onLeft: false,
  center: true,
  onRight: false,
  medium: false,
  large: false,
  fixed: false,
  absolute: false,
  alternative: false,

  didInsertElemenet() {},

  willDestroyElement() {}
});
