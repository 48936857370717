import Component from '@ember/component';
import { assign } from '@ember/polyfills';
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  intl: service(),
  store: service(),
  currentUser: service(),
  classNames: ['contact-select-full'],
  classNameBindings: ['selectedTab'],
  newContactModel: null,
  showTabs: 'select,new,import',
  selectedTab: 'select',
  expandAll: true,
  selectedContact: null,
  loaded: false,
  tabs: computed('showTabs', function () {
    const tabs = (this.showTabs || '').split(',');
    if (!tabs.includes(this.get('selectedTab'))) {
      this.set('selectedTab', tabs[0]);
    }
    return tabs;
  }),
  btnImportText: computed('options.btnImportText', function () {
    return this.get('options.btnImportText') || this.intl.t('actions.import');
  }),
  btnNewText: computed('options.btnNewText', function () {
    return (
      this.get('options.btnNewText') || this.intl.t('actions.save_and_select')
    );
  }),
  btnSelectText: computed('options.btnSelectText', function () {
    return this.get('options.btnSelectText') || this.intl.t('actions.confirm');
  }),
  init() {
    this._super(...arguments);

    // defaults
    this.set('options', assign(this.options, { selectExpandAll: true }));

    const showTabs = this.get('options.showTabs');
    let selectedTab = this.get('options.selectedTab');
    if (showTabs) {
      this.setProperties({ showTabs });
      const tabs = showTabs.split(',');
      this.set('selectedTab', selectedTab || tabs[0]);
    }
    const newContactModel = this.get('options.editContact');
    if (newContactModel) {
      this.setProperties({ newContactModel });
    }
  },
  didInsertElement() {
    this._super(...arguments);

    if (!this.get('newContactModel')) {
      this.set('newContactModel', this.store.createRecord('contact'));
    }
    if (this.get('options.invoiceContact')) {
      this.set('newContactModel.invoiceContact', true);
    }

    // render modal and then items
    later(() => {
      this.set('loaded', true);
    }, 0);
  },
  willDestroyElement() {
    if (this.get('newContactModel.isNew')) {
      this.get('newContactModel').rollbackAttributes();
    }
  },
  _confirm(selectedContact) {
    tryInvoke(this.get('options'), 'onSelect', [selectedContact]);
    tryInvoke(this, 'close');
  },
  actions: {
    confirm(selectedContact) {
      this._confirm(selectedContact || this.get('selectedContact'));
    },
    contactImport(contact) {
      this._confirm(contact);
    },
    save() {
      const model = this.get('newContactModel');
      const modelAttributes = Array.from(model.constructor.attributes.keys());
      const cloned = this.get('newContactModel').serialize({ includeId: true });

      let contact;
      if (this.store.hasRecordForId(model.constructor.modelName, 'faked')) {
        contact = this.store.peekRecord(model.constructor.modelName, 'faked');
        contact.setProperties(model.getProperties(modelAttributes));
      } else {
        const normalizedContact = this.store.normalize(
          model.constructor.modelName,
          {
            attributes: cloned,
            type: model.constructor.modelName,
            id: 'faked'
          }
        );
        normalizedContact.data.id = 'faked';
        contact = this.store.push(normalizedContact);
      }
      this._confirm(contact);
    }
  }
});
