import Component from '@ember/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/string';

export default Component.extend({
  attributeBindings: ['productId:id', 'itemWidth:style'],
  classNames: ['product-card-item'],
  productId: computed('productItem.id', function () {
    return this.productItem.id.toLowerCase();
  }),
  itemWidth: computed('productItemCount', function () {
    return htmlSafe(`width: ${(100 / this.productItemCount).toFixed(2)}%`);
  }),
  productItemCount: null
});
