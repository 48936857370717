import Component from '@ember/component';
import Logger from '../../utils/logger';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';
import { trySet } from '@ember/object';

export default Component.extend({
  store: service(),
  session: service(),
  tagName: 'form',
  classNames: ['text-left', 'login-form'],
  attributeBindings: ['novalidate'],
  novalidate: true,
  didInsertElement() {
    this.set('model', { login: '', password: '', otp: null, sms: null });
  },
  _useProvider(credentials, mojeId) {
    if (mojeId) {
      return this.get('session').authenticate('authenticator:torii', 'mojeid');
    }

    return this.get('session').authenticate(
      'authenticator:wy-api',
      credentials
    );
  },
  authenticate(mojeId = false) {
    this.set('loginLoading', true);
    this.$('.login-errors').removeClass('active');

    let credentials = {
      identification: this.get('model.login'),
      password: this.get('model.password'),
      otp: this.get('model.otp'),
      sms: this.get('model.sms')
    };

    return this._useProvider(credentials, mojeId)
      .then(
        () => {
          tryInvoke(this, 'authenticationSuccess');
        },
        dataError => {
          const { body } = dataError;
          const { error } = JSON.parse(body || '{}');
          const { code } = error || {};
          if (code === 24) {
            this.set('otp', 'otp');
          } else if (code === 1034) {
            this.set('otp', 'sms');
          } else {
            this.$('.login-errors').addClass('active');
          }

          tryInvoke(this, 'authenticationFailed');
          Logger.error(dataError);
        }
      )
      .finally(() => {
        trySet(this, 'loginLoading', false);
      });
  },
  submit() {
    this.authenticate(false);
    return false;
  },
  actions: {
    authenticate(mojeId) {
      this.authenticate(mojeId);
    },
    close() {
      tryInvoke(this, 'close');
    }
  }
});
