import Component from '@ember/component';
import config from '../config/environment';

export default Component.extend({
  classNames: ['top-page-info-block'],
  classNameBindings: ['disabled:hidden'],
  disabled: false,
  isLocalCs: config.LOCALE === 'cs',

  didInsertElement() {
    const isElDisabled = JSON.parse(
      window.localStorage.getItem('isTopPageInfoBlockDisabled') || false
    );
    if (isElDisabled) {
      this.set('disabled', true);
    }
  },
  actions: {
    close() {
      this.set('disabled', true);
      window.localStorage.setItem('isTopPageInfoBlockDisabled', 'true');
    }
  }
});
