import { computed } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Model.extend({
  countryConverter: service(),
  enabled: not('hidden'),
  transferAuto: attr('boolean'),
  name: alias('id'),
  position: attr('number'),
  supportedContactCc: attr({ defaultValue: [] }),
  contactCountries: computed({
    get() {
      return this.get('supportedContactCc').map(countryCode =>
        this.countryConverter.alpha2ToId(countryCode)
      );
    },
    set(key, value) {
      this.get('supportedContactCc').pushObject(
        this.countryConverter.idToAlpha2(value)
      );
      return value;
    }
  }), // [],
  attrPerson: attr('string'),
  attrCompany: attr('string'),
  docPerson: attr('string'),
  docCompany: attr('string'),
  dnsChecker: attr('string'),
  confirmBy: attr('string'),
  emailValidation: attr('string'),
  length: attr(),
  suspension: attr(), // {days: .., months: ..}
  offers: attr(),
  adminContact: attr('string'),
  activeOffer: computed('offers.[]', function () {
    return this.get('offers.firstObject');
  }),
  httpsOnly: attr('boolean'),
  categories: null,
  init() {
    this._super(...arguments);
    this.set('categories', []);
  },
  // WY-API attrs
  flagsDomain: attr(),
  hidden: attr('number'),
  supportedMonths: attr(),
  regPrice: attr('number'),
  renewPrice: attr('number'),
  renewCustomerPrice: attr('number'),
  price: attr('number'),
  customerPrice: attr('number'),
  flagsDomainArray: computed('flagsDomain', function () {
    return (this.flagsDomain || '').split(',');
  }),
  isTransferPayed: computed('flagsDomainArray.[]', function () {
    return this.flagsDomainArray.includes('TRANSFER_RENEW');
  })
});
