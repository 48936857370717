import Component from '@ember/component';
import { computed } from '@ember/object';
import config from '../config/environment';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

const DEFAULT_DIALING_CODE = config.DEFAULT_DIALING_CODE || '420';

export default Component.extend({
  store: service(),
  tagName: 'select',
  selected: null,
  defaultDialingCode: DEFAULT_DIALING_CODE,
  countries: null,
  dialingCodes: computed('countries.[]', function () {
    return this.get('countries')
      .sortBy('callingCode')
      .mapBy('callingCode')
      .without('')
      .uniq();
  }),
  init() {
    this._super(...arguments);

    this.set('countries', this.get('store').peekAll('country'));

    scheduleOnce('afterRender', () => {
      if (this.get('selected')) {
        if (
          this.get('selected').toString() !== this.get('defaultDialingCode')
        ) {
          this.$('option').removeProp('selected');
          this.$(`option[value="${this.get('selected')}"]`).prop(
            'selected',
            true
          );
        }
      }
    });
  },
  change() {
    this.set('selected', this.$().val());
    tryInvoke(this, 'onChange', [this.$().val()]);
  }
});
