import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import keyIndexes from 'domena-mobile/utils/key-indexes';
import config from '../config/environment';

export default Controller.extend({
  cms: service(),
  badgeItems: computed(function () {
    return keyIndexes(
      this.get('cms._translationData.about-us'),
      'about-us-more'
    );
  }),
  clientsLogos: alias(
    'cms._translationData.about-us.about-us-clients-0.images'
  ),
  localIsCs: config.LOCALE === 'cs',
  weHelpLogos: alias('cms._translationData.about-us.about-us-csr-0.images'),
  weImproveLogos: alias('cms._translationData.about-us.about-us-grow-0.images')
});
