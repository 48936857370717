import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  tagName: 'label',
  classNames: ['inline-label', 'radio-container'],
  classNameBindings: ['selected'],
  click() {
    tryInvoke(this, 'onChange');
  }
});
