import WySerializer from './wy-api';

export default WySerializer.extend({
  primaryKey: 'dns_group',
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'findRecord') {
      payload.data = payload.data.nsg;
      return this._super(store, primaryModelClass, payload, id, requestType);
    } else {
      return this._super(...arguments);
    }
  }
});
