import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  routing: service(),
  classNames: ['modal-component'],
  classNameBindings: ['isOpen', 'isBig', 'isDanger'],
  name: alias('modal.name'),
  model: alias('modal.model'),
  options: alias('modal.options'),
  isBig: alias('options.isBig'),
  isDanger: alias('options.isDanger'),
  isOpen: alias('modal.isOpen'),
  header: alias('options.header'),
  closeOnBackropClick: true,
  click(event) {
    if (this.closeOnBackropClick && event.target.id === this.elementId) {
      tryInvoke(this, 'close', [this.modal, undefined, { isSubmitted: false }]);
    }
  },
  actions: {
    close() {
      tryInvoke(this, 'close', [this.modal, ...arguments]);
    },
    transitionTo() {
      this.get('routing.transitionTo')(...arguments);
    },
    replaceWith() {
      this.get('routing.replaceWith')(...arguments);
    }
  }
});
