import Common from '../mixins/common';
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import config from '../config/environment';
import { inject as service } from '@ember/service';

export default Component.extend(Common, {
  config,
  store: service(),
  session: service(),
  currentUser: service(),
  navMenu: service(),
  modal: service(),
  router: service(),
  intl: service(),
  isOpen: alias('navMenu.isOpen'),
  enabledItems: config.LOCALE === 'cs',
  menuClose() {
    this.navMenu.close();
  },
  actions: {
    menuClose() {
      this.menuClose();
    },
    logout() {
      this.menuClose();
      this.session.invalidate();
    },
    showLoginModal() {
      if (!this.get('session.isAuthenticated')) {
        this.menuClose();
        this.modal.show('login-modal', null, {
          afterLogin: 'admin',
          header: this.intl.t('login.title')
        });
      }
    }
  }
});
