import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  intl: service(),
  navDots: service(),
  currentUser: service(),
  classNames: 'nav-dots',
  classNameBindings: ['separate'],
  active: 1,
  visited: alias('navDots.visited'),
  separate: false,
  titles: computed('intl', function () {
    return ['basket', 'contact_info', 'payment', 'summary'].map(item =>
      this.get('intl').t(`nav_dots.${item}`)
    );
  }),
  pages: computed('titles', function () {
    return this.get('titles.length');
  }),
  actions: {
    // TODO - seems not to be working at all CHECK
    toPage(index) {
      let page = index > 0 ? index + 2 : index + 1;
      let visited =
        this.get('visited') > 1 ? this.get('visited') : this.get('visited') + 1;

      if (page < this.get('active') + 1 || page <= visited) {
        tryInvoke(this, 'action', [page]);
      }
    }
  }
});
