import DS from 'ember-data';
import NonRestActions from '../mixins/non-rest-action';

export default DS.Model.extend(NonRestActions, {
  iv: DS.attr('string'),
  loginData: DS.attr('string'),
  url: DS.attr('string'),
  original_admin(path) {
    return this.nonRestAction('original_admin', 'GET', { path }).then(data => {
      return this.store.push(data);
    });
  }
});
