import { alias } from '@ember/object/computed';
import DS from 'ember-data';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/string';

export default DS.Model.extend({
  deployed: DS.attr('boolean'),
  changed: DS.attr('boolean'),
  deployedAt: DS.attr('date'),
  updatedAt: DS.attr('date'),
  thumb: DS.attr('string'),
  name: alias('id'),
  backgroundImage: computed('thumb', function () {
    const siteUrl = this.get('thumb');
    if (siteUrl) {
      return htmlSafe(`background-image: url(${siteUrl});`);
    }
  })
});
