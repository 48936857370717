import { computed, observer } from '@ember/object';

import CartItem from './cart-item';
import DS from 'ember-data';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default CartItem.extend({
  checkout: service(),
  intl: service(),
  quantity: DS.attr('number', { defaultValue: 12 }),
  users: DS.attr('number', { defaultValue: 1 }),
  hostingType: alias('extra.mailHostingType'),
  mailHostingObject: computed(
    'extra.mailHostingType',
    'selectedCapacity',
    'selectedPeriod',
    'availableCapacities',
    function () {
      return this.store.peekAll('mailHostingPlan').find(mailHosting => {
        return (
          mailHosting.get('period') === this.get('selectedPeriod') &&
          (!mailHosting.get('capacity') ||
            mailHosting.get('capacity') ===
              (this.get('selectedCapacity') ||
                this.get('availableCapacities.firstObject'))) &&
          mailHosting.get('domainServiceType') ===
            this.get('extra.mailHostingType')
        );
      });
    }
  ),
  selectedPeriod: alias('quantity'),
  selectedCapacity: DS.attr('string'),
  selectedUsers: DS.attr('number'),
  itemPrice: computed(
    'mailHostingObject.price',
    'quantity',
    'basketPrice',
    function () {
      if (this.get('basketPrice') === 0)
        return parseFloat(this.get('basketPrice'));

      return parseFloat(
        this.get('basketPrice') ||
          this.get('mailHostingObject.price') *
            parseInt(this.get('quantity'), 10)
      );
    }
  ),
  displayName: computed('extra.mailHostingName', 'tldName', function () {
    return `${this.get('extra.mailHostingName')}.${this.get('tldName')}`;
  }),
  domainName: alias('extra.mailHostingName'),
  tldName: alias('extra.tldName'),
  userName: alias('extra.emailName'),
  fullName: computed('extra.mailHostingName', 'userName', function () {
    const domainName = this.get('extra.mailHostingName');
    const { userName, tldName } = this.getProperties('userName', 'tldName');
    return userName
      ? `${userName}@${domainName}.${tldName}`
      : `${domainName}.${tldName}`;
  }),
  availableCapacities: computed('hostingType', 'selectedPeriod', function () {
    return this.store
      .peekAll('mailHostingPlan')
      .filterBy('domainServiceType', this.get('hostingType'))
      .filterBy('period', this.get('selectedPeriod'))
      .mapBy('capacity')
      .filter(Boolean)
      .uniq()
      .sort((itemA, itemB) => {
        if (!itemA || !itemB) {
          return 0;
        }
        return itemA.localeCompare(itemB, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
  }),
  availablePeriods: computed('hostingType', function () {
    return this.store
      .peekAll('mailHostingPlan')
      .filterBy('domainServiceType', this.get('hostingType'))
      .mapBy('period')
      .uniq();
  }),
  saveObserver: observer(
    'quantity',
    'selectedPeriod',
    'selectedCapacity',
    function () {
      this._super(...arguments);
    }
  ),
  selectedVariant: computed(
    'mailHostingObject.pricePer',
    'selectedCapacity',
    'quantity',
    function () {
      if (this.get('mailHostingObject.pricePer') === 'user_period') {
        const userCount = this.get('selectedUsers');
        return `${userCount} ${this.get('intl')
          .t('users', { count: userCount })
          .toString()}`;
      } else {
        if (this.get('selectedCapacity')) {
          return `${this.get('selectedCapacity')} GB`;
        } else {
          return null;
        }
      }
    }
  ),
  attrs() {
    const attributes = {
      ...this._super(this.hostingType),
      extra: this.extra,
      name: this.name,
      pack: this.hostingType
    };

    const capacity =
      this.get('selectedCapacity') ||
      this.get('availableCapacities.firstObject');

    const users = this.get('selectedUsers') || 1;

    attributes.extra = {
      ...attributes.extra,
      mailHostingEmail: this.get('checkout.model.mailHostingEmail'),
      mailHostingName: this.get('checkout.model.mailHostingName'),
      mailHostingPhone: this.get('checkout.model.mailHostingPhone'),
      capacity,
      users
    };

    attributes.params.push({
      name: 'OP_CONTACT_EMAIL',
      value: attributes.extra.mailHostingEmail
    });

    attributes.params.push({
      name: 'OP_FIRST_MAIL',
      value: attributes.extra.mailHostingName || null
    });

    attributes.params.push({
      name: 'DISK',
      value: attributes.extra.capacity || null
    });

    attributes.params.push({
      name: 'COUNT',
      value: attributes.extra.users || null
    });

    return attributes;
  }
});
