import Component from '@ember/component';
import config from '../config/environment';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

const DEFAULT_DIALING_CODE = `+${config.DEFAULT_DIALING_CODE}` || '+420';

export default Component.extend({
  store: service(),
  classNames: ['email-service-complete'],
  isOpen: false,
  email: '',
  dialingCode: DEFAULT_DIALING_CODE,
  phone: '',
  name: '',
  mailHostingOrder: null,
  showErrors: false,
  openObserver: observer('isOpen', function () {
    if (this.get('isOpen')) {
      this.get('mailHostingOrder').setProperties({
        adminEmail: this.get('email'),
        dialingCode: this.get('dialingCode'),
        phone: this.get('phone'),
        userName: this.get('name'),
        isNameNeeded: this.get('isNameNeeded.length') > 0
      });
      this.set('showErrors', false);
    }
  }),
  didInsertElement() {
    this.set(
      'mailHostingOrder',
      this.get('store').createRecord('mail-hosting-order')
    );
  },
  willDestroyElement() {
    this.get('mailHostingOrder').rollbackAttributes();
  },
  submit() {
    if (this.get('mailHostingOrder.validations.isValid')) {
      this.setProperties({
        email: this.get('mailHostingOrder.adminEmail'),
        dialingCode: this.get('mailHostingOrder.dialingCode'),
        phone: this.get('mailHostingOrder.phone'),
        name: this.get('mailHostingOrder.userName')
      });
      this.send('close');
    } else {
      this.set('showErrors', true);
    }
    return false;
  },
  actions: {
    close() {
      this.set('isOpen', false);
    }
  }
});
