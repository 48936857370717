import { all, hash } from 'rsvp';

import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import cookiesParser from '../utils/cookies-parser';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/thanks'
    }
  }
];

export default Route.extend(AuthenticatedRouteMixin, {
  fastboot: service(),
  session: service(),
  gtmEvents: service(),
  headTags: HEAD_TAGS,
  cms: service(),
  cart: service(),
  tips: service(),
  checkout: service(),
  currentUser: service(),
  queryParams: {
    invoice_id: {},
    order_ids: {}
  },
  model(params) {
    if (this.get('fastboot.isFastBoot')) {
      return;
    }
    return hash({
      invoice: params.invoice_id
        ? this.store.findRecord('invoice', params.invoice_id, { reload: true })
        : this.store.createRecord('invoice'),
      domainChecks: this.store.peekAll('domain-check'),
      orders: params.order_ids
        ? all(
            params.order_ids.split(',').map(id => this.store.find('order', id))
          ).catch(() => [])
        : []
    });
  },
  afterModel(model) {
    this.get('currentUser')._loadUserCredit();
    this.get('tips').resetTip();
    this.get('cart')
      .clear()
      .then(() => {
        this.get('checkout').reset();
        return this.get('checkout').clear();
      });

    if (!model) {
      return;
    }

    const invoice = model.invoice;
    const cookies = cookiesParser();
    const { checkout_model, order_items } = cookies || {};

    if (invoice) {
      if (invoice.get('orders.length') === 0) {
        invoice.set('orders', model.orders);
      }

      // ecommerce
      scheduleOnce('afterRender', () => {
        this.gtmEvents.logEcommerce(invoice, order_items, checkout_model);
        document.cookie = 'order_items=; path=/';
      });

      invoice.get('domainOrders').forEach(domain => {
        let mailHostingOrder = invoice
          .get('mailHostingOrders')
          .findBy('domainName', domain.get('domainName'));

        if (mailHostingOrder) {
          mailHostingOrder.set('hidden', true);
          let prop = mailHostingOrder.getProperties(
            'domainServiceType',
            'users'
          );
          domain.setProperties({
            mailHostingType: prop.domainServiceType,
            mailHostingUsers: prop.users,
            mailHostingOrder
          });
        }
      });
    }
  }
});
