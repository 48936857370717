import { computed } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';

export default Model.extend({
  registration: attr(),
  renewal: attr(),
  tld: attr('string'),
  transfer: attr(),
  safetyGuard: attr(),
  minRegistrationYears: computed('registration', function () {
    // get first item in hash
    let prices = this.get('registration');
    let min = Infinity;

    for (let key in prices) {
      if (key < min) {
        min = key;
      }
    }
    return min;
  }),
  minRenewalYears: computed('renewal', function () {
    // get first item in hash
    let prices = this.get('renewal');
    let min = Infinity;

    for (let key in prices) {
      if (key < min) {
        min = key;
      }
    }
    return min;
  }),
  minTransferYears: computed('transfer', function () {
    // get first item in hash
    let prices = this.get('transfer');
    let min = Infinity;

    for (let key in prices) {
      if (key < min) {
        min = key;
      }
    }
    return min;
  }),
  defaultPrice: computed('minRegistrationYears', function () {
    let years = parseInt(this.get('minRegistrationYears'), 10);
    return this.get(`registration.${years}`);
  }),
  defaultRenewalPrice: computed('minRenewalYears', function () {
    let years = parseInt(this.get('minRenewalYears'), 10);
    return this.get(`renewal.${years}`);
  }),
  defaultTransferPrice: computed('minTransferYears', function () {
    let years = parseInt(this.get('minTransferYears'), 10);
    return this.get(`transfer.${years}`);
  }),
  perYear: computed('minRegistrationYears', 'defaultPrice', function () {
    let years = parseInt(this.get('minRegistrationYears'), 10);
    return this.get('defaultPrice') / years;
  })
});
