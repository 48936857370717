import * as Sentry from '@sentry/browser';

import EmberObject, { computed } from '@ember/object';
/* eslint-disable max-lines */
import { Promise as EmberPromise, all, resolve } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import { alias, filter } from '@ember/object/computed';

import Common from '../mixins/common';
import LocaleCurrencyMap from '../utils/locale-currency-map';
import Logger from '../utils/logger';
import config from 'domena-mobile/config/environment';
import fetchAjax from '../utils/ajax';
import { isPresent } from '@ember/utils';
import { later } from '@ember/runloop';
import { storageFor } from 'ember-local-storage';

export default Service.extend(Common, {
  currency: computed('session.data.authenticated.currency', function () {
    const { LOCALE } = config;

    return (
      this.get('session.data.authenticated.currency') ||
      LocaleCurrencyMap(LOCALE) ||
      'CZK'
    );
  }),
  currencySymbol: computed('currency', function () {
    return this.intl.t(`currency.${this.currency}`);
  }),
  intl: service(),
  store: service(),
  session: service(),
  fastboot: service(),
  cart: service(),
  checkout: service(),
  torii: service(),
  router: service(),
  domainList: service(),
  serverCart: service(),
  tldList: service(),
  storedFavouriteTlds: storageFor('favouriteTlds'),
  favouriteTlds: null,
  defaultFeaturedTlds: null,
  useDefaultTlds: computed('favouriteTlds', 'defaultFeaturedTlds', function () {
    return this._arraySame(
      this.get('favouriteTlds'),
      this.get('defaultFeaturedTlds')
    );
  }),
  showConfirmation: false,
  user: null,
  identities: null,
  loginState: null,
  contacts: null,
  invoiceContacts: null,
  ssoOrigin: null,
  loadedData: null,
  afterLoginAction: null,
  mojeId: alias('identities.identity.ipas.mojeIdUsername'),
  hasCartAdminItems: filter('cart.baseItems', function (item) {
    return item.get('source') === 'admin';
  }),
  init() {
    this._super(...arguments);
    this.setProperties({ favouriteTlds: [], defaultFeaturedTlds: [] });
    // this._setAccountRefreshTimer();
    this.set(
      'loadedData',
      EmberObject.create({
        credit: true,
        prices: false,
        contacts: false,
        invoiceContacts: false
      })
    );
  },
  setUser() {
    return this._userChange().then(() => {
      return all([
        this._loadProfile(),
        // this._ssoOriginLoad(),
        this.cart.load()
      ]).catch(error => {
        Logger.error(error);
        return this.session.invalidate();
      });
    });
  },
  // invoiceContacts: filterBy('contacts', 'invoiceContact'),
  defaultInvoice: computed('loadedData.invoiceContacts', function () {
    if (!this.get('invoiceContacts')) {
      return null;
    }
    return this.get('invoiceContacts.firstObject');
  }),
  name: computed('user.name', function () {
    let name = this.get('user.name');
    return name ? name : 'nenalezeno';
  }),
  companyName: computed('user.companyName', function () {
    let companyName = this.get('user.companyName');
    return companyName ? companyName : 'nenalezeno';
  }),
  email: computed('user.email', function () {
    return this.get('user.email');
  }),
  phone: computed('user.phone', function () {
    return this.get('user.phone');
  }),
  phoneWithoutDial: computed('user.phoneWithoutDial', function () {
    return this.get('user.phoneWithoutDial');
  }),
  deposit: alias('credit.deposit'),
  depositTaxIncluded: computed(
    'user.currentVatRate',
    'deposit',
    'loadedData.credit',
    function () {
      return this.get('deposit') * this.get('user.currentVatRate');
    }
  ),

  reload() {
    return resolve(); // TODO co s tim?
  },
  unload() {
    let currentUser = this.get('store').peekRecord('user', 'current');
    this.get('loadedData').setProperties({
      prices: false,
      contacts: false,
      invoiceContacts: false
    });
    if (currentUser) {
      currentUser.deleteRecord();
      currentUser.unloadRecord();
    }

    this.set('user', null);
    return this._userChange();
  },
  cleanData() {
    let modelsForUnload = [
      'account',
      'contact',
      'deposit-transaction',
      'domain-order',
      'domain',
      'domain-service',
      'cart',
      'invoice',
      'mail-hosting-order',
      'mail-redirect',
      'mail-renewal-order',
      'name-server',
      'nsset',
      'order',
      'payment',
      'profile',
      'renewal-order',
      'safety-guard-deactivation-order',
      'safety-guard-order',
      'site',
      'special-redirect',
      'ssl-cert',
      'ssl-cert-order',
      'sso-url',
      'tax-invoice',
      'transfer-order',
      'transfer-request',
      'user',
      'web-redirect',
      'zone-template',
      'zone'
    ];
    let modelsForDestroy = ['invoice-local'];
    let modelsForReset = [];

    modelsForUnload.forEach(model => {
      this.store.unloadAll(model);
    });

    const promisses = [];

    modelsForDestroy.forEach(modelName => {
      promisses.pushObject(
        this.store
          .findAll(modelName, { backgroundReload: false })
          .then(records =>
            records.compact().forEach(record => record.destroyRecord())
          )
      );
    });

    modelsForReset.forEach(modelName => {
      promisses.pushObject(
        this.store
          .findAll(modelName, {
            backgroundReload: false
          })
          .then(records =>
            records.compact().forEach(record => {
              if (typeof record.reset === 'function') {
                record.reset();
                return record.save();
              } else {
                Logger.error('Reset function is not defined.');
              }
            })
          )
      );
    });

    window.localStorage.removeItem('domenacz:pagination');

    promisses.pushObject(this.get('checkout').reset());
    promisses.pushObject(this.get('cart').clear());
    promisses.pushObject(this.get('domainList').reset());
    promisses.pushObject(this.get('serverCart').delete());
    promisses.pushObject(this.unload());
    return all(promisses);
  },
  saveFavourite(data) {
    const storage = this.get('storedFavouriteTlds');
    storage.clear();
    storage.pushObjects(data);
    const isCustomTld = isPresent(this.get('storedFavouriteTlds'));
    const userProfile = this.get('user.profile');
    if (this.get('session.isAuthenticated') && userProfile) {
      userProfile.set('favoriteTlds', storage.toArray());
      userProfile.get('content').save();
    }

    // pokud se maji dat vypnout custom tld, vzdy prepsat uzivatelskym vstupem
    // this._updateFavourite(()=>{}, this.get('storedFavouriteTlds').toArray());
    this._updateFavourite(
      () => {},
      isCustomTld ? storage.toArray() : this.get('defaultFeaturedTlds')
    );
  },
  loadFavourites() {
    const storageFavouriteTlds = this.get('storedFavouriteTlds').toArray();
    const userProfile = this.get('user.profile');
    const featuredTlds = this.get('store')
      .peekAll('tld')
      .filterBy('defaultFeatured')
      .mapBy('id');
    this.set('defaultFeaturedTlds', featuredTlds);
    return new EmberPromise(resolve => {
      // LOGGED IN
      if (this.get('session.isAuthenticated') && userProfile) {
        const userFavouriteTlds = userProfile.get('favoriteTlds') || [];
        // logged in user
        if (isPresent(userFavouriteTlds)) {
          // TODO clean up
          // user favourite tlds
          this.saveFavourite(userFavouriteTlds);
          this._updateFavourite(resolve, userFavouriteTlds);
        } else {
          // user dont have favourite
          this._updateFavourite(resolve, featuredTlds);
        }
      } else if (storageFavouriteTlds.get('length') > 0) {
        // guest user
        // there are any saved tlds -> use them
        this._updateFavourite(resolve, storageFavouriteTlds);
      } else {
        // use default favourite
        this._updateFavourite(resolve, featuredTlds);
      }
    });
  },
  _pushContact(modelName) {
    const user = this.get('user');
    const userData = user.getProperties(
      'invoiceName',
      'contactEmail',
      'contactPhone',
      'invoicePostcode',
      'invoiceState',
      'invoiceStreet',
      'invoiceTown',
      'vatRate'
    );
    this.store.push({
      data: {
        type: modelName,
        id: 'default',
        attributes: {
          name: userData.invoiceName,
          email: userData.contactEmail,
          phone: userData.contactPhone,
          zip: userData.invoicePostcode,
          country: userData.invoiceState,
          street: userData.invoiceStreet,
          city: userData.invoiceTown,
          vatRate: userData.vatRate
        }
      }
    });
  },
  loadContacts() {
    this.get('store').unloadAll('contact');
    this.set('contacts', []);
    this.set('loadedData.contacts', false);
    this.set('loadedData.invoiceContacts', false);

    return all([
      this.get('store')
        .findAll('contact', { reload: true })
        .then(contacts => {
          this.set('contacts', contacts);
          if (window.heap) {
            try {
              window.heap.addUserProperties({
                userContactsCount: contacts.get('length')
              });
            } catch (e) {
              Logger.error(e);
            }
          }
          return contacts;
        }),
      this.store.findAll('billingProfile').then(invoiceContacts => {
        this.set('invoiceContacts', invoiceContacts);
        return invoiceContacts;
      })
    ]).then(data => {
      this.set('loadedData.contacts', true);
      this.set('loadedData.invoiceContacts', true);

      // neni kontakt
      if (!data[0].length) {
        this._pushContact('contact');
        this.set('contacts', this.store.peekAll('contact'));
      }

      // neni billing profile
      if (!data[1].length) {
        this._pushContact('billingProfile');
        this.set('invoiceContacts', this.store.peekAll('billingProfile'));
      }
    });
  },
  _updateFavourite(resolveFnc, data) {
    this.set('favouriteTlds', data);
    resolveFnc(data);
  },
  _loadProfile() {
    if (!this.get('session.isAuthenticated')) {
      return resolve();
    }

    return this.store.find('profile', 'current').then(profile => {
      const user = this.store.peekRecord('user', 'current');
      user.set('profile', profile);
      this.set('user', user);

      Sentry.configureScope(scope => {
        scope.setUser(profile.getProperties('name', 'login', 'email'));
      });
      if (window.heap) {
        try {
          window.heap.identify(profile.get('login'));
        } catch (e) {
          Logger.error(e);
        }
      }

      this._loadUserCredit();
      return profile;
    });
  },
  _loadUserCredit() {
    this.set('loadedData.credit', false);
    return this.store.find('credit', 'current').then(credit => {
      this.set('credit', credit);
      this.set('loadedData.credit', true);
      return credit;
    });
  },
  _userChange() {
    return all([
      this.loadFavourites(),
      this.tldList._loadServiceList(),
      this.tldList._loadTlds().then(() => this.tldList._updatePrices())
    ]);
  },
  _arraySame(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every(element => {
        if (array2.indexOf(element) > -1) {
          return (element = array2[array2.indexOf(element)]);
        }
      })
    );
  },
  _ssoOriginLoad() {
    const tmp = this.get('store').createRecord('ssoUrl');
    return tmp.original_admin().then(result => {
      this.set('ssoOrigin', result.getProperties('id', 'iv', 'loginData'));
      tmp.rollbackAttributes();
      return result;
    });
  },
  mojeIdConnect() {
    const { access_token } = this.get('session.data.authenticated');
    return this.get('torii')
      .open('idp', { action: 'login', externalProvider: 'mojeid' })
      .then(data => {
        return fetchAjax(`${config.API_HOST}/api/v2/auth/user/identities`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            data: { attributes: { id_token: data.id_token } }
          })
        });
      });
  },
  mojeIdDisconnect() {
    const { access_token } = this.get('session.data.authenticated');
    return fetchAjax(`${config.API_HOST}/api/v2/auth/user/identities/mojeid`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    });
  },
  loadBaseData() {
    return all([
      this.loadFavourites(),
      this.cart.load(true)
      // this.store.findAll('mailHostingPlan', { reload: true })
    ]);
  },
  loadUserData() {
    return this.setUser().then(() => {
      return this.loadBaseData().then(() => {
        return this.loadContacts();
      });
    });
  },
  afterLogin() {
    if (window.location.pathname.search('signin') !== -1) {
      return this.router.replaceWith('home');
    }
    return resolve();
  },
  afterLogout() {
    return this.cleanData().then(() => {
      return this.loadBaseData();
    });
  },
  checkUserLogin() {
    if (
      !window ||
      !window.localStorage ||
      this.get('session.isAuthenticated')
    ) {
      return;
    }

    if (this.lastCheckPathname === window.location.pathname) {
      return resolve();
    }

    this.set('lastCheckPathname', window.location.pathname);

    return new EmberPromise(resolve => {
      // EDGE close tab warning hack
      later(() => {
        this.get('session')
          .authenticate('authenticator:wy-api', { refresh: true })
          .then(
            () => {
              resolve(this.loadUserData());
            },
            () => {
              Logger.log('Not logged in');
              resolve();
            }
          );
      }, 1000);
    });
  }
});
