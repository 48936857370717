import CartItem from './cart-item';
import DS from 'ember-data';
import { computed } from '@ember/object';
import domainParser from 'domena-mobile/utils/domain-parser';
import { inject as service } from '@ember/service';

export default CartItem.extend({
  store: service(),
  certType: computed('certTypeId', function () {
    return this.store.peekRecord('sslCertType', this.certTypeId);
  }),
  certTypeId: DS.attr('string'),
  quantity: DS.attr('number', { defaultValue: 12 }),
  years: computed('quantity', {
    get() {
      return this.quantity / 12;
    },
    set(key, value) {
      this.set('quantity', value * 12);
      return value;
    }
  }),
  itemPrice: computed(
    'certType.prices',
    'quantity',
    'basketPrice',
    function () {
      if (this.get('basketPrice') === 0)
        return parseFloat(this.get('basketPrice'));

      return parseFloat(
        this.get('basketPrice') || this.get(`certType.prices.${this.quantity}`)
      );
    }
  ),
  tld: computed('extra.commonName', function () {
    const parsedDomain = domainParser(
      this.get('extra.commonName'),
      this.store.peekAll('tld')
    );
    return parsedDomain.tld;
  }),
  domainName: computed('extra.commonName', function () {
    const parsedDomain = domainParser(
      this.get('extra.commonName'),
      this.store.peekAll('tld')
    );
    return parsedDomain.domain || this.get('extra.commonName');
  }),
  fullName: computed('extra.commonName', function () {
    const parsedDomain = domainParser(
      this.get('extra.commonName'),
      this.store.peekAll('tld')
    );
    return (
      `${parsedDomain.domain}.${parsedDomain.tld}` ||
      this.get('extra.commonName')
    );
  }),
  attrs() {
    const data = this._super(this.certTypeId);
    data.params.push({ name: 'certTypeId', value: this.certTypeId });
    return {
      ...data,
      extra: this.extra,
      name: this.name,
      pack: this.certTypeId
    };
  }
});
