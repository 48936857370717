import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'picture',
  classNameBindings: ['class'],
  imageObject: null,
  src: alias('imageObject.url'),
  alt: alias('imageObject.alt'),
  title: alias('imageObject.title'),
  height: computed('imageObject.height', 'imgHeight', 'imgWidth', function () {
    const imageObjectHeight =
      this.imageObject?.customData?.height || this.imageObject?.height;

    if (this.imgWidth && !this.imgHeight) {
      return;
    }

    return this.imgHeight || imageObjectHeight;
  }),
  width: computed('imageObject.width', 'imgHeight', 'imgWidth', function () {
    const imageObjectWidth =
      this.imageObject?.customData?.width || this.imageObject?.width;

    if (this.imgHeight && !this.imgWidth) {
      return;
    }

    return this.imgWidth || imageObjectWidth;
  }),
  webpSrcSet: alias('imageObject.responsiveImage.webpSrcSet'),
  srcSet: alias('imageObject.responsiveImage.srcSet')
});
