import { reject, resolve } from 'rsvp';

import JWT from 'ember-simple-auth-token/authenticators/jwt';
import ajax from '../utils/ajax';
import { assign } from '@ember/polyfills';
import { inject as service } from '@ember/service';
import config from '../config/environment';

export default JWT.extend({
  session: service(),
  makeRefreshData() {
    return;
  },

  makeRequest(url, data, headers) {
    const { method = 'POST' } = headers || {};
    delete headers.method;

    return ajax(url, {
      method: method,
      headers: assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        headers
      ),
      body: data && JSON.stringify(data)
    })
      .then(response => {
        return { json: response };
      })
      .catch(error => {
        return reject(error);
      });
  },

  refreshAccessToken(token) {
    return this.makeRequest(this.serverTokenRefreshEndpoint, null, {
      authorization: `Bearer ${token}`,
      method: 'GET'
    })
      .then(response => {
        const sessionData = this.handleAuthResponse(response.json);
        this.trigger('sessionDataUpdated', sessionData);
        return sessionData;
      })
      .catch(error => {
        this.handleTokenRefreshFail(error.status);
        return reject(error);
      });
  },

  restore(orgData) {
    return this._super(...arguments).then(data => {
      const { token, exp } = data || {};
      if (token) {
        orgData = { ...orgData, token, exp };
        return resolve(orgData);
      } else {
        return reject();
      }
    });
  },

  authenticate({ identification, password, otp, lticket, sms }) {
    if (!identification || !(password || lticket)) {
      return Promise.reject(Error('No credentials'));
    }
    const postData = {
      login: identification,
      password,
      billfirm: config.LOCALE === 'cs' ? 'IGN' : 'WEBG'
    };

    if (otp) {
      postData.otp = otp;
    }

    if (sms) {
      postData.sms = sms;
    }

    if (lticket) {
      postData.lticket = lticket;
    }

    return ajax(this.serverTokenEndpoint, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(postData)
    }).then(response => {
      const { data, success } = response || {};
      if (success) {
        return this.handleAuthResponse(data || {});
      } else {
        return reject(data);
      }
    }, reject);
  }
});
