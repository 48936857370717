import DS from 'ember-data';
import NonRestActions from '../mixins/non-rest-action';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

export default DS.Model.extend(NonRestActions, {
  // invoice: DS.belongsTo('invoice', {inverse: 'orders', polymorphic: true}), // nefunguje, je potreva nastavit v podedenych
  invoiceContactId: DS.attr('number'),
  // invoiceContact: DS.belongsTo('contact'), // tohle asi neni potreba jako relation
  registrationRulesConsent: DS.attr('boolean'),
  cartItemId: DS.attr('string'),
  price: DS.attr('number'),
  vat: DS.attr('number'),
  state: DS.attr('string'),
  cancel() {
    return this.nonRestAction('cancel', 'PUT', {});
  },
  // types: domain, mail-hosting, renewal, safety-guard, safety-guard-deactivation, transfer-order
  modelName: computed('state', function () {
    // state - nejaky atribut ktery se po load nastavi
    return (
      this.constructor.modelName &&
      this.constructor.modelName.replace('-order', '')
    );
  }),
  type: alias('modelName'),
  displayName: alias('type'),
  totalPrice: alias('price'),
  awaitingPaymentInvoice: alias('invoice.awaitingPayment'),
  order: DS.attr()
});
