import Route from '@ember/routing/route';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/password-init'
      }
    }
  ]),
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  },
  setupController(controller) {
    controller.set('user', this.store.createRecord('user'));

    if (controller.get('hash')) {
      controller.set('page', 'set');
    }
  },
  resetController(controller) {
    controller.setProperties({
      page: null,
      login: null,
      hash: null
    });
  },
  actions: {
    confirmData() {
      this.controller.set('ipasError', '');

      let validations = this.controller.get('user.validations.attrs');

      if (
        validations.get('password.isValid') &&
        validations.get('passwordConfirm.isValid')
      ) {
        const adapter = this.store.adapterFor('user');

        adapter
          .ajax(adapter.buildURL() + '/user/password', 'POST', {
            data: {
              data: {
                attributes: {
                  login: this.controller.get('login'),
                  req_code: this.controller.get('hash'),
                  password: this.controller.get('user.password')
                }
              }
            }
          })
          .then(
            () => {
              this.transitionTo('signin', {
                queryParams: { passwordChanged: true }
              });
            },
            error => {
              this.controller.set('ipasError', error.errors[0]);
            }
          );
      } else {
        this.controller.set('showErrors', true);
      }
    }
  }
});
