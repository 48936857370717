import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function iconText(params, { icon, text }) {
  if (!text) {
    return '';
  }

  let _icon = icon ? ` di-${icon}` : '';

  return new htmlSafe(`<i class="domena-font${_icon || ' icon-empty'}"></i>
     <span class="text">${text}</span>`);
}

export default helper(iconText);
