/* eslint-disable max-lines */
import { isEmpty, isPresent } from '@ember/utils';

import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import Common from '../mixins/common';
import Logger from '../utils/logger';
import Route from '@ember/routing/route';
import { all } from 'rsvp';
import config from 'domena-mobile/config/environment';
import { makeArray } from '@ember/array';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';

const defaultSiteName = 'DOMENA.cz';

export default Route.extend(ApplicationRouteMixin, Common, {
  cart: service(),
  checkout: service(),
  cms: service(),
  config: service(),
  currentUser: service(),
  events: service(),
  gtmEvents: service(),
  headData: service(),
  ideas: service(),
  intl: service(),
  modal: service(),
  navDots: service(),
  router: service(),
  session: service(),
  tips: service(),
  tldList: service(),
  fastboot: service(),
  queryParams: {
    'mail-hosting-type': {},
    admin: {}
  },
  init() {
    this._super(...arguments);

    this.router.on('routeWillChange', () => {
      return this.get('events').logEvent(
        'willTransition',
        null,
        this.get('url')
      );
    });

    this.router.on('routeDidChange', () => {
      window && window.scrollTo && window.scrollTo(0, 0);
      return this.get('events').logEvent(
        'didTransition',
        null,
        this.get('url')
      );
    });
  },
  whiteBackground: false,
  title: function (tokens) {
    tokens = makeArray(tokens);
    if (isEmpty(tokens)) {
      tokens.push(`${this.intl.t('info.domain_registration')}`);
    }

    const titleFromCMS = this.cms.getSeoTitle(this.router.currentRouteName);
    if (titleFromCMS) {
      tokens.shift();
      tokens.unshift(titleFromCMS);
    }

    const globalSeo = this.cms.globalSeo || { fallbackSeo: {} };
    tokens.push(globalSeo.siteName || defaultSiteName);
    return tokens.reverse().join(' | ');
  },
  handleError(error) {
    Logger.error(error);
  },
  beforeModel() {
    this.config.check();

    this.intl.setLocale(config.LOCALE);
    this.get('tips').setRandomTip();
    const dataPromises = [this.store.findAll('paymentMethod')];

    all(dataPromises).catch(error => {
      Logger.error(error);
    });

    const dataRequiredPromises = [
      this.store.findAll('country'),
      this.get('checkout').loadCurrentCheckoutModel()
    ];

    if (!this.get('fastboot.isFastBoot')) {
      dataRequiredPromises.push(
        this.get('cms')
          .loadOffersCache()
          .then(data => {
            return this.get('cms').processOffersResponse(data);
          })
      );
    }

    return all(dataRequiredPromises).catch(error => {
      this.handleError(error);
    });
  },
  async model() {
    try {
      // TODO optimalizovat pro prihlaseneho usera - delaji se dva requesty.
      const tlds = await this.tldList._loadTlds();
      this.set('ideas.tlds', tlds.mapBy('name'));
      await this.tldList._loadServiceList();
      await this.tldList._createDomainCheckList();
      await this.tldList._createPrices();
      await this.tldList._updatePrices();
    } catch (error) {
      this.handleError(error);
    }
  },
  afterModel() {
    const data = [
      this.cms.loadAllCache().then(data => {
        const translationData = this.cms.processPagesResponse(data);
        return this.intl.addTranslations(config.LOCALE, {
          cms: translationData
        });
      })
    ];

    if (this.get('session.isAuthenticated')) {
      data.push(
        this.get('currentUser')
          .loadUserData()
          .then(() => {
            this.gtmEvents.logLoggedIn();
          })
      );
    } else {
      this.gtmEvents.logLoggedOut();
      data.push(this.get('currentUser').loadBaseData());
    }

    if (window.isIE11 && window.isIE11()) {
      // definovano v index.html
      this.modal.show('info/ie-browser', null, {
        modalClasses: 'ie-browser__modal'
      });
    }

    return all(data);
  },
  activate() {
    this._setBodyClass();
    this.get('events').createHandlers();
  },
  sessionAuthenticated() {
    return this.currentUser.afterLogin().then(() => {
      return this.get('currentUser')
        .loadUserData()
        .then(() => {
          this.gtmEvents.logLoggedIn();
        });
    });
  },
  sessionInvalidated() {
    this.gtmEvents.logLoggedOut();
    return this.currentUser.afterLogout().then(() => {
      this.replaceWith('home');
    });
  },
  _setBodyClass() {
    scheduleOnce('afterRender', () => {
      if (this.get('fastboot.isFastBoot')) return;
      document.querySelector('body').classList.remove('loading');
      document
        .querySelector('html')
        .classList.remove(this.get('lastRouteClass'));
      document
        .querySelector('body')
        .classList.remove(this.get('lastRouteClass'));
      const routeUrl = window.location.pathname.substring(1);
      const routeClass = routeUrl
        .split('/')
        .filter(item => isPresent(item))
        .map(part => `${part}-route`)
        .join(' ');
      this.set('lastRouteClass', routeClass);
      if (routeClass) {
        document.querySelector('html').classList.remove(routeClass);
        document.querySelector('body').classList.remove(routeClass);
      }
    });
  },
  actions: {
    willTransition() {
      if (this.get('modal.isOpen')) {
        window.history.forward();
        this.get('modal').closeAll();
      }
      this.send('modalClose');
    },
    didTransition() {
      if (this.get('fastboot.isFastBoot')) return;

      const navBar = document.querySelector('.nav-bar');
      navBar && navBar.classList.remove('collapsed');
    },
    transitionTo() {
      for (let i = 0; i < arguments.length; i++) {
        const element = arguments[i];
        if (element['externalUrl']) {
          delete element['externalUrl'];
          if (JSON.stringify(element) === JSON.stringify({})) {
            delete arguments[i];
            arguments.length--;
          }
          break;
        }
      }
      this.transitionTo(...arguments);
    },
    replaceWith() {
      this.replaceWith(...arguments);
    },
    modalOpen(name, controller, model) {
      this.get('events').logEvent('open', 'modal', name);

      this.render(name, {
        outlet: 'simple-modal',
        into: 'application',
        controller: controller,
        model: model
      });

      this.onBackClose('modal');
      this.controller.set('isModalOpen', name);
    },
    modalClose() {
      let name = this.controller.get('isModalOpen');

      this.get('events').logEvent('close', 'modal', name);

      if (name) {
        this.disconnectOutlet({
          outlet: 'simple-modal',
          parentView: 'application'
        });

        this.store
          .peekAll('user')
          .filterBy('key', 'login_tmp')
          .forEach(data => {
            data.rollbackAttributes();
          });

        this.controller.set('isModalOpen', false);
        if (this.get('fastboot.isFastBoot')) return;
        document.querySelector('.login-errors').classList.remove('active');
      }
    }
  }
});
