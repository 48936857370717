import { alias, sort, uniqBy } from '@ember/object/computed';
import { computed, observer } from '@ember/object';

import $ from 'jquery';
import Component from '@ember/component';
import domainParser from '../utils/domain-parser';
import { isArray } from '@ember/array';
import { isPresent } from '@ember/utils';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { trySet } from '@ember/object';

export default Component.extend({
  store: service(),
  cart: service(),
  currentUser: service(),
  deviceDetection: service(),
  events: service(),
  taskQueue: service(),
  domainList: service(),
  countItemsInCart: alias('cart.count'),
  domainName: '',
  lastGroupNumber: 2,
  perPage: 10,
  lastPage: 2,
  limitOutputHeight: false,
  refresh: false,
  showFavourite: false,
  showConfirmation: alias('currentUser.showConfirmation'),
  selectedTlds: null,
  selectedFavouriteTldsString: computed('selectedTlds.[]', function () {
    return this.get('selectedTlds')
      .map(item => `.${item}`)
      .join(', ');
  }),
  tld: computed('domainName', 'refresh', function () {
    let parsedDomain = {};
    if (this.get('domainName').indexOf('.') !== -1) {
      const tlds = this.get('store').peekAll('tld');
      parsedDomain = domainParser(this.get('domainName'), tlds);
    }
    return parsedDomain.tld;
  }),
  checkName: computed('domainName', 'refresh', function () {
    let parsedDomain = { domain: this.get('domainName') };
    if (this.get('domainName').indexOf('.') !== -1) {
      const tlds = this.get('store').peekAll('tld');
      parsedDomain = domainParser(this.get('domainName'), tlds);
    }
    this.get('taskQueue').cancel('domainCheckTask');
    return parsedDomain.domain;
  }),
  listedGroups: null,
  onlyActive: false,
  delayTimer: null,
  availableDomains: computed('checkName', function () {
    const domains = this.get('domains');
    const featured = [];
    this.get('currentUser.favouriteTlds').forEach(tldName => {
      const domain = domains.findBy('tldName', tldName);
      if (domain) {
        featured.pushObject(domain);
      }
    });
    const withOffer = domains
      .filter(domain => domain.get('activeOffer'))
      .sortBy('tldName');
    const domainGroups = this._orderDomainGroups(
      domains.filter(
        domain =>
          !domain.get('tld.defaultFeatured') && !domain.get('activeOffer')
      )
    );
    let out = [];
    out = out.concat(featured);
    out = out.concat(withOffer);
    domainGroups.forEach(group => {
      out = out.concat(group.items);
    });
    return out.uniqBy('tldName');
  }),
  groupedDomains: computed('domains.@each.categories', 'tld', function () {
    const tld = this.get('tld');
    const domains = this.get('domains')
      .filter(domain => {
        return domain.get('tldName') !== tld;
      })
      .sortBy('tldName');
    return this._orderDomainGroups(domains);
  }),
  filteredTldsDomains: computed('domains.[]', 'tldName', function () {
    let tldName = this.get('tldName');
    const withDot = tldName[0] === '.';
    if (tldName.length === 1 && withDot) {
      return;
    }
    tldName = withDot ? tldName.substr(1) : tldName;
    tldName = tldName.toLowerCase();
    return this.get('domains')
      .filter(domain => domain.get('tldName').indexOf(tldName) > -1)
      .sortBy('tldName');
  }),
  _orderDomainGroups(domains) {
    let out = {};
    domains.forEach(item => {
      let categories = item.get('categories') || [];
      if (categories.length > 0) {
        categories.forEach(category => {
          if (isArray(out[category])) {
            out[category].push(item);
          } else {
            out[category] = [item];
          }
        });
      } else {
        if (isArray(out['null'])) {
          out['null'].push(item);
        } else {
          out['null'] = [item];
        }
      }
    });

    let ordered = [];

    Object.keys(out)
      .sort((a, b) => {
        return (
          this.get('listedGroups').indexOf(a) -
          this.get('listedGroups').indexOf(b)
        );
      })
      .forEach(function (key) {
        ordered.push({ name: key, items: out[key].sortBy('tldName') });
      });

    return ordered;
  },
  domainsAvailablePaged: computed(
    'availableDomains.[]',
    'lastPage',
    function () {
      return this.get('availableDomains').slice(
        0,
        this.get('perPage') * this.get('lastPage')
      );
    }
  ),
  domainsVisiblePagedAmount: computed(
    'onlyActive',
    'domainsAvailablePaged.@each.state',
    function () {
      if (this.get('onlyActive')) {
        return this.get('domainsAvailablePaged').filter(domain =>
          ['available', 'initial'].includes(domain.get('state'))
        ).length;
      } else {
        return this.get('domainsAvailablePaged').length;
      }
    }
  ),
  domainsVisiblePagedAmountObserver: observer(
    'domainsVisiblePagedAmount',
    'perPage',
    function () {
      if (this.get('domainsVisiblePagedAmount') < this.get('perPage')) {
        this.send('loadNextPage');
      }
    }
  ),
  domainsPaged: computed('groupedDomains.[]', 'lastGroupNumber', function () {
    return this.get('groupedDomains').slice(0, this.get('lastGroupNumber'));
  }),
  featuredSort: null,
  featuredUnique: uniqBy('featured', 'tldName'),
  sortedFeatured: sort('featuredUnique', 'featuredSort'),
  featured: computed(
    'domains.@each.{activeOffer,position}',
    'currentUser.favouriteTlds',
    function () {
      const domains = this.get('domains').filter(domain => {
        return domain.get('activeOffer'); // || this.get('currentUser.favouriteTlds').includes(domain.get('tldName'));
      });
      this.get('currentUser.favouriteTlds').forEach(tldName => {
        domains.pushObject(this.get('domains').findBy('tldName', tldName));
      });
      return domains;
    }
  ),
  // pokud hledam koncovku je prvni
  highlighted: computed('domainName', 'refresh', function () {
    return this.get('domains').find(domain => {
      return this._containsTld(this.get('domainName'), domain.get('tldName'));
    });
  }),
  _containsTld(name, tldName) {
    if (!name || !tldName) {
      return false;
    }

    if (name.indexOf('.') === -1) {
      return false;
    }
    const tlds = this.get('store').peekAll('tld');
    const parsedDomain = domainParser(name, tlds);
    return tldName === parsedDomain.tld;
  },
  _groupHeight(index) {
    const nextGroupLength = this.get('groupedDomains')
      .objectAt(index)
      .items.get('length');
    const headerHeight = this.$('.collapse-list .toggle:first').outerHeight();
    const resultRowHeight = this.$(
      '.collapse-list .domain-result-item:first'
    ).outerHeight();
    return headerHeight + resultRowHeight * nextGroupLength;
  },
  _scrollTop(callback = () => {}) {
    $('html,body').animate({ scrollTop: 0 }, 'fast', callback);
  },
  init() {
    this._super(...arguments);
    // Lifestyle - lifestyle, Služby a zboží - services, Ajťácké - it,
    // Firmy a instituce - company, Média - media, Kreativní - suffixes, Sportovní - sport,
    // Gastronomie - gastronomy, Byznys - business, Hravé - creative, Imageovky - personal, Zájmové - hobby,
    // Lechtivé - playful, Barvy - colors, Střední Evropa - central_europe, Západní Evropa - western_europe,
    // Jižní Evropa - south_europe Východní a severní Evropa - eastern_north_europe, Amerika - america, Asie - asia, Ostrovy - islands
    const groups = [
      'lifestyle',
      'services',
      'it',
      'company',
      'media',
      'suffixes',
      'sport',
      'gastronomy',
      'business',
      'creative',
      'personal',
      'hobby',
      'playful',
      'colors',
      'central_europe',
      'western_europe',
      'south_europe',
      'eastern_north_europe',
      'america',
      'asia',
      'islands',
      'null'
    ];
    this.set('selectedTlds', []);
    this.set('listedGroups', groups);
    this.set('featuredSort', ['position']);
  },
  didInsertElement() {
    $('body').on('search:enterpress', () => {
      if (isPresent(this.get('tld'))) {
        this._scrollTop();
      }
    });
    this.domainsVisiblePagedAmount;
    this.domainList.getDasToken();
  },
  willDestroyElement() {
    this.get('taskQueue').cancel('domainCheckTask');
    $('body').off('search:enterpress');
  },
  actions: {
    loadNextGroup() {
      const nextGroupIndex = this.get('lastGroupNumber') + 1;
      if (
        nextGroupIndex <= this.get('groupedDomains.length') &&
        !this.get('loadingNextGroup')
      ) {
        this.set('loadingNextGroup', true);
        const screenHeight =
          window.innerHeight - $('.top-page-stack').outerHeight();
        let counter = this.get('lastGroupNumber');
        let notEnoughItems = true;
        let totalHeight = 0;
        do {
          if (counter >= this.get('groupedDomains.length') - 1) {
            notEnoughItems = false;
            break;
          }
          totalHeight += this._groupHeight(counter + 1);
          if (totalHeight > screenHeight) {
            notEnoughItems = false;
          } else {
            counter++;
          }
        } while (notEnoughItems);

        const pageToLoadCount = this.get('deviceDetection.isMobileAlt')
          ? 2
          : Math.min(counter - this.get('lastGroupNumber') + 1, 3);
        this.incrementProperty('lastGroupNumber', pageToLoadCount);
        scheduleOnce('afterRender', () => {
          this.set('loadingNextGroup', false);
        });
      }
    },
    loadNextPage() {
      if (!this.get('loadingNextGroup')) {
        this.set('loadingNextGroup', true);
        this.incrementProperty('lastPage');
        scheduleOnce('afterRender', () => {
          this.set('loadingNextGroup', false);
        });
      }
    },
    showFavourite() {
      this.set('selectedTlds', this.get('currentUser.favouriteTlds').toArray());
      trySet(this, 'showFavourite', true);
    },
    toggleTldSelected(name) {
      const selected = this.get('selectedTlds');
      if (selected.includes(name)) {
        selected.removeObject(name);
      } else {
        selected.pushObject(name);
      }
    },
    saveFavourite() {
      trySet(this, 'showFavourite', false);
      this.set('tldName', null);
      this.get('currentUser').saveFavourite(this.get('selectedTlds'));
    },
    closeFavouriteDialog() {
      // favourite reset
      this.set('selectedTlds', this.get('currentUser.favouriteTlds'));
      trySet(this, 'showFavourite', false);
      this.set('tldName', null);
    },
    confirmAction(value) {
      this.get('showConfirmation').callback(value);
    }
  }
});
