import Component from '@ember/component';
import { debounce } from '@ember/runloop';
import config from '../config/environment';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['headline-underline'],
  color: '#f5a623',
  isLocaleCs: config.LOCALE === 'cs',
  router: service(),
  cms: service(),
  strokeWidth: '7',
  tagName: 'h1',
  top: '80px',
  findLetterIndexes(text, letter = /\s/) {
    const indexes = [];
    for (let index = text.length; index--; ) {
      if (text[index].match(letter)) {
        indexes.push(index);
      }
    }
    return indexes.reverse();
  },
  getTextSizes(text = 'w') {
    const placeholder = document.createElement(this.tagName);
    placeholder.className = this.class;
    placeholder.classList.add('underline-placeholder');
    placeholder.innerText = text;
    placeholder.style.setProperty('color', 'transparent', 'important');
    placeholder.style.setProperty('position', 'absolute', 'important');
    placeholder.style.setProperty('white-space', 'nowrap', 'important');
    placeholder.style.setProperty('width', 'auto', 'important');
    this.element.appendChild(placeholder);
    const { offsetHeight: textHeight, offsetWidth: textWidth } = placeholder;
    placeholder.remove();
    return { textHeight, textWidth };
  },
  getHeaderTextLines() {
    if (!this.element) {
      return [];
    }
    const elementWidth = this.element.childNodes[0].offsetWidth;
    const headerText = this.element.innerText;
    const lines = headerText.split(/\n/);
    const headerLines = [];
    lines.forEach(line => {
      const words = line.split(/\x20/).reverse(); // use only regular space (skip &nbsp;)
      const lastLineWords = [];
      let rest = [];

      while (words.length) {
        const word = words.pop();
        const nextWord = words[words.length - 1];

        lastLineWords.push(`${word} `);
        const nextLineWidth = this.getTextSizes(
          [...lastLineWords, nextWord].join('')
        ).textWidth;

        if (nextLineWidth > elementWidth) {
          headerLines.push(lastLineWords.join('').trimRight());
          lastLineWords.clear();
          rest.clear();
        } else {
          rest = lastLineWords;
        }
      }

      if (rest.length) {
        headerLines.push(rest.join('').trimRight());
      }
    });

    return headerLines;
  },
  getRandomSvgUnderline(elementWidth) {
    let offsetYMin = -12;
    let offsetYMax = 12;

    let offsetY =
      Math.floor(Math.random() * (offsetYMax - offsetYMin)) + offsetYMin;
    const randomPath = `m ${this.strokeWidth / 2} ${this.strokeWidth} c ${
      elementWidth / 3
    } ${offsetY} ${(elementWidth / 3) * 2} ${-offsetY} ${
      elementWidth - this.strokeWidth
    } 0`;

    return `<svg height="20px" width="${elementWidth}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill="none" stroke-linecap="round" stroke="${this.color}" stroke-width="${this.strokeWidth}"><path d="${randomPath}"></path></g></svg>`;
  },
  generateSvg() {
    const root = document.documentElement;

    const cssStrokeWidth = getComputedStyle(root).getPropertyValue(
      `--${this.tagName}-stroke-width`
    );
    if (!this.element) {
      return;
    }
    const elementHeight = this.element.childNodes[0].offsetHeight;
    const isCentered = getComputedStyle(this.element).textAlign === 'center';

    if (cssStrokeWidth) {
      this.set('strokeWidth', cssStrokeWidth);
    }

    const cssStrokeColor = getComputedStyle(root).getPropertyValue(
      config.PAGE_COLOR_THEMES[config.LOCALE][this.router.currentRouteName] ||
        '--stroke-color'
    );
    this.set('color', cssStrokeColor);

    const { underline } = this.cms.currentPageData || {};
    const { cssRgb } = underline || {};
    if (cssRgb) {
      this.set('color', cssRgb);
    }

    const lineHeight = this.getTextSizes().textHeight;
    let underlineData = [];

    // Optimalizace, pokud není text více řádkový neřešíme
    if (Math.round(elementHeight / lineHeight) > 1) {
      underlineData = this.getHeaderTextLines().map((headerLine, index) => {
        const textWidth = this.getTextSizes(headerLine).textWidth;
        return {
          text: headerLine,
          textUnderlineSvg: this.getRandomSvgUnderline(textWidth),
          textWidth,
          top: lineHeight * (index + 1),
          left: isCentered ? `calc(50% - ${textWidth / 2}px)` : 0
        };
      });
    } else {
      const textWidth = this.element.childNodes[0].offsetWidth;
      const text = this.element.innerText;

      underlineData = [
        {
          text,
          textUnderlineSvg: this.getRandomSvgUnderline(textWidth),
          textWidth,
          top: lineHeight,
          left: isCentered ? `calc(50% - ${textWidth / 2}px)` : 0
        }
      ];
    }
    this.set('underlineData', underlineData);
  },
  _resizeCallback() {
    this.generateSvg();
  },
  didInsertElement() {
    this.generateSvg();

    this.set('resizeHandler', () => {
      debounce(this, this._resizeCallback, 200);
    });
    window.addEventListener('resize', this.resizeHandler);
  },
  willDestroyElement() {
    window.removeEventListener('resize', this.resizeHandler);
  }
});
