import { getOwner } from '@ember/application';
import { computed, get } from '@ember/object';
import Service from '@ember/service';
import ENV from '../config/environment';
import semVerCheck from '../utils/semantic-version-check';

const CURRENT_VERSION = ENV.appVersion || '0';

export default Service.extend({
  __config: computed(function () {
    return getOwner(this).factoryFor('config:environment').class;
  }),
  unknownProperty(path) {
    return get(this, `__config.${path}`);
  },
  currentVersion: CURRENT_VERSION,
  check() {
    const savedVersion = this._load();
    if (semVerCheck(savedVersion, this.currentVersion) < 0) {
      if (window && window.localStorage) {
        const LS = window.localStorage;
        LS.clear();
        this._save();
      }
    } else {
      this._save();
    }
  },
  _load() {
    if (window && window.localStorage) {
      const LS = window.localStorage;
      const config = JSON.parse(LS.getItem('domena-config') || '{}');
      return config.version || '0';
    }
    return this.currentVersion;
  },
  _save() {
    if (window && window.localStorage) {
      const LS = window.localStorage;
      const config = JSON.parse(LS.getItem('domena-config') || '{}');
      config.version = this.currentVersion;
      LS.setItem('domena-config', JSON.stringify(config));
    }
  }
});
