import { computed, set } from '@ember/object';

import Base from './base-cart-item';
import { SERVICE_TYPES } from '../../utils/service-config';
import { dasherize } from '@ember/string';
import { gt } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Base.extend({
  intl: service(),
  store: service(),
  cart: service(),
  currentUser: service(),
  modal: service(),
  classNames: ['cart-item', 'cart-item-registration'],
  classNameBindings: ['hasYears'],
  showMailHostinPlanChooser: false,
  hasYears: gt('model.quantity', 1),
  yearsData: computed('model._priceObject.registration', function () {
    let registration = this.get('model._priceObject.registration');
    if (isEmpty(registration)) {
      return [];
    }
    return Object.keys(registration).map(item => {
      return parseInt(item);
    });
  }),
  availableRelatedServices: computed(
    'model.{availableServices.length,related.length}',
    'isWithYola',
    function () {
      const related = this.get('model.related');
      let availableServices = this.get('model.availableServices');
      // WYAPI neumi objednat email a hosting najednou - pokud vzberu email disable hosting a naopak
      availableServices.forEach(service => {
        set(service, 'disabled', false);
      });

      const itemsForDomain = this.cart.items.filter(item => {
        const itemFullName = this.cart._getItemFullName(item.type, item);
        return itemFullName === this.model.fullName;
      });

      const itemsByType = this.cart._itemsByType(itemsForDomain);

      const isEmailInCart = Boolean(itemsByType.email);
      const isHostingInCart = Boolean(itemsByType.hosting);
      const isYolaInCart = Boolean(itemsByType.yola);

      if (isEmailInCart) {
        const hostingService = availableServices.find(
          item => item.type === SERVICE_TYPES.HOSTING
        );
        if (hostingService) {
          set(hostingService, 'disabled', true);
        }
      }

      if (isHostingInCart) {
        const emailService = availableServices.find(
          item => item.type === SERVICE_TYPES.EMAIL
        );
        if (emailService) {
          set(emailService, 'disabled', true);
        }

        const yolaService = availableServices.find(
          item => item.type === SERVICE_TYPES.YOLA
        );
        if (yolaService) {
          set(yolaService, 'disabled', true);
        }
      }

      if (isYolaInCart) {
        const hostingService = availableServices.find(
          item => item.type === SERVICE_TYPES.HOSTING
        );
        if (hostingService) {
          set(hostingService, 'disabled', true);
        }
      }

      return availableServices.map(service => {
        // TODO jak tohle udelat? Jako observer? Je potreba aby to reagovalo na related
        set(
          service,
          'relatedObjects',
          related.filter(
            item => dasherize(item.type) === dasherize(service.type)
          )
        );
        return service;
      });
    }
  ),
  actions: {
    disabled() {
      return false;
    },
    toggleSafetyGuard(safetyGuard) {
      const domain = this.get('model');
      if (safetyGuard) {
        this.get('cart').removeItem('safetyGuard', {
          name: domain.get('name'),
          tld: domain.get('tld'),
          parentId: domain.get('id'),
          parentType: domain.get('type')
        });
      } else {
        this.get('cart').addItem('safetyGuard', {
          name: domain.get('name'),
          tld: domain.get('tld'),
          parentId: domain.get('id'),
          parentType: domain.get('type'),
          source: 'domena'
        });
      }
    },
    toggleEmail(emailCartItem) {
      const domain = this.get('model');
      if (emailCartItem) {
        this.get('cart').removeItem('email', {
          name: `${domain.get('name')}.${domain.get('tldName')}`,
          extra: emailCartItem.get('extra')
        });
      } else {
        this.get('modal').show('forms/new-email-form', null, {
          header:
            '<div class="info-outside-offset text-left">' +
            '<h3 class="m-0 m-r-2 choose-variant">' +
            `${this.intl.t('info.select_email_variant')}` +
            '</h3>' +
            `<span class="tag tag--outline-success">${this.intl.t(
              'info.month_for_free'
            )}</span></div>`,
          isBig: true,
          domain,
          withFilter: false,
          modalClasses: 'new-email-form-modal'
        });
      }
    },
    toggleHosting(hostingCartItem) {
      const domain = this.get('model');
      if (hostingCartItem) {
        this.get('cart').removeItem('hosting', {
          name: `${domain.get('name')}.${domain.get('tldName')}`,
          extra: hostingCartItem.get('extra')
        });
      } else {
        this.get('modal').show('hosting-plan-select', null, {
          customerDomain: `${domain.get('name')}.${domain.get('tldName')}`,
          domain,
          type: 'domena'
        });
      }
    },
    toggleYola(webEditorCartItem) {
      const domain = this.get('model');
      if (webEditorCartItem) {
        this.get('cart').removeItem('yola', {
          name: `${domain.get('name')}.${domain.get('tldName')}`,
          extra: webEditorCartItem.get('extra')
        });
      } else {
        this.get('modal').show('forms/new-yola-form', null, {
          customerDomain: `${domain.get('name')}.${domain.get('tldName')}`,
          domain,
          type: 'domena'
        });
      }
    },
    openSafetyGuardHelp() {
      this.modal.show('partials/insurance');
    }
  },
  _addEmail(domain, type) {
    const selectedPlan = this.store
      .peekAll('mailHostingPlan')
      .filter(plan => plan.id.includes(`${this.selectedPlan}.`))
      .sortBy('period')
      .get('firstObject');

    this.get('cart').addItem('email', {
      name: `${domain.get('name')}.${domain.get('tldName')}`,
      quantity: selectedPlan.get('period') || 1,
      extra: {
        mailHostingType: type,
        mailHostingName: domain.get('name'),
        tldName: domain.get('tldName'),
        modelName: selectedPlan?.constructor?.modelName
      },
      parentId: domain.get('id'),
      parentType: 'registration',
      source: 'domena'
    });
  }
});
