import { buildValidations, validator } from 'ember-cp-validations';

import Model from 'ember-data/model';
import NonRestActions from '../mixins/non-rest-action';
import attr from 'ember-data/attr';
import { computed } from '@ember/object';
import config from '../config/environment';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import regex from '../utils/regex';

const validations = buildValidations({
  name: [
    validator('length', {
      min: 4,
      messageKey: 'validations.short_name'
    }),
    validator('length', {
      max: 64,
      messageKey: 'validations.long_name'
    }),
    validator('format', {
      regex: regex.FULL_NAME_REGEX,
      messageKey: 'validations.min_length_4_with_space'
    })
  ],
  email: validator('format', {
    type: 'email',
    messageKey: 'validations.email'
  }),
  phone: [
    validator('format', {
      allowBlank: true,
      regex: /^[0-9]+$/,
      messageKey: 'validations.only_numbers'
    }),
    validator('length', {
      is: 9,
      messageKey: 'validations.phone_number_cz',
      dependentKeys: ['model.dialingCode'],
      disabled: computed(function () {
        return this.get('model.dialingCode') !== '+420';
      }).volatile()
    }),
    validator('length', {
      min: 5,
      max: 15,
      messageKey: 'validations.phone_number_other',
      dependentKeys: ['model.dialingCode'],
      disabled: computed(function () {
        return this.get('model.dialingCode') === '+420';
      }).volatile()
    })
  ],
  ic: validator('format', {
    regex: /^[0-9]+/,
    messageKey: 'validations.number',
    dependentKeys: ['model.company'],
    disabled: computed(function () {
      return !this.get('model.company');
    }).volatile()
  }),
  dic: validator('format', {
    regex:
      config.LOCALE === 'cs' ? /^([A-Z]{2}[A-Z0-9+.*]{5,15})$/ : /^[0-9]{10}$/,
    messageKey: 'validations.number',
    dependentKeys: ['model.company'],
    disabled: computed(function () {
      return (
        !this.get('model.company') ||
        !this.get('model.dic') ||
        this.get('model.dic.length') === 0
      );
    }).volatile()
  }),
  companyName: [
    validator('length', {
      min: 4,
      messageKey: 'validations.short_company_name',
      dependentKeys: ['model.company'],
      disabled: computed(function () {
        return !this.get('model.company');
      }).volatile()
    }),
    validator('length', {
      max: 64,
      messageKey: 'validations.long_company_name',
      dependentKeys: ['model.company'],
      disabled: computed(function () {
        return !this.get('model.company');
      }).volatile()
    })
  ],
  street: validator('format', {
    regex: /^[a-zA-Z\u00C0-\u017F0-9.]+\s+[a-zA-Z\u00C0-\u017F0-9]+/,
    messageKey: 'validations.street_house_number'
  }),
  countryId: validator('presence', {
    presence: true,
    messageKey: 'validations.country'
  }),
  city: validator('length', {
    min: 2,
    messageKey: 'validations.min_length_2'
  }),
  zip: [
    validator('length', {
      is: 5,
      messageKey: 'validations.zip_cz',
      dependentKeys: ['model.countryId'],
      disabled: computed(function () {
        return parseInt(this.get('model.countryId'), 10) !== 203;
      }).volatile()
    }),
    validator('number', {
      allowString: true,
      integer: true,
      messageKey: 'validations.zip_cz',
      dependentKeys: ['model.countryId'],
      disabled: computed(function () {
        return parseInt(this.get('model.countryId'), 10) !== 203;
      }).volatile()
    }),
    validator('format', {
      regex: /^[\da-zA-Z\-\s]{2,10}$/,
      messageKey: 'validations.zip_other',
      dependentKeys: ['model.countryId'],
      disabled: computed(function () {
        return parseInt(this.get('model.countryId'), 10) === 203;
      }).volatile()
    })
  ]
});

export default Model.extend(NonRestActions, validations, {
  currentUser: service(),
  intl: service(),
  allValid: computed('isValid', 'validations.isValid', function () {
    return this.get('isValid') && this.get('validations.isValid');
  }),
  name: attr('string'),
  email: attr('string'),
  secondaryEmails: attr('string'),
  defaultInvoiceContact: attr('boolean'),
  company: attr('boolean'),
  ic: attr('string', { defaultValue: '' }),
  dic: attr('string', { defaultValue: '' }),
  dialingCode: attr('string', {
    defaultValue: `+${config.DEFAULT_DIALING_CODE}` || '+420'
  }),
  phone: attr('string', { defaultValue: '' }),
  invoiceContact: attr('boolean'),
  phoneFormatted: computed({
    get() {
      return this.get('phone');
    },
    set(key, value) {
      this.set('phone', value.replace(/[\s+/-]/g, ''));
      return value;
    }
  }),
  companyName: attr('string', { defaultValue: '' }),
  city: attr('string', { defaultValue: '' }),
  zip: attr('string', { defaultValue: '' }),
  street: attr('string'),
  owner: attr({ defaultValue: () => ({}) }),
  countryId: computed({
    get() {
      const countries = this.store
        .peekAll('country')
        .findBy('alpha2', this.get('owner.state'));
      return (
        (countries && countries.get('id')) || config.DEFAULT_COUNTRY_ID || 203
      );
    },
    set(key, value) {
      const countries = this.store.peekRecord('country', value);
      this.set('owner.state', countries.get('alpha2'));
      this.set('invoiceState', countries.get('alpha2'));
      return value;
    }
  }),
  vatRate: attr('number'),
  hiddenAttributes: attr(),
  rrIds: attr(),
  usageCount: attr('number', { defaultValue: 0 }),
  lastUsedAt: attr('date'),
  displayName: computed('company', 'name', 'companyName', function () {
    return this.company ? this.companyName : this.name;
  }),
  emails: computed('email', 'secondaryEmails', function () {
    let email = this.get('email'),
      secondaryEmails = this.get('secondaryEmails');
    return secondaryEmails ? `${email}, ${secondaryEmails}` : email;
  }),
  emailName: computed('email', function () {
    if (this.get('isEmailSecret')) {
      return this.intl.t('secret_values.email');
    }
    return this.get('email');
  }),
  isEmailSecret: computed('hiddenAttributes.[]', function () {
    return (this.get('hiddenAttributes') || []).includes('email');
  }),
  emailsName: computed('isEmailSecret', 'emails', function () {
    if (this.get('isEmailSecret')) {
      return this.intl.t('secret_values.email');
    }
    return this.get('emails');
  }),
  icName: computed('ic', function () {
    let ic = this.get('ic');
    return ic ? `${this.intl.t('ic')} ${ic}` : '';
  }),
  isDicSecret: computed('hiddenAttributes.[]', function () {
    return (this.get('hiddenAttributes') || []).includes('dic');
  }),
  dicName: computed('isDicSecret', 'dic', function () {
    if (this.get('isDicSecret')) {
      return this.intl.t('secret_values.vatNumber');
    }
    let dic = this.get('dic');
    return dic ? `${this.intl.t('dic')} ${dic}` : '';
  }),
  isPhoneSecret: computed('hiddenAttributes.[]', function () {
    return (this.get('hiddenAttributes') || []).includes('phone');
  }),
  phoneWithoutDial: computed('phone', function () {
    return (this.get('phone') || '').split('.').pop();
  }),
  phoneWithDial: computed(
    'dialingCode',
    'phone',
    'phoneWithoutDial',
    function () {
      if (!this.get('phone')) {
        return '';
      }
      return `${this.get('dialingCode')} ${this.get('phoneWithoutDial')}`;
    }
  ),
  phoneName: computed('isPhoneSecret', 'phoneWithDial', function () {
    if (this.get('isPhoneSecret')) {
      return this.intl.t('secret_values.phone');
    }
    return this.get('phoneWithDial');
  }),
  isAddressSecret: computed('hiddenAttributes.[]', function () {
    let hidden = this.get('hiddenAttributes') || [];
    return hidden.includes('city') || hidden.includes('zip');
  }),
  addressName: computed('isAddressSecret', 'city', 'zip', function () {
    if (this.get('isAddressSecret')) {
      return this.intl.t('secret_values.address');
    }
    return `${this.get('zip')} ${this.get('city')}`;
  }),
  isStreetSecret: computed('hiddenAttributes.[]', function () {
    return (this.get('hiddenAttributes') || []).includes('street');
  }),
  streetName: computed('isStreetSecret', 'street', function () {
    if (this.get('isStreetSecret')) {
      return this.intl.t('secret_values.street');
    }
    return this.get('street');
  }),
  country: computed('countryId', function () {
    if (!this.get('countryId')) {
      return '';
    }
    return this.store.peekRecord('country', this.get('countryId')).get('name');
  }),
  isCountrySecret: computed('hiddenAttributes.[]', function () {
    return (this.get('hiddenAttributes') || []).includes('country_id');
  }),
  countryName: computed('isCountrySecret', 'country', function () {
    if (this.get('isCountrySecret')) {
      return this.intl.t('secret_values.country');
    }
    return this.get('country');
  }),
  hasHiddenAttributes: computed('hiddenAttributes.[]', function () {
    return isPresent(this.get('hiddenAttributes'));
  }),
  hasRrIds: computed('rrIds.[]', function () {
    if (!this.get('rrIds')) {
      return false;
    }
    return Object.keys(this.get('rrIds')).length !== 0;
  }),
  rrIdsValues: computed('rrIds.[]', function () {
    if (!this.get('rrIds')) {
      return '';
    }
    return Object.keys(this.get('rrIds'))
      .map(i => this.get('rrIds')[i])
      .join(' ');
  }),
  currentVatRate: computed('vatRate', function () {
    return this.get('vatRate') || 1.21;
  }),
  whois(params) {
    return this.nonRestAction('whois', 'GET', params);
  },
  import(params) {
    return this.nonRestAction('import', 'POST', {
      data: { attributes: params }
    }).then(data => {
      return this.store.push(data);
    });
  },
  type: 'owner',
  // WY-API attrs
  createdAt: attr('date'),
  updatedAt: attr('date')
});
