import Component from '@ember/component';
import { scheduleOnce } from '@ember/runloop';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  didInsertElement() {
    scheduleOnce('afterRender', () => {
      tryInvoke(this, 'action');
    });
  }
});
