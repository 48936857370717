export function initialize(application) {
  ['component', 'controller', 'route'].forEach(type => {
    application.inject(type, 'currentUser', 'service:currentUser');
  });
}

export default {
  name: 'current-user',
  initialize
};
