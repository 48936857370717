import Route from '@ember/routing/route';
import cookiesParser from '../utils/cookies-parser';
import { hash } from 'rsvp';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/payment'
    }
  }
];

export default Route.extend({
  headTags: HEAD_TAGS,
  checkout: service(),
  tips: service(),
  cart: service(),
  gtmEvents: service(),
  queryParams: {
    invoice_id: {},
    order_ids: {}
  },

  model(params) {
    return hash({
      domains: this.store.peekAll('domain-order'),
      domainChecks: this.store.peekAll('domain-check'),
      invoice: params.invoice_id
        ? this.store
            .findRecord('invoice', params.invoice_id, { reload: true })
            .catch(() => {
              return null;
            })
        : null
      // domainsSelected: this.store.findAll('domain-selected')
    });
  },
  afterModel(model) {
    this.get('tips').resetTip();
    this.get('cart')
      .clear()
      .then(() => {
        this.get('checkout').reset();
        return this.get('checkout').clear();
      });

    if (!model.invoice) {
      return;
    }

    const cookies = cookiesParser();
    const { checkout_model, order_items } = cookies || {};

    scheduleOnce('afterRender', () => {
      this.gtmEvents.logEcommerce(model.invoice, order_items, checkout_model);
      document.cookie = 'order_items=; path=/';
    });
  }
});
