import WyApiSerializer from './wy-api';

export default WyApiSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload /*, id, requestType*/) {
    return {
      data: {
        id: 'current',
        type: 'coupon',
        attributes: {
          isValid: Boolean(payload.valid)
        }
      }
    };
  }
});
