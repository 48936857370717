import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/signup'
    }
  }
];

export default Route.extend({
  session: service(),
  beforeModel() {
    if (this.get('session.isAuthenticated')) {
      this.replaceWith('home');
    }
  },
  headTags: HEAD_TAGS,
  actions: {
    willTransition({ targetName }) {
      if (targetName !== 'signin') {
        this.set('currentUser.afterLoginAction', null);
      }
    }
  }
});
