export default function domainParser(domainName, tlds = [], process = true) {
  const tldsNames = tlds.mapBy('name');
  if (process) {
    domainName = domainName.replace(new RegExp('^www.'), '');
  }

  let out = {},
    domainParts = domainName.split('.'),
    tld = domainParts.pop(),
    domain = domainParts.pop(),
    subdomains = domainParts;

  let notParsedOut = { tld, domain, subdomains, parsed: false };
  tldsNames.forEach(tld => {
    let tldRegex = new RegExp(`${tld}$`);
    if (domainName.search(tldRegex) >= 0) {
      if (tld.length > (out.tld || '').length) {
        let domainPart = domainName.replace(tldRegex, '');
        if (domainPart) {
          let subdomains = (domainPart[domainPart.length - 1] === '.'
              ? domainPart.slice(0, -1)
              : domainPart
            ).split('.'),
            domain = subdomains.pop();
          out = { tld, domain, subdomains, parsed: true };
        } else {
          out = { tld, domain: null, subdomains: [], parsed: true };
        }
      }
    }
  });
  return Object.keys(out).length === 0 ? notParsedOut : out;
}
