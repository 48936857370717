import { computed } from '@ember/object';
import Model from 'ember-data/model';
import attr from 'ember-data/attr';

export default Model.extend({
  name: attr('string'),
  content: attr('string'),
  active: attr('boolean'),
  domainName: attr('string'),
  zoneTemplateId: attr('string'),
  displayName: computed('name', 'domainName', function () {
    return this.get('name') || this.get('domainName');
  })
});
