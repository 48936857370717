import { cancel, later } from '@ember/runloop';
import $ from 'jquery';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  events: service(),
  forceTimer: null,
  timerInterval: 5000,
  targetSelector: null,
  _targetSelectorEvented: null,
  didInsertElement() {
    this.set('scrollHandler', () => {
      this._timerWindup();
      this._elemVisibleCheck();
    });
    this.get('events').on('scroll:changeRaw', this.get('scrollHandler'));
    const targetSelector = this.get('targetSelector');
    if (targetSelector) {
      $(targetSelector).on('scroll', this.get('scrollHandler'));
      this.set('_targetSelectorEvented', targetSelector);
    }
    this._elemVisibleCheck();
  },
  willDestroyElement() {
    cancel(this.get('forceTimer'));
    this.get('events').off('scroll:changeRaw', this.get('scrollHandler'));
    if (this.get('_targetSelectorEvented')) {
      $(this.get('_targetSelectorEvented')).off(
        'scroll',
        this.get('scrollHandler')
      );
    }
  },
  _timerWindup() {
    cancel(this.get('forceTimer'));
    if (!this.get('isDestroying') && !this.get('isDestroyed')) {
      this.set(
        'forceTimer',
        later(() => {
          this._elemVisibleCheck();
        }, this.timerInterval)
      );
    }
  },
  _elemVisibleCheck() {
    if (this.get('events').isVisible(this.$(), true, window.outerHeight / 2)) {
      this.get('action')();
    }
    // this._timerWindup();
  }
});
