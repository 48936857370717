import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { filterBy, mapBy } from '@ember/object/computed';

export default Component.extend({
  cart: service(),
  store: service(),
  domainsCheck: null,
  tldNames: null,
  registrationItems: filterBy('cart.notDeletedItems', 'type', 'registration'),
  itemTldNames: mapBy('registrationItems', 'tldName'),
  suggestedDomains: computed(
    'itemTldNames',
    'name',
    'domainsCheck.@each.available',
    function () {
      const itemTldNames = this.get('tldNames') || this.get('itemTldNames');
      const domainsChecks = this.get('domainsCheck');
      if (!domainsChecks) {
        return [];
      }
      return domainsChecks
        .filter(domainCheck => {
          const props = domainCheck.getProperties('name', 'state', 'tldName');
          return (
            !props.state ||
            (['available', 'checking', 'initial'].includes(props.state) &&
              !itemTldNames.includes(props.tldName))
          );
        })
        .sortBy('position')
        .slice(0, 3);
    }
  ),
  init() {
    this._super(...arguments);
    this.set('domainsCheck', this.get('store').peekAll('domain-check'));
  }
});
