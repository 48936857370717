import { filter, filterBy, not, notEmpty } from '@ember/object/computed';

import DS from 'ember-data';
import Model from 'ember-data/model';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import domainParser from '../utils/domain-parser';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Model.extend({
  currentUser: service(),
  domainServices: null,
  name: DS.attr('string'),
  dead: DS.attr('boolean'),
  autoRenew: DS.attr('boolean'),
  idnName: DS.attr('string'),
  expiresOn: DS.attr('date'),
  safetyGuard: DS.attr('boolean'),
  ignumNameServers: DS.attr('boolean'),
  ownedByIgnum: DS.attr('boolean'),
  payer: DS.attr('boolean'),
  invoiceContact: DS.belongsTo('contact'),
  ownerContact: DS.belongsTo('contact'),
  unpaidOrders: DS.hasMany('orders'),
  ongoingOrders: DS.hasMany('orders'),
  ongoingActiveOrders: filter('ongoingOrders', function (order) {
    return ['registration', 'renewal'].includes(order.get('type'));
  }),
  awaitingActiveOrders: filterBy('unpaidOrders', 'awaitingPaymentInvoice'),
  unpaidRenewalOrders: filterBy('unpaidOrders', 'type', 'renewal'),
  unpaidRegistrationOrders: filterBy('unpaidOrders', 'type', 'registration'),
  isExpired: computed('expiresOn', function () {
    const expiresOn = this.get('expiresOn');
    if (!expiresOn) {
      return true;
    }
    return moment().isAfter(moment(expiresOn));
  }),
  safetyGuardAvailable: computed('expiresOn', function () {
    const expiresOn = this.get('expiresOn');
    if (!expiresOn) {
      return false;
    }
    return moment().add(40, 'days').isBefore(moment(expiresOn));
  }),
  tld: DS.belongsTo('tld'),
  price: DS.belongsTo('price'),
  categories: alias('tld.categories'),
  domainName: computed('name', function () {
    const tldName = this.get('tldName');
    return (
      this.get('name') &&
      this.get('name').replace(tldName ? `.${tldName}` : /\.\w+$/, '')
    );
  }),
  tldName: computed('tld.name', 'name', function () {
    const tldName = this.get('tld.name');
    const domainName = this.get('name');
    let parsedDomain = {};

    if (domainName && domainName.indexOf('.') !== -1) {
      const tlds = this.get('store').peekAll('tld');
      parsedDomain = domainParser(domainName, tlds);
    }

    return tldName || parsedDomain.tld;
  }),
  displayName: alias('name'),
  priceValue: alias('price.defaultPrice'),
  activeOffer: alias('tld.activeOffer'),
  perYearValue: computed(
    'price.perYear',
    'currentUser.loadedData.prices',
    function () {
      if (this.get('currentUser.loadedData.prices') && this.get('price')) {
        return this.get('price.perYear');
      }
    }
  ),
  isInactive: alias('dead'),
  fullName: alias('name'),
  isActive: not('isInactive'),
  hasAwaitingOrders: notEmpty('awaitingActiveOrders'),
  hasUnpaidOrders: notEmpty('unpaidOrders'),
  hasOngoingOrders: notEmpty('ongoingActiveOrders'),
  totalPrice: alias('price.perYear'),
  activeDomainServices: filterBy('domainServices', 'isActive'),
  _priceObject: computed('currentUser.loadedData.prices', function () {
    // wait for prices
    if (this.get('currentUser.loadedData.prices')) {
      return this.store.peekRecord('price', this.get('tldName'));
    }
  }),
  extraClassesString: computed('isInactive', 'hasUnpaidOrders', function () {
    const out = [];
    if (this.get('isInactive')) out.push('is-inactive');
    if (this.get('hasUnpaidOrders')) out.push('unpaid');
    return out.join(' ');
  })
});
