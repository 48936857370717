import { scheduleOnce } from '@ember/runloop';
import { oneWay } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  classNames: 'toggle-button',
  tagName: 'span',
  _checked: false,
  checked: oneWay('_checked'),
  click() {
    scheduleOnce('afterRender', () => {
      this.toggleProperty('_checked');
    });
  }
});
