import Helper from '@ember/component/helper';
import moneyString from '../utils/money-string';
import { inject as service } from '@ember/service';

export default Helper.extend({
  currentUser: service(),
  intl: service(),
  compute(params, hash) {
    hash = {
      currency: this.currentUser.currency,
      ...hash
    };
    hash.currency = this.intl.t(`currency.${hash.currency}`);
    return moneyString(params, hash);
  }
});
