import Component from '@ember/component';
import { computed } from '@ember/object';
import keyIndexes from 'domena-mobile/utils/key-indexes';
import { inject as service } from '@ember/service';

export default Component.extend({
  blockData: computed('pageSlug', 'datoSlug', function () {
    return this.get(`cms._translationData.${this.pageSlug}.${this.datoSlug}`);
  }),
  cms: service(),
  classNames: ['landing-page__block', 'landing-page__why-block', 'text-center'],
  pageSlug: computed('datoSlug', function () {
    return this.datoSlug.split('-').slice(0, -1).join('-');
  }),
  slugKey: computed('pageSlug', 'datoSlug', function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  }),
  whyItems: computed('pageSlug', 'datoSlug', function () {
    return keyIndexes(
      this.get(`cms._translationData.${this.pageSlug}`),
      `${this.datoSlug}`
    );
  })
});
