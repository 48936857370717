import DS from 'ember-data';

export default DS.Model.extend({
  invoiceId: DS.attr('number'),
  paymentMethodId: DS.attr('number'),
  options: DS.attr(),
  redirectUrl: DS.attr('string'),
  successUrl: DS.attr('string'),
  failureUrl: DS.attr('string'),
  vs: DS.attr('string'),
  amount: DS.attr('number'),
  status: DS.attr('string'), // failed, success, pending
  updateAt: DS.attr('date')
});
