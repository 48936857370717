import EmberObject, { observer } from '@ember/object';

import Service from '@ember/service';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Service.extend({
  currentUser: service(),
  gtmEvents: service(),
  isOpen: false,
  isDanger: false,
  modals: null,
  init() {
    this._super(...arguments);
    this.set('modals', []);
  },
  observer: observer('modals.@each.isOpen', function () {
    const isOpen = this.get('modals').isAny('isOpen');
    this.set('isOpen', isOpen);
    if (isOpen) {
      document.body.classList.add('disable-scroll');
      document.documentElement.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
      document.documentElement.classList.remove('disable-scroll');
    }
  }),
  show(name = '', model = null, options = {}) {
    const type = options['type'] || 'domena';
    delete options['type'];

    const modalObject = EmberObject.create({
      name,
      model,
      options,
      isOpen: true,
      type
    });
    modalObject['id'] = guidFor(modalObject);
    this.gtmEvents.logModalOpen(modalObject);
    this.get('modals').pushObject(modalObject);
    return modalObject;
  },
  close(modal, ...rest) {
    let isSubmitted = true;
    const lastArg = rest.pop();
    if (typeof lastArg === 'object') {
      isSubmitted = (lastArg || {}).isSubmitted;
    }

    const modalObject = this.get('modals').findBy('id', modal.get('id'));
    if (!modalObject) {
      return modalObject;
    }
    modalObject.set('isOpen', false);
    tryInvoke(modalObject.options, 'closeAction');
    this.gtmEvents.logModalClose(modalObject, isSubmitted);
    this.get('modals').removeObject(modalObject);
    return modalObject;
  },
  closeAll() {
    this.get('modals').forEach(element => {
      this.close(element);
    });
  }
});
