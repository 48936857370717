import LocalStorageStore from 'ember-simple-auth/session-stores/local-storage';
import { resolve } from 'rsvp';

export default LocalStorageStore.extend({
  _handleStorageEvent() {},
  restore() {
    if (!window || !window.localStorage) {
      return resolve({});
    }

    const data = window.localStorage.getItem(this.key);
    return resolve(JSON.parse(data) || {});
  }
});
