import Component from '@ember/component';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: [
    'product-cards-block',
    'landing-page__block',
    'landing-page__product',
    'landing-page__product--hosting',
    'landing-page__block--light'
  ],
  cms: service(),
  productItems: null,
  sortedProductItems: computed('productItems.[]', function () {
    const typesOrder = this.cms?.pages?.produkty?.blocks.mapBy('slug');
    return this.productItems?.toArray()?.sort((itemA, itemB) => {
      const itemAPos = typesOrder.indexOf(dasherize(itemA.pack));
      const itemBPos = typesOrder.indexOf(dasherize(itemB.pack));
      return itemAPos - itemBPos;
    });
  })
});
