import { alias, filterBy } from '@ember/object/computed';
import { computed, trySet } from '@ember/object';
import { isEmpty, tryInvoke } from '@ember/utils';

import Common from 'domena-mobile/mixins/common';
import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend(Common, {
  gtmEvents: service(),
  store: service(),
  classNames: ['nsset-select'],
  selected: alias('model'),
  selectedTemp: null,
  search: '',
  listPage: 1,
  filterList(items) {
    if (this.get('search.length') === 0) {
      return items;
    }

    return items.filter(item => {
      let id = this.stripAccent(item.get('id').toLowerCase()),
        search = this.stripAccent(this.get('search').toLowerCase());

      return id.indexOf(search) !== -1;
    });
  },
  nssets: null,
  nssetsIgnum: filterBy('nssets', 'ignum'),
  nssetsIgnumFiltered: computed('search', 'nssetsIgnum.[]', function () {
    return this.filterList(this.get('nssetsIgnum'));
  }),
  nssetsOwn: filterBy('nssets', 'ignum', false),
  nssetsOwnFiltered: computed('search', 'nssetsOwn.[]', function () {
    return this.filterList(this.get('nssetsOwn'));
  }),
  nssetsLoaded: false,
  hasErrors: computed(
    'search',
    'nssetsIgnumFiltered.[]',
    'nssetsOwnFiltered.[]',
    function () {
      return (
        this.get('search.length') !== 0 &&
        isEmpty(this.get('nssetsIgnumFiltered')) &&
        isEmpty(this.get('nssetsOwnFiltered'))
      );
    }
  ),
  loadData() {
    this.set('nssets', this.get('store').findAll('nsset'))
      .then(nssets => {
        const nsset = nssets.get('firstRecord');
        this.setProperties({ selected: nsset, selectedTemp: nsset });
      })
      .finally(() => {
        trySet(this, 'nssetsLoaded', true);
      });
  },
  didInsertElement() {
    this.loadData();
    this.set('selectedTemp', this.get('selected'));
  },
  actions: {
    confirm() {
      this.gtmEvents.logSubmitForm('nsset-select');
      tryInvoke(this, 'close', [this.get('selectedTemp')]);
    },
    searchChange() {
      this.set('listPage', 1);
    }
  }
});
