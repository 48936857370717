import config from '../config/environment';

export default {
  name: 'i18n',
  initialize: function (instance) {
    const intl = instance.lookup('service:intl');
    const requestedLocale = config.LOCALE;

    if (intl.get('locales').includes(requestedLocale)) {
      intl.setLocale('locale', requestedLocale);
    }
  }
};
