import Route from '@ember/routing/route';

export default Route.extend({
  queryParams: {
    domainName: {},
    vizitkaName: {}
  },
  model(params) {
    const { domainName, vizitkaName } = params;
    return [domainName, vizitkaName];
  }
});
