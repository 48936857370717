import DS from 'ember-data';

export default DS.Model.extend({
  code: DS.attr('string'),
  disabled: DS.attr('boolean'),
  icon: DS.attr('string'),
  icons: DS.attr(),
  name: DS.attr('string'),
  online: DS.attr('boolean'),
  options: DS.attr(),
  paymentType: DS.attr('string')
});
