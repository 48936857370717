import { helper } from '@ember/component/helper';

export function findBy([data], hash) {
  if (!data || !hash.key || !hash.value) {
    return null;
  }
  return data.findBy(hash.key, hash.value);
}

export default helper(findBy);
