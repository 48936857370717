import { computed } from '@ember/object';
import DS from 'ember-data';
import Order from './order';
import { alias } from '@ember/object/computed';
import domainParser from 'domena-mobile/utils/domain-parser';

export default Order.extend({
  invoice: DS.belongsTo('invoice'),
  // invoice: DS.belongsTo('invoice', {inverse: 'orders', polymorphic: true}), // FIXME
  ownerContactId: DS.attr('number'),
  adminContactId: DS.attr('number'),
  domainName: DS.attr('string'),
  years: DS.attr('number'),
  safetyGuard: DS.attr('boolean'),
  identityCardNumber: DS.attr('string'), // attrPerson -> 'identity_card'
  vatNumber: DS.attr('string'), // attrPerson -> 'dic'
  xxxId: DS.attr('string'), // attrPerson -> xxx_id
  xxxActive: DS.attr('boolean'), // attrPerson -> xxx_id
  // docUrl: DS.attr('string'),
  autoRenew: DS.attr('boolean'),
  nssetId: DS.attr('string'),
  name: computed('domainName', function () {
    return this.get('domainName').replace(/\.\w+$/, '');
  }),
  tldName: computed('domainName', function () {
    const domainName = this.get('domainName');
    let parsedDomain = {};

    if (domainName && domainName.indexOf('.') !== -1) {
      const tlds = this.get('store').peekAll('tld');
      parsedDomain = domainParser(domainName, tlds);
    }

    return parsedDomain.tld;
  }),
  type: 'registration',
  displayName: alias('domainName'),
  priceObject: computed(function () {
    return this.get('store').peekRecord('price', this.get('tldName'));
  }),
  safetyGuardPrice: computed('priceObject', function () {
    return parseFloat(this.get('priceObject.safetyGuard'));
  }),
  tld: alias('tldName')
});
