import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  fastboot: service(),
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  },
  actions: {
    didTransition() {
      if (this.get('fastboot.isFastBoot')) {
        this.set('fastboot.response.statusCode', 404);
      }
    }
  }
});
