import Controller from '@ember/controller';
import Payment from '../mixins/payment';
import { computed } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

export default Controller.extend(Payment, {
  intl: service(),
  queryParams: ['errorCodes'],
  ordersFail: filterBy('model', 'isNew'),
  errorMessages: computed('errorCodes', function () {
    return new Intl.ListFormat()
      .format(
        (this.errorCodes || '')
          .split(',')
          .map(errorCode =>
            this.intl.t(`order.errors.${underscore(errorCode)}`)
          )
      )
      .toLowerCase();
  }),
  actions: {
    retry() {
      this.makeOrder();
    },
    cancel() {
      this.get('cart').clear();
      this.transitionToRoute('home');
    }
  }
});
