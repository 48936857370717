import Base from './base-cart-item';
import { computed } from '@ember/object';

export default Base.extend({
  classNames: ['cart-item', 'cart-item-email'],
  classNameBindings: ['hasYears'],
  hasYears: computed('model.quantity', function () {
    return this.get('model.quantity') > 1;
  })
});
