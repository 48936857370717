import Component from './form-group';

export default Component.extend({
  classNames: ['graphic-domain-name'],
  classNameBindings: ['secure'],
  secure: false,
  wildcard: false,
  autofocus: false,
  didInsertElement() {
    if (this.autofocus) {
      this.$('input').trigger('focus');
    }
  }
});
