import { computed } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import Common from 'domena-mobile/mixins/common';
import { tryInvoke } from '@ember/utils';
import FormGroup from 'domena-mobile/components/form-group';

export default FormGroup.extend(Common, {
  classNames: ['input-suggest'],
  value: '',
  label: '',
  filter: null,
  attrName: '',
  noValidate: true,
  getFilter: task(function* (value) {
    return yield tryInvoke(this, 'filter', [value]);
  }),
  filterItems: task(function* (value) {
    value = this.stripAccent(value.toLowerCase());
    this.set('value', value);
    if (value.length < 2) {
      return [];
    }
    yield timeout(250);
    return yield this.get('getFilter').perform(value);
  }).restartable(),
  isDropdownOpen: computed('filterItems.lastSuccessful.value.[]', {
    get() {
      return this.get('filterItems.lastSuccessful.value.length') > 0;
    },
    set(key, value) {
      return value;
    }
  }),
  actions: {
    setValue(value = '') {
      this.setProperties({ value, isDropdownOpen: false });
      this.$('[type="text"]').val(value);
    },
    setValueFromDropdown(value = '') {
      this.send('setValue', value);
      tryInvoke(this, 'afterDropdown');
    }
  }
});
