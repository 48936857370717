import Helper from '@ember/component/helper';
import { isPresent } from '@ember/utils';

export default Helper.extend({
  compute([price], { decimal = 2 }) {
    if (isPresent(price) && !isNaN(price)) {
      return Number.parseFloat(Number.parseFloat(price).toFixed(decimal));
    }

    return '<div style="display: inline-block;"><div class="loader"></div></div>';
  }
});
