import { buildValidations, validator } from 'ember-cp-validations';

import DS from 'ember-data';
import { computed } from '@ember/object';
import config from 'domena-mobile/config/environment';
import { isBlank } from '@ember/utils';
import regex from '../utils/regex';

const phoneValidations = attr => [
  validator('format', {
    allowBlank: true,
    regex: /^[0-9]+$/,
    messageKey: 'validations.only_numbers'
  }),
  validator('length', {
    is: 9,
    messageKey: 'validations.phone_number_cz',
    dependentKeys: ['model.dialingCode'],
    disabled: computed('model.dialingCode', `model.${attr}`, function () {
      return (
        this.get('model.dialingCode') !== '+420' ||
        isBlank(this.get(`model.${attr}`))
      );
    })
  }),
  validator('length', {
    min: 5,
    max: 15,
    messageKey: 'validations.phone_number_other',
    dependentKeys: ['model.dialingCode'],
    disabled: computed('model.dialingCode', `model.${attr}`, function () {
      return (
        this.get('model.dialingCode') === '+420' ||
        isBlank(this.get(`model.${attr}`))
      );
    })
  })
];

const validations = buildValidations({
  name: [
    validator('length', {
      min: 4,
      messageKey: 'validations.short_name'
    }),
    validator('length', {
      max: 64,
      messageKey: 'validations.long_name'
    }),
    validator('format', {
      // IPAS ji nepotrebuje staci jen text
      regex: regex.FULL_NAME_REGEX,
      messageKey: 'validations.min_length_4_with_space',
      disabled: computed('model.formName', function () {
        return this.get('model.formName') !== 'forms/account-change-name-form';
      })
    })
  ],
  email: validator('format', {
    type: 'email',
    messageKey: 'validations.email'
  }),
  secondaryEmail: validator('format', {
    allowBlank: true,
    type: 'email',
    messageKey: 'validations.email'
  }),
  onlyNumber: phoneValidations('onlyNumber')
  // secondaryPhone: phoneValidations('secondaryPhone'),
});

export default DS.Model.extend(validations, {
  formName: null,
  priceList: DS.belongsTo('price-list'),
  deposit: DS.attr('number'),
  depositVs: DS.attr('number'),
  email: DS.attr('string'),
  name: DS.attr('string'),
  pairFailedPayment: DS.attr('boolean'),
  phone: DS.attr('string'),
  secondaryEmail: DS.attr('string'),
  // secondaryPhone: DS.attr('string'),
  sms: DS.attr('string'),
  spending: DS.attr('number'),
  /* smsNumber: '',
  smsEnabled: Ember.computed('sms', {
    get() {
      return Ember.isPresent(this.get('sms'));
    },
    set(key, value) {
      this.set('sms', value ? this.get('smsNumber') : null);
    }
  }),*/
  useDeposit: DS.attr('boolean'),
  useDepositForEmailServices: DS.attr('boolean'),
  vat: DS.attr('number'),
  percentageVat: computed('vat', function () {
    return this.get('vat') * 0.01 + 1;
  }),
  dialingCode: computed('phone', {
    get() {
      const phone = this.get('phone');
      if (!phone) {
        return `+${config.DEFAULT_DIALING_CODE}` || '+420';
      }
      const index = phone.indexOf('.');
      return phone.substring(0, index);
    },
    set(key, value) {
      this.set(
        'phone',
        this.get('onlyNumber') && value
          ? `${value}.${this.get('onlyNumber')}`
          : null
      );
      return value;
    }
  }),
  onlyNumber: computed('phone', {
    get() {
      const phone = this.get('phone');
      if (!phone) {
        return '';
      }
      const index = phone.indexOf('.');
      return phone.substring(index + 1, phone.length);
    },
    set(key, value) {
      this.set(
        'phone',
        this.get('dialingCode') && value
          ? `${this.get('dialingCode')}.${value}`
          : null
      );
      return value;
    }
  }),
  priceListPDF: computed('priceList', function () {
    return `${
      config.API_HOST
    }/api/v2/price_lists/${this.get('priceList.id')}.pdf`;
  })
});
