import WyApiAdapter from './wy-api';
import SingletonModelAdapterMixin from '../mixins/singleton-model-adapter';
import { inject as service } from '@ember/service';

const ERROR_CODE_MAP = [
  {
    code: 1233,
    pointer: '/data/attributes/phone',
    messageKey: 'invalid_phone'
  }
];

export default WyApiAdapter.extend(SingletonModelAdapterMixin, {
  intl: service(),
  namespace: 'auth',
  urlForCreateRecord() {
    return `${this.host}/order/create-user`;
  },
  handleResponse() {
    const data = this._super(...arguments);
    if (data instanceof Error) {
      const { errors = [] } = data;

      data.errors = errors.map(error => {
        const { detail = {} } = error;
        const errorItem = ERROR_CODE_MAP.findBy('code', detail.code);
        return {
          status: '422',
          source: { pointer: errorItem?.pointer },
          title: detail.message,
          code: detail.code,
          detail: detail.message
        };
      });
    }
    return data;
  }
});
