import { helper } from '@ember/component/helper';

export function country(params /* , hash*/) {
  let id = String(params[0]);
  let country = params[1].findBy('id', id);

  return country ? country.get('name') : '';
}

export default helper(country);
