import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { hash } from 'rsvp';

const DEFAULT_PER_PAGE = 15;
const SSL_CACHE_TIME = 0; // cache off

export default Service.extend({
  store: service(),
  page: 1,
  pages: computed('sslTotal', 'perPage', function () {
    return Math.ceil(this.sslTotal / this.perPage);
  }),
  perPage: DEFAULT_PER_PAGE,
  loaded: false,
  _loadData() {
    return hash({
      sslCerts: this.store.findAll('sslCert'),
      sslOrders: this.store.query('sslCertOrder', {
        filter: { state: ['ready', 'started', 'created'] },
        include: 'invoice'
      })
    }).then(({ sslCerts, sslOrders }) => {
      this.setProperties({
        sslCerts,
        sslOrders,
        loaded: new Date().getTime(),
        sslTotal: sslCerts.length + sslOrders.length
      });
    });
  },
  async load() {
    if (this.loaded + SSL_CACHE_TIME < new Date().getTime()) {
      await this._loadData();
    }
    return {
      sslCerts: this.sslCerts,
      sslOrders: this.sslOrders
    };
  },
  data: computed('page', 'perPage', 'sslCerts', 'sslOrders', function () {
    const offset = (this.page - 1) * this.perPage;
    const data = [...this.sslOrders.toArray(), ...this.sslCerts.toArray()];
    return data.slice(offset, offset + this.perPage);
  })
});
