import FormGroup from 'domena-mobile/components/form-group';
import { inject as service } from '@ember/service';

export default FormGroup.extend({
  store: service(),
  tagName: 'div',
  value: null,
  showError: false,
  focusOut() {},
  didInsertElement() {
    this._super(...arguments);
    this.set('value', this.defaultValue);
  },
  actions: {
    async submit() {
      this.setProperties({
        loading: true,
        hasErrors: false,
        showError: false
      });
      let coupon;
      try {
        coupon = await this.store.queryRecord('coupon', {
          code: this.value
        });

        this.set('loading', false);
        coupon.set('code', this.value);
        if (coupon.isValid) {
          this.set('showError', true);
          this.set('validCoupon', coupon.code);
          this.onSubmit?.(coupon.code);
        } else {
          this.onSubmit?.(null);
          this.set('validCoupon', null);
          this.set('hasErrors', true);
        }
      } catch {
        this.setProperties({
          loading: false,
          hasErrors: true
        });
      }
    },
    clear() {
      this.set('value', null);
      this.onSubmit?.(null);
      this.set('validCoupon', null);
      this.set('hasErrors', true);
    }
  }
});
