import { computed, observer } from '@ember/object';

import Component from '@ember/component';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  cart: service(),
  classNames: ['payment-method'],
  currentUser: service(),
  paymentMethods: null,
  price: 0,
  selected: null,
  session: service(),
  store: service(),
  userSelected: null,
  didInsertElement() {
    this.set('paymentMethods', this.get('store').peekAll('paymentMethod'));
    this.get('currentUser')
      .reload()
      .then(() => {
        this._checkSelected();
      });
  },
  observer: observer('price', 'currentUser.deposit', function () {
    this._checkSelected();
  }),
  availableMethods: computed(
    'paymentMethods',
    'currentUser.deposit',
    'price',
    function () {
      const formSettings = this.store.peekRecord('formSettings', 'current');
      const ibankingTypes = formSettings && formSettings.get('ibankingTypes');
      return (this.paymentMethods || []).filter(
        paymentMethod =>
          ibankingTypes.includes(paymentMethod.paymentType) &&
          (paymentMethod.code !== 'credit'
            ? true
            : this.currentUser.deposit && this.currentUser.deposit > this.price)
      );
    }
  ),
  _checkSelected() {
    if (!this.get('session.isAuthenticated')) {
      return;
    }

    if (isEmpty(this.get('selected'))) {
      const methodCode = this.paymentMethods
        .filterBy('disabled', false)
        .get('firstObject.code');
      // Když je dostatečný kredit a už nebyla vybrána metoda, vybere se platební metoda kreditem
      let paymentMethod = methodCode || 'card';
      if (this.paymentMethods) {
        if (this.get('cart.totalPrice') <= this.get('currentUser.deposit')) {
          paymentMethod = 'credit';
        }
        this.set('selected', this.paymentMethods.findBy('code', paymentMethod));
      }
    }
  },
  actions: {
    select(value, channel) {
      this.set('selected', this.get('paymentMethods').findBy('code', value));
      this.set('selectedChannel', channel);
      this.set('userSelected', this.get('selected.code'));
    },
    confirm() {
      tryInvoke(this, 'action');
    }
  }
});
