import { all } from 'rsvp';
import { computed } from '@ember/object';
import { filterBy, mapBy, alias } from '@ember/object/computed';
import Controller from '@ember/controller';
import Payment from '../mixins/payment';
import Logger from '../utils/logger';
import { inject as service } from '@ember/service';

export default Controller.extend(Payment, {
  cart: service(),
  checkout: service(),
  queryParams: ['errorCodes'],
  // TODO-FIX - nemelo by byt potreba
  items: alias('cart.baseItems'),
  orderObjects: mapBy('items', 'domainObject'),
  cartItemsSuccess: filterBy('items', 'orderId'),
  cartItemsFail: filterBy('items', 'orderError'),
  ordersSuccessTotalPrice: computed(
    'cartItemsSuccess.@each.{itemPrice,relatedPrice}',
    function () {
      return this.get('cartItemsSuccess').reduce((sum, item) => {
        return sum + item.get('itemPrice') + item.get('relatedPrice');
      }, 0);
    }
  ),
  vatRate: computed('checkout.model.ownerContact.vatRate', function () {
    return this.get('checkout.model.ownerContact.vatRate')
      ? this.get('checkout.model.ownerContact.vatRate')
      : 1.21;
  }),
  actions: {
    reorder() {
      const orderForReorder = this.get('cart.baseItems')
        .mapBy('orderObject')
        .compact()
        .filterBy('isNew', false); // base items with domain orderObject
      orderForReorder.mapBy('cartItem').forEach(cartItem => {
        orderForReorder.pushObjects(
          cartItem
            .get('related')
            .mapBy('orderObject')
            .compact()
            .filterBy('isNew', false)
        );
      });
      this.invoice(orderForReorder);
    },
    cancelOrder() {
      const ordersForCancel = [];
      const successOrders = this.get('cart.notDeletedItems')
        .mapBy('orderObject')
        .compact()
        .filterBy('isNew', false);
      successOrders.forEach(order => {
        ordersForCancel.push(order.cancel());
      });

      all(ordersForCancel).then(
        () => {
          this.get('cart').clear();
          this.transitionToRoute('home');
        },
        error => {
          Logger.error(error);
        }
      );
    }
  }
});
