import Component from './form-group';
import domainParser from 'domena-mobile/utils/domain-parser';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  classNames: ['form-group', 'ssl-confirm-email'],
  classNameBindings: ['isInvalid'],
  domainName: '',
  domainPart: '',
  mailPart: '',
  hasValidationErrors: false,
  isInvalid: computed('mailPart', 'domainPart', function () {
    return !this.get('mailPart') || !this.get('domainPart');
  }),
  hasErrors: computed('isInvalid', 'showErrors', 'showError', function () {
    return (
      (this.get('showErrors') || this.get('showError')) &&
      this.get('hasValidationErrors')
    );
  }),
  wrapperClass: computed(
    'focused',
    'hasErrors',
    'showError',
    'isInvalid',
    'showState',
    function () {
      if (!this.get('showState')) {
        return '';
      }

      return [
        this.get('hasErrors')
          ? 'wrapper-error'
          : !this.get('isInvalid') && this.get('showError')
          ? 'wrapper-valid'
          : '',
        this.get('focused') ? 'focused' : ''
      ].join(' ');
    }
  ),
  awailableDomains: computed('domainName', function () {
    const domainName = this.get('domainName');
    if (!domainName) {
      return [];
    }
    const parsedDomain = domainParser(
      this.get('domainName'),
      this.store.peekAll('tld'),
      false
    );
    const options = [this.get('domainName')];

    let subDomains = [...parsedDomain.subdomains];

    if (subDomains.length) {
      subDomains.shift();
      options.unshift(
        `${subDomains.length ? `${subDomains.join('.')}.` : ''}${
          parsedDomain.domain
        }.${parsedDomain.tld}`
      );
    }

    return options;
  }),
  model: computed('mailPart', 'domainPart', {
    get() {
      if (this.get('mailPart') && this.get('domainPart')) {
        return `${this.get('mailPart')}@${this.get('domainPart')}`;
      }
      return '';
    },
    set(key, value) {
      if (value) {
        const [mailPart, domainPart] = (value || '').split('@');
        this.setProperties({ mailPart, domainPart });
      }
      return value;
    }
  }),
  didInsertElement() {
    this.setProperties({
      domainPart: this.get('awailableDomains.firstObject')
    });
  }
});
