import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  basketHash: DS.attr('string'),
  rurl: DS.attr('string'),
  currency: DS.attr('string'),
  order: DS.attr(),
  paylinks: DS.attr('boolean', { defaultValue: false }),
  idsObjects: DS.attr(),
  invoice: DS.attr(),
  paymentLink: DS.attr(),
  // returnet atributes
  iDcommission: DS.attr('string'),
  iDitem: DS.attr('string'),
  iDservice: DS.attr('string'),
  automatic: DS.attr('boolean'),
  dtFrom: DS.attr('date'),
  dtTill: DS.attr('date'),
  itemSubtext: DS.attr('string'),
  itemText: DS.attr('string'),
  processed: DS.attr('boolean'),
  timestamp: DS.attr('date'),
  unitName: DS.attr('string'),
  unitPrice: DS.attr('number'),
  unitQuantity: DS.attr('number'),
  unitVatrate: DS.attr('number'),
  price: computed('unitPrice', function () {
    return parseFloat(this.get('unitPrice'));
  }),

  // aliases
  quantity: computed(function () {
    return parseInt(this.get('unitQuantity'), 10);
  }),
  type: 'wy-item',
  modelName: computed('state', function () {
    // state - nejaky atribut ktery se po load nastavi
    return (
      this.constructor.modelName &&
      this.constructor.modelName.replace('-order', '')
    );
  })
});
