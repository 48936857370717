import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
// import Domain from './domain-base'; // TODO-FIX domain/domain-base
import Domain from './domain';
import attr from 'ember-data/attr';

export default Domain.extend({
  key: attr('string'),
  name: attr('string'),
  years: attr('number'),
  insurance: attr('boolean'),
  createdAt: attr('date'),
  officeEssentialsUsers: attr('number', { defaultValue: 1 }),
  officePremiumUsers: attr('number', { defaultValue: 1 }),
  exchangeOnlineUsers: attr('number', { defaultValue: 1 }),
  mailHostingType: attr('string'),
  mailHostingName: attr('string'),
  mailHostingTldName: attr('string'),
  notBuy: attr('boolean', { defaultValue: false }),
  displayName: or('mailHostingName', 'name'),
  tldName: computed('tld.name', 'mailHostingTldName', function () {
    return this.get('mailHostingTldName')
      ? this.get('mailHostingTldName')
      : this.get('tld.name');
  }),
  mailHostingUsers: computed(
    'mailHostingType',
    'officeEssentialsUsers',
    'officePremiumUsers',
    'exchangeOnlineUsers',
    function () {
      let users = 0;

      switch (this.get('mailHostingType')) {
        case 'office365_essentials':
          users = this.get('officeEssentialsUsers');
          break;
        case 'office365_premium':
          users = this.get('officePremiumUsers');
          break;
        case 'exchange_online':
          users = this.get('exchangeOnlineUsers');
          break;
      }

      return users;
    }
  ),
  mailHostingPrice: computed(
    'mailHostingType',
    'officeEssentialsUsers',
    'officePremiumUsers',
    'exchangeOnlineUsers',
    function () {
      let price = 0;
      let mailHostingPlan = this.store.peekAll('mailHostingPlan');

      let ignumMmailHosting = mailHostingPlan.findBy(
        'domainServiceType',
        'flexi_mail'
      );
      let office365Essentials = mailHostingPlan.findBy(
        'domainServiceType',
        'office365_essentials'
      );
      let office365Premium = mailHostingPlan.findBy(
        'domainServiceType',
        'office365_premium'
      );
      let exchangeOnline = mailHostingPlan.findBy(
        'domainServiceType',
        'exchange_online'
      );

      switch (this.get('mailHostingType')) {
        case 'flexi_mail':
          price = ignumMmailHosting.get('price');
          break;
        case 'office365_essentials':
          price =
            office365Essentials.get('price') *
            this.get('officeEssentialsUsers');
          break;
        case 'office365_premium':
          price =
            office365Premium.get('price') * this.get('officePremiumUsers');
          break;
        case 'exchange_online':
          price = exchangeOnline.get('price') * this.get('exchangeOnlineUsers');
          break;
      }

      if (price) {
        price = parseFloat(price);
      }

      return price;
    }
  ),
  totalPrice: computed(
    'years',
    'price',
    'insurance',
    'mailHostingPrice',
    function () {
      if (this.get('notBuy')) {
        return this.get('mailHostingPrice');
      }
      if (this.get('price.isLoaded')) {
        let insurance = this.get('insurance')
          ? this.get('price.safetyGuard')
          : 0;
        return (
          this.get(`price.registration.${this.get('years')}`) +
          insurance +
          this.get('mailHostingPrice')
        );
      } else {
        return null;
      }
    }
  )
});
