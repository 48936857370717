import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  cms: service(),
  classNames: ['landing-page__block', 'landing-page__custom-solution'],
  imgHeight: 284,
  pageSlug: computed(function () {
    return this.datoSlug.split('-').slice(0, -1).join('-');
  }),
  slugKey: computed(function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  })
});
