// import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { observer } from '@ember/object';

export default Component.extend(Common, {
  attributeBindings: ['id'],
  classNames: ['top-page-stack'],
  id: 'top-page-stack',
  isDesktop: alias('deviceDetection.isDesktopLarge'),

  desktopObserver: observer('deviceDetection.width', function () {
    this._deviceDetectionHandler();
  }),
  _deviceDetectionHandler() {
    document.removeEventListener('scroll', this.scrollHandler);
    if (!this.isDesktop) {
      document.addEventListener('scroll', this.scrollHandler, {
        passive: true
      });
      this.scrollHandler();
    }
  },
  _menuHideControlInit() {
    let previousScrollPosition = window.pageYOffset;
    this.set('scrollHandler', () => {
      let currentScrollPosition = window.pageYOffset || window.scrollTop;
      const userScrollDown = currentScrollPosition > previousScrollPosition;
      const userScrollThreshold =
        previousScrollPosition - currentScrollPosition;
      const thisStackEl = this.element;
      const scrollHigherThanNavbar =
        window.pageYOffset > thisStackEl.clientHeight;
      const SCROLLAMOUNT = 30;
      const SCROLLPERIOD = 100;

      // Scroll down
      if (userScrollDown) {
        if (scrollHigherThanNavbar) {
          const domainSearch = document.querySelector('.domain-search');
          const isDomainSearchOntop = domainSearch?.classList.contains(
            'has-value'
          );

          const domainSearchAndReuslt = document.querySelector(
            '.domain-search-and-result-component'
          );
          const noLimitOutputHeight = domainSearchAndReuslt?.classList.contains(
            'show-all-domains'
          );

          thisStackEl.style.transform = `translate3d(0, -${
            thisStackEl.clientHeight -
            (isDomainSearchOntop && noLimitOutputHeight ? 76 : 0)
          }px, 0)`;
        }
        // Scroll up. Includes defined treshhold of scroll amount needed for firing the action.
      } else if (
        userScrollThreshold > SCROLLAMOUNT ||
        !scrollHigherThanNavbar
      ) {
        thisStackEl.style.transform = 'translate3d(0, 0, 0)';
      }
      // Sets prev scroll
      setTimeout(() => {
        previousScrollPosition =
          currentScrollPosition <= 0 ? 0 : currentScrollPosition;
      }, SCROLLPERIOD);
    });
  },
  didInsertElement() {
    this._super(...arguments);
    this._menuHideControlInit();
    this._deviceDetectionHandler();
  }
});
