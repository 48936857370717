import { underscore } from '@ember/string';
import ENV from 'domena-mobile/config/environment';
import JSONAPIAdapter from 'ember-data/adapters/json-api';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import { pluralize } from 'ember-inflector';
import { inject as service } from '@ember/service';

const API_HOST = ENV.API_HOST || '';

export default JSONAPIAdapter.extend(DataAdapterMixin, {
  session: service('session'),
  authorize(xhr) {
    let { access_token } = this.get('session.data.authenticated');
    if (access_token) {
      xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    }
  },
  namespace: 'api/v2',
  host: API_HOST,
  pathForType(type) {
    let underscoredType = underscore(type);
    return pluralize(underscoredType);
  },
  ajaxOptions(url, type, options) {
    let hash = this._super(url, type, options);
    hash.timeout = 60000;
    return hash;
  }
});
