import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import config from '../config/environment';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

const PRODUCT_ITEMS = {
  cs: ['product-item-0', 'product-item-1', 'product-item-2', 'product-item-3'],
  sk: ['product-item-0', 'product-item-1', 'product-item-2', 'product-item-3']
};

export default Controller.extend({
  cart: service(),
  cms: service(),
  config,
  currentUser: service(),
  enableBlogPosts: config.LOCALE === 'cs',
  galleryData: alias('cms._translationData.home.references-homepage.images'),
  intl: service(),
  modal: service(),
  name: '',
  productItems: PRODUCT_ITEMS[config.LOCALE],
  queryParams: ['name'],
  session: service(),
  showAllPartners: false,

  _transferAction() {
    // TODO transfer
    // this.router.transitionTo('after-login', { queryParams: { type: 'transfer' } });
  },
  hideTop: computed('deviceDetection.isDesktop', 'name', function () {
    return (
      isPresent(this.get('name')) && !this.get('deviceDetection.isDesktop')
    );
  }),
  actions: {
    transferDomain() {
      if (this.get('session.isAuthenticated')) {
        this._transferAction();
      } else {
        this.set('currentUser.afterLoginAction', 'domainTransfer');
        this.modal.show('login-modal', null, {
          infoText: `${this.intl.t('login.info_text')}`,
          header: this.intl.t('login.title'),
          onClose: loginSuccess => {
            if (loginSuccess) {
              this._transferAction();
            }
            this.set('currentUser.afterLoginAction', null);
          }
        });
      }
    },
    handleScrollToCards() {
      setTimeout(() => {
        const el = document.querySelector('#get_most_out_of_domains');
        const headerHeight = document.querySelector('.top-page-stack')
          .clientHeight;
        if (el && headerHeight) {
          window.scrollTo({
            top: el.offsetTop - headerHeight + 30,
            behavior: 'smooth'
          });
        }
      }, 100);
    }
  }
});
