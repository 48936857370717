import { next, scheduleOnce } from '@ember/runloop';

import $ from 'jquery';
import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { trySet } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Component.extend({
  classNames: ['domain-search-and-result-component'],
  classNameBindings: ['limitOutputHeight::show-all-domains'],
  currentUser: service(),
  focus: null,
  isDesktop: alias('deviceDetection.isDesktopLarge'),
  isFixedOnTop: false,
  isMobile: alias('deviceDetection.isMobileAlt'),
  limitOutputHeight: false,
  name: '',
  onlyActive: false,
  refresh: false,
  store: service(),
  taskQueue: service(),

  didInsertElement() {
    this.set(
      'domains',
      this.store
        .peekAll('domain-check')
        .sortBy('tld.position')
        .filterBy('tld.enabled')
    );
    $('body').on('search:reset', () => {
      next(() => {
        trySet(this, 'showFavourite', false);
        trySet(this, 'tldName', '');
      });
    });

    let previousScrollPosition = window.pageYOffset;
    this.set('scrollHandler', () => {
      const domainSearch = document.querySelector('.domain-search');
      const domainSearchHasValue = domainSearch?.classList.contains(
        'has-value'
      );

      if (domainSearchHasValue) {
        if (!this.get('limitOutputHeight')) {
          let currentScrollPosition = window.pageYOffset || window.scrollTop;
          const userScrollDown = currentScrollPosition > previousScrollPosition;
          const userScrollThreshold =
            previousScrollPosition - currentScrollPosition;
          const infoBlock =
            document.querySelector('.home .jumbotron.back-blue') ||
            document.querySelector('.domains-promo-bar');
          const scrollOffsetThreshold = 152;
          const infoBlockOffset = infoBlock?.offsetTop;
          const SCROLLAMOUNT = 30;
          const SCROLLPERIOD = 100;

          // Desktop
          if (this.isDesktop) {
            if (
              window.pageYOffset > 160 &&
              (!infoBlockOffset || window.pageYOffset < infoBlockOffset - 130)
            ) {
              // 130 padding
              this.set('isFixedOnTop', true);
            } else {
              this.set('isFixedOnTop', false);
            }
            // Mobile
            // Scroll down
          } else {
            if (userScrollDown && window.pageYOffset > scrollOffsetThreshold) {
              if (infoBlockOffset && window.pageYOffset > infoBlockOffset) {
                this.set('isFixedOnTop', false);
                return;
              }
              this.set('isFixedOnTop', true);
              // Scroll up
            } else if (window.pageYOffset < scrollOffsetThreshold) {
              this.set('isFixedOnTop', false);
            } else if (userScrollThreshold > SCROLLAMOUNT) {
              if (infoBlockOffset && window.pageYOffset < infoBlockOffset) {
                this.set('isFixedOnTop', true);
              }
            }

            // Sets prev scroll
            setTimeout(() => {
              previousScrollPosition =
                currentScrollPosition <= 0 ? 0 : currentScrollPosition;
            }, SCROLLPERIOD);
          }
        }
      }
    });
    document.addEventListener('scroll', this.scrollHandler, { passive: true });
  },
  willDestroyElement() {
    this._super(...arguments);
    document.removeEventListener('scroll', this.scrollHandler);
  },
  onlyActiveChange: observer('onlyActive', function () {
    scheduleOnce('afterRender', () => {
      $('html,body').animate({ scrollTop: 0 }, 'fast');
    });
  }),
  actions: {
    handleSearch() {
      this.toggleProperty('refresh');
    }
  }
});
