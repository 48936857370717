define("domena-mobile/services/head-tags", ["exports", "ember-cli-meta-tags/services/head-tags"], function (_exports, _headTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _headTags.default;
    }
  });
});
