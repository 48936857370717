import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import keyIndexes from 'domena-mobile/utils/key-indexes';

export default Component.extend({
  block: false,
  cms: service(),
  classNames: ['landing-page__block', 'landing-page__faq-block'],
  pageSlug: computed('datoSlug', function () {
    return this.datoSlug.split('-').slice(0, -1).join('-');
  }),
  slugKey: computed('pageSlug', 'datoSlug', function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  }),
  advices: computed('pageSlug', 'datoSlug', function () {
    return keyIndexes(
      this.get(`cms._translationData.${this.pageSlug}`),
      `${this.datoSlug}`
    );
  }),
  actions: {
    expandAdvice(advice) {
      let $elm = this.$('.advice-row.' + advice);
      $elm.toggleClass('advice-row--expanded');
    }
  }
});
