import WyApiSerializer from './wy-api';

export default WyApiSerializer.extend({
  primaryKey: 'IDbillprofile',
  normalizeResponse() {
    const payload = this._super(...arguments);

    return {
      ...payload,
      data: payload.data.map(item => {
        item.attributes.city = item.attributes.customerTown;
        delete item.attributes.customerTown;
        item.attributes['companyName'] = item.attributes.customerName;
        item.attributes.name = item.attributes.customerName;
        delete item.attributes.customerName;
        item.attributes['dialingCode'] = ((
          item.attributes.customerPhone || ''
        ).match(/^\+[0-9]+./) || [''])[0].replace('.', '');
        item.attributes.dic = item.attributes.customerIcdph;
        delete item.attributes.customerIcdph;
        item.attributes.email = item.attributes.customerEmail;
        delete item.attributes.customerEmail;
        item.attributes.phone = item.attributes.customerPhone;
        delete item.attributes.customerPhone;
        item.attributes.street = item.attributes.customerStreet;
        delete item.attributes.customerStreet;
        item.attributes.zip = item.attributes.customerPostcode;
        delete item.attributes.customerPostcode;

        return item;
      })
    };
  },
  serialize(record, options) {
    let json = this._super(record, options);

    if (json.customer_ico === '') {
      json.customer_ico = null;
    }
    if (json.customer_icdph === '') {
      json.customer_icdph = null;
    }
    if (json.customer_phone === '') {
      json.customer_phone = null;
    }
    if (json.customer_name === '') {
      json.customer_name = null;
    }
    if (json.customer_town === '') {
      json.customer_town = null;
    }
    if (json.customer_postcode === '') {
      json.customer_postcode = null;
    }

    return json;
  }
});
