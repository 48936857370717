import $ from 'jquery';
import Component from '@ember/component';
import { isPresent } from '@ember/utils';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: [
    'domains-page-search-wrap',
    'flex-column',
    'flex-wrap',
    'h-100',
    'hero-limited-height',
    'landing-page__block',
    'landing-page__hero--domains',
    'landing-page__hero'
  ],
  classNameBindings: ['limitOutputHeight::show-full-list'],
  cms: service(),
  limitOutputHeight: true,
  hideTop: computed('deviceDetection.isDesktop', 'name', function () {
    return (
      isPresent(this.get('name')) && !this.get('deviceDetection.isDesktop')
    );
  }),
  pageSlug: computed('datoSlug', function () {
    return this.datoSlug.split('-').slice(0, 1).join('-');
  }),
  slugKey: computed('pageSlug', 'datoSlug', function () {
    return `${this.pageSlug}.${this.datoSlug}`;
  }),
  titleTextColor: alias('cms.currentPageData.heroTitleColor.cssRgb'),
  subTitleTextColor: alias('cms.currentPageData.heroSubtitleColor.cssRgb'),
  backgroundColor: alias('cms.currentPageData.heroBackgroundColor.cssRgb'),
  styleTag: computed(
    'titleTextColor',
    'subTitleTextColor',
    'backgroundColor',
    function () {
      // Get the dynamic values
      let bgColor = this.backgroundColor;
      let subTitleColor = this.subTitleTextColor;
      let titleColor = this.titleTextColor;

      // Construct the CSS
      let styleString = `
      .landing-page__hero {
        background: ${bgColor} !important;
      }

      .landing-page__hero h1 {
        color: ${titleColor} !important;
      }

      .subheadline-2--medium {
        color: ${subTitleColor} !important;
      }
    `;

      // Return the HTML safe string
      return htmlSafe(styleString);
    }
  ),
  actions: {
    toggleLimitOutputHeight() {
      const loh = Boolean(this.get('limitOutputHeight'));
      if (!loh) {
        $('html,body').animate({ scrollTop: 0 }, 'fast');
      }
      this.set('limitOutputHeight', !loh);
    }
  }
});
