import Service, { inject as service } from '@ember/service';
import { debounce, scheduleOnce } from '@ember/runloop';

import $ from 'jquery';
import { computed } from '@ember/object';
import { reads } from '@ember/object/computed';

export default Service.extend({
  fastboot: service(),
  isFastBoot: reads('fastboot.isFastBoot'),
  isMobileDevice: true,
  width: 0,
  mobileScreen: 480,
  tabletScreenAlt: 640,
  tabletScreen: 835,
  desktopScreen: 820,
  desktopScreenAlt: 960,
  desktopLargeScreen: 980,
  _resize() {
    this.set('width', $(window).outerWidth());
    this.set('isMobileDevice', this._isMobile());
  },
  init() {
    this._super(...arguments);
    this.set('isMobileDevice', this._isMobile());

    if (!this.get('isFastBoot')) {
      this.set('width', $(window).outerWidth());
      scheduleOnce('afterRender', () => {
        window.addEventListener('resize', () => {
          debounce(this, this._resize, 250);
        });
      });
    } else {
      this.set('width', this._isMobile() ? 0 : 821);
    }
  },
  isDesktop: computed('width', function () {
    return this.get('width') > this.get('desktopScreen');
  }),
  isDesktopLarge: computed('width', function () {
    return this.get('width') > this.get('desktopLargeScreen');
  }),
  isDesktopAlt: computed('property', function () {
    return this.get('width') > this.get('desktopScreenAlt');
  }),
  isTabletAlt: computed('width', function () {
    if (
      this.get('width') > this.get('tabletScreenAlt') &&
      this.get('width') < this.get('desktopScreenAlt')
    ) {
      return true;
    }
  }),
  isTablet: computed('width', function () {
    if (
      this.get('width') > this.get('tabletScreenAlt') &&
      this.get('width') < this.get('desktopScreenAlt')
    ) {
      return true;
    }
  }),
  isMobileAlt: computed('property', function () {
    return this.get('width') < this.get('tabletScreenAlt');
  }),
  isMobile: computed('width', function () {
    return this.get('width') < this.get('mobileScreen');
  }),
  _isMobile() {
    let userAgent = '';
    if (this.get('isFastBoot')) {
      let headers = this.get('fastboot.request.headers');
      userAgent = headers.get('User-Agent');
    } else {
      userAgent = navigator.userAgent;
    }

    return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(
      userAgent
    );
  }
});
