import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function domenaIcon([icon] /* , hash*/) {
  if (!icon) {
    return '';
  }
  return new htmlSafe('<i class="domena-font di-' + icon + '"></i>');
}

export default helper(domenaIcon);
