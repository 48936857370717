import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  classNames: ['favourite-list-item', 'domain-result-item'],
  classNameBindings: ['disabled:disabled', 'checked:selected-tld'],
  click() {
    if (this.get('disabled')) {
      return;
    }
    tryInvoke(this, 'action', [this.name]);
  }
});
