import WySerializer from './wy-api';
import moment from 'moment';

export default WySerializer.extend({
  primaryKey: 'IDdiscount',
  normalizeResponse(store, primaryModelClass, payload, ...rest) {
    payload.data = (payload.discounts_info || []).map(discountInfo => {
      const discount = discountInfo.discount || {}; // check
      discount.from = moment(discount.dt_from).startOf('day').toISOString();
      discount.until = moment(discount.dt_till).endOf('day').toISOString();
      discount.conditions = discountInfo.conditions;
      discountInfo.discount = discount;
      return discountInfo.discount;
    });
    const data = this._super(store, primaryModelClass, payload, ...rest);
    return data;
  }
});
