import DS from 'ember-data';
import config from '../config/environment';
import { inject as service } from '@ember/service';

export default DS.Model.extend({
  currentUser: service(),
  cartItems: DS.hasMany('cartItem'),
  createdAt: DS.attr('date'),
  currency: DS.attr('string'),
  key: DS.attr('string'),
  lang: DS.attr('string', { defaultValue: config.LOCALE }),
  formName: DS.attr('string'),
  hash: DS.attr('string'),
  iDbasket: DS.attr('number'),
  iDcustomer: DS.attr('number'),
  order: DS.attr(),
  updatedAt: DS.attr('date')
});
