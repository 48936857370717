import { cancel, later, scheduleOnce } from '@ember/runloop';

import $ from 'jquery';
import Common from '../mixins/common';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

const ERRORS = {
  aroundDashes: false,
  onlyLettersAndNumbers: false,
  twoDashes: false,
  twoDots: false
};

export default Component.extend(Common, {
  classNames: ['domain-search'],
  classNameBindings: ['value:has-value'],
  currentUser: service(),
  deviceDetection: service(),
  errors: ERRORS,
  focus: false,
  haveResults: false,
  instantSetValue: false,
  resetScroll: false,
  router: service(),
  scrollHandler: null,
  typeDelayTimer: null,
  value: '',
  isErrors: computed(
    'errors.{onlyLettersAndNumbers,aroundDashes,twoDots,twoDashes}',
    function () {
      for (let error in this.get('errors')) {
        if (this.get('errors')[error]) {
          return true;
        }
      }
      return false;
    }
  ),
  didInsertElement() {
    this.set('errors', {
      aroundDashes: false,
      onlyLettersAndNumbers: false,
      twoDashes: false,
      twoDots: false
    });
    scheduleOnce('afterRender', () => {
      this.send('resetErrors');
      this.$('input').val(this.get('value'));
      this.$('[type="text"]').on('keypress', e => {
        if (e.keyCode === 13) {
          $('body').trigger('search:enterpress');
        }
      });
    });
    this._super(...arguments);
  },

  willDestroyElement() {
    this._super(...arguments);
  },
  didRender() {
    if (this.get('focus')) {
      this.$('input').focus();
    }
  },
  _processInput(value) {
    if (this.get('instantSetValue')) {
      this._resetErrors();
      this.set('value', this._processValue(value));

      // propagate value to domain-search-and-result input
      $('.domain-search-and-result-component input').val(this.get('value'));
    } else {
      const timeout = this.get('isMobile') ? 500 : 150;
      cancel(this.get('typeDelayTimer'));
      this.set(
        'typeDelayTimer',
        later(() => {
          this._resetErrors();
          this.set('value', this._processValue(value));
        }, timeout)
      );
    }

    if (isEmpty(value)) {
      this._scrollTop(() => {});
    }
  },
  actions: {
    search(e) {
      e?.preventDefault();
      if (this.get('resetScroll')) {
        this._scrollTop();
      }

      tryInvoke(this, 'onSearch');

      if (!this.haveResults) {
        const path = 'home';
        this.router.transitionTo(path, {
          queryParams: { name: this.get('value') }
        });
      }
      return false;
    },
    processInput(value) {
      this._processInput(value);
    },
    remove() {
      this._scrollTop(() => {
        this.send('resetErrors');
        this.setProperties({ value: '' });
        this.$('input').val('').focus();
      });
    },
    resetErrors() {
      this._resetErrors();
    }
  },
  _processValue(value = '') {
    value = this.stripAccent(value.toLowerCase());
    let withoutLettersAndNumbers = value.replace(/[^a-z0-9.-]+/g, '');

    if (value !== withoutLettersAndNumbers) {
      value = withoutLettersAndNumbers;
      this.set('errors.onlyLettersAndNumbers', true);
    }

    let withoutDashes = value.replace(/^-/g, '');

    if (value !== withoutDashes) {
      value = withoutDashes;
      this.set('errors.aroundDashes', true);
    }

    let withoutTwoDots = value.replace(/\.\./g, '.');

    if (value !== withoutTwoDots) {
      value = withoutTwoDots;
      this.set('errors.twoDots', true);
    }

    let withoutTwoDashes = value.replace(/--/g, '-');

    if (value !== withoutTwoDashes) {
      value = withoutTwoDashes;
      this.set('errors.twoDashes', true);
    }
    return value;
  },
  _scrollTop(callback = () => {}) {
    $('html,body').animate({ scrollTop: 0 }, 'fast', callback);
  },
  _resetErrors() {
    for (let error in this.get('errors')) {
      this.set(`errors.${error}`, false);
    }
  }
});
