import { getOwner } from '@ember/application';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Logger from '../utils/logger';

export default Component.extend({
  modal: service(),
  _clickHandler: null,
  componentExists(componentName) {
    const owner = getOwner(this);
    const lookup = owner.lookup('component-lookup:main');
    if (!lookup.componentFor) {
      return !!lookup.lookupFactory(componentName);
    }
    return !!(
      lookup.componentFor(componentName, owner) ||
      lookup.layoutFor(componentName, owner)
    );
  },
  didInsertElement() {
    this._super(...arguments);
    this.set('_clickHandler', e => {
      e.preventDefault();
      const $el = this.$(e.target);
      const modalName = $el.attr('href').substr(1);
      const headerText = $el.attr('title');
      if (modalName) {
        if (this.componentExists(`info/${modalName}`)) {
          this.modal.show(`info/${modalName}`, null, {
            header: headerText,
            modalClasses: 'info'
          });
        } else {
          Logger.error(`Component ${modalName} doesn't exists`);
        }
      }
    });
    this.$().on('click', '.inline-action', this.get('_clickHandler'));
  },
  willDestroyElement() {
    this._super(...arguments);
    this.$().off('click', '.inline-action', this.get('_clickHandler'));
  }
});
