import { isEmpty } from '@ember/utils';
import { filterBy, notEmpty } from '@ember/object/computed';
import EmberObject, { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import regex from '../utils/regex';

export default Service.extend({
  intl: service(),
  ideas: service(),
  exceptions: null,
  errorHeader: null,
  errors: filterBy('exceptions', 'hasError'),
  hasErrors: notEmpty('errors'),
  hasNameError: filterBy('errors', 'type', 'oneName'),
  errorMessages: computed('errors.[]', function () {
    return this.get('errors')
      .map(error => {
        return this.get('intl').t(
          `tld_exceptions.errors.${error.get('messageKey')}`
        );
      })
      .join(', ');
  }),
  init() {
    this._super(...arguments);
    const data = [
      EmberObject.create({ type: 'eu', hasError: false, messageKey: 'eu' }),
      EmberObject.create({
        type: 'vatNumber',
        hasError: false,
        messageKey: 'vatNumber'
      }),
      EmberObject.create({
        type: 'identityCardNumber',
        hasError: false,
        messageKey: 'identityCardNumber'
      }),
      EmberObject.create({ type: 'doc', hasError: false, messageKey: 'doc' }),
      EmberObject.create({
        type: 'xxxId',
        hasError: false,
        messageKey: 'xxxId'
      }),
      EmberObject.create({
        type: 'xxxActive',
        hasError: false,
        messageKey: 'xxxActive'
      }),
      EmberObject.create({
        type: 'oneName',
        hasError: false,
        messageKey: 'oneName'
      }),
      EmberObject.create({
        type: 'https',
        hasError: false,
        messageKey: 'httpsNeeded'
      })
    ];
    this.set('exceptions', data);
  },
  exceptionAttrOp: null,
  exceptionDocShow: false,
  exceptionsCheck(domains, contact) {
    let error = false;

    if (domains && contact) {
      domains.forEach(domain => {
        // countries check
        let tldAllowedCountries = domain.get('tldObject.contactCountries');

        if (!isEmpty(tldAllowedCountries)) {
          if (!tldAllowedCountries.includes(contact.get('countryId'))) {
            this.get('exceptions').findBy('type', 'eu').set('hasError', true);
            error = true;
          }
        }
      });
    }

    if (
      this.get('ideas.addressbook') === 'owner' &&
      contact &&
      contact.get('company')
        ? false
        : (contact.get('name') || '').search(regex.FULL_NAME_REGEX) === -1
    ) {
      this.get('exceptions').findBy('type', 'oneName').set('hasError', true);
      error = true;
    }

    return error;
  },
  requiredAttributesCheck(domains, order, contact, edit) {
    let error = false;
    let attrName;

    domains.forEach(domain => {
      attrName = contact.get('company')
        ? domain.get('tldObject.attrCompany')
        : domain.get('tldObject.attrPerson');

      switch (attrName) {
        case 'identity_card': {
          if (!order.get('identityCardNumber') || edit) {
            this.get('exceptions')
              .findBy('type', 'identityCardNumber')
              .set('hasError', true);
            error = true;
          }
          break;
        }
        case 'dic': {
          let tld = domain.get('tldName');
          if (
            (tld === 'dk' || tld === 'nu' || tld === 'hk') &&
            contact.get('dic')
          ) {
            order.set('vatNumber', contact.get('dic'));
          }
          if (!order.get('vatNumber') || edit) {
            this.get('exceptions')
              .findBy('type', 'vatNumber')
              .set('hasError', true);
            error = true;
          }
          break;
        }
        case 'xxx_id': {
          if (!order.get('xxxId') || isEmpty(order.get('xxxActive')) || edit) {
            this.get('exceptions')
              .findBy('type', 'xxxId')
              .set('hasError', true);
            this.get('exceptions')
              .findBy('type', 'xxxActive')
              .set('hasError', true);
            error = true;
          }
          break;
        }
      }
    });

    return error;
  },
  requiredDocCheck(domains, order, company) {
    let error = false;
    let attrName;

    domains.forEach(domain => {
      attrName = company
        ? domain.get('tldObject.docCompany')
        : domain.get('tldObject.docPerson');

      switch (attrName) {
        case 'ireland_relation':
        case 'identity_doc':
        case 'business_doc':
          if (!order.get('docUrl')) {
            this.get('exceptions').findBy('type', 'doc').set('hasError', true);
            error = true;
            // FIXME: nastavime ze byla zobrazena hlaska, tohle je potreba jinak
            order.set('docUrl', 'http://');
          }
          break;
      }
    });

    return error;
  },
  httpsCheck(domains, order) {
    let error = false;
    domains.forEach(domain => {
      if (domain.get('tldObject.httpsOnly') && !order.get('httpsCheck')) {
        this.get('exceptions').findBy('type', 'https').set('hasError', true);
        order.set('httpsCheck', true);
        error = true;
      }
    });
    return error;
  },
  errorReset() {
    this.get('exceptions').forEach(exception => {
      exception.set('hasError', false);
    });
  },
  checkAll(domains, contact, order, edit = false) {
    if (!contact || !order) {
      return true;
    }

    this.errorReset();

    // country check
    let checkResultError = this.exceptionsCheck(domains, contact);
    // required attr check
    let attrCheckError = this.requiredAttributesCheck(
      domains,
      order,
      contact,
      edit
    );
    // required document check
    let attrDocError = this.requiredDocCheck(
      domains,
      order,
      contact.get('company')
    );
    // https check
    const httpsCheckError = this.httpsCheck(domains, order);

    if (
      !checkResultError &&
      !attrCheckError &&
      !attrDocError &&
      !httpsCheckError
    ) {
      return true;
    }

    return false;
  }
});
