import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['summary-domain-line'],
  insurance: computed('domainObject.price', function () {
    return this.get('domainObject.insurance')
      ? this.get('domainObject.price.safetyGuard')
      : 0;
  })
});
