import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';

export default Component.extend({
  classNames: ['input-search'],
  classNameBindings: ['small:input-search-small'],
  value: '',
  placeholder: '',
  small: false,
  focus: false,
  actions: {
    focusIn() {
      this.set('focus', true);
    },
    focusOut() {
      this.set('focus', false);
    },
    search() {
      tryInvoke(this, 'action', [this.get('value')]);
    },
    processInput(value) {
      if (
        value.length > 0 &&
        value.length < 3 &&
        !this.get('deviceDetection.isDesktop')
      ) {
        this.set('showErrors', true);
      }
    },
    remove() {
      this.set('value', '');
      tryInvoke(this, 'remove');
    }
  }
});
