import DS from 'ember-data';
import CartItem from './cart-item';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const validations = buildValidations({
  authCode: validator('format', {
    // IPAS ji nepotrebuje staci jen text
    regex: /^[_a-zA-Z0-9]{4}[-][a-zA-Z0-9]{4}[-][a-zA-Z0-9]{4}[-][a-zA-Z0-9]{4}/,
    messageKey: 'validations.password_for_transfer',
    disabled: computed('model.tld', function () {
      return this.get('model.tld') !== 'eu';
    })
  })
});

export default CartItem.extend(validations, {
  currentUser: service(),
  tld: DS.attr('string'),
  authCode: DS.attr('string'),
  nameServerNames: DS.attr(),
  setDefaults() {
    if (!this.get('quantity')) {
      this.set('quantity', this.get('defaultQuantity'));
    }
  },
  _priceObject: computed('currentUser.loadedData.prices', function () {
    // wait for prices
    if (this.get('currentUser.loadedData.prices')) {
      return this.store.peekRecord('price', this.get('tld'));
    }
  }),
  tldObject: computed('tld', function () {
    return this.get('store').peekRecord('tld', this.get('tld'));
  }),
  defaultQuantity: computed('_priceObject', function () {
    const regObject = this.get('_priceObject.transfer');
    if (!regObject) {
      return;
    }
    return Math.min(...Object.keys(regObject).map(item => parseInt(item, 10)));
  }),
  domainName: alias('name'),
  fullName: computed('domainName', 'tld', function () {
    return `${this.get('domainName')}.${this.get('tld')}`;
  }),
  itemPrice: computed(
    '_priceObject.transfer',
    'defaultQuantity',
    'years',
    'basketPrice',
    function () {
      if (!this.get('tldObject.isTransferPayed')) {
        return 0;
      }

      if (this.get('basketPrice') === 0)
        return parseFloat(this.get('basketPrice'));

      return parseFloat(
        this.get('basketPrice') ||
          this.get(
            `_priceObject.transfer.${
              this.get('years') || this.get('defaultQuantity')
            }`
          )
      );
    }
  ),
  years: alias('quantity'),
  displayName: alias('fullName'),
  attrs() {
    return {
      ...this._super(),
      name: `${this.name}.${this.tld}`,
      pack: 'Transfer',
      type: 'transfer',
      auth_code: this.authCode
    };
  }
});
