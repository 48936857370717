import { alias, mapBy } from '@ember/object/computed';
import { camelize, dasherize } from '@ember/string';

import DS from 'ember-data';
import ENV from 'domena-mobile/config/environment';
import Logger from '../utils/logger';
import SERVICE_CONFIG from '../utils/service-config';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

const TYPES = ['email', 'hosting', 'safetyGuard'];

export default DS.Model.extend({
  currentUser: service(),
  key: DS.attr('string'),
  name: DS.attr('string'),
  type: DS.attr('string'), // types email, registration, renewal, safety-guard, ssl-cert, transfer
  quantity: DS.attr('number', { defaultValue: 12 }),
  parentId: DS.attr('string'),
  parentType: DS.attr('string'),
  createdAt: DS.attr('date'),
  orderId: DS.attr('string'),
  orderError: DS.attr(), // array of codes
  source: DS.attr('string', { defaultValue: 'domena' }),
  tld: DS.attr('string'),
  _orderObject: null,
  _priceObject: computed('currentUser.loadedData.prices', function () {
    // wait for prices
    if (this.get('currentUser.loadedData.prices')) {
      return this.store.peekRecord('price', this.get('tld'));
    }
  }),
  orderObject: computed('orderId', '_orderObject', function () {
    const orderObject = this.get('_orderObject');
    const orderId = this.get('orderId');
    if (!orderId) {
      return;
    }

    if (orderObject) {
      return orderObject;
    }

    // TODO-FIX až bude fungovat show na order tak odkomentovat
    // this.store.findRecord(this.convertKeyToClassName(), this.get('orderId')).then(order => {
    //   this.set('_orderObject', order);
    // });
  }),
  isPersisted: computed('orderObject.isNew', function () {
    return (
      typeof this.get('orderObject') !== 'undefined' ||
      this.get('orderObject.isNew') === false
    );
  }),
  extra: DS.attr(),
  period: alias('quantity'),
  price: 0,
  totalPrice: alias('price'),
  fullName: alias('name'),
  setDefaults() {},
  _relatedObjects: null,
  relatedPrice: computed('related.@each.itemPrice', function () {
    const related = this.get('related');
    return related.get('length')
      ? related.reduce((sum, item) => sum + item.get('itemPrice'), 0)
      : 0;
  }),
  relatedTypes: mapBy('related', 'type'),
  related: computed('_relatedObjects.length', function () {
    const related = this.get('_relatedObjects');
    if (isPresent(related)) {
      return related;
    }
    TYPES.forEach(type => {
      Logger.log(`loading ${this.get('id')}#${type} related cart item`, type);
      const records = this.store.peekAll(`${dasherize(type)}-cart-item`);
      this.get('_relatedObjects').addObjects(
        records.filterBy('parentId', this.get('id'))
      );
    });
    return [];
  }),
  displayName: alias('fullName'),
  _didCreate() {
    const { parentType, parentId } = this.getProperties(
      'parentType',
      'parentId'
    );
    if (parentId) {
      Logger.log(
        `adding item to related pId:${parentId} pType:${parentType} tType:${this.get(
          'type'
        )}`
      );
      const record = this.store.peekRecord(`${parentType}-cart-item`, parentId);
      record && record.get('_relatedObjects').addObject(this);
    }
  },
  _didDelete() {
    const { parentType, parentId } = this.getProperties(
      'parentType',
      'parentId'
    );
    if (parentId) {
      Logger.log(
        `removing item from related pId:${parentId} pType:${parentType} tType:${this.get(
          'type'
        )}`
      );
      const record = this.store.peekRecord(`${parentType}-cart-item`, parentId);
      record && record.get('_relatedObjects').removeObject(this);
    }
  },
  init() {
    this._super(...arguments);
    this.set('_relatedObjects', []);
  },
  convertKeyToClassName() {
    const _map = {
      registration: 'domain-order',
      email: 'mail-hosting-order',
      safetyGuard: 'safety-guard-order',
      renewal: 'renewal-order',
      transfer: 'transfer-order'
    };
    return _map[this.get('type')] || `${dasherize(this.get('type'))}-order`;
  },
  attrs(packId) {
    const packCofig = SERVICE_CONFIG[ENV.LOCALE][packId];
    const extraParams = {
      id: this.id,
      parentId: this.parentId,
      parentType: this.parentType,
      cartItemType: this.constructor.modelName,
      tld: this.get('tld'),
      key: this.key,
      extra: this.extra
    };

    return {
      period: parseInt(this.quantity, 10),
      params: Object.keys(extraParams).map(key => ({
        name: key,
        value: extraParams[key] || null
      })),
      type: packCofig ? packCofig.type : 'extra'
    };
  },
  parseAttrs() {
    const data = this.toJSON();
    data.id = this.id;
    data.type = this.type ? camelize(this.type) : undefined;
    return data;
  },

  // WY aatrs
  basketInfo: DS.attr(),
  basketPrice: alias('basketInfo.price.final')
});
