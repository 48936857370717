import { isEmpty, isPresent, tryInvoke } from '@ember/utils';
import Component from '@ember/component';
import Common from 'domena-mobile/mixins/common';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend(Common, {
  ideas: service(),
  store: service(),
  tagName: 'form',
  classNames: ['contact-select-form'],
  model: null,
  contactAttrName: alias('options.contactAttrName'),
  selected: alias('model'),
  selectedTemp: null,
  search: '',
  listPage: 1,
  justCreatedContact: null,
  contacts: null,
  expandAll: false,
  contactsValid: computed(
    'contacts.[]',
    'ideas.notDeletedItems.[]',
    'justCreatedContact',
    function () {
      // TODO-FIX: ideas již neexistuje
      const contacts = this.get('contacts')
        .filter(contact => {
          return (
            !contact.get('isNew') &&
            this.isContactValidComplex(
              contact,
              this.get('ideas.notDeletedItems')
            )
          );
        })
        .sortBy('displayName');

      const justCreatedContact = this.get('justCreatedContact');
      if (isPresent(justCreatedContact)) {
        contacts.removeObject(justCreatedContact);
        contacts.unshiftObject(justCreatedContact);
      }
      return contacts;
    }
  ),
  contactsFiltered: computed('search', 'contactsValid.[]', function () {
    if (this.get('search.length') === 0) {
      return this.get('contactsValid');
    }

    return this.get('contactsValid').filter(item => {
      const attributes = [
        'name',
        'companyName',
        'emails',
        'phone',
        'ic',
        'dic',
        'street',
        'city',
        'country',
        'rrIdsValues'
      ];

      const properites = item.getProperties(attributes),
        keys = attributes
          .map(i => properites[i])
          .join(';')
          .toLowerCase(),
        search = this.get('search').toLowerCase();

      return this.stripAccent(keys).indexOf(this.stripAccent(search)) !== -1;
    });
  }),
  hasErrors: computed('search', 'contactsFiltered.[]', function () {
    return (
      this.get('search.length') !== 0 && isEmpty(this.get('contactsFiltered'))
    );
  }),
  loadData() {
    let contacts = this.get('store').peekAll('contact');
    if (this.isInvoice) {
      contacts = this.get('store').peekAll('billingProfile');
    }
    this.set('contacts', contacts);
  },
  init() {
    this._super(...arguments);
    if (!this.get('contacts')) {
      this.loadData();
    }
  },
  didReceiveAttrs() {
    if (this.get('contactAttrName')) {
      this.set(
        'selectedTemp',
        this.get(`selected.${this.get('contactAttrName')}`)
      );
    }
  },
  actions: {
    searchChange() {
      this.set('listPage', 1);
    },
    confirm() {
      if (this.get('contactAttrName')) {
        this.set(
          `selected.${this.get('contactAttrName')}`,
          this.get('selectedTemp')
        );
      }
      tryInvoke(this, 'close');
      tryInvoke(this, 'confirm', [this.get('selectedTemp')]);
    }
  }
});
