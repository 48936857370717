import WyApiAdapter from './wy-api';
import SingletonModelAdapterMixin from '../mixins/singleton-model-adapter';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

const addDefaultParams = (url, currency) => {
  try {
    const outputUrl = new URL(url);
    if (currency) {
      outputUrl.searchParams.append('currency', currency);
    }
    return outputUrl.toString();
  } catch (e) {
    return url;
  }
};

export default WyApiAdapter.extend(SingletonModelAdapterMixin, {
  currentUser: service(),
  namespace: 'order',
  pathForType(type) {
    return camelize(type);
  },
  buildURL() {
    const url = this._super(...arguments);
    return addDefaultParams(url, this.get('currentUser.currency'));
  }
});
