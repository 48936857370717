import { scheduleOnce } from '@ember/runloop';
import { hash } from 'rsvp';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import Common from '../mixins/common';
import Route from '@ember/routing/route';

export default Route.extend(Common, {
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/cart'
      }
    }
  ]),
  session: service(),
  cart: service(),
  model() {
    return hash({
      countries: this.store.peekAll('country'),
      paymentMethods: this.store.peekAll('paymentMethod'),
      mailHostingPlan: this.store.peekAll('mailHostingPlan')
    });
  },
  setupController(controller) {
    if (!this.get('session.isAuthenticated')) {
      scheduleOnce('afterRender', () => {
        controller.set('page', this.PAGE.CART);
      });
    }
    this._super(...arguments);
    controller.setProperties({
      mailHostingType: this.controllerFor('application').get(
        'mail-hosting-type'
      ),
      agreeWithTerms: true,
      agreeErrors: false,
      registrationRulesConsent: false
    });

    controller.get('navDots').reset();
    // add extra services to cart
    this.cart.addExtraServices();
  },
  resetController(controller) {
    controller.setProperties({
      addressbook: 'owner',
      lastCreatedContact: null,
      showErrors: false
    });
  },
  actions: {
    willTransition() {
      this.controller.set('orderLoading', false);
      this.controllerFor('application').set('mail-hosting-type', undefined);
      if (!$) {
        return;
      }
      scheduleOnce('afterRender', () => {
        $('body, html').removeClass('no-mobile-submenu');
      });
    },
    didTransition() {
      if (!$) {
        return;
      }
      scheduleOnce('afterRender', () => {
        $('body, html').addClass('no-mobile-submenu');
      });
      $(window).scrollTop(0);
    }
  }
});
