import { Promise as EmberPromise, reject } from 'rsvp';
import fetch from 'fetch';

class DataError extends Error {
  constructor(message, code, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DataError);
    }

    this.name = 'DataError';
    // Custom debugging information
    this.message = `${code}: ${message}`;
    this.body = message;
    this.code = code;
  }
}

export default function fetchAjax(url, options) {
  if (typeof window === 'undefined' || typeof fetch === 'undefined') {
    return reject();
  }
  return new EmberPromise((resolve, reject) => {
    fetch(url, options).then(response => {
      if (response.ok) {
        if (response.status === 204) {
          return resolve(response);
        }
        response.json().then(data => {
          resolve(data);
        });
      } else {
        response.text().then(body => {
          reject(new DataError(body, response.status));
        }, reject);
      }
    }, reject);
  });
}
