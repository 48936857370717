import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function markdown([text], { block = false }) {
  const markdownIt = window.markdownit;
  const markdownItAttrs = window.markdownItAttrs;
  const markdownItInstance = markdownIt({
    breaks: true,
    quotes: '“”‘’',
    linkify: false
  });

  markdownItInstance.use(markdownItAttrs, {
    // optional, these are default options
    leftDelimiter: '{',
    rightDelimiter: '}',
    allowedAttributes: ['id', 'class', 'tooltip-label']
  });
  return htmlSafe(markdownItInstance[block ? 'render' : 'renderInline'](text));
}

export default helper(markdown);
