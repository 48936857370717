import { scheduleOnce } from '@ember/runloop';
import { computed } from '@ember/object';
import { tryInvoke } from '@ember/utils';
import Component from '@ember/component';

export default Component.extend({
  classNameBindings: ['noValidate'],
  classNames: ['form-group'],
  disabled: false,
  name: '',
  validationName: null,
  noLowerCase: false,
  noValidate: false,
  placeholder: null,
  showError: false,
  showErrors: false,
  showServerErrors: true,
  showState: true,
  toLowerCase: false,
  type: 'text',
  value: null,
  hasErrors: computed(
    'value',
    'showErrors',
    'showError',
    'name',
    'validationName',
    function () {
      return (
        (this.get('showErrors') || this.get('showError')) &&
        this.get(
          `model.validations.attrs.${
            this.get('validationName') || this.get('name')
          }.isInvalid`
        )
      );
    }
  ),
  inputClass: computed('hasErrors', 'focused', function () {
    let errors = this.get('hasErrors') ? 'errors' : '';
    let present = this.get('value') ? 'present' : '';
    let focused = this.get('focused') ? 'focused' : '';

    return [errors, present, focused].join(' ');
  }),
  wrapperClass: computed(
    'focused',
    'hasErrors',
    'showError',
    'value',
    'noValidate',
    'showState',
    function () {
      if (!this.get('showState')) {
        return '';
      }

      return [
        this.get('hasErrors')
          ? 'wrapper-error'
          : this.get('value') && this.get('showError')
          ? 'wrapper-valid'
          : '',
        this.get('focused') ? 'focused' : ''
      ].join(' ');
    }
  ),
  init() {
    this._super(...arguments);

    scheduleOnce('afterRender', () => {
      if (this.get('showErrors')) {
        this.$('input').trigger('focusout');
      }
    });
  },
  focusIn() {
    this.set('focused', true);
  },
  focusOut() {
    this.set('showError', true);
    this.set('focused', false);
    tryInvoke(this, 'focus-out', [this.get('value')]);
  },
  actions: {
    processInput() {
      if (this.get('toLowerCase')) {
        this.set('value', this.get('value').toLowerCase());
        return;
      }

      if (this.get('type') === 'email' && !this.get('noLowerCase')) {
        this.set('value', this.get('value').toLowerCase());
      }
    }
  }
});
