import CartItem from './cart-item';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import domainParser from 'domena-mobile/utils/domain-parser';

export default CartItem.extend({
  months: alias('quantity'),
  availablePeriods: alias('yolaPlan.supportedPeriods'),
  yolaPlan: computed('extra.yolaType', function () {
    return this.store.peekRecord('yolaPlan', this.get('extra.yolaType'));
  }),
  itemPrice: computed(
    'hostingPlan.customerPrices',
    'quantity',
    'basketPrice',
    function () {
      if (this.get('basketPrice') === 0)
        return parseFloat(this.get('basketPrice'));

      return parseFloat(
        this.get('basketPrice') ||
          this.get(`hostingPlan.customerPrices.${this.quantity}`),
        10
      );
    }
  ),
  domainName: computed('name', function () {
    const parsedDomain = domainParser(
      this.get('name'),
      this.store.peekAll('tld')
    );
    return parsedDomain.domain || this.get('name');
  }),
  selectedPeriod: alias('quantity'),
  attrs() {
    const attributes = {
      ...this._super(this.extra.yolaType),
      name: this.name,
      pack: this.extra.yolaType
    };

    attributes.params.push({
      name: 'OP_yola_import',
      value: this.extra.yolaImport
    });

    return attributes;
  }
});
