import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';
import { trySet } from '@ember/object';

export default Component.extend({
  actions: {
    setDomains(value) {
      trySet(this, 'selectedDomainCount', value);
      tryInvoke(this, 'changed', [this.selectedDomainCount]);
    }
  }
});
