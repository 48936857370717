import { computed } from '@ember/object';
import Component from '@ember/component';
import Common from '../mixins/common';
import { tryInvoke } from '@ember/utils';

export default Component.extend(Common, {
  classNames: ['selection-numbers'],
  classNameBindings: ['single'],
  value: 1,
  min: 1,
  max: 1000000,
  data: null,
  dataIndex: 0,
  dataIndexInit() {
    let data = this.get('data');
    if (this.isArray(data)) {
      this.set('min', data[0]);
      this.set('max', data[data.length - 1]);
      const value = parseInt(this.get('value'), 10);
      this.set('dataIndex', data.indexOf(value === -1 ? 0 : value));
    }
  },
  text: '',
  single: computed('data', function () {
    return this.get('data.length') === 1;
  }),
  didInsertElement() {
    this.dataIndexInit();
  },
  didUpdateAttrs() {
    this.dataIndexInit();
  },
  actions: {
    increment() {
      let data = this.get('data');
      if (this.isArray(data)) {
        if (this.get('dataIndex') < data.length - 1) {
          this.incrementProperty('dataIndex');
          this.set('value', data[this.get('dataIndex')]);
        }
      } else if (this.get('value') < this.get('max')) {
        this.incrementProperty('value');
      }
      tryInvoke(this, 'changed', [this.value]);
    },
    decrement() {
      let data = this.get('data');
      if (this.isArray(data)) {
        if (this.get('dataIndex') > 0) {
          this.decrementProperty('dataIndex');
          this.set('value', data[this.get('dataIndex')]);
        }
      } else if (this.get('value') > this.get('min')) {
        this.decrementProperty('value');
      }
      tryInvoke(this, 'changed', [this.value]);
    }
  }
});
