import { helper } from '@ember/component/helper';

export function range(params, { length, start = 1 }) {
  const outArray = [];
  for (let index = start; index < length + 1; index++) {
    outArray.push(index);
  }
  return outArray;
}

export default helper(range);
