import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { inject as service } from '@ember/service';

export default Component.extend({
  cms: service(),
  classNames: ['landing-page__block', 'landing-page__hero'],
  slugKey: computed('datoSlug', function () {
    return this.datoSlug + '.' + this.datoSlug;
  }),
  topOffset: true,
  titleTextColor: alias('cms.currentPageData.heroTitleColor.cssRgb'),
  subTitleTextColor: alias('cms.currentPageData.heroSubtitleColor.cssRgb'),
  backgroundColor: alias('cms.currentPageData.heroBackgroundColor.cssRgb'),
  styleTag: computed(
    'titleTextColor',
    'subTitleTextColor',
    'backgroundColor',
    function () {
      // Get the dynamic values
      let bgColor = this.backgroundColor;
      let subTitleColor = this.subTitleTextColor;
      let titleColor = this.titleTextColor;

      // Construct the CSS
      let styleString = `
      .landing-page__hero {
        background: ${bgColor} !important;
      }

      .landing-page__hero h1 {
        color: ${titleColor} !important;
      }

      .subheadline-2--medium {
        color: ${subTitleColor} !important;
      }
    `;

      // Return the HTML safe string
      return htmlSafe(styleString);
    }
  )
});
