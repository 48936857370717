import { alias, filter, mapBy } from '@ember/object/computed';

import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Controller.extend({
  cart: service(),
  currentUser: service(),
  invoice: alias('model.invoice'),
  orders: filter('invoice.orders', function (order) {
    return order.get('hidden') !== true;
  }),
  adminEmail: alias('invoice.mailHostingOrders.firstObject.adminEmail'),
  mailHosting: computed(
    'invoice.mailHostingOrders.@each.domainServiceType',
    function () {
      const ALLOWED_TYPES = [
        'IGN_FlexiMail',
        'ExchangeOnline',
        'Microsoft365_B_Basic',
        'Microsoft365_B_Standard'
      ];

      const orders = this.get('invoice.mailHostingOrders') || [];
      return orders
        .mapBy('domainServiceType')
        .map(
          type =>
            ALLOWED_TYPES.includes(type) &&
            `${this.intl.t(`cms.produkty.${dasherize(type)}.title`)}`
        )
        .filter(Boolean)
        .join(' a ');
    }
  ),
  confirmationEmail: computed(
    'currentUser.email',
    'invoice.domainOrders.[]',
    function () {
      if (!this.get('invoice')) {
        return '';
      }

      let out = '';

      this.get('invoice.domainOrders')
        .compact()
        .forEach(domain => {
          let tld = this.store.peekRecord('tld', domain.get('tldName'));

          if (isPresent(tld.get('confirmBy'))) {
            out = this.get('currentUser.email');
          }
        });

      return out;
    }
  ),
  itemTldNames: mapBy('invoice.domainOrders', 'tldName'),
  suggestedName: computed(
    'invoice.{domainOrders,mailHostingOrders}',
    function () {
      let orders = this.get('invoice.domainOrders') || [];
      orders = orders.toArray();
      const mailOrders = this.get('invoice.mailHostingOrders') || [];
      orders.pushObjects(mailOrders.toArray());
      const domains = orders.sortBy('id');
      return (
        domains.get('lastObject.onlyDomainName') ||
        domains.get('lastObject.name')
      );
    }
  )
});
