import EmberRouter from '@ember/routing/router';
import config from './config/environment';
import { inject as service } from '@ember/service';

const defaultTwitterAccount = '@ignum';
const enableRoutes = false;

const Router = EmberRouter.extend({
  events: service(),
  currentUser: service(),
  location: config.locationType,
  rootURL: config.rootURL,
  cms: service(),
  router: service(),
  headData: service(),
  enableRoutes,
  setTitle(title) {
    // pokud se bude odinstalovavat documentTitle plugin je potreba prepsat
    let seoTags = this.cms.getSeoTags(this.router.currentRouteName);
    if (seoTags && seoTags.length) {
      const headTags = this.get('headData.headTags') || [];
      const globalSeo = this.cms.globalSeo || { fallbackSeo: {} };
      const faviconMetaTags = this.cms.faviconMetaTags;
      let canonicalTag = headTags.find(
        item =>
          item.type === 'link' &&
          item.attrs &&
          item.attrs.rel === 'canonical' &&
          item.attrs.href
      );
      if (canonicalTag) {
        seoTags.unshift({
          type: 'meta',
          attrs: {
            property: 'og:url',
            content: canonicalTag.attrs.href
          }
        });
      }
      this.set('headData.headTags', [
        ...headTags,
        ...seoTags,
        {
          type: 'meta',
          attrs: {
            name: 'twitter:creator',
            content: globalSeo.twitterAccount || defaultTwitterAccount
          }
        },
        ...faviconMetaTags
      ]);
    }

    this.get('headData.headTags') &&
      this.get('headData.headTags').push({
        type: 'title',
        content: title
      });
    this._super(...arguments);
  }
});

Router.map(function () {
  this.route('branding');
  this.route('cart');
  this.route('contact');
  this.route('home', { path: '/' });
  this.route('order-all-fail');
  this.route('order-fail');
  this.route('password-reset');
  this.route('payment-fail');
  this.route('payment-method');
  this.route('payment');
  this.route('signin');
  this.route('thanks');
  this.route('tips');
  this.route('email-k-domene');
  this.route('dictionary', { path: '/slovnik-pojmu' });
  this.route('redirect');
  this.route('error-page', { path: '*path' });
  this.route('stylesheet');
  this.route('microsoft-365');
  this.route('registration-rules-consent');
  this.route('hosting');
  this.route('ssl-certificates', { path: '/ssl-certifikaty' });
  this.route('search-redirect');
  this.route('password-init', { path: '/firstpasswordset' });
  this.route('signup');
  this.route('terms-and-conditions');
  this.route('price-list', { path: '/ceniky' });
  // this.route('price-list', {
  //   path: config.LOCALE === 'cs' ? '/ceniky' : '/cennik'
  // });
  this.route('about-us', { path: '/o-nas' });
  this.route('domains', { path: '/domeny' });
  this.route('application-error');
  this.route('payment-check');
  this.route('yola', { path: '/webeditor' });
  this.route('webeditor-upgrade');
  this.route('wordpress-hosting');
});

export default Router;
