import EmberObject from '@ember/object';
import Logger from './logger';
import { assign } from '@ember/polyfills';
import { dasherize } from '@ember/string';
import domainParser from './domain-parser';

export default EmberObject.extend({
  isEnabled: false,
  dataLayerObject: null,
  defaultTransactionProps: Object({
    // 'transactionId': '1234',
    transactionAffiliation: 'Ignum',
    // 'transactionTotal': 1034,
    // 'transactionTax': 35,
    // 'transactionShipping': 135,
    event: 'trackTrans',
    transactionProducts: []
  }),
  defaultItemsProps: Object({
    // 'itemCategory': 'Oděvy',
    // 'itemId': 'DD44',
    // 'itemName': 'Tričko',
    // 'price': 324,
    // 'quantity': 1
  }),
  isAwailable() {
    return typeof window.ga === 'function';
  },
  init() {
    if (this.isAwailable()) {
      window.dataLayer = window.dataLayer || [];
      this.set('isEnabled', true);
      this.set('dataLayerObject', EmberObject.create({}));
      Logger.log('e-commerce enabled');
    }
  },
  addTransaction(data) {
    Logger.log('adding e-commerce transaction', data);
    if (this.isAwailable() && this.get('isEnabled')) {
      assign(data, this.defaultTransactionProps, data);
      this.set('dataLayerObject', JSON.parse(JSON.stringify(data)));
      Logger.log('e-commerce transaction added', data);
    }
  },
  addItem(data) {
    Logger.log('adding e-commerce item', data);
    if (this.isAwailable() && this.get('isEnabled')) {
      assign(data, this.defaultItemsProps, data);
      this.get('dataLayerObject.transactionProducts').pushObject(data);
      Logger.log('e-commerce item added', data);
    }
  },
  commit() {
    if (this.isAwailable() && this.get('isEnabled')) {
      const data = this.get('dataLayerObject');
      const itemsSum = data.transactionProducts.reduce((sum, item) => {
        return sum + item.unitPrice * item.unitQuantity;
      }, 0);
      const vat = data.transactionTotal - itemsSum;
      data['transactionTax'] = vat;
      window.dataLayer.push(
        JSON.parse(JSON.stringify(this.get('dataLayerObject')))
      );
      this.set('dataLayerObject', EmberObject.create({}));
      Logger.log('e-commerce sent', window && window.location.href);
    }
  },
  sendInvoice(invoice, intl, savedOrderItems) {
    this.addTransaction({
      transactionId: invoice.get('id'),
      transactionTotal: invoice.get('totalPrice')
    });

    invoice.get('orders').forEach((order, index) => {
      if (
        order.get('modelName') === 'domain' ||
        order.get('modelName') === 'renewal' ||
        order.get('modelName') === 'transfer-order'
      ) {
        let price =
          order.get('price') -
          (order.get('safetyGuard') ? order.get('safetyGuardPrice') || 0 : 0);
        this.addItem({
          itemCategory: intl.t('ecommerce.registration').toString(),
          itemId: order.get('id'),
          itemName: order.get('tldName'),
          price: Math.round(price / order.get('years')),
          quantity: order.get('quantity')
        });

        if (order.get('safetyGuard')) {
          this.addItem({
            itemCategory: intl.t('ecommerce.safety-guard').toString(),
            itemId: `${order.get('id')}_safety_guard`,
            itemName: 'Pojistka',
            price: order.get('safetyGuardPrice'),
            quantity: 1
          });
        }
      }

      if (order.get('modelName') === 'mail-hosting') {
        this.addItem({
          itemCategory: intl.t('ecommerce.mail-hosting').toString(),
          itemId: order.get('id'),
          itemName: `${intl
            .t(
              `cms.produkty.${dasherize(order.get('domainServiceType'))}.title`
            )
            .toString()}${
            order.get('capacity') ? ` ${order.get('capacity')}GB` : ''
          }`,
          licenceQuantity: order.get('users'),
          price: Math.round(order.get('price') / order.get('period')),
          quantity: 1
        });
      }

      if (order.get('modelName') === 'safety-guard') {
        this.addItem({
          itemCategory: intl.t('ecommerce.safety-guard').toString(),
          itemId: `${order.get('id')}_safety_guard`,
          itemName: 'Pojistka',
          price: order.get('price'),
          quantity: 1
        });
      }

      if (order.get('modelName') === 'ssl-cert') {
        this.addItem({
          itemCategory: intl.t('ecommerce.ssl-cert').toString(),
          itemId: `${order.get('id')}_ssl_cert`,
          itemName: intl
            .t(`cms.produkty.${dasherize(order.get('sslCertTypeId'))}.title`)
            .toString(),
          itemVariant: order.get('years'),
          price: Math.round(order.get('price') / order.get('years')),
          quantity: 1
        });
      }

      if (order.get('modelName') === 'wy') {
        // snad wy order zachova poradi items
        const savedOrder = (savedOrderItems || [])[index];
        if (!savedOrderItems) return;
        let packName = intl
          .t(`cms.produkty.${dasherize(savedOrder.pack)}.title`)
          .toString();
        if (savedOrder.pack === 'Registration') {
          const { tld: tldName } = domainParser(savedOrder.name);
          packName = tldName;
        }
        this.addItem({
          itemCategory: intl.t(`ecommerce.${savedOrder.type}`).toString(),
          itemId: `${order.get('iDitem')}_wy_order`,
          itemName: packName,
          price: order.get('price'),
          quantity: order.get('quantity')
        });
      }
    });

    this.commit();
  }
});
