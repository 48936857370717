import CartItem from './cart-item';
import DS from 'ember-data';
import SERVICE_CONFIG from '../utils/service-config';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import config from '../config/environment';
import { inject as service } from '@ember/service';

export default CartItem.extend({
  currentUser: service(),
  tld: DS.attr('string'),
  setDefaults() {
    if (!this.get('quantity')) {
      this.set('quantity', 1);
    }
  },
  _priceObject: computed('currentUser.services.[]', function () {
    const formSettings = this.store.peekRecord('formSettings', 'current');
    const domainInsurance = formSettings
      .get('packs')
      .findBy('pack', 'DOMAIN_INSURANCE');

    if (domainInsurance) {
      return {
        safetyGuard:
          parseFloat(domainInsurance.customer_price) * this.get('quantity')
      };
    }
  }),
  tldObject: computed('tld', function () {
    return this.store.peekRecord('tld', this.tld);
  }),
  domainName: alias('name'),
  itemPrice: computed('_priceObject.safetyGuard', 'basketPrice', function () {
    if (this.get('basketPrice') === 0)
      return parseFloat(this.get('basketPrice'));

    return parseFloat(
      this.get('basketPrice') || this.get('_priceObject.safetyGuard')
    );
  }),
  fullName: computed('name', 'tld', function () {
    return `${this.get('name')}.${this.get('tld')}`;
  }),
  displayName: alias('fullName'),
  attrs() {
    return {
      ...this._super(),
      extra: this.extra,
      name: `${this.name}.${this.tld}`,
      pack: 'DOMAIN_INSURANCE',
      type: SERVICE_CONFIG[config.LOCALE].DOMAIN_INSURANCE.type
    };
  }
});
