import Route from '@ember/routing/route';

const HEAD_TAGS = [
  {
    type: 'link',
    tagId: 'canonical-link',
    attrs: {
      rel: 'canonical',
      href: 'https://www.domena.cz/ssl-certifikaty'
    }
  }
];

export default Route.extend({
  headTags: HEAD_TAGS,
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  }
});
