import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  balanceBase: DS.attr('number', { defaultValue: 0 }),
  balanceBonus: DS.attr('number', { defaultValue: 0 }),
  currency: DS.attr('string', { defaultValue: 'CZK' }),
  deposit: computed('balanceBase', 'balanceBonus', function () {
    return parseFloat(this.balanceBase) + parseFloat(this.balanceBonus);
  })
});
