import { computed, observer, set } from '@ember/object';

import Common from '../mixins/common';
import Controller from '@ember/controller';
import config from '../config/environment';
import { inject as service } from '@ember/service';

const WHY_ITEMS = [
  'webova-vizitka-why-0',
  'webova-vizitka-why-1',
  'webova-vizitka-why-2',
  'webova-vizitka-why-3',
  'webova-vizitka-why-4',
  'webova-vizitka-why-5'
];
const REFERENCES_A = ['0', '2'];
const REFERENCES_B = ['1', '3'];
const SLIDE_TYPES = ['personal', 'corporate', 'event', 'website-product'];

export default Controller.extend(Common, {
  enableTips: config.LOCALE === 'cs',
  session: service(),
  whyItems: WHY_ITEMS,
  referencesA: REFERENCES_A,
  referencesB: REFERENCES_B,
  slides: SLIDE_TYPES.map(type => ({ type, isActive: type === 'corporate' })),
  activeSlideType: computed('slides.[]', function () {
    return this.get('slides').findBy('isActive', true).type;
  }),
  observer: observer('isGuidepostOpen', function () {
    if (this.get('isGuidepostOpen')) {
      this.setProperties({ loginActive: false, loginLoading: false });
    }
  }),
  isModal: false,
  loginActive: false,
  loginLoading: false,
  getActiveSlideIndex() {
    let slides = this.get('slides');
    let currentSlide = slides.findBy('isActive', true);
    return slides.indexOf(currentSlide);
  },
  actions: {
    activateSlide(slide) {
      this.get('slides').setEach('isActive', false);
      set(slide, 'isActive', true);
      this.notifyPropertyChange('slides');
    },
    slideNext() {
      let slides = this.get('slides');
      let currentIndex = this.getActiveSlideIndex();
      let nextSlide = slides.objectAt(
        slides.length - 1 === currentIndex ? 0 : currentIndex + 1
      );
      this.send('activateSlide', nextSlide);
    },
    slidePrev() {
      let slides = this.get('slides');
      let currentIndex = this.getActiveSlideIndex();
      let prevSlide = slides.objectAt(
        currentIndex === 0 ? slides.length - 1 : currentIndex - 1
      );
      this.send('activateSlide', prevSlide);
    },
    modalOpen() {
      this.set('isModal', true);
    },
    modalClose() {
      this.set('loginActive', false);
      this.set('isModal', false);
    },
    checkLogin() {
      if (!this.get('session.isAuthenticated')) {
        this.set('loginActive', true);
      }
    },
    afterLoginSuccess() {
      this.set('loginActive', false);
      this.set('isModal', false);
      // TODO after login
    },
    withNewDomain() {
      this.send('modalClose');
      this.transitionToRoute('domains');
    }
  }
});
