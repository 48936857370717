import { next } from '@ember/runloop';
import { Promise as EmberPromise, hash } from 'rsvp';
import { isEmpty, isPresent, tryInvoke } from '@ember/utils';
import { alias } from '@ember/object/computed';
import { computed, trySet } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import Common from 'domena-mobile/mixins/common';

export default Component.extend(Common, {
  store: service(),
  classNames: ['zone-file-select'],
  selected: alias('model'),
  selectedTemp: null,
  search: '',
  listPage: 1,
  filterList(items) {
    if (this.get('search.length') === 0) {
      return items;
    }

    return (items || []).filter(item => {
      let name = this.stripAccent(item.get('displayName').toLowerCase()),
        search = this.stripAccent(this.get('search').toLowerCase());

      return name.indexOf(search) !== -1;
    });
  },
  zones: null,
  zonesFiltered: computed('search', 'zones.[]', function () {
    return this.filterList(this.get('zones'));
  }),
  zonesLoaded: false,
  zonesTemplates: null,
  zonesTemplatesFiltered: computed('search', 'zonesTemplates.[]', function () {
    return this.filterList(this.get('zonesTemplates'));
  }),
  hasErrors: computed(
    'search',
    'zonesFiltered.[]',
    'zonesTemplatesFiltered.[]',
    function () {
      return (
        this.get('search.length') !== 0 &&
        isEmpty(this.get('zonesFiltered')) &&
        isEmpty(this.get('zonesTemplatesFiltered'))
      );
    }
  ),
  loadFromOrder(zones, type) {
    return new EmberPromise((resolve, reject) => {
      let { zoneId, zoneType } = this.get('order').getProperties(
        'zoneId',
        'zoneType'
      );
      if (
        isPresent(zoneId) &&
        isEmpty(this.get('selected')) &&
        zoneType === type
      ) {
        next(() => resolve(zones.findBy('id', zoneId)));
      } else {
        reject();
      }
    });
  },
  selectBoth(zone) {
    this.setProperties({ selected: zone, selectedTemp: zone });
  },
  loadData() {
    const { zoneId, zoneType } = this.get('model').getProperties(
      'zoneId',
      'zoneType'
    );
    hash({
      zones: this.get('store').findAll('zone'),
      zonesTemplates: this.get('store').findAll('zone-template')
    })
      .then(({ zones, zonesTemplates }) => {
        this.setProperties({ zones, zonesTemplates });
        if (zoneType === 'zone') {
          const zone = zones.findBy('id', zoneId);
          this.selectBoth(zone);
        } else if (zoneType === 'zone-template') {
          const zonesTemplate = zonesTemplates.findBy('id', zoneId);
          this.selectBoth(zonesTemplate);
        }
      })
      .finally(() => {
        trySet(this, 'zonesLoaded', true);
      });
  },
  didInsertElement() {
    this.loadData();
    this.set('selectedTemp', this.get('selected'));
  },
  actions: {
    confirm() {
      this.set('selected', this.get('selectedTemp'));
      if (this.selected && this.selected.constructor.modelName === 'zone') {
        this.selected.reload().then(() => {
          tryInvoke(this, 'close', [this.get('selected')]);
        });
        return;
      }

      tryInvoke(this, 'close', [this.get('selected')]);
    },
    searchChange() {
      this.set('listPage', 1);
    }
  }
});
