import Component from '@ember/component';
import Logger from 'domena-mobile/utils/logger';
import Regex from 'domena-mobile/utils/regex';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';
import domainParser from 'domena-mobile/utils/domain-parser';
import { resolve } from 'rsvp';
import { inject as service } from '@ember/service';
import { tryInvoke } from '@ember/utils';

const FORBIDDEN_DOMAINS = [
  'seznam.cz',
  'centrum.cz',
  'email.cz',
  'tiscali.cz',
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'facebook.com',
  'me.com',
  'icloud.com',
  'atlas.cz',
  'volny.cz'
];

export default Component.extend({
  currentUser: service(),
  intl: service(),
  store: service(),
  cart: service(),
  taskQueue: service(),
  tagName: 'form',
  classNames: ['new-email-form'],
  withFilter: true,
  domain: null,
  options: null,
  loadingText: '',
  page: 'selectPlan',
  selectedPlan: null,
  selectedPlanCode: null,
  emailName: '',
  domainName: '',
  domainNameSetFromDropdown: false,
  parsedDomain: null,
  emailAddress: computed({
    get() {
      const { emailName, domainName } = this.getProperties(
        'emailName',
        'domainName'
      );
      return emailName && domainName
        ? `${this.get('emailName')}@${this.get('domainName')}`
        : '';
    },
    set(key, value) {
      const domain = this.get('domain.fullName');
      if (domain) {
        value = `${value}@${domain}`;
      }
      const lastIndex = value.lastIndexOf('@');
      let parts = [value.substr(0, lastIndex), value.substr(lastIndex + 1)];

      if (domain) {
        this.set('emailName', parts[0]);
        this.set('domainName', domain);
      } else if (parts.length > 1) {
        this.set('emailName', parts[0]);
        this.set('domainName', parts[1]);
      } else {
        this.set('emailName', null);
        this.set('domainName', parts[0]);
      }
      return this.get('emailName');
    }
  }),
  disabled: computed(
    'emailName',
    'domainName',
    'selectedPlanCode',
    function () {
      const { emailName, domainName } = this.getProperties(
        'emailName',
        'domainName'
      );
      const emailValue = emailName ? `${emailName}@${domainName}` : domainName;
      return (
        !emailValue ||
        !this.get('selectedPlanCode') ||
        !Regex.EMAIL_DOMAIN_REGEX.test(emailValue)
      );
    }
  ),
  freeMailValidation: computed('domainName', function () {
    if (!this.domainName || this.domainName.length === 0) {
      return '';
    }
    const tlds = this.get('store').peekAll('tld');
    const parsedDomain = domainParser(this.domainName, tlds);
    return `${parsedDomain.domain}.${parsedDomain.tld}`;
  }),
  didInsertElement() {
    // TODO filtr options
    const options = this.get('options');
    this.setProperties(options);

    this._super(...arguments);
    const domain = this.get('domain.fullName');
    if (domain) {
      this.set('domainName', domain);
    }
    if (this.get('selectedPlanCode')) {
      this._setMailHostingPlan(this.get('selectedPlanCode'));
      this.set('page', 'setDomainName');
    } else {
      this.set('page', 'selectPlan');
    }
  },
  _setMailHostingPlan(plan) {
    const store = this.get('store');
    const tlds = store.peekAll('tld');
    const model = this.get('model');

    if (model) {
      this.set('domainName', model);
      const parsedDomain = domainParser(model, tlds);
      this.set('parsedDomain', parsedDomain);
      this._addEmail(parsedDomain);
    } else {
      this.set('page', 'setDomainName');
      let header = this.get('intl').t(`cms.produkty.${dasherize(plan)}.title`);
      header += ` ${this.intl.t('info.good_choice')}`;

      // nejde set properties
      this.set('options.header', header);
      this.set('options.isBig', false);
      tryInvoke(this, 'setVariant', [plan]);
    }
  },
  submit() {
    return false;
  },
  actions: {
    filter(name) {
      if (!this.withFilter) {
        return resolve([]);
      }
      return this.get('store').query('domain', {
        filter: { name_start: name },
        sort: 'name',
        page: { limit: 50 }
      });
    },
    setMailHostingPlan(planCode, plan) {
      this.set('selectedPlan', plan);
      this.set('selectedPlanCode', planCode);
      this._setMailHostingPlan(planCode);
    },
    addEmailToCheckout() {
      const parsedDomain = this.get('parsedDomain');
      this._addEmail(parsedDomain);
    },
    checkAvailability() {
      this.set('freeMailError', '');
      if (FORBIDDEN_DOMAINS.includes(this.get('freeMailValidation'))) {
        this.set('freeMailError', `${this.intl.t('free_mail.error')}`);
        return false;
      }

      const store = this.get('store');
      const tlds = store.peekAll('tld');
      const parsedDomain = domainParser(this.get('domainName'), tlds);
      if (this.get('domain')) {
        this._addEmail(parsedDomain, this.get('domain.id'));
        return;
      }
      this.set('parsedDomain', parsedDomain);

      if (this.get('domainNameSetFromDropdown')) {
        this._addEmail(parsedDomain);
        this.set('domainNameSetFromDropdown', false);
        return;
      }

      const domainCheck = store
        .peekAll('domain-check')
        .findBy('tldName', parsedDomain.tld);
      if (!domainCheck) {
        this._addEmail(parsedDomain);
        return;
      }
      this.set('loadingText', `${this.intl.t('free_mail.loading')}`);
      this.get('taskQueue')
        .enqueue(
          'domainCheckTask',
          domainCheck,
          `${parsedDomain.domain}.${parsedDomain.tld}`
        )
        .then(
          data => {
            this.set('loadingText', '');
            if (!data) return;
            if (data.get('available')) {
              this._addRegistrationEmail(parsedDomain);
            } else {
              this.set('page', 'domainUnavailable');
            }
          },
          e => {
            Logger.error(e);
          }
        )
        .finally(() => {
          this.set('error', null);
        });
      this.set('options.header', `${this.intl.t('free_mail.your_domain')}`);
    },
    findAnotherDomain() {
      this.set('domainName', null);
      this.set('page', 'setDomainName');
    },
    afterDomainNameFromDropdown() {
      this.set('domainNameSetFromDropdown', true);
    }
  },
  async _addEmail(parsedDomain, id) {
    if (parsedDomain) {
      const name = [...parsedDomain.subdomains, parsedDomain.domain].join('.');
      const extra = {
        mailHostingType: this.get('selectedPlanCode'),
        mailHostingName: name,
        tldName: parsedDomain.tld,
        emailName: this.get('emailName')
      };

      const data = {
        name: `${name}.${parsedDomain.tld}`,
        extra
      };

      if (!this.get('options.domainExists')) {
        data['parentId'] = id;
        data['parentType'] = 'registration';
      }

      try {
        await this.get('cart').addItem('email', data);
        tryInvoke(this, 'close', [this.get('selectedPlanCode')]);
      } catch (error) {
        Logger.error(error);
        if (error.name === 'InvalidItemsCombinationError') {
          this.set('hasErrors', {
            localeString: 'cart_items.cannot_add',
            invalidItems: error.invalidItems.map(item =>
              this.intl.t(`cart_items.with.${item}`)
            )
          });
        }
      }
    }
  },
  async _addRegistrationEmail(parsedDomain) {
    try {
      await this.get('cart')
        .addItem(
          'registration',
          {
            name: parsedDomain.domain,
            tld: parsedDomain.tld
          },
          false
        )
        .then(item => {
          this._addEmail(parsedDomain, item.id);
        });
    } catch (error) {
      Logger.error(error);
      if (error.name === 'InvalidItemsCombinationError') {
        this.set('hasErrors', {
          localeString: 'cart_items.cannot_add',
          invalidItems: error.invalidItems.map(item =>
            this.intl.t(`cart_items.with.${item}`)
          )
        });
      }
    }
  }
});
