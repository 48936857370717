import Common from '../mixins/common';
import Route from '@ember/routing/route';
import domainParser from 'domena-mobile/utils/domain-parser';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend(Common, {
  cart: service(),
  fastboot: service(),
  taskQueue: service(),
  queryParams: {
    domainFullName: {
      replace: true
    }
  },
  model(params) {
    if (!params.domainFullName || this.get('fastboot.isFastBoot')) {
      return;
    }

    const parsedDomain = domainParser(
      params.domainFullName,
      this.store.peekAll('tld')
    );
    const domainCheck = this.store
      .peekAll('domainCheck')
      .findBy('tld.name', parsedDomain.tld);

    if (!domainCheck) {
      return this.replaceWith('home');
    }
    return hash({
      domainCheck: this.get('taskQueue').enqueue(
        'domainCheckTask',
        domainCheck,
        `${parsedDomain.domain}.${parsedDomain.tld}`
      ),
      parsedDomain
    });
  },
  afterModel(model) {
    if (!model || this.get('fastboot.isFastBoot')) {
      return;
    }

    const parsedDomain = model.parsedDomain;

    if (model.domainCheck.get('available')) {
      this.get('cart')
        .addItem('registration', {
          name: parsedDomain.domain,
          tld: parsedDomain.tld
        })
        .then(() => {
          this.replaceWith('cart', {
            queryParams: { page: this.get('PAGE.CART') }
          });
        });
    } else {
      this.replaceWith('home', {
        queryParams: { name: `${parsedDomain.domain}.${parsedDomain.tld}` }
      });
    }
  }
});
