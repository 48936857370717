import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default Helper.extend({
  currentUser: service(),
  compute([action, name], { url }) {
    const sso = this.get('currentUser.ssoOrigin');
    if (sso) {
      const iv = window.encodeURIComponent(sso.iv),
        loginData = window.encodeURIComponent(sso.loginData),
        path = `domain/${action}/index.html?domain=${name}`;
      return `${sso.id}/${url || path}&iv=${iv}&login_data=${loginData}`;
    }
    return '';
  }
});
