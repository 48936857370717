import { hash } from 'rsvp';
import Route from '@ember/routing/route';

export default Route.extend({
  headTags: Object.freeze([
    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        href: 'https://www.domena.cz/email-k-domene'
      }
    }
  ]),
  model() {
    return hash({
      mailHostingPlans: this.store.peekAll('mailHostingPlan'),
      user: this.store.createRecord('user', { key: 'login_tmp' })
    });
  },
  resetController(controller) {
    controller.get('model.user').rollbackAttributes();
  },
  activate() {
    this.controllerFor('application').set('whiteBackground', true);
  },
  deactivate() {
    this.controllerFor('application').set('whiteBackground', false);
  }
});
