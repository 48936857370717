import Component from '@ember/component';
import { tryInvoke } from '@ember/utils';
import { trySet } from '@ember/object';

export default Component.extend({
  didInsertElement() {
    if (
      this.get('model.mailHostingObject.pricePer') === 'period' &&
      !this.get('selectedCapacity')
    ) {
      const capacity = this.get('model.availableCapacities.firstObject');
      this.set('selectedCapacity', capacity);
      tryInvoke(this, 'changed', [capacity]);
    }
  },
  actions: {
    setUsers(value) {
      trySet(this, 'selectedUsers', value);
      tryInvoke(this, 'changed', [this.selectedUsers]);
    },
    setCapacity(value) {
      trySet(this, 'selectedCapacity', value);
      tryInvoke(this, 'changed', [this.selectedCapacity]);
    }
  }
});
