import Service, { inject as service } from '@ember/service';

import Logger from '../utils/logger';
import { SERVICE_TYPES } from '../utils/service-config';
import domainParser from '../utils/domain-parser';
import config from '../config/environment';

const GTM_DEBUG = config.environment === 'development';

export default Service.extend({
  currentUser: service(),
  store: service(),
  cart: service(),

  _createProductParams(cartItem) {
    const attrs = cartItem.attrs();
    let packName = attrs.pack;
    const { tld: tldName, domain: domainName } = domainParser(attrs.name);

    if (['Registration'].includes(attrs.pack)) {
      packName = `${(attrs.pack || '').toLowerCase()} ${tldName}`;
    }

    const offers = (this.store.peekAll('offer') || []).filterBy('active');
    const haveDomainOffer =
      cartItem.type === SERVICE_TYPES.REGISTRATION &&
      Boolean(offers.findBy('tld', cartItem.tldName));

    const params = attrs.params || [];
    const userCount = params.findBy('name', 'COUNT');
    const diskSizeGB = params.findBy('name', 'DISK');
    let quantity = 1;

    return {
      item_name: packName,
      item_id: `${attrs.pack}_${domainName}.${tldName}`, //`${order.get('iDitem')}_wy_order`,
      price: cartItem.get('itemPrice'),
      item_category: attrs.type,
      item_variant:
        diskSizeGB && diskSizeGB.value
          ? `${attrs.period} months / ${parseInt(diskSizeGB.value, 10)}GB`
          : `${attrs.period} months`,
      quantity,
      dimension4: haveDomainOffer ? 'sleva' : '',
      licence_quantity: userCount ? userCount.value : 0
    };
  },

  dataLayer() {
    if (typeof window === 'undefined') {
      return [];
    }

    return window.dataLayer || [];
  },
  logModalOpen(modal) {
    if (GTM_DEBUG) {
      Logger.log('modalOpen', modal);
    }

    const _self = this;

    this.dataLayer().push({
      event: 'gaEvent',
      gaEventData: {
        eCat: 'Modal window',
        eAct: modal.name,
        eLab: 'Window open'
      },
      eventCallback() {
        _self.dataLayer().push({ gaEventData: undefined });
      }
    });
  },

  logModalClose(modal, isSubmitted) {
    if (GTM_DEBUG) {
      Logger.log('modalClose', modal, isSubmitted);
    }

    const _self = this;

    if (!isSubmitted) {
      this.dataLayer().push({
        event: 'gaEvent',
        gaEventData: {
          eCat: 'Modal window',
          eAct: modal.name,
          eLab: 'Window closed'
        },
        eventCallback() {
          _self.dataLayer().push({ gaEventData: undefined });
        }
      });
    } else {
      this.dataLayer().push({
        event: 'gaEvent',
        gaEventData: {
          eCat: 'Modal window',
          eAct: modal.name,
          eLab: 'Window confirmed'
        },
        eventCallback() {
          _self.dataLayer().push({ gaEventData: undefined });
        }
      });
    }
  },

  logSubmitForm(formName) {
    const _self = this;

    this.dataLayer().push({
      event: 'gaEvent',
      gaEventData: {
        eCat: 'Form',
        eAct: formName,
        eLab: 'Form sent'
      },
      eventCallback() {
        _self.dataLayer().push({ gaEventData: undefined });
      }
    });
  },

  logCartAdd(type, cartItem) {
    const currentUser = this.currentUser;
    if (GTM_DEBUG) {
      Logger.log('cartAdd', type, cartItem);
    }

    this.dataLayer().push({
      event: 'gaEeAdd',
      currencyCode: currentUser.get('currency'),
      items: [this._createProductParams(cartItem)]
    });
  },

  logCartRemove(type, cartItem) {
    const currentUser = this.currentUser;
    if (GTM_DEBUG) {
      Logger.log('cartRemove', type, cartItem);
    }

    this.dataLayer().push({
      event: 'gaEeRemove',
      currencyCode: currentUser.get('currency'),
      items: [this._createProductParams(cartItem)]
    });
  },

  logCartChange(step) {
    const currentUser = this.currentUser;
    if (GTM_DEBUG) {
      Logger.log('cartPageChange');
    }

    const items = this.cart.items || [];

    const products = items.map(cartItem => this._createProductParams(cartItem));

    this.dataLayer().push({
      event: 'gaEeCheckout',
      currencyCode: currentUser.get('currency'),
      items: products,
      step
    });
  },

  logYolaFreeEcommerce(yolaData) {
    const currentUser = this.currentUser;
    this.dataLayer().push({
      event: 'gaEePurchase',
      transaction_id: yolaData.userID,
      affiliation: 'Webglobe',
      coupon: '',
      currency: currentUser.get('currency'),
      value: 0,
      tax: 0,
      items: [
        {
          item_name: 'YOLA_FREE_SUB',
          item_id: `${yolaData.hash}_wy_order`,
          price: '0',
          item_category: 'extra',
          item_variant: '1 months',
          quantity: 1,
          dimension4: ''
        }
      ]
    });
  },

  logEcommerce(invoice, savedOrderItems, checkoutModel) {
    const currentUser = this.currentUser;
    const offers = (this.store.peekAll('offer') || []).filterBy('active');

    if (GTM_DEBUG) {
      Logger.log('logEcommerce', savedOrderItems, checkoutModel);
    }

    if (!savedOrderItems) return;

    let products = invoice.get('orders').map((order, index) => {
      // snad wy order zachova poradi items
      const savedOrder = (savedOrderItems || [])[index];
      if (!savedOrder) return;
      let packName = savedOrder.pack;
      const params = savedOrder.params || [];
      // let quantity = order.get('quantity');
      const userCount = params.findBy('name', 'COUNT');
      const diskSizeGB = params.findBy('name', 'DISK');

      const { tld: tldName } = domainParser(savedOrder.name);
      if (['Registration'].includes(savedOrder.pack)) {
        packName = `${(savedOrder.pack || '').toLowerCase()} ${tldName}`;
      }
      const haveDomainOffer = Boolean(offers.findBy('tld', tldName));
      return {
        item_name: packName,
        item_id: `${order.get('iDitem')}_wy_order`,
        price: (order.get('price') || '').toString(),
        item_category: savedOrder.type,
        item_variant:
          diskSizeGB && diskSizeGB.value
            ? `${savedOrder.period} months / ${parseInt(
                diskSizeGB.value,
                10
              )}GB`
            : `${savedOrder.period} months`,
        quantity: 1,
        dimension4: haveDomainOffer ? 'sleva' : '',
        licence_quantity: userCount ? userCount.value : 0
      };
    });

    this.dataLayer().push({
      event: 'gaEePurchase',
      transaction_id: invoice.get('id'),
      affiliation: 'Webglobe',
      coupon: checkoutModel.coupon || '',
      currency: currentUser.get('currency'),
      value: invoice.get('priceBase'),
      tax: invoice.get('priceVat'),
      items: products.filter(item => Boolean(item))
    });
  },

  logLoggedIn() {
    const userId = this.currentUser.get('user.profile.iDcustlogin');
    if (GTM_DEBUG) {
      Logger.log('loggedIn', userId);
    }
    this.dataLayer().push({ userType: 'logged in' });
    this.dataLayer().push({ userId });
  },

  logLoggedOut() {
    if (GTM_DEBUG) {
      Logger.log('loggedOut');
    }
    this.dataLayer().push({ userType: 'not logged in' });
  },

  logSignUp(data) {
    if (GTM_DEBUG) {
      Logger.log('signedUp', data);
    }

    const _self = this;

    this.dataLayer().push({
      event: 'gaEvent',
      gaEventData: {
        eCat: 'Registration',
        eAct: 'New user',
        eLab: 'Registration complete'
      },
      eventCallback() {
        _self.dataLayer().push({ gaEventData: undefined });
      }
    });
  }
});

/*
---------------------------------------------------------------
4.5.1. Tlačítka modálních oken
---------------------------------------------------------------
data-ga-type="event-cal"
data-ga-ec="ModalWindow"
data-ga-ea="Je táto doména vaša?"
data-ga-el="Button: Pokračovať do košíka"

---------------------------------------------------------------
4.5.2. Tlačítko Objednať
---------------------------------------------------------------
data-ga-type="event-cal"
data-ga-ec="Ecommerce"
data-ga-ea="Click: Buy product"
data-ga-el="WY Hosting"

*/
