import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';
import Common from '../mixins/common';

export function highlightText(params /* , hash*/) {
  if (!params[0]) {
    return null;
  }
  let common = Common.mixins[0].properties;
  let text = params[0].toString();
  let searched = common.stripAccent(
    params[1].toString().toLowerCase().replace(/[<>]/g, '')
  );
  let index = common.stripAccent(text.toLowerCase()).indexOf(searched);

  while (searched.length && index !== -1) {
    const slice = text.slice(0, index);
    let indexOpen = slice.lastIndexOf('<');
    let indexClose = slice.lastIndexOf('>');
    if (indexClose >= indexOpen) {
      return htmlSafe(
        text.substring(0, index) +
          '<span class="bold text-black">' +
          text.substring(index, index + searched.length) +
          '</span>' +
          text.substring(index + searched.length)
      );
    }
    index = common.stripAccent(text.toLowerCase()).indexOf(searched, index + 1);
  }
  return text;
}

export default helper(highlightText);
